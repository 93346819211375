
import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Row} from 'react-bootstrap';

import withNavigationDeviceProps from '../components/withNavigationDeviceProps';

import Title from '../../../components/Title';
import DevicePageLayout from '../components/DevicePageLayout';
import UserLink from '../../user/components/UserLink';
import ActivityList from '../../app/components/ActivityList';
import { FitMachineActivityCard } from '../components/DeviceCard';

import { fetchDeviceErrors } from '../actions';
import {
  getDevice,
} from '../selectors';

function DeviceActivity({
  deviceId,
  device = {},
  fetchDeviceErrors,
}) {

  // handle logs state (as we need to insert a unique identifier for each row)
  // note: when a unique identifier is available from the API we can drop this component state
  const [logs, setLogs] = useState([]);
  useEffect(() => {
    const logs = (device && device._embedded && device._embedded.fmerrors) || [];
    const uniquelyIdentifiedLogs = logs.map((log, index, { length }) => {
      return {
        error_time: log.error_time,
        error_code: log.error_code,
        error_name: log.error_name,
        error_string: log.error_string,
        orderedTimestamp: `${log.errorTime}-${`${index}`.padStart(10, '0')}`,
        reverse_order: length - index,
      };
    });
    setLogs(uniquelyIdentifiedLogs);
  }, [device && device._embedded && device._embedded.fmerrors]);

  const [loading, setLoading] = useState(true);

  // ensure that the refresh handler calls the specific device in question
  const getDeviceErrors = useCallback(async () => {
    if (!device.archived) {
      setLoading(true);
      await fetchDeviceErrors(device);
      setLoading(false);
    }
  }, [device.id, device.archived]);

  // when device is available (or changes), fetch the errors
  useEffect(() => {
    getDeviceErrors();
  }, [getDeviceErrors]);

  // return archived status message
  if (device.archived) {
    return (
      <Fragment>
        <p>
          Activity is not currently available for archived equipment.
        </p>
        <p>
          You can vote for this feature on the <Link to="/whats-new">What's New page</Link>.
        </p>
      </Fragment>
    );
  }

  return (
    <Row>
      <Col xs={12}>
        <ActivityList
          title={<Title title="Activity" loading={loading} />}
        >
          {logs.map(log => {
            return (
              <FitMachineActivityCard
                key={log.reverse_order}
                deviceId={deviceId}
                timestamp={log.error_time}
              >
                Error {log.error_code}{log.error_name && ` (${log.error_name})`}: {
                  log.error_string || 'Unknown'
                }
              </FitMachineActivityCard>
            );
          })}
          <FitMachineActivityCard deviceId={deviceId} timestamp={device.installation_date}>
            FitMachine onboarded {device.onboarded_by && (
              <Fragment>
                by <UserLink
                  userId={device.onboarded_by}
                  userName={device.onboarded_name}
                />
              </Fragment>
            )}
          </FitMachineActivityCard>
        </ActivityList>
      </Col>
    </Row>
  );
}

const mapStateToProps = (state, { deviceId }) => {
  return {
    device: getDevice(state, deviceId),
  };
};
const mapDispatchToProps = { fetchDeviceErrors };

const ConnectedDeviceActivity = withNavigationDeviceProps(
  connect(mapStateToProps, mapDispatchToProps)(DeviceActivity)
);

export default function DeviceActivityPage() {
  return (
    <DevicePageLayout>
      <div className="my-4">
        <ConnectedDeviceActivity />
      </div>
    </DevicePageLayout>
  );
}
