
import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Form, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import withNavigationDeviceProps from '../components/withNavigationDeviceProps';

import {
  fetchDeviceInfoThenImages,
  fetchDeviceImages,
  submitEquipmentDetails,
  getImageUploadS3FileUrl,
  deleteDeviceImage,
} from '../actions';

import Title from '../../../components/Title';
import NumberInput from '../../../components/form/NumberInput';
import LocationInput from '../../../components/form/LocationInput';
import SingleDatePicker, { dateFormat } from '../../../components/form/SingleDatePicker';
import ImageUpload from '../../../components/form/ImageUpload';
import NotFound from '../../../components/NotFound';
import LoadingSpinner from '../../../components/LoadingSpinner';
import EquipmentPageLayout from '../components/EquipmentPageLayout';
import RelearnModalForm from '../components/RelearnModalForm';
import { getRunningStatusText } from '../components/RunningStatus';

import {
  Units as PowerUnits,
  getUserDisplayPreference as getUserPowerDisplayPreference,
} from '../../../components/values/Power';

import {
  Units as RmsUnits,
  getUserDisplayPreference as getUserRmsDisplayPreference,
} from '../../../components/values/Rms';

import { getFormValues, isFloatEqual } from '../../../lib/utils';
import { getDevice } from '../selectors';

class EditEquipment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetchingDevice: true,
      submit: {},
      running_cutoff: "",
      power_rating: "",
    };
    this.form = undefined;
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    const { fetchDeviceInfoThenImages, deviceToEditId } = this.props;
    this.setState({ fetchingDevice: true });
    try {
      await fetchDeviceInfoThenImages({ id: deviceToEditId });
    }
    catch(e) {
      // user probably doesn't have access to this device
    }
    this.setState({ fetchingDevice: false });

    // set mew value
    this.maybeSetNewValues();
  }

  async componentDidUpdate(prevProps) {
    // reset running cut off if the page has changed to another device
    const { deviceToEdit: prevDevice={} } = prevProps;
    const { deviceToEdit: nextDevice={} } = this.props;
    if (prevDevice.id !== nextDevice.id) {
      this.maybeSetNewValues();
    }
  }

  maybeSetNewValues = () => {
    const { deviceToEdit, displayRms, displayPower } = this.props;
    if (deviceToEdit && deviceToEdit.id) {
      // convert units for display
      // we convert even for kW, in case the value was given in Hp and we need to truncate the digits
      const runningCutoff = displayRms.convert(deviceToEdit.running_cutoff);
      const powerRating = displayPower.convert(parseFloat(deviceToEdit.power_rating));

      this.setState({
        running_cutoff: !isNaN(runningCutoff)
          ? runningCutoff
          : deviceToEdit.running_cutoff || '', // or display what was passed or nothing
        power_rating: !isNaN(powerRating)
          ? powerRating
          : deviceToEdit.power_rating || '', // or display what was passed or nothing
      });
    }
  }

  handleSubmit(e, additionalFields={}) {
    e.preventDefault();
    const formValues = getFormValues(e, this.form);

    const { displayPower, displayRms } = this.props;

    // convert power rating back to platform units
    const powerRating = parseFloat(formValues.equipment_power_rating);
    formValues.equipment_power_rating = !isNaN(powerRating)
      ? displayPower.convertBack(powerRating)
      : formValues.equipment_power_rating;

    // convert cutoff back to platform units
    const runningCutoff = parseFloat(formValues.rms_running_cutoff);
    formValues.rms_running_cutoff = !isNaN(runningCutoff)
      ? displayRms.convertBack(runningCutoff)
      : formValues.rms_running_cutoff;

    const { fetchDeviceInfoThenImages, submitEquipmentDetails, deviceToEdit } = this.props;
    const equipmentDetails = {
      ...formValues,
      // override formValues with custom mods
      fitmachine_serial: undefined,
      equipment_running: undefined,
      fitmachine_install_date: formValues.fitmachine_install_date
        // todo: the API currently only store date and *not* time information
        // to keep the user experience consistent, the date should be set in UTC
        // as it is read as UTC, ie. the date comes back with a T00:00:00+00:00 format
        ? moment.utc(formValues.fitmachine_install_date, dateFormat).toISOString()
        : undefined,
      // do not include the power rating if it hasn't changed
      // (it may have be seen in other units, and this will cause slight conversion errors)
      equipment_power_rating: !isFloatEqual(formValues.equipment_power_rating, deviceToEdit.power_rating)
        // check if the value is actually a number and not 0
        ? !isNaN(parseFloat(formValues.equipment_power_rating || ''))
          // pass real number as a string
          ? `${formValues.equipment_power_rating}`
          // or empty string to empty the value
          : `${formValues.equipment_power_rating}`.trim() === ''
            ? ''
            // or something to be rejected (with an error toast)
            : null
        : undefined,
      // do not include the running cutoff if it hasn't changed
      rms_running_cutoff: !isFloatEqual(formValues.rms_running_cutoff, deviceToEdit.running_cutoff)
        // check if the value is actually a number and not 0
        ? !isNaN(parseFloat(formValues.rms_running_cutoff || ''))
          // pass real number
          ? parseFloat(formValues.rms_running_cutoff)
          // or pass string to be rejected (with an error toast)
          : `${formValues.rms_running_cutoff}`.trim()
        : undefined,
      // add id
      id: deviceToEdit.id,
      ...additionalFields,
    };

    const submittedAt = Date.now();
    this.setState({ submit: { submittedAt } });

    // submit form then handle feedback
    submitEquipmentDetails(equipmentDetails)
      .then(() => {
      // if still displaying the same submission then update with success
        this.setState(({ submit }) => {
          if (submit.submittedAt === submittedAt) {
            return { submit: { succeededAt: new Date() } };
          }
        });
        // refetch device info
        fetchDeviceInfoThenImages(deviceToEdit);
      })
      .catch((error) => {
      // if still displaying the same submission then update with failure
        this.setState(({ submit }) => {
          if (submit.submittedAt === submittedAt) {
            return { submit: { error: error.message || 'Error' } };
          }
        });
      });
  }


  render() {
    const {
      deviceToEdit,
      fetchDeviceImages,
      deleteDeviceImage,
      displayRms,
    } = this.props;
    const { fetchingDevice, submit } = this.state;

    // clone device
    const deviceObject = { ...deviceToEdit };

    if (!deviceObject.id && !fetchingDevice) {
      return <NotFound />;
    }

    const title = (
      <Title
        title={`Editing Equipment${
          deviceObject.equipment_name ? `: ${deviceObject.equipment_name}` : ''
        }`}
        loading={submit.submittedAt}
        lastFetch={submit.succeededAt}
        error={submit.error}
      />
    );

    if (!deviceObject.id) {
      return (
        <Container>
          {title}
          <LoadingSpinner />
        </Container>
      );
    }

    if (deviceObject && deviceObject.archived) {
      return (
        <Container>
          {title}
          <p>
            Editing equipment is not currently available for archived devices.
          </p>
          <p>
            Contact MOVUS Support to unarchive devices.
          </p>
        </Container>
      );
    }

    // check if the cutoff value has changed within a single floating point accuracy
    const cutoffChanged = !isFloatEqual(
      displayRms.convertBack(parseFloat(this.state.running_cutoff)),
      deviceObject.running_cutoff
    );
    return (
      <Container style={{marginBottom: "50px"}}>
        <Title
          title={`Editing Equipment${
            deviceObject.equipment_name ? `: ${deviceObject.equipment_name}` : ''
          }`}
          loading={submit.submittedAt}
          lastFetch={submit.succeededAt}
          error={submit.error}
        />
        <Form ref={el => this.form = el}>
          <Title title="Site Details" />
          <Row>
            <Col xs={12}>
              <Form.Group as={Row} controlId="siteName">
                <Form.Label column sm="2">
                  Site name
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    key={`site_name-${deviceObject.site_name}`}
                    type="text"
                    name="site_name"
                    defaultValue={deviceObject.site_name}
                    onChange={this.handleChange}
                  />
                  <Form.Text id="organisation_name_help" className="text-muted">
                    This should describe the overall area in enough detail to be understood by colleagues.
                    <br/>
                    The official company site name should be used. Example: "Luggage Point STP"
                  </Form.Text>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="subareaName">
                <Form.Label column sm="2">
                  Sub-area name
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    key={`sub_area_name-${deviceObject.sub_area_name}`}
                    type="text"
                    name="site_subarea_name"
                    defaultValue={deviceObject.sub_area_name} onChange={this.handleChange}
                  />
                  <Form.Text id="organisation_name_help" className="text-muted">
                    This is the name of the area within the site. Example: "Blower Room"
                  </Form.Text>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="fitmachine_install_date">
                <Form.Label column sm="2">
                  Installation date
                </Form.Label>
                <Col sm="10">
                  <SingleDatePicker
                    key={`installation_date-${deviceObject.installation_date}`}
                    date={deviceObject.installation_date}
                    isOutsideRange={() => false}
                    showDefaultInputIcon={true}
                    id="fitmachine_install_date" // PropTypes.string.isRequired,
                    placeholder="install date"
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="Note">
                <Form.Label column sm="2">
                  Note
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    as="textarea"
                    key={`note-${deviceObject.note}`}
                    name="note"
                    defaultValue={deviceObject.note}
                  />
                  <Form.Text id="note_help" className="text-muted">
                    Enter any other information concerning the site details.
                  </Form.Text>
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Title title="Equipment Details" />
          <Row>
            <Col xs={12}>
              <Form.Group as={Row} controlId="isoClass">
                <Form.Label column sm="2">
                  ISO class
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    as="select"
                    key={`iso_class-${deviceObject.iso_class}`}
                    name="equipment_iso_class"
                    defaultValue={deviceObject.iso_class}
                  >
                    { ["Class I", "Class II", "Class III", "Class IV"].map((e, i) => <option key={i} value={e}>{e}</option>) }
                  </Form.Control>
                  <Form.Text id="iso_class_help" className="text-muted">
                     Please refer above Machine Groups table to determine your machine ISO class.
                  </Form.Text>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="equipmentName">
                <Form.Label column sm="2">
                  Equipment name
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    key={`equipment_name-${deviceObject.equipment_name}`}
                    type="text"
                    name="equipment_name"
                    defaultValue={deviceObject.equipment_name}
                  />
                  <Form.Text id="equipment_name_help" className="text-muted">
                    Use the equipment name as indicated on the local start/stop panel
                    or as indicated in the equipment list. Example: "Asset tag name"
                  </Form.Text>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="equipmentNumber">
                <Form.Label column sm="2">
                  Equipment number
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    key={`equipment_number-${deviceObject.equipment_number}`}
                    type="text"
                    name="equipment_number"
                    defaultValue={deviceObject.equipment_number}
                  />
                  <Form.Text id="equipment_number_help" className="text-muted">
                    Use the equipment number as indicated on the local start/stop panel
                    or as indicated on the equipment list. Example: "Asset tag number"
                  </Form.Text>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="equipmentBrand">
                <Form.Label column sm="2">
                  Equipment brand
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    key={`equipment_brand-${deviceObject.equipment_brand}`}
                    type="text"
                    name="equipment_brand"
                    defaultValue={deviceObject.equipment_brand}
                  />
                  <Form.Text id="equipment_brand_help" className="text-muted">
                    As indicated on equipment nameplate
                  </Form.Text>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="equipmentModel">
                <Form.Label column sm="2">
                  Equipment model/number
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    key={`equipment_model-${deviceObject.equipment_model}`}
                    type="text"
                    name="equipment_model"
                    defaultValue={deviceObject.equipment_model}
                  />
                  <Form.Text id="equipment_model_help" className="text-muted">
                    As indicated on equipment nameplate
                  </Form.Text>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="equipmentCapacity">
                <Form.Label column sm="2">
                  Equipment capacity
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    key={`equipment_capacity-${deviceObject.equipment_capacity}`}
                    type="text"
                    name="equipment_capacity"
                    defaultValue={deviceObject.equipment_capacity}
                  />
                  <Form.Text id="equipment_capacity_help" className="text-muted">
                    As indicated on equipment nameplate. Example: flow rate in m<sup>3</sup>/s, total mass in kg
                  </Form.Text>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="equipmentType">
                <Form.Label column sm="2">
                  Equipment type
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    key={`equipment_type-${deviceObject.equipment_type}`}
                    type="text"
                    name="equipment_type"
                    defaultValue={deviceObject.equipment_type}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="powerRating">
                <Form.Label column sm="2">
                  Power rating (<PowerUnits />)
                </Form.Label>
                <Col sm="10">
                  <NumberInput
                    name="equipment_power_rating"
                    min={0}
                    value={this.state.power_rating}
                    onChange={e => {
                      this.setState({
                        power_rating: e.target.value,
                      });
                    }}
                  />
                  <Form.Text id="power_rating_help" className="text-muted">
                    As indicated on motor nameplate
                  </Form.Text>
                </Col>
              </Form.Group>
              { deviceObject.max_rpm !== false &&
                <Form.Group as={Row} controlId="equipmentMaxRpm">
                  <Form.Label column sm="2">
                    Max. RPM
                  </Form.Label>
                  <Col sm="10">
                    <NumberInput
                      key={`max_rpm-${deviceObject.max_rpm}`}
                      name="equipment_max_rpm"
                      min={0}
                      defaultValue={deviceObject.max_rpm}
                    />
                  </Col>
                </Form.Group>
              }
              <Form.Group as={Row} controlId="confinedSpace">
                <Form.Label column sm="2">
                  Confined space
                </Form.Label>
                <Col sm="10">
                  <Form.Check
                    key={`confined_space-${deviceObject.confined_space}`}
                    type="checkbox"
                    aria-label="Confined space"
                    name="confined_space"
                    defaultChecked={deviceObject.confined_space}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="variableSpeedDrive">
                <Form.Label column sm="2">
                  Variable speed drive
                </Form.Label>
                <Col sm="10">
                  <Form.Check
                    key={`variable_speed_drive-${deviceObject.variable_speed_drive}`}
                    type="checkbox"
                    aria-label="Variable speed drive"
                    name="variable_speed_drive"
                    defaultChecked={deviceObject.variable_speed_drive}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="runningCondition">
                <Form.Label column sm="2">
                  Running condition
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    key={`running-${deviceObject.running}`}
                    type="text"
                    name="equipment_running"
                    defaultValue={getRunningStatusText(deviceObject.running)}
                    disabled
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="runningCutoff">
                <Form.Label column sm="2">
                  Running cutoff ({<RmsUnits />})
                </Form.Label>
                <Col sm="10">
                  <NumberInput
                    name="rms_running_cutoff"
                    min={0}
                    value={this.state.running_cutoff}
                    onChange={e => {
                      this.setState({
                        running_cutoff: e.target.value,
                      });
                    }}
                  />
                  <Form.Text id="running_cutoff_help" className="text-muted">
                    Vibration (RMS) cutoff to determine running status
                  </Form.Text>
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Title title="FitMachine Details" />
          <Row>
            <Col xs={12}>
              <Form.Group as={Row} controlId="macaddress">
                <Form.Label column sm="2">
                  MAC address
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    key={`running-${deviceObject.serial}`}
                    type="text"
                    name="fitmachine_serial"
                    defaultValue={deviceObject.serial}
                    disabled
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="2">
                  Location
                </Form.Label>
                <Col sm="10">
                  <LocationInput
                    latitude={deviceObject.latitude}
                    longitude={deviceObject.longitude}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Title title="Equipment Images"/>
          {[
            {
              name: 'overall_images',
              title: 'Overall image',
              description: 'An overall image of the equipment that shows its position in the area.',
            },
            {
              name: 'fitmachine_images',
              title: 'FitMachine image',
              description: 'A close-up image of the FitMachine showing its location on the piece of equipment and the QR code.',
            },
            {
              name: 'local_nameplate_image',
              title: 'Equipment nameplate image',
              description: 'A close-up image of the equipment nameplate being monitored by the FitMachine (eg. if it’s on a pump bearing, the pump).',
            },
            {
              name: 'motor_nameplate_image',
              title: 'Motor nameplate image',
              description: 'A close-up image of the nameplate of the motor powering that piece of equipment, if required.',
            },
            {
              name: 'gateway_image', // ruby dash uses 'gateway_image'
              aliases: ['gateway_images'], // react dash used 'gateway_images' until this commit
              title: 'Gateway location image',
              description: 'A general view of the Gateway location.',
            },
          ].map(({ name, aliases=[], title, description }) => {
            const names = [name, ...aliases];
            return (
              <Row key={name}>
                <Col xs={12}>
                  <Form.Group as={Row} controlId={name}>
                    <Form.Label column sm="2">
                      {title}
                    </Form.Label>
                    <Col sm={10}>
                      <div className="col-form-label">
                        <Form.Text id={`${name}_help`} className="text-muted">
                          {description}
                        </Form.Text>
                      </div>
                      <ImageUpload
                        multiple
                        accept="image/*;capture=camera"
                        images={
                          deviceObject.images && [...deviceObject.images]
                            .filter(image => names.includes(image.name))
                            .map(({ id, url='' }) => {
                              return {
                                id,
                                src: url,
                                alt: url.split('/').pop(), // get filename from url
                              };
                            })
                        }
                        onDelete={image => deleteDeviceImage(deviceObject, image)}
                        getUploadS3FileUrlFromFile={async file => (
                          this.props.getImageUploadS3FileUrl(deviceObject, {
                            name,
                            file_name: file.name,
                          })
                        )}
                        onSuccess={() => fetchDeviceImages(deviceObject)}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            );
          })}
          <Row>
            <Col xs={12}>
              {cutoffChanged && this.state.running_cutoff > 0 ? (
                <RelearnModalForm
                  deviceId={deviceObject.id}
                  showPreviousValue={true}
                  recalibrate={(device, { fitmachine_onboard_date }, e) => {
                    // submit without DOM form validation
                    this.handleSubmit(e, {
                      fitmachine_onboard_date,
                      recalibrate: true,
                    });
                  }}
                  header="Are you sure you want to restart learning?"
                  body={(
                    <Fragment>
                      <p>
                        Changing the running cutoff requires the device to relearn.
                        {' '}
                        <OverlayTrigger
                          placement="auto"
                          overlay={(
                            <Tooltip>
                              The running status of historical data may have changed.
                            </Tooltip>
                          )}
                        >
                          <Button variant="light" size="sm">
                            Why?
                          </Button>
                        </OverlayTrigger>
                      </p>
                      <p>
                        By default, MachineCloud will start learning from the same date &amp; time
                        this device used previously.
                        Learning may take some time, but alarms can still be raised.
                        Historical equipment data will remain available after learning completes.
                      </p>
                    </Fragment>
                  )}
                  confirmText="Update and restart learning"
                >
                  <Button variant="success" size="lg" className="float-right mb-chat-widget" type="button">
                    Update
                  </Button>
                </RelearnModalForm>
              ) : (
                <Button variant="success" size="lg" className="float-right mb-chat-widget" type="button"
                  onClick={this.handleSubmit} // submit without DOM form validation
                >
                  Update
                </Button>
              )}
            </Col>
          </Row>
        </Form>
      </Container>
    );
  }

}

const mapStateToProps = (state, { deviceId }) => {
  return {
    deviceToEditId: deviceId,
    deviceToEdit: getDevice(state, deviceId),
    displayPower: getUserPowerDisplayPreference(state),
    displayRms: getUserRmsDisplayPreference(state),
  };
};

const mapDispatchToProps = {
  fetchDeviceInfoThenImages,
  fetchDeviceImages,
  deleteDeviceImage,
  submitEquipmentDetails,
  getImageUploadS3FileUrl,
};

const ConnectedEditEquipment = withNavigationDeviceProps(
  connect(mapStateToProps, mapDispatchToProps)(EditEquipment)
);

export default function EditEquipmentPage() {
  return (
    <EquipmentPageLayout>
      <div className="my-4">
        <ConnectedEditEquipment />
      </div>
    </EquipmentPageLayout>
  );
}
