
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, Col, Row, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Icon } from '@blueprintjs/core';

import Bar from '../../../components/Bar';
import VolumeImage from '../../alarm/VolumeImage';
import ToneImage from '../../alarm/ToneImage';
import TemperatureImage from '../../alarm/TemperatureImage';
import AlarmFFTModal from '../../alarm/components/AlarmFFTModal';
import AlarmSoundButton from '../../alarm/components/AlarmSoundButton';
import { FitMachineActivityCard } from '../components/DeviceCard';

import { getAlarm, getAlarmDevice } from '../../alarm/selectors';

import './deviceAlarmCard.scss';

function FitMachineAlarmCard({ alarm={}, device={} }) {
  return (
    <FitMachineActivityCard
      deviceId={alarm.device_id}
      timestamp={alarm.alarm_timestamp}
      className="fitmachine-alarm-card"
    >
      <Card.Text as="div">
        <Row>
          {alarm.alarm_type === 'red' && (
            // red alarm
            <Col xs="auto" className="flow-col-md flex-grow-1 mb-3">
              <p className="mb-1">
                FitMachine has detected that <Link to={`/equipment/${alarm.device_id}`}>
                  {device.equipment_name}
                </Link> is showing a major deviation from its normal operating behaviour.
                We recommend you review the details of this deviation on the MachineCloud
                and inspect the equipment.
              </p>
            </Col>
          )}
          {alarm.alarm_type === 'yellow' && (
            // yellow alarm
            <Col xs="auto" className="flow-col-md flex-grow-1 mb-3">
              <p className="mb-1">
                FitMachine has detected that <Link to={`/equipment/${alarm.device_id}`}>
                  {device.equipment_name}
                </Link> is showing a minor deviation from its normal operating behaviour.
                We recommend you review the details of this deviation on the MachineCloud.
              </p>
            </Col>
          )}
          <Col xs="auto" className="flow-col-lg flex-grow-1">
            <Row>
              <Col xs="auto" className="flow-col-xs flex-grow-1 mb-3">
                <p className="title mb-0">Condition</p>
                <div className="mt-2 mb-1">
                  <Bar conditionValue={alarm.condition_overall} />
                </div>
              </Col>
              <Col xs="auto" className="flow-col-sm flex-grow-1">
                <Row className="small-gutters">
                  <Col xs="auto" className="flow-col-xxs mb-3">
                    <p className="title mb-0">AI Vol</p>
                    <div className="mb-1">
                      <VolumeImage rms_value={alarm.rms} isoClass={alarm.equipment_iso_class} />
                    </div>
                  </Col>
                  <Col xs="auto" className="flow-col-xxs mb-3">
                    <p className="title mb-0">AI Tone</p>
                    <div className="mb-1">
                      <ToneImage vibration_condition={alarm.condition_vibration} />
                    </div>
                  </Col>
                  <Col xs="auto" className="flow-col-xxs mb-3">
                    <p className="title mb-0">AI Temp</p>
                    <div className="mb-1">
                      <TemperatureImage temperature_condition={alarm.condition_temperature} />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="small-gutters">
          <Col xs="auto" className="mb-1">
            <OverlayTrigger
              placement="bottom"
              overlay={(
                <Tooltip>View the FFT for this alarm</Tooltip>
              )}
            >
              {triggerProps => (
                // set overlay trigger on button not entire modal
                <AlarmFFTModal alarmId={alarm.id}>
                  <Button
                    {...triggerProps}
                    variant="outline-secondary"
                    className="mb-0"
                  >
                    <Icon
                      iconSize="1.2em"
                      icon="timeline-area-chart"
                      title="FFT"
                    /> <span>FFT</span>
                  </Button>
                </AlarmFFTModal>
              )}
            </OverlayTrigger>
          </Col>
          <Col xs="auto" className="mb-1">
            <OverlayTrigger
              placement="bottom"
              overlay={(
                <Tooltip>Play an audio recreation of this alarm</Tooltip>
              )}
            >
              <AlarmSoundButton
                variant="outline-secondary"
                alarmId={alarm.id}
                soundType="alarm"
                className="mb-0"
              >
                <span>Alarm</span>
              </AlarmSoundButton>
            </OverlayTrigger>
          </Col>
          <Col xs="auto" className="mb-1">
            <OverlayTrigger
              placement="bottom"
              overlay={(
                <Tooltip>Play an audio recreation of this equipment's learning period</Tooltip>
              )}
            >
              <AlarmSoundButton
                variant="outline-secondary"
                alarmId={alarm.id}
                soundType="baseline"
                className="mb-0"
              >
                <span>Learning period</span>
              </AlarmSoundButton>
            </OverlayTrigger>
          </Col>
        </Row>
      </Card.Text>
    </FitMachineActivityCard>
  );
}

const mapStateToProps = (state, { alarmId }) => {
  return {
    device: getAlarmDevice(state, alarmId),
    alarm: getAlarm(state, alarmId),
  };
};

export default connect(mapStateToProps)(FitMachineAlarmCard);
