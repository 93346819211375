
import {
  isTopLevelGroupActive,
  getGroupDevices,
  getActiveGroupId,
  getGroupDevicesAvailable,
  getActiveGroupLoadingState,
} from "../organisation/selectors";

import { getPlaceholderImageForDevice } from './utils';

function getDevicesById(state) {
  return state && state.device && state.device.cloudDevices;
}

function getActiveDeviceIds(state) {
  return state && state.device && state.device.idsActive;
}

function getArchivedDeviceIds(state) {
  return state && state.device && state.device.idsArchived;
}

// basic selector cache
// todo: remove when cloudDevices is refactored and this is no longer of use
const devicesCache = {
  idsActive: [],
  idsArchived: [],
  devicesById: {},
  // devices is the cached representation of combination of ids and devicesById
  // ie: ids.map(id => devicesById[id])
  devices: [],
};
export function getDevices(state, { archived=false, forOrg=false }={}) {
  // return group devices
  if (!forOrg && !isTopLevelGroupActive(state)) {
    return getGroupDevices(state, getActiveGroupId(state));
  }
  // return organisation devices
  const devicesById = getDevicesById(state);
  // devices are available
  if (devicesById) {
    const idsActive = getActiveDeviceIds(state) || [];
    const idsArchived = getArchivedDeviceIds(state) || [];
    // return cached array to reduce react render work by passing shallow comparison when appropriate
    if (
      (devicesCache.devicesById !== devicesById) ||
      (devicesCache.idsActive !== idsActive) ||
      (devicesCache.idsArchived !== idsArchived)
    ) {
      // else get the new devices array
      // ensure only found devices are returned
      // devices shouldn't be removed form the state (although they may
      // become stale) but we should check here again anyway just in case
      const devicesActive = idsActive.map(id => devicesById[id]).filter(Boolean);
      const devicesArchived = idsArchived.map(id => devicesById[id]).filter(Boolean);
      // and restock the devices cache
      devicesCache.idsActive = idsActive;
      devicesCache.idsArchived = idsArchived;
      devicesCache.devicesById = devicesById;
      devicesCache.devicesActive = devicesActive;
      devicesCache.devicesArchived = devicesArchived;
    }
    // return asked for list
    return !archived ? devicesCache.devicesActive : devicesCache.devicesArchived;
  }
}

export function getArchivedDevices(state) {
  return getDevices(state, { archived: true });
}

export function hasAvailableDevices(state) {
  // get available devices of subgroup or org group
  const availableDevices = !isTopLevelGroupActive(state)
    ? getGroupDevicesAvailable(state, getActiveGroupId(state))
    : getDevices();
  return !!(availableDevices && availableDevices.length > 0);
}

export function getDeviceListState(state) {
  if (!isTopLevelGroupActive(state)) {
    return getActiveGroupLoadingState(state) || {};
  };
  const { device: { loading, lastFetch, error }={} } = state;
  return { loading, lastFetch, error };
}

export function getDevice(state, deviceId) {
  // don't look up getDevicesById directly, as the user may be in a group
  // without access to the device in question

  // restrict access to devices when inside a group
  if (!isTopLevelGroupActive(state)) {
    const devices = [...getDevices(state) || [], ...getArchivedDevices(state) || []];
    const foundDevice = devices && devices.find(device => device.id === deviceId);
    if (!foundDevice) {
      return undefined;
    }
  };

  // return root-level device
  const devicesById = getDevicesById(state);
  return devicesById[deviceId];
}

export function getDeviceHasTimeseriesData(state, deviceId) {
  const device = getDevice(state, deviceId) || {};
  const [versionMajorMinor] = `${device.fitmachine_type}`.match(/\d+\.\d+/) || [];
  // if version is known and more than v3
  // we assume the device hase timeseries data
  // function may return `true`, `false`, or `undefined` (for no version found)
  return versionMajorMinor && Number(versionMajorMinor) >= 3;
}

export function getDevicePlaceholderImage(state, deviceId) {
  return getPlaceholderImageForDevice(getDevice(state, deviceId));
}

export function getDeviceTimezone(state, deviceId) {
  const device = getDevice(state, deviceId);
  return device && device._embedded && device._embedded.overviewMetadata && device._embedded.overviewMetadata.timezone;
};

export function getDeviceOverview(state, deviceId) {
  const device = getDevice(state, deviceId);
  return device && device._embedded && device._embedded.overview;
}

export function getDeviceRuntime(state, deviceId) {
  const device = getDevice(state, deviceId);
  return device && device._embedded && device._embedded.runtime;
}

export function getDeviceRuntimeTimezone(state, deviceId) {
  const device = getDevice(state, deviceId);
  return device &&
    device._embedded &&
    device._embedded.runtimeMetadata &&
    device._embedded.runtimeMetadata.timezone;
}

export function getDeviceEvents(state, deviceId) {
  const device = getDevice(state, deviceId);
  return device && device._embedded && device._embedded.events;
}

export function getDeviceAlarms(state, deviceId) {
  const device = getDevice(state, deviceId);
  return device && device._embedded && device._embedded.alarms;
}

export function getDeviceAlarmListState(state, deviceId) {
  const device = getDevice(state, deviceId);
  return device && device._embedded && device._embedded.alarms_state;
}

export function getFirstSampleDateFromDevice(device={}) {
  // convert everything to epochs
  // the mapDeviceFromApi function return moments
  const fitmachine_install_date = !!device.installation_date && device.installation_date.valueOf();
  const fitmachine_onboard_date = !!device.calibration_start && device.calibration_start.valueOf();
  // calculate a best guess for a devices first sample date
  return fitmachine_onboard_date && fitmachine_install_date
    ? new Date(
      Math.min(
        new Date(fitmachine_onboard_date).valueOf(),
        new Date(fitmachine_install_date).valueOf()
      )
    )
    : fitmachine_onboard_date || fitmachine_install_date
      ? new Date(fitmachine_onboard_date || fitmachine_install_date)
      : new Date('2015-01-01'); // MOVUS founded year
}
