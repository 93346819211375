import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { fetchUser, fetchUserPreferences } from '../../modules/user/actions';
import { fetchOrganisations, fetchOrganisationPreferences, fetchOrganisationWithId } from '../../modules/organisation/actions';

import { getOrganisation, getOrganisationState, getOrganisationTags } from '../../modules/organisation/selectors';
import { getUser, getUserTags, getUserToken, isUserLoggedIn } from '../../modules/user/selectors';

function AuthProvider({
  children,
  userToken,
  userIsLoggedIn,
  user = {},
  userTags,
  organisation,
  organisationState = {},
  organisationTags,
  fetchUser,
  fetchOrganisations,
  fetchOrganisationWithId,
  fetchUserPreferences,
  fetchOrganisationPreferences,
  onLogout,
  onLogin,
}) {

  const userId = user && user.id;
  const hasToken = !!userToken;

  /*
   * Begin UserLogin / AppLoad logic to populate redux state
   * when complete, the isUserLoggedIn selector --> userIsLoggedIn prop should be true
   */

  // if the token changes then reset the fetching state of everything here
  useEffect(() => {
    setFetchingUser(false);
    setFetchingUserTags(false);
    setFetchingOrganisation(false);
    setFetchingOrganisationInfo(false);
    setFetchingOrganisationTags(false);
  }, [userToken]);

  // ensure user exists
  const [fetchingUser, setFetchingUser] = useState(false);
  useEffect(() => {
    if (hasToken && !userId && !fetchingUser) {
      setFetchingUser(true);
      fetchUser();
    }
    else if (userId) {
      setFetchingUser(false);
    }
  }, [hasToken, userId, fetchingUser, fetchUser]);

  // ensure user tags exist
  const [fetchingUserTags, setFetchingUserTags] = useState(false);
  useEffect(() => {
    if (hasToken && userId && !userTags && !fetchingUserTags) {
      setFetchingUserTags(true);
      fetchUserPreferences(user);
    }
    else if (userTags) {
      setFetchingUserTags(false);
    }
  }, [hasToken, user, userId, userTags, fetchingUserTags, fetchUserPreferences]);

  // ensure organisation exists
  const [fetchingOrganisation, setFetchingOrganisation] = useState(false);
  useEffect(() => {
    if (hasToken && userId && !organisation && !fetchingOrganisation) {
      setFetchingOrganisation(true);
      fetchOrganisations();
    }
    else if (organisation) {
      setFetchingOrganisation(false);
    }
  }, [hasToken, userId, organisation, fetchingOrganisation, setFetchingOrganisation]);

  // ensure all organisation details exist
  // (the organisation property `product_code` is only available on GET/organisation/[id])
  const [fetchingOrganisationInfo, setFetchingOrganisationInfo] = useState(false);
  const lastOrganisationFetch = organisationState.lastFetch;
  useEffect(() => {
    // if last fetch was longer than an hour ago
    const hasRecentlyBeenFetched = (lastOrganisationFetch || 0) > (Date.now() - 1000*60*60);
    if (hasToken && organisation && !hasRecentlyBeenFetched && !fetchingOrganisationInfo) {
      setFetchingOrganisationInfo(true);
      fetchOrganisationWithId(organisation);
    }
    else if (hasRecentlyBeenFetched) {
      setFetchingOrganisationInfo(false);
    }
  }, [hasToken, organisation, lastOrganisationFetch, fetchingOrganisationInfo, fetchOrganisationWithId]);

  // ensure organisation tags exist
  const [fetchingOrganisationTags, setFetchingOrganisationTags] = useState(false);
  useEffect(() => {
    if (hasToken && organisation && !organisationTags && !fetchingOrganisationTags) {
      setFetchingOrganisationTags(true);
      fetchOrganisationPreferences(organisation);
    }
    else if (organisationTags) {
      setFetchingOrganisationTags(false);
    }
  }, [hasToken, organisation, organisationTags, fetchingOrganisationTags, fetchUserPreferences]);

  /*
   * End UserLogin / AppLoad logic to populate redux state
   */

  // provide lifecycle hooks
  useEffect(() => {
    if (userIsLoggedIn) {
      onLogin && onLogin();
    }
    else {
      onLogout && onLogout();
    }
  }, [userIsLoggedIn]);

  return children;
}

const mapStateToProps = state => {
  const organisation = getOrganisation(state);
  return {
    userToken: getUserToken(state),
    userIsLoggedIn: isUserLoggedIn(state),
    user: getUser(state),
    userTags: getUserTags(state),
    organisation,
    organisationState: organisation && getOrganisationState(state, organisation.id),
    organisationTags: getOrganisationTags(state),
  };
};
const mapDispatchToProps = {
  fetchUser,
  fetchOrganisations,
  fetchOrganisationWithId,
  fetchUserPreferences,
  fetchOrganisationPreferences,
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthProvider);
