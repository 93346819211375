import React from 'react';
import { Alert } from 'react-bootstrap';

const style = {
  borderRadius: 0,
  marginBottom: -1,
};

export default function BaseChartEditToolbar({ className, children }) {
  return (
    <Alert variant="warning" className={className} style={style}>
      {children}
    </Alert>
  );
}
