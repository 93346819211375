import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Table, Button, ProgressBar } from 'react-bootstrap';

import Title from '../../../components/Title';

import './university.scss';
import { IoIosPlayCircle } from 'react-icons/io';
import { trackEvent } from '../actions';

const { error } = console;

// make a Google APIs hook
const useYouTubeClient = () => {

  // get the var state
  const [ytClient, setYtClient] = useState(() => {
    // get stored youtube client
    return window.gapi && window.gapi.client && window.gapi.client.youtube;
  });

  let gapi;

  useEffect(() => {
    const id = 'google-apis-script';
    const url = 'https://apis.google.com/js/api.js';

    // load once and persist
    if (!document.getElementById(id)) {
      // add script tag to body
      const script = document.createElement('script');
      script.id = id;
      script.src = url;
      script.async = true;
      // ensure load script can be called multiple times
      script.onload = () => {
        // add if not yet defined
        if (!gapi && window.gapi) {
          gapi = window.gapi;
          // load the client and when ready, initialise it
          gapi.load('client', async () => {
            gapi.client.setApiKey(process.env.REACT_APP_GOOGLE_APIS_KEY);
            await gapi.client.load('https://www.googleapis.com/discovery/v1/apis/youtube/v3/rest');
            setYtClient(gapi.client.youtube);
          });
        }
      };
      document.body.appendChild(script);
    }
  }, []);

  return ytClient;
};

// make a YouTube hook
const useYouTube = () => {

  // get the YouTube state
  const [yt, setYt] = useState(window['YT']);

  useEffect(() => {
    const id = 'youtube-script';
    const url = 'https://www.youtube.com/iframe_api';

    // load once and persist
    if (!document.getElementById(id)) {
      // add script tag to body
      const script = document.createElement('script');
      script.id = id;
      script.src = url;
      script.async = true;
      // ensure load script can be called multiple times
      script.onload = () => {
        if (!yt && window.YT) {
          window.YT.ready(() => setYt(window.YT));
        }
      };
      document.body.appendChild(script);
    }
  }, []);

  return yt;
};

function VideoProgressBar({ playing, getProgress }) {
  const [progress, setProgress] = useState(0);
  // constantly check for updates
  useEffect(() => {
    // set progress every partial second
    const checkInterval = setInterval(() => setProgress(getProgress()), 250);
    return () => clearInterval(checkInterval);
  }, []);
  // render a progress bar
  return (
    <ProgressBar striped={playing} now={100*progress} />
  );
}

const playlistId = 'PLz02HSL8XgfpagMlaqzf-n0B-MsvyZHCA';

let videoCheckTime = Date.now();

function University({ trackEvent }) {

  const [player, setPlayer] = useState(null);
  const [playlist, setPlaylist] = useState([]);
  const [currentVideoId, setCurrentVideoId] = useState(null);
  const [currentVideoState, setCurrentVideoState] = useState(null);

  // get the YouTube state
  const yt = useYouTube();
  const ytClient = useYouTubeClient();

  // fetch the playlist items when the YouTube client loads
  useEffect(() => {
    if (ytClient) {
      const cumulativePlaylist = [];
      (async function getPlaylistPage(nextPageToken) {
        try {
          // get playlist items
          // link: https://developers.google.com/youtube/v3/docs/playlistItems/list
          const { result } = await ytClient.playlistItems.list({
            playlistId,
            part: 'snippet',
            pageToken: nextPageToken,
            // return maximum number of videos allowed by API
            maxResults: 50,
          });

          // exit if there are no items
          if (!result || !result.items) {
            throw new Error('No playlist items received');
          }

          // save these playlist items inside this scope
          // and update the React component
          cumulativePlaylist.push(...result.items);
          setPlaylist(cumulativePlaylist);

          // fetch next page
          if (result.nextPageToken) {
            getPlaylistPage(result.nextPageToken);
          }
        }
        catch (e) {
          const errorMessage = e && e.result && e.result.error && e.result.error.message;
          // note: tested with 403 error when no token is provided:
          // -> "Daily Limit for Unauthenticated Use Exceeded. Continued use requires signup."
          error('YouTube getPlaylist error:', errorMessage || e);
        }
      })();
    }
  }, [ytClient]);

  // update the YouTube player when the iframe_api is ready
  // link: https://developers.google.com/youtube/iframe_api_reference#cueVideoById
  useEffect(() => {
    // youtube is loaded
    if (yt) {
      const player = new yt.Player('youtube-university-playlist', {
        width: '100%',
        height: '100%',
        // set player options:
        // link: https://developers.google.com/youtube/player_parameters#Parameters
        playerVars: {
          rel: 0, // only load related videos from the same channel
        },
        events: {
          onReady() {
            // set up the playlist
            player.cuePlaylist({
              listType: 'playlist',
              list: playlistId,
              index: 0,
            });
            // pass player object to the rest of the component
            setPlayer(player);
          },
          onStateChange(event) {
            // get current playlist
            const { video_id: videoId } = player.getVideoData();

            // get player state:
            // link: https://developers.google.com/youtube/iframe_api_reference#Playback_status
            const playerStateByCode = {
              '-1': 'unstarted',
              '0': 'ended',
              '1': 'playing',
              '2': 'paused',
              '3': 'buffering',
              '5': 'video cued',
            };

            // update current video id if it has changed
            if (currentVideoId !== videoId) {
              setCurrentVideoId(videoId);
            }

            // set change to video status if relevant
            const videoState = playerStateByCode[`${event.data}`];
            if (currentVideoState !== videoState) {
              setCurrentVideoState(videoState);
            }
          },
        }
      });
    }
  }, [yt]);

  const getProgress = useCallback(() => {
    return player.getDuration()
      ? player.getCurrentTime() / player.getDuration()
      : 0;
  }, [player]);

  const [playingVideoStats, setPlayingVideoStats] = useState({});

  useEffect(() => {
    // let timeout;
    const check = () => {
      if (player && currentVideoState === 'playing') {
        const { video_id: videoId, title } = player.getVideoData();
        if (playingVideoStats.videoId === videoId) {

          const played = playingVideoStats.played + (Date.now() - videoCheckTime);
          const percentage = Math.round(100*played/playingVideoStats.duration);

          setPlayingVideoStats({
            ...playingVideoStats,
            played,
            percentage,
          });

          if (playingVideoStats.percentage < 75 && percentage >= 75) {
            trackEvent('Educational Video Watched', {
              video_title: title,
              video_id: videoId,
            });
          }
        }
        else {
          setPlayingVideoStats({ videoId, played: 0, duration: player.getDuration()*1000, percentage: 0 });
          trackEvent('Educational Video Started', {
            video_title: title,
            video_id: videoId,
          });
        }
      }
      videoCheckTime = Date.now();
    };
    const timeout = setTimeout(check, 100);
    return () => clearTimeout(timeout);
  }, [player, currentVideoState, playingVideoStats, trackEvent]);

  const activePlaylistItem = playlist.find(({ snippet }) => {
    return snippet.resourceId.videoId === currentVideoId;
  });

  // stretch the container to the remaining space of the page
  return (
    <Container fluid>
      <Title title="MOVUS University"/>
      <p>
        Tutorials covering how to deploy, use, and apply the FitMachine solution
      </p>
      <Row>
        <Col xs={12} lg={8}>
          <div className="youtube__player-container">
            <div id="youtube-university-playlist" className="youtube__player" />
          </div>
          {activePlaylistItem && (
            <div className="d-none d-lg-block mt-4">
              <div className="youtube___list-title montserrat">
                {activePlaylistItem.snippet.title}
              </div>
              {activePlaylistItem.snippet.description && (
                <div className="youtube___list-description text-muted mt-1">
                  {activePlaylistItem.snippet.description}
                </div>
              )}
            </div>
          )}
        </Col>
        <Col xs={12} lg={4}>
          <Table className="youtube___list" striped hover>
            <tbody>
              {playlist.map(({ snippet }) => {
                // get item parts
                const {
                  title,
                  thumbnails,
                  resourceId: { kind, videoId },
                } = snippet;

                if (kind !== 'youtube#video') {
                  return null;
                }

                // display row for each video
                const active = videoId === currentVideoId;
                const playing = currentVideoState === 'playing';
                return (
                  <tr
                    key={videoId}
                    className={`youtube___list--${active ? 'active' : 'playable'}`}
                  >
                    <td>
                      <Button
                        size="lg"
                        variant="outline-secondary"
                        disabled={active}
                        style={{
                          backgroundImage: `url(${thumbnails.default.url})`,
                          backgroundSize: 'cover',
                          backgroundPosition: 'center',
                        }}
                        onClick={() => {
                          if (player) {
                            // get new video index from current player state by id
                            const index = player.getPlaylist().findIndex(id => id === videoId);
                            // only play videos that are in the current player
                            if (index >= 0) {
                              player.loadPlaylist({
                                listType: 'playlist',
                                list: playlistId,
                                index,
                              });
                            }
                          }
                        }}
                      >
                        <IoIosPlayCircle size="1.4em" />
                      </Button>
                      {active && (
                        <VideoProgressBar
                          playing={playing}
                          getProgress={getProgress}
                        />
                      )}
                    </td>
                    <td>
                      <div className="youtube___list-title montserrat">{title}</div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
}

export default connect(null, { trackEvent })(University);
