import React, { Component } from 'react';
import {  Form, Row, Col, Button } from 'react-bootstrap';
import Title from  '../../../components/Title';
import {
  submitNewOrganisationDetails,
  submitNewChildOrganisationDetails,
  fetchOrganisationWithId,
} from '../actions';
import { connect } from 'react-redux';
import { getFormValues } from '../../../lib/utils';
import history from '../../../history';

import OrganisationPageLayout from '../components/OrganisationPageLayout';
import ParentOrganisationInput from '../components/form/ParentOrganisationInput';

class NewOrganisation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submit: {}
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit(e) {
    e.preventDefault();
    const formValues = getFormValues(e);
    const { submitNewOrganisationDetails, submitNewChildOrganisationDetails } = this.props;

    const submittedAt = Date.now();
    this.setState({ submit: { submittedAt } });

    const { parent_organisation_id, ...payload } = formValues;

    // submit form then handle feedback
    const submitPromise = formValues.parent_organisation_id
      ? submitNewChildOrganisationDetails(parent_organisation_id, payload)
      : submitNewOrganisationDetails(payload);

    submitPromise
      .then(async response => {
        // push user to the newly created org
        if (response && response.headers && response.headers.location) {
          const matches = `${response.headers.location}`.match(/(\d+)$/);
          if (matches && matches[1]) {
            await fetchOrganisationWithId({ id: matches[1] });
            history.push(`/organisations/${matches[1]}`);
          }
          else {
            history.push('/organisations/admin');
          }
        }
        // if still displaying the same submission then update with success
        this.setState(({ submit }) => {
          if (submit.submittedAt === submittedAt) {
            return { submit: { succeededAt: new Date() } };
          }
        });
      })
      .catch((error) => {
        // if still displaying the same submission then update with failure
        this.setState(({ submit }) => {
          if (submit.submittedAt === submittedAt) {
            return { submit: { error: error.message || 'Error' } };
          }
        });
      });
  }

  render() {
    const { submit } = this.state;

    return (
      <OrganisationPageLayout>
        <Form  onSubmit={ this.handleSubmit } >
          <Title
            title="New Organisation"
            loading={submit.submittedAt}
            error={submit.error}
            lastFetch={submit.succeededAt}
          />
          <Form.Group as={Row} controlId="organisationName">
            <Form.Label column sm="2">
              Name
            </Form.Label>
            <Col sm="10">
              <Form.Control type="text" placeholder="Name" name="name" />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="organisationSubDomain">
            <Form.Label column sm="2">
              Subdomain
            </Form.Label>
            <Col sm="10">
              <Form.Control type="text" placeholder="Subdomain"  name="sub_domain" />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="2">
              Parent Organisation
            </Form.Label>
            <Col sm="10">
              <ParentOrganisationInput />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Col xs={12}>
              <Button variant="success" type="submit" size="lg" className="float-right mb-chat-widget">
                Submit
              </Button>
            </Col>
          </Form.Group>
        </Form>
      </OrganisationPageLayout>
    );
  }
}

const mapDispatchToProps = {
  submitNewOrganisationDetails,
  submitNewChildOrganisationDetails,
};

export default connect(null, mapDispatchToProps)(NewOrganisation);
