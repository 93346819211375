
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import BaseModal from './BaseModal';
import './basicModal.scss';

export default class BasicModal extends Component {

  renderModal = ({ shown, close }) => {
    const { header, subHeader, body, footer, size, className } = this.props;
    return (
      <Modal show={shown} onHide={close} size={size} className={className}>
        <Modal.Header closeButton>
          <div>
            {header && <Modal.Title>{header}</Modal.Title>}
            {subHeader}
          </div>
        </Modal.Header>
        {body && (
          // allow body to be able to call the close function is desired
          <Modal.Body>
            {typeof body === 'function' ? body({ close }) : body}
          </Modal.Body>
        )}
        {footer && (
          // allow footer to be able to call the close function is desired
          <Modal.Footer>
            {typeof footer === 'function' ? footer({ close }) : footer}
          </Modal.Footer>
        )}
      </Modal>
    );
  }

  render() {
    return (
      <BaseModal
        renderModal={this.renderModal}
        onShow={this.props.onShow}
        onClose={this.props.onClose}
      >
        {this.props.children}
      </BaseModal>
    );
  }
}
