
import thunkMiddleware from 'redux-thunk';

import apiMiddleware from '../lib/apiMiddleware';
import bugsnagMiddleware from '../lib/bugsnagMiddleware';
import segmentMiddleware from '../lib/segmentMiddleware';
import googleAnalyticsMiddleware from '../lib/googleAnalyticsMiddleware';
import crossTabStorageMiddleWare from './crossTabStorageMiddleWare';

export default [
  thunkMiddleware,
  apiMiddleware,
  segmentMiddleware,
  googleAnalyticsMiddleware,
  crossTabStorageMiddleWare,
  bugsnagMiddleware,
];
