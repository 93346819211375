
import React from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import {
  IoIosAddCircleOutline,
  IoIosCloud,
  IoIosRadio,
} from 'react-icons/io';

import TableToolbar from '../../../components/TableToolbar';

import AddOrganisationTokenFormModal from './AddOrganisationTokenFormModal';
import AddStreamingConfigurationFormModal from './AddStreamingConfigurationFormModal';

const buttonProps = {
  target: "_blank",
  rel: "noopener noreferrer",
  variant: "outline-secondary",
};

const AddButton = ({ organisationId }) => {
  return (
    <Dropdown>
      <Dropdown.Toggle variant="primary" className="pl-1">
        <IoIosAddCircleOutline size="1.4em" /> Add{' '}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <AddOrganisationTokenFormModal organisationId={organisationId}>
          <Dropdown.Item>
            <IoIosCloud size="1.2em" /> <span>Add API Token</span>
          </Dropdown.Item>
        </AddOrganisationTokenFormModal>
        <AddStreamingConfigurationFormModal organisationId={organisationId}>
          <Dropdown.Item>
            <IoIosRadio size="1.2em" /> <span>Add Streaming Configuration</span>
          </Dropdown.Item>
        </AddStreamingConfigurationFormModal>
      </Dropdown.Menu>
    </Dropdown>
  );
};

function DeveloperCentreToolbar({
  tableProps,
  loading,
  organisationId,
}) {

  return (
    <TableToolbar
      searchable
      title="Developers Admin"
      loading={loading}
      buttonGroups={[
        [<AddButton key="add" organisationId={organisationId} />],
        [
          <LinkContainer key="tokens" to="/developer/admin/tokens">
            <Button variant="outline-secondary">API Tokens</Button>
          </LinkContainer>,
          <LinkContainer key="streaming" to="/developer/admin/streaming">
            <Button variant="outline-secondary">Streaming Configuration</Button>
          </LinkContainer>,
          <Button
            key="documentation"
            href="https://www.movus.com.au/apidocs/index.html"
            {...buttonProps}
          >
            API Documentation
          </Button>,
          <Button
            key="support"
            href="https://learn.movus.com.au/knowledge/understanding-developer-centre"
            {...buttonProps}
          >
            Support
          </Button>,
        ],
      ]}
      tableProps={tableProps}
    />
  );
}

export default DeveloperCentreToolbar;
