
import {
  headerFormatter,
  relativeDateFormatter,
  relativeDateFilter,
} from '../../components/table/formatters';

import {
  timeSortFunc,
} from '../../components/table/utils';

export const alarmTimestamp = {
  dataField: 'alarm_timestamp',
  text: 'Date/Time',
  headerFormatter,
  formatter: relativeDateFormatter,
  filterValue: relativeDateFilter,
  sort: true,
  sortFunc: timeSortFunc,
};
