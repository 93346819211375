import React, { Fragment, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';

import { fetchOrganisations } from '../../actions';
import { isAuthorised } from '../../../user/selectors';
import { getOrganisations, getOrganisation } from '../../selectors';
import Private from '../../../../components/Private';

function ParentOrganisationInput(props) {

  const {
    fetchOrganisations,
    organisations = [],
    parentOrganisationId,
    userIsSuperAdmin,
    Container = Fragment
  } = props;

  const nonChildOrganisations = organisations
    // filter to relevant organisations
    .filter(org => !org.parent_organisation_id)
    // sort by organisation name
    .sort((a, b) => `${a.name}`.localeCompare(`${b.name}`));

  // refresh organisations list on load
  useEffect(() => {
    fetchOrganisations();
  }, []);

  return (
    <Private minUserType="Partner Admin" minOrgType="Partner">
      <Container>
        {userIsSuperAdmin && !parentOrganisationId && (
          <Form.Check
            id="parent_organisation_id_null"
            name="parent_organisation_id"
            type="radio"
            label="No parent"
            value=""
            defaultChecked={!parentOrganisationId}
          />
        )}
        {nonChildOrganisations.map(({ id, name }) => (
          <Form.Check
            key={`parent_organisation_id-${id}`}
            id={`parent_organisation_id-${id}`}
            name="parent_organisation_id"
            type="radio"
            label={name}
            value={id}
            defaultChecked={
              // set as current value
              (id === parentOrganisationId) ||
              // or default to the only value if only one is available
              (!userIsSuperAdmin && nonChildOrganisations.length === 1)
            }
          />
        ))}
      </Container>
    </Private>
  );
}

const mapStateToProps = (state, { organisationId }) => {
  const organisations = getOrganisations(state);
  const organisationToEdit = organisationId && getOrganisation(state, organisationId);
  return {
    organisations,
    parentOrganisationId: organisationToEdit && organisationToEdit.parent_organisation_id,
    userIsSuperAdmin: isAuthorised(state, { minUserType: 'Super Admin', minOrgType: 'MOVUS' }),
  };
};
const mapDispatchToProps = { fetchOrganisations };

export default connect(mapStateToProps, mapDispatchToProps)(ParentOrganisationInput);
