import { CALL_API } from '../../lib/apiMiddleware';

// action types
export const ACTION_TYPES = {
  REQUEST_GATEWAY_LIST: 'gateways/REQUEST_GATEWAY_LIST',
  RECEIVE_GATEWAY_LIST: 'gateways/RECEIVE_GATEWAY_LIST',
  GATEWAY_LIST_FAILURE: 'gateways/GATEWAY_LIST_FAILURE',

  REQUEST_GATEWAY: 'gateways/REQUEST_GATEWAY',
  RECEIVE_GATEWAY: 'gateways/RECEIVE_GATEWAY',
  GATEWAY_FAILURE: 'gateways/GATEWAY_FAILURE',
};

export function requestGatewayList() {
  return {
    type: ACTION_TYPES.REQUEST_GATEWAY_LIST,
  };
}

export function failureGatewayList() {
  return {
    type: ACTION_TYPES.GATEWAY_LIST_FAILURE,
  };
}

export function receiveGatewayList() {
  return {
    type: ACTION_TYPES.RECEIVE_GATEWAY_LIST,
  };
}

export function fetchGateways() {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      // todo: enforce a certain filter cut-off to be in line with
      // visualisations placed on the Map
      endpoint: '/gateways?age_in_days=30',
      requestAction: requestGatewayList(),
      successAction: receiveGatewayList(),
      errorAction: failureGatewayList()
    });
  };
}

export function requestGateway(gateway) {
  return {
    type: ACTION_TYPES.REQUEST_GATEWAY,
    gateway,
  };
}

export function receiveGateway(gateway) {
  return {
    type: ACTION_TYPES.RECEIVE_GATEWAY,
    gateway,
  };
}

export function failureGateway(gateway) {
  return {
    type: ACTION_TYPES.GATEWAY_FAILURE,
    gateway,
  };
}
export function fetchGateway(gateway) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/gateways/${gateway.id}`,
      requestAction: requestGateway(gateway),
      successAction: receiveGateway(gateway),
      errorAction: failureGateway(gateway)
    });
  };
}
