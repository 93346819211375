import createReducer from '../../lib/createReducer';

import { ACTION_TYPES } from './actions';

export const DEFAULT_STATE = {
  version: undefined,
  build: undefined,
};

export default createReducer(DEFAULT_STATE, {
  [ACTION_TYPES.APP_LOAD](state) {
    return {
      ...state,
      version: process.env.REACT_APP_MACHINECLOUD_DASH_VERSION,
      build: process.env.REACT_APP_TEAMCITY_BUILD_NUMBER,
      // capture previous state (just after rehydration)
      previousVersion: state.version,
      previousBuild: state.build,
    };
  },
});
