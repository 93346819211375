
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import withNavigationDeviceProps from '../components/withNavigationDeviceProps';

import AICalendar from '../../../components/charts/AICalendar';
import AIChart from '../../../components/charts/AIChart';
import MeasuredDataChart from '../../../components/charts/MeasuredDataChart';
import EquipmentUsageChart from '../../../components/charts/EquipmentUsageChart';
import DeviceSamplesContext from '../../../components/charts/DeviceSamplesContext';

import EquipmentPageLayout from '../components/EquipmentPageLayout';
import { getDevice } from '../selectors';

function EquipmentCharts({ device={} }) {

  // return archived status message
  if (device.archived) {
    return (
      <div className="my-4">
        <p>
          Sample data is not currently available for archived devices.
        </p>
        <p>
          You can vote for this feature on the <Link to="/whats-new">What's New page</Link>.
        </p>
      </div>
    );
  }

  return (
    <DeviceSamplesContext deviceId={device.id}>
      {chartProps => (
        <Fragment>
          <AICalendar deviceId={device.id} {...chartProps} />
          <AIChart deviceId={device.id} {...chartProps} />
          <MeasuredDataChart deviceId={device.id} {...chartProps} />
          <EquipmentUsageChart deviceId={device.id} {...chartProps} />
        </Fragment>
      )}
    </DeviceSamplesContext>
  );
}

const mapStateToProps = (state, { deviceId }) => ({
  device: getDevice(state, deviceId),
});

const ConnectedEquipmentCharts = withNavigationDeviceProps(
  connect(mapStateToProps)(EquipmentCharts)
);

export default function EquipmentDetail() {
  return (
    <EquipmentPageLayout>
      <div className="mb-chat-widget">
        <ConnectedEquipmentCharts />
      </div>
    </EquipmentPageLayout>
  );
}
