import { CALL_API } from '../../lib/apiMiddleware';
import { getActiveGroupId, isTopLevelGroupActive } from '../organisation/selectors';
import { fetchGroupMembers } from '../organisation/actions';
// import { CALL_BLE } from 'lib/bluetoothMiddleware';
// import { COMMANDS } from '@movusoz/react-native-ble';

// action types
export const ACTION_TYPES = {
  REQUEST_BEGIN: 'devices/REQUEST_BEGIN',
  REQUEST_FAIL: 'devices/REQUEST_FAIL',

  RECEIVE_CLOUD_DEVICE_LIST: 'devices/RECEIVE_CLOUD_DEVICE_LIST',
  RECEIVE_DEVICE_INFO: 'devices/RECEIVE_DEVICE_INFO',
  RECEIVE_FIRMWARE_VERSION: 'devices/RECEIVE_FIRMWARE_VERSION',

  REQUEST_DEVICE_INTERACTIVE_FFT: 'devices/REQUEST_DEVICE_INTERACTIVE_FFT',
  RECEIVE_DEVICE_INTERACTIVE_FFT: 'devices/RECEIVE_DEVICE_INTERACTIVE_FFT',
  DEVICE_INTERACTIVE_FFT_FAILURE: 'devices/DEVICE_INTERACTIVE_FFT_FAILURE',

  REQUEST_DEVICE_WATERFALL_PLOT: 'devices/REQUEST_DEVICE_WATERFALL_PLOT',
  RECEIVE_DEVICE_WATERFALL_PLOT: 'devices/RECEIVE_DEVICE_WATERFALL_PLOT',
  DEVICE_WATERFALL_PLOT_FAILURE: 'devices/DEVICE_WATERFALL_PLOT_FAILURE',

  REQUEST_DEVICE_EVENTS: 'devices/REQUEST_DEVICE_EVENTS',
  RECEIVE_DEVICE_EVENTS: 'devices/RECEIVE_DEVICE_EVENTS',
  DEVICE_EVENTS_FAILURE: 'devices/DEVICE_EVENTS_FAILURE',

  REQUEST_DEVICE_ALARMS: 'devices/REQUEST_DEVICE_ALARMS',
  RECEIVE_DEVICE_ALARMS: 'devices/RECEIVE_DEVICE_ALARMS',
  DEVICE_ALARMS_FAILURE: 'devices/DEVICE_ALARMS_FAILURE',

  REQUEST_DEVICE_IMAGES: 'devices/REQUEST_DEVICE_IMAGES',
  RECEIVE_DEVICE_IMAGES: 'devices/RECEIVE_DEVICE_IMAGES',
  DEVICE_IMAGES_FAILURE: 'devices/DEVICE_IMAGES_FAILURE',

  REQUEST_DEVICE_IMAGE_DELETE: 'devices/REQUEST_DEVICE_IMAGE_DELETE',
  RECEIVE_DEVICE_IMAGE_DELETE: 'devices/RECEIVE_DEVICE_IMAGE_DELETE',
  DEVICE_IMAGE_DELETE_FAILURE: 'devices/DEVICE_IMAGE_DELETE_FAILURE',

  REQUEST_DEVICE_IMAGE_UPLOAD_S3_FILE_URL: 'devices/REQUEST_DEVICE_IMAGE_UPLOAD_S3_FILE_URL',
  RECEIVE_DEVICE_IMAGE_UPLOAD_S3_FILE_URL: 'devices/RECEIVE_DEVICE_IMAGE_UPLOAD_S3_FILE_URL',
  DEVICE_IMAGE_UPLOAD_S3_FILE_URL_FAILURE: 'devices/DEVICE_IMAGE_UPLOAD_S3_FILE_URL_FAILURE',

  REQUEST_DEVICE_ERRORS: 'devices/REQUEST_DEVICE_ERRORS',
  RECEIVE_DEVICE_ERRORS: 'devices/RECEIVE_DEVICE_ERRORS',
  DEVICE_ERRORS_FAILURE: 'devices/DEVICE_ERRORS_FAILURE',

  REQUEST_DEVICE_SAMPLES_BEGIN: 'devices/REQUEST_DEVICE_SAMPLES_BEGIN',
  REQUEST_DEVICE_SAMPLES_FAIL: 'devices/REQUEST_DEVICE_SAMPLES_FAIL',
  RECEIVE_DEVICE_SAMPLES: 'devices/RECEIVE_DEVICE_SAMPLES',

  REQUEST_DEVICE_OVERVIEW: 'devices/REQUEST_DEVICE_OVERVIEW',
  RECEIVE_DEVICE_OVERVIEW: 'devices/RECEIVE_DEVICE_OVERVIEW',
  DEVICE_OVERVIEW_FAILURE: 'devices/DEVICE_OVERVIEW_FAILURE',

  REQUEST_DEVICE_RUNTIME: 'devices/REQUEST_DEVICE_RUNTIME',
  RECEIVE_DEVICE_RUNTIME: 'devices/RECEIVE_DEVICE_RUNTIME',
  DEVICE_RUNTIME_FAILURE: 'devices/DEVICE_RUNTIME_FAILURE',

  RECEIVE_DEVICE_RECALIBRATION: 'devices/RECEIVE_DEVICE_RECALIBRATION',

  REQUEST_EQUIPMENT_UPDATE: 'equipments/REQUEST_EQUIPMENT_UPDATE',
  RECEIVE_EQUIPMENT_UPDATE: 'equipments/RECEIVE_EQUIPMENT_UPDATE',
  EQUIPMENT_UPDATE_FAIL: 'equipments/EQUIPMENT_UPDATE_FAIL',

  REQUEST_DEVICE_MUTE_STATUS_UPDATE: 'devices/REQUEST_DEVICE_MUTE_STATUS_UPDATE',
  RECEIVE_DEVICE_MUTE_STATUS_UPDATE: 'devices/RECEIVE_DEVICE_MUTE_STATUS_UPDATE',
  DEVICE_MUTE_STATUS_UPDATE_FAIL:'devices/DEVICE_MUTE_STATUS_UPDATE_FAIL',

  // REQUEST_WIFI_PROFILES: 'devices/REQUEST_WIFI_PROFILES',
  // RECEIVE_WIFI_PROFILES: 'devices/RECEIVE_WIFI_PROFILES',

  // REQUEST_WIFI_CONNECTIVITY: 'devices/REQUEST_WIFI_CONNECTIVITY',
  // RECEIVE_WIFI_CONNECTIVITY: 'devices/RECEIVE_WIFI_CONNECTIVITY',

  UPDATE_DRAFT: 'devices/UPDATE_DRAFT',
  DISCARD_DRAFT: 'devices/DISCARD_DRAFT',

  CREATE_TEMPLATE: 'devices/CREATE_TEMPLATE',
  DELETE_TEMPLATE: 'devices/DELETE_TEMPLATE',

  UPDATE_NEARBY: 'devices/UPDATE_NEARBY',

  REQUEST_ARCHIVE_DEVICE: 'devices/REQUEST_ARCHIVE_DEVICE',
  RECEIVE_ARCHIVE_DEVICE: 'devices/RECEIVE_ARCHIVE_DEVICE',
  ARCHIVE_DEVICE_FAILURE: 'devices/ARCHIVE_DEVICE_FAILURE',

  REQUEST_UNARCHIVE_DEVICE: 'devices/REQUEST_UNARCHIVE_DEVICE',
  RECEIVE_UNARCHIVE_DEVICE: 'devices/RECEIVE_UNARCHIVE_DEVICE',
  UNARCHIVE_DEVICE_FAILURE: 'devices/UNARCHIVE_DEVICE_FAILURE',
};

export function requestBegin(device) {
  return {
    type: ACTION_TYPES.REQUEST_BEGIN,
    device
  };
}

export function requestFail(device) {
  return {
    type: ACTION_TYPES.REQUEST_FAIL,
    device
  };
}

export function receiveCloudDeviceList(options) {
  return {
    type: ACTION_TYPES.RECEIVE_CLOUD_DEVICE_LIST,
    archived: options && options.filter === 'archived',
    options,
  };
}

export function fetchDevices(options) {
  const { filter=[], search=[], forOrg=false } = options || {};
  // filter or search may be values or arrays of values
  const queryParams = new URLSearchParams();
  if (filter.length) {
    queryParams.set('filter', filter);
  }
  if (search.length) {
    queryParams.set('search', search);
  }
  const queryString = queryParams.toString();
  return (dispatch, getState) => {
    const state = getState();
    if (!forOrg && !isTopLevelGroupActive(state)) {
      return fetchGroupMembers({ id: getActiveGroupId(state) })(dispatch, getState);
    }
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/devices${queryString ? `?${queryString}` : ''}`,
      requestAction: requestBegin(),
      successAction: receiveCloudDeviceList(options),
      errorAction: requestFail()
    });
  };
}

export function receiveDeviceInfo(device) {
  return {
    type: ACTION_TYPES.RECEIVE_DEVICE_INFO,
    device
  };
}

export function fetchDeviceInfo(device) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/devices/${device.id}`,
      requestAction: requestBegin(device),
      successAction: receiveDeviceInfo(device),
      errorAction: requestFail(device)
    });
  };
}

export function requestDeviceEvents(device) {
  return {
    type: ACTION_TYPES.REQUEST_DEVICE_EVENTS,
    device
  };
}

export function receiveDeviceEvents(device) {
  return {
    type: ACTION_TYPES.RECEIVE_DEVICE_EVENTS,
    device
  };
}

export function deviceEventsFailure(device) {
  return {
    type: ACTION_TYPES.DEVICE_EVENTS_FAILURE,
    device
  };
}

export function fetchDeviceEvents(device, { source='system', event_type }={}) {
  return dispatch => {
    const queryParams = new URLSearchParams();
    if (source) {
      queryParams.set('source', source);
    }
    if (event_type) {
      queryParams.set('event_type', event_type);
    }
    const queryString = queryParams.toString();
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/devices/${device.id}/logs${
        // append query string
        queryString ? `?${queryString}` : ''
      }`,
      requestAction: requestDeviceEvents(device),
      successAction: receiveDeviceEvents(device),
      errorAction: deviceEventsFailure(device),
    });
  };
}

export function requestDeviceInteractiveFFT(device) {
  return {
    type: ACTION_TYPES.REQUEST_DEVICE_INTERACTIVE_FFT,
    device
  };
}

export function receiveDeviceInteractiveFFT(device) {
  return {
    type: ACTION_TYPES.RECEIVE_DEVICE_INTERACTIVE_FFT,
    device
  };
}

export function deviceInteractiveFFTFailure(device) {
  return {
    type: ACTION_TYPES.DEVICE_INTERACTIVE_FFT_FAILURE,
    device
  };
}

export function fetchDeviceInteractiveFFT(device) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/devices/${device.id}/quickref?type=fft`,
      requestAction: requestDeviceInteractiveFFT(device),
      successAction: receiveDeviceInteractiveFFT(device),
      errorAction: deviceInteractiveFFTFailure(device),
    });
  };
}

export function requestDeviceWaterfallPlot(device) {
  return {
    type: ACTION_TYPES.REQUEST_DEVICE_WATERFALL_PLOT,
    device
  };
}

export function receiveDeviceWaterfallPlot(device) {
  return {
    type: ACTION_TYPES.RECEIVE_DEVICE_WATERFALL_PLOT,
    device
  };
}

export function deviceWaterfallPlotFailure(device) {
  return {
    type: ACTION_TYPES.DEVICE_WATERFALL_PLOT_FAILURE,
    device
  };
}

export function fetchDeviceWaterfallPlot(device) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/devices/${device.id}/quickref?type=waterfall`,
      requestAction: requestDeviceWaterfallPlot(device),
      successAction: receiveDeviceWaterfallPlot(device),
      errorAction: deviceWaterfallPlotFailure(device),
    });
  };
}

export function requestDeviceAlarms(device) {
  return {
    type: ACTION_TYPES.REQUEST_DEVICE_ALARMS,
    device
  };
}

export function receiveDeviceAlarms(device) {
  return {
    type: ACTION_TYPES.RECEIVE_DEVICE_ALARMS,
    device
  };
}

export function deviceAlarmsFailure(device) {
  return {
    type: ACTION_TYPES.DEVICE_ALARMS_FAILURE,
    device
  };
}

export function fetchDeviceAlarms(device) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/devices/${device.id}/alarms`,
      requestAction: requestDeviceAlarms(device),
      successAction: receiveDeviceAlarms(device),
      errorAction: deviceAlarmsFailure(device),
    });
  };
}

export function requestDeviceImages(device) {
  return {
    type: ACTION_TYPES.REQUEST_DEVICE_IMAGES,
    device
  };
}

export function receiveDeviceImages(device) {
  return {
    type: ACTION_TYPES.RECEIVE_DEVICE_IMAGES,
    device
  };
}

export function deviceImagesFailure(device) {
  return {
    type: ACTION_TYPES.DEVICE_IMAGES_FAILURE,
    device
  };
}

export function fetchDeviceImages(device) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/devices/${device.id}/images`,
      requestAction: requestDeviceImages(device),
      successAction: receiveDeviceImages(device),
      errorAction: deviceImagesFailure(device),
    });
  };
}

export function fetchDeviceInfoThenImages(device) {
  return dispatch => {
    return fetchDeviceInfo(device)(dispatch)
      .then(() => fetchDeviceImages(device)(dispatch))
      .catch(() => {}); // device probably doesn't exist
  };
}

export function getImageUploadS3FileUrl(device, data={}) {
  const {
    name, // type of image
    file_name = new Date().toISOString(), // default filename to current time
  } = data;
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'post',
      endpoint: `/devices/${device.id}/images`,
      data: { name, file_name },
      requestAction: { device, type: ACTION_TYPES.REQUEST_DEVICE_IMAGE_UPLOAD_S3_FILE_URL },
      successAction: { device, type: ACTION_TYPES.RECEIVE_DEVICE_IMAGE_UPLOAD_S3_FILE_URL },
      errorAction: { device, type: ACTION_TYPES.DEVICE_IMAGE_UPLOAD_S3_FILE_URL_FAILURE },
    });
  };
}

export function requestDeviceImageDelete(device, image) {
  return {
    type: ACTION_TYPES.REQUEST_DEVICE_IMAGE_DELETE,
    device,
    image,
  };
}

export function receiveDeviceImageDelete(device, image) {
  return {
    type: ACTION_TYPES.RECEIVE_DEVICE_IMAGE_DELETE,
    device,
    image,
  };
}

export function deviceImageDeleteFailure(device, image) {
  return {
    type: ACTION_TYPES.DEVICE_IMAGE_DELETE_FAILURE,
    device,
    image,
  };
}

export function deleteDeviceImage(device, image) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'delete',
      endpoint: `/devices/${device.id}/images/${image.id}`,
      requestAction: requestDeviceImageDelete(device, image),
      successAction: receiveDeviceImageDelete(device, image),
      errorAction: deviceImageDeleteFailure(device, image),
    });
  };
}

export function requestDeviceErrors(device) {
  return {
    type: ACTION_TYPES.REQUEST_DEVICE_ERRORS,
    device,
  };
}

export function receiveDeviceErrors(device) {
  return {
    type: ACTION_TYPES.RECEIVE_DEVICE_ERRORS,
    device,
  };
}

export function deviceErrorsFailure(device) {
  return {
    type: ACTION_TYPES.DEVICE_ERRORS_FAILURE,
    device,
  };
}

export function fetchDeviceErrors(device) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/devices/${device.id}/fmerrors`,
      requestAction: requestDeviceErrors(device),
      successAction: receiveDeviceErrors(device),
      errorAction: deviceErrorsFailure(device),
    });
  };
}

export function requestDeviceSamplesBegin(device) {
  return {
    type: ACTION_TYPES.REQUEST_DEVICE_SAMPLES_BEGIN,
    device,
  };
}

export function requestDeviceSamplesFail(device) {
  return {
    type: ACTION_TYPES.REQUEST_DEVICE_SAMPLES_FAIL,
    device,
  };
}

export function receiveDeviceSamples(device) {
  return {
    type: ACTION_TYPES.RECEIVE_DEVICE_SAMPLES,
    device,
  };
}

export function fetchDeviceSamples(device, { isCancelled, dateRange={}, onData } = {}) {
  return dispatch => {

    const { startTime, minimumDataPeriod: minimumTimePeriod=0 } = dateRange;

    const userCacheNamespace = `/devices/${device.id}/samples`;
    const dispatchOptions = {
      type: CALL_API,
      method: 'get',
      readCache: false,
      userCacheNamespace,
      endpoint: userCacheNamespace,
      requestAction: requestDeviceSamplesBegin(device),
      successAction: receiveDeviceSamples(device),
      errorAction: requestDeviceSamplesFail(device),
      errorToast: {
        header: 'Could not get chart data',
        timeout: false,
      },
    };

    // allow pages to recursively keep loading until outside of date range
    // or isCancelled returns a truthy value
    let latestSampleTime;
    let earliestSampleTime;

    function formatSampleTimeForAPI(time) {
      return (new Date(time).getTime()/1000).toFixed(1);
    }

    // set the time extents of the received data
    function setSampleDateRange(samples=[]) {
      if (samples[0]) {
        if (!latestSampleTime || new Date(samples[0].sample_time) > latestSampleTime) {
          latestSampleTime = new Date(samples[0].sample_time).getTime();
        }
      }
      if (samples[samples.length - 1]) {
        if (!earliestSampleTime ||
          new Date(samples[samples.length - 1].sample_time) < earliestSampleTime) {
          earliestSampleTime = new Date(samples[samples.length - 1].sample_time).getTime();
        }
      }
    }

    let cacheKeys;

    async function successHandler({ data, cache }={}) {

      // if the request was cancelled then don't handle changes from these samples
      if (isCancelled && isCancelled()) {
        return;
      }

      const samples = data && data._embedded && data._embedded.samples;
      // set sample times
      setSampleDateRange(samples);

      const nextLink = data && data._links && data._links.next;
      const withinDateRange = !!(startTime && earliestSampleTime > startTime);
      const withinTimePeriod = !minimumTimePeriod ||
        latestSampleTime - earliestSampleTime < minimumTimePeriod;

      const stillFetching = !!(nextLink && (withinDateRange || withinTimePeriod));

      if (onData) {
        // add data to context at maybe a throttled rate if we expect more requests
        // update even if an empty list was returned (might be end of pages to update immediately)
        onData(device, samples || [], { stillFetching, hasMore: !!nextLink });
      }

      // if there is a next page to deal with that matches our conditions then handle it
      if (stillFetching) {

        // fetch the cacheKeys if we don't have them yet,
        // the up-to-date cacheKeys don't need to be read each time (can be ~30ms after ~30 pages)
        // as the HTTP requests we are adding to the cache recursively aren't going to match
        // the requests for newer pages down the current request chain
        if (!cacheKeys) {
          cacheKeys = cache && await cache.keys();
        }
        // attempt to find a previous cache key that matches any page
        // continuation inside the current result set.
        // for example: we have 100 results, and a nextLink page to
        // continue from result 100, which has no cache,
        // but we do have 20 pages cached starting from result 99,
        // we should follow the cached page chain, instead of fetching
        // and storing another 20 pages
        const samplePages = samples.map(({ sample_time }) => {
          return formatSampleTimeForAPI(sample_time);
        });
        const foundCacheKey = cacheKeys && cacheKeys.find(({ url }) => {
          const queryParams = new URLSearchParams(new URL(url).search);
          const page = queryParams.get('page');
          // don't allow the same page to be fetched again
          if (parseInt(data.page) === parseInt(page)) {
            return false;
          }
          return samplePages.includes(page);
        });

        dispatch({
          ...dispatchOptions,
          userCacheNamespace,
          // if the cache has a key that matches a stored cache space
          // then use that as the next page, otherwise use nextLink
          endpoint: foundCacheKey
            ? `/devices/${device.id}/samples${new URL(foundCacheKey.url).search}`
            : `/devices/${device.id}/samples${new URL(nextLink).search}`,
          // read cache of next page depending if this page reaches past 4 days ago
          readCache: new Date() - new Date(earliestSampleTime)
            > 4 * 24 * 60 * 60 * 1000,
        }).then(successHandler);
      }
    }

    // start the request chain
    return dispatch(dispatchOptions).then(successHandler);
  };
}

export function requestDeviceOverview(device) {
  return {
    type: ACTION_TYPES.REQUEST_DEVICE_OVERVIEW,
    device
  };
}

export function receiveDeviceOverview(device) {
  return {
    type: ACTION_TYPES.RECEIVE_DEVICE_OVERVIEW,
    device
  };
}

export function deviceOverviewFailure(device) {
  return {
    type: ACTION_TYPES.DEVICE_OVERVIEW_FAILURE,
    device
  };
}

export function fetchDeviceOverview(device) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/devices/${device.id}/overview`,
      requestAction: requestDeviceOverview(device),
      successAction: receiveDeviceOverview(device),
      errorAction: deviceOverviewFailure(device),
    });
  };
}

export function requestDeviceRuntime(device) {
  return {
    type: ACTION_TYPES.REQUEST_DEVICE_RUNTIME,
    device
  };
}

export function receiveDeviceRuntime(device) {
  return {
    type: ACTION_TYPES.RECEIVE_DEVICE_RUNTIME,
    device
  };
}

export function deviceRuntimeFailure(device) {
  return {
    type: ACTION_TYPES.DEVICE_RUNTIME_FAILURE,
    device
  };
}

export function fetchDeviceRuntime(device, { view='summary' }={}) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/devices/${device.id}/runtime?view=${view}`,
      requestAction: requestDeviceRuntime(device),
      successAction: receiveDeviceRuntime(device),
      errorAction: deviceRuntimeFailure(device),
    });
  };
}

function receiveRecalibration(device) {
  return {
    type: ACTION_TYPES.RECEIVE_DEVICE_RECALIBRATION,
    device,
  };
}

export function recalibrate(device, {
  recalibrate = true,
  fitmachine_onboard_date = new Date().toISOString(),
  rms_running_cutoff,
} = {}) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'put',
      endpoint: `/devices/${device.id}`,
      data: {
        recalibrate,
        fitmachine_onboard_date,
        rms_running_cutoff,
      },
      requestAction: requestBegin(device),
      successAction: receiveRecalibration(device),
      errorAction: requestFail(device),
      successToast: 'Recalibration started',
      errorToast: 'Recalibration failed',
    });
  };
}

export function recalibrateAndRefetch(device, payload) {
  return dispatch => {
    // call recalibrate
    return recalibrate(device, payload)(dispatch)
      // and then fetch info
      .then(() => fetchDeviceInfo(device)(dispatch));
  };
}

// submit device details
function requestEquipmentUpdate() {
  return {
    type: ACTION_TYPES.RECEIVE_EQUIPMENT_UPDATE
  };
}

function receiveEquipmentUpdate() {
  return {
    type: ACTION_TYPES.RECEIVE_EQUIPMENT_UPDATE
  };
}

function equipmentUpdateFail() {
  return {
    type: ACTION_TYPES.EQUIPMENT_UPDATE_FAIL
  };
}

export function submitEquipmentDetails(details) {
  const equipmentId = details.id;
  delete details.id;
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'put',
      endpoint: `/devices/${equipmentId}`,
      data: details,
      requestAction: requestEquipmentUpdate(),
      successAction: receiveEquipmentUpdate(),
      errorAction: equipmentUpdateFail(),
      successToast: 'Device edited',
    });
  };
}
export function requestDeviceMuteStatusUpdate(device) {
  return {
    type: ACTION_TYPES.REQUEST_DEVICE_MUTE_STATUS_UPDATE,
    device,
  };
}

export function receiveDeviceMuteStatusUpdate(device, time) {
  return {
    type: ACTION_TYPES.RECEIVE_DEVICE_MUTE_STATUS_UPDATE,
    device,
    mute_advisory_for: time,
  };
}

export function deviceMuteStatusUpdateFail(device) {
  return {
    type: ACTION_TYPES.DEVICE_MUTE_STATUS_UPDATE_FAIL,
    device,
  };
}

export function updateDeviceMuteStatus(device, { time, timeString }) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'put',
      endpoint: `/devices/${device.id}/status`,
      data: {
        mute_advisory_for: parseInt(time)
      },
      requestAction: requestDeviceMuteStatusUpdate(device),
      successAction: receiveDeviceMuteStatusUpdate(device, time),
      errorAction: deviceMuteStatusUpdateFail(device),
      successToast: `Yellow notifications ${ time === 0 ? 'unmuted' : `muted for ${timeString}` }`,
      errorToast: `${ time === 0 ? 'Unmuting' : 'Muting' } failed`,
    });
  };
}

// export function receiveFirmwareVersion (device) {
//   return {
//     type: ACTION_TYPES.RECEIVE_FIRMWARE_VERSION,
//     device
//   };
// }

// export function fetchFirmwareVersion (device) {
//   return dispatch => {
//     dispatch({
//       type: CALL_BLE,
//       device,
//       command: COMMANDS.REQUEST_FIRMWARE_VERSION,
//       requestAction: requestBegin(device),
//       successAction: receiveFirmwareVersion(device),
//       errorAction: requestFail(device)
//     });
//   };
// }

// export function requestWifiProfiles (device) {
//   return {
//     type: ACTION_TYPES.REQUEST_WIFI_PROFILES,
//     device
//   };
// }

// export function receiveWifiProfiles (device) {
//   return {
//     type: ACTION_TYPES.RECEIVE_WIFI_PROFILES,
//     device
//   };
// }

// export function fetchWifiProfiles (device) {
//   return dispatch => {
//     dispatch({
//       type: CALL_BLE,
//       device,
//       command: COMMANDS.REQUEST_WIFI_PROFILES,
//       requestAction: requestWifiProfiles(device),
//       successAction: receiveWifiProfiles(device),
//       errorAction: requestFail(device)
//     });
//   };
// }

// export function requestWifiConnectivity (device) {
//   return {
//     type: ACTION_TYPES.REQUEST_WIFI_CONNECTIVITY,
//     device
//   };
// }

// export function receiveWifiConnectivity (device) {
//   return {
//     type: ACTION_TYPES.RECEIVE_WIFI_CONNECTIVITY,
//     device
//   };
// }

// export function fetchWifiConnectivity (device) {
//   return dispatch => {
//     dispatch({
//       type: CALL_BLE,
//       device,
//       command: COMMANDS.REQUEST_WIFI_CONNECTIVITY,
//       requestAction: requestWifiConnectivity(device),
//       successAction: receiveWifiConnectivity(device),
//       errorAction: requestFail(device)
//     });
//   };
// }

export function updateDraft(index, draft, overwrite) {
  return {
    type: ACTION_TYPES.UPDATE_DRAFT,
    index,
    draft,
    overwrite
  };
}

export function discardDraft(index) {
  return {
    type: ACTION_TYPES.DISCARD_DRAFT,
    index
  };
}

export function createTemplate(name, template) {
  return {
    type: ACTION_TYPES.CREATE_TEMPLATE,
    name,
    template
  };
}

export function deleteTemplate(name) {
  return {
    type: ACTION_TYPES.DELETE_TEMPLATE,
    name
  };
}

export function updateNearby(devices) {
  return {
    type: ACTION_TYPES.UPDATE_NEARBY,
    devices
  };
}

function requestArchiveDevice(device) {
  return {
    type: ACTION_TYPES.REQUEST_ARCHIVE_DEVICE,
    device
  };
}

function receiveArchiveDevice(device) {
  return {
    type: ACTION_TYPES.RECEIVE_ARCHIVE_DEVICE,
    device
  };
}

function archiveDeviceFailure(device) {
  return {
    type: ACTION_TYPES.ARCHIVE_DEVICE_FAILURE,
    device
  };
}

export function archiveDevice(device) {
  return dispatch => {
    return dispatch ({
      type: CALL_API,
      method: 'put',
      data: {
        archived: true,
      },
      endpoint: `/devices/${device.id}`,
      requestAction: requestArchiveDevice(device),
      successAction: receiveArchiveDevice(device),
      errorAction: archiveDeviceFailure(device),
      successToast: "Device Archived",
      errorToast: "Archiving Failed"
    });
  };
}

function requestUnarchiveDevice(device) {
  return {
    type: ACTION_TYPES.REQUEST_UNARCHIVE_DEVICE,
    device
  };
}

function receiveUnarchiveDevice(device) {
  return {
    type: ACTION_TYPES.RECEIVE_UNARCHIVE_DEVICE,
    device
  };
}

function unarchiveDeviceFailure(device) {
  return {
    type: ACTION_TYPES.UNARCHIVE_DEVICE_FAILURE,
    device
  };
}

export function unarchiveDevice(device) {
  return dispatch => {
    return dispatch ({
      type: CALL_API,
      method: 'put',
      data: {
        archived: false,
      },
      endpoint: `/devices/${device.id}`,
      requestAction: requestUnarchiveDevice(device),
      successAction: receiveUnarchiveDevice(device),
      errorAction: unarchiveDeviceFailure(device),
      successToast: "Device Unarchived",
      errorToast: "Unarchiving Failed",
    });
  };
}
