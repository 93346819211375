
import React, { useState, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Form } from 'react-bootstrap';

import FormModal from '../../../components/FormModal';
import { useFocusableRef } from '../../../components/form/useFocusableRef';

import { getOrganisation, getGroup, getGroupTreeNode, getGroupTreeNamePath } from '../selectors';
import { fetchGroup, fetchOrganisationGroups, createGroup, selectActiveGroup } from '../actions';
import { getFormValues } from '../../../lib/utils';

const confirmButtonProps = {
  variant: 'success',
};

function AddGroupFormModal({
  groupId,
  groupNode = {},
  // get most current data from group list, default to (presumably stale) data from group tree
  group = { id: groupNode.id, group_name: groupNode.name },
  groupTreeNamePath,
  organisation = {},
  selectActiveGroup,
  fetchGroup,
  createGroup,
  fetchOrganisationGroups,
  children,
  ...props
}) {

  const [name, setName] = useState('');
  const [pristineName, setPristineName] = useState(true);
  const [valid, setValid] = useState(false);

  const formRef = useRef(null);
  const [nameRef, setShown] = useFocusableRef(null);

  const handleSubmit = useCallback(async e => {
    const { group_name, parent_id } = getFormValues(e, formRef.current) || {};
    if (group_name && parseInt(parent_id) > 0) {
      try {
        const { headers } = await createGroup({ group_name, parent_id: parseInt(parent_id) });
        await fetchOrganisationGroups(organisation);
        const groupId = headers && headers.location && parseInt(headers.location.split('/').pop());
        if (groupId > 0) {
          selectActiveGroup({ id: groupId });
        }
      }
      catch(err) {
        // allow error to prevent closing of the modal
        throw new Error(err);
      }
    }
  }, [createGroup, formRef.current, organisation]);

  return (
    <FormModal
      // set defaults
      header="Add group"
      confirmText="Add group"
      confirmButtonProps={confirmButtonProps}
      // add given props
      {...props}
      // override given props
      size="md"
      valid={valid}
      onShow={useCallback(() => {
        setShown(true);
        fetchGroup(group);
      }, [group.id])}
      onClose={useCallback(() => {
        setShown(false);
        setName('');
      }, [])}
      form={(
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Form.Group as={Row} controlId="group_name">
            <Form.Label column sm={5}>
              Name
            </Form.Label>
            <Col sm={7}>
              <Form.Control
                ref={nameRef}
                type="text"
                name="group_name"
                isInvalid={!pristineName && !valid}
                value={name}
                onChange={e => {
                  const name = e.target.value;
                  setPristineName(false);
                  setName(name);
                  setValid(!!name);
                }}
              />
            </Col>
          </Form.Group>
          <Form.Control
            type="hidden"
            name="parent_id"
            defaultValue={group.id}
          />
          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Parent group
            </Form.Label>
            <Form.Label column sm={7}>
              <p>
                {groupTreeNamePath}
              </p>
              <Form.Text id="equipment_model_help" className="text-muted">
                Choose a different parent group by selecting it in the sidebar and pressing “Add”
              </Form.Text>
            </Form.Label>
          </Form.Group>
        </Form>
      )}
    >
      {children}
    </FormModal>
  );
}

const mapStateToProps = (state, { groupId }) => {
  return {
    groupId,
    group: getGroup(state, groupId),
    groupNode: getGroupTreeNode(state, groupId),
    groupTreeNamePath: getGroupTreeNamePath(state, groupId),
    organisation: getOrganisation(state),
  };
};
const mapDispatchToProps = {
  selectActiveGroup,
  fetchGroup,
  fetchOrganisationGroups,
  createGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddGroupFormModal);
