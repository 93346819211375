
import React, { Fragment, useCallback } from 'react';
import { connect } from 'react-redux';
import { Dropdown, ButtonGroup } from 'react-bootstrap';
import { DropdownTopWindowButton } from '../../../components/DropdownButton';
import moment from 'moment';

import {
  IoIosSchool,
  IoIosNotifications,
  IoIosNotificationsOff
} from "react-icons/io";

// Other
import Private from '../../../components/Private';
import RelearnModalForm from './RelearnModalForm';
import ArchiveDeviceModal from './ArchiveDeviceModal';

// actions
import { fetchDeviceInfo, updateDeviceMuteStatus } from '../actions';
import { getDevice } from '../selectors';

function DeviceContextMenu({
  as = ButtonGroup,
  title = '',
  device,
  fetchDeviceInfo,
  updateDeviceMuteStatus,
  // remove device id from props to spread
  deviceId,
  ...props
}) {

  const muteFor48h = useCallback(e => {
    e.preventDefault();
    updateDeviceMuteStatus(device, {
      time: moment.duration({ hours: 48 }).asSeconds(),
      timeString: '48 hours',
    });
  }, [device]);

  const muteFor1w = useCallback(e => {
    e.preventDefault();
    updateDeviceMuteStatus(device, {
      time: moment.duration({ week: 1 }).asSeconds(),
      timeString: '1 week',
    });
  }, [device]);

  const unmute = useCallback(e => {
    e.preventDefault();
    updateDeviceMuteStatus(device, { time: 0 });
  }, [device]);

  // skip if device has not yet been loaded
  if (!device) {
    return null;
  }

  return (
    <DropdownTopWindowButton
      as={as}
      title={title}
      // add defaults
      menuAlign="right"
      variant="outline-secondary"
      // allow overrides
      {...props}
    >
      { device.mute_advisory_for === 0 ? (
        <Fragment>
          <Dropdown.Header>Yellow notifications currently not muted</Dropdown.Header>
          <Dropdown.Item onClick={muteFor48h}>
            <IoIosNotificationsOff size="1.2em" /> <span>Mute yellow notifications for 48 hours</span>
          </Dropdown.Item>
          <Dropdown.Item onClick={muteFor1w}>
            <IoIosNotificationsOff size="1.2em" /> <span>Mute yellow notifications for 1 week</span>
          </Dropdown.Item>
        </Fragment>
      ) : device.mute_advisory_for > 0 ? (
        <Fragment>
          <Dropdown.Header>Yellow notifications currently muted for {
            moment.duration({ seconds: device.mute_advisory_for }).humanize()
          }</Dropdown.Header>
          <Dropdown.Item onClick={unmute}>
            <IoIosNotifications size="1.2em" /> <span>Unmute</span>
          </Dropdown.Item>
        </Fragment>
      ) : null}
      <Private minUserType="Admin">
        <Dropdown.Divider />
        <Dropdown.Header>Admin tools</Dropdown.Header>
        <RelearnModalForm deviceId={device.id}>
          <Dropdown.Item eventKey="2">
            <IoIosSchool size="1.2em" /> <span>Restart learning</span>
          </Dropdown.Item>
        </RelearnModalForm>
        <ArchiveDeviceModal
          deviceId={device.id}
          onSuccess={fetchDeviceInfo}
        />
      </Private>
    </DropdownTopWindowButton>
  );
}

const mapStateToProps = (state, { deviceId } ) => ({
  device: getDevice(state, deviceId),
});

export default connect(mapStateToProps, {
  fetchDeviceInfo,
  updateDeviceMuteStatus,
})(DeviceContextMenu);
