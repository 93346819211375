import React, { Fragment, useState, useEffect, useCallback, useMemo } from 'react';

import { Row, Col, Dropdown } from 'react-bootstrap';

// Imports for react-bootstrap-table2
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import "./table.scss";

// determine from props whether we should use our custom PaginatedTable component
function usePaginatedTable({ pagination, paginationOptions }) {
  // allow pagination === true to use our default pagination table.
  // but pagination can still be given a paginationFactory if desired
  // that will not use our custom PaginatedTable component
  return pagination === true || !!paginationOptions;
}

function DefaultLoadingDataIndication() {
  return (
    <div>Loading...</div>
  );
}

function DefaultNoDataIndication() {
  return (
    <div>No data</div>
  );
}

function DefaultNoFiliteredDataIndication({ searchProps: { searchText }={} }) {
  return `${searchText || ''}`.trim().length ? (
    <div>No search results for "{searchText}"</div>
  ) : (
    <div>No data</div>
  );
}

function BaseTable(props) {
  const {
    // things for our custom table
    loading,
    renderHeader,
    renderFooter,
    pagination,
    refreshHandler,
    loadingDataIndication = DefaultLoadingDataIndication,
    noDataIndication = DefaultNoDataIndication,
    noFilteredDataIndication = DefaultNoFiliteredDataIndication,
    // things for around the table
    searchProps,
    csvProps,
    columnToggleProps,
    paginationProps,
    setPaginationSizePerPage,
    customProps,
    // things for react-bootstrap-table2
    ...tableProps
  } = props;

  const {
    data = [],
    hasData = !!data.length,
  } = tableProps;

  const totalCount = props.data.length;
  const [dataSizeProps, setDataSizeProps] = useState({
    filteredCount: totalCount,
    totalCount,
  });

  const auxiliaryProps = {
    // defined by react-bootstrap-table2
    searchProps,
    csvProps,
    columnToggleProps,
    paginationProps,
    dataSizeProps,
    // custom state handlers
    setPaginationSizePerPage,
    customProps,
  };

  // if a refreshHandler is specified then set up a timer to execute it
  refreshHandler && useEffect(() => {
    // set a recurring interval to execute the refreshHandler
    const interval = setInterval(refreshHandler, 1000*60*5); // tick every 5 minutes
    // return a cleanup handler for when the component will unmount
    return () => clearInterval(interval);
  }, []);

  const noFilteredDataIndicationWithContext = useCallback(() => {
    return noFilteredDataIndication({ searchProps });
  }, [searchProps]);

  return (
    <Fragment>
      {typeof renderHeader === 'function' && renderHeader(auxiliaryProps)}
      <BootstrapTable
        // set style defaults, these can be overridden by given props
        bordered={false}
        striped
        hover
        responsive
        noDataIndication={noDataIndication}
        onDataSizeChange={({ dataSize }) => {
          setDataSizeProps({
            filteredCount: dataSize,
            totalCount,
          });
        }}
        // given props
        {...tableProps}
        // ensure pagination in an object
        pagination={typeof pagination === 'object' ? pagination : undefined}
        // override props
        bootstrap4
        // override display when there is data, but it is filtered to no results
        {...hasData ? {
          noDataIndication: noFilteredDataIndicationWithContext,
        } : {}}
        // override display when loading
        {...loading ? {
          noDataIndication: loadingDataIndication,
        } : {}}
      />
      {typeof renderFooter === 'function' && renderFooter(auxiliaryProps)}
    </Fragment>
  );
}

// accepts "paginationOptions" as an object of options
// and a custom renderFooter
function PaginatedTable(props) {

  const {
    pagination: ignore, // ignore flag so that it doesn't get passed down
    paginationOptions = {},
    renderFooter = renderPaginationFooter, // default to render inside a Col
    ...tableProps
  } = props;

  const [sizePerPage, setPaginationSizePerPage] = useState(paginationOptions.sizePerPage || 25);

  // only compute the paginationProp when its dependencies change
  const paginationProp = useMemo(() => paginationFactory({
    // set some sane default options
    custom: true,
    hidePageListOnlyOnePage: true,
    // add the given options
    ...paginationOptions,
    // todo: To avoid resetting to page 1 on every search change.
    // This is currently done because at this level of context we do not know the dataSize.
    // If you are viewing page 2 of data, then perform a search with 0 results
    // then React will throw an error because currently the table handles filtering internally,
    // and re-renders before firing the onDataSizeChange handler.
    // It is recommended to use switch to a remote table and handle all filtering
    // actions with our own handlers on the BaseTable component.
    // https://github.com/react-bootstrap-table/react-bootstrap-table2/issues/432
    // Declaring the pagination as custom=false, also allows the table to switch pages correctly
    // as this is done further down where it has the context of what the dataSize is.
    page: 1,
    // add stateful information
    sizePerPage,
  }), [
    // recompute when new sizePerPage state information is available
    sizePerPage,
    // react hooks may show a warning in development if the array size has changed
    // but regardless: it will compare arrays of different sizes correctly
    // and will do so in production without warning
    // see: https://github.com/facebook/react/blob/03944bfb0bdacfe35b2a1722426ff744ae47d018/packages/react-reconciler/src/ReactFiberHooks.js#L336-L358
    // allow shallow comparison of paginationOptions keys and values
    ...Object.keys(paginationOptions),
    ...Object.values(paginationOptions),
  ]);

  // these props are from the pagination provider,
  // and include props.paginationTableProps and props.paginationProps
  const renderTable = useCallback(({ paginationTableProps, ...otherPaginationProps }) => {
    return (
      <BaseTable
        // apply given props
        {...tableProps}
        // apply pagination props for the table
        {...paginationTableProps}
        // pass through pagination props for other table elements
        {...otherPaginationProps}
        // give a default footer if not specified
        renderFooter={renderFooter}
        // allow ability to set the pagination size
        setPaginationSizePerPage={setPaginationSizePerPage}
      />
    );
  }, [
    renderFooter,
    setPaginationSizePerPage,
    // recalculate if tableProp keys change
    ...Object.keys(tableProps),
    // recalculate if table prop values change
    ...Object.values(tableProps),
  ]);

  return (
    <PaginationProvider pagination={paginationProp}>
      {renderTable}
    </PaginationProvider>
  );
}

// accepts "search" as a boolean or object of options
function ToolkitTable(props) {
  const { data, columns, keyField='id', search } = props;

  // these props are from the toolkit provider,
  // and include baseProps, searchProps, csvProps, columnToggleProps
  const renderTable = useCallback(({ baseProps, ...otherToolkitProps }) => {
    const SubTable = usePaginatedTable(props) ? PaginatedTable : BaseTable;
    return (
      <SubTable
        // apply given props
        {...props}
        // apply toolkit props for the table
        {...baseProps}
        // pass through searchProps and other props for other table elements
        {...otherToolkitProps}
      />
    );
  }, [
    // change if shallow compare props has changed
    ...Object.keys(props),
    ...Object.values(props),
  ]);

  return (
    <ToolkitProvider
      keyField={keyField}
      data={data}
      columns={columns}
      // declare the table to be searchable or not, can be object or boolean
      search={typeof search === 'object' ? search : !!search}
    >
      {renderTable}
    </ToolkitProvider>
  );
}

// accepts "search" as a boolean or object of options
// accepts "pagination" as a boolean or results of a paginationFactory
// accepts "paginationOptions" as an object of options, that will combine with our default options
export default function Table({ search=true, pagination=false, ...props }) {
  // default all tables to searchable tables
  // default all tables to non-paginated tables

  // choose your table wisely
  return search ? (
    <ToolkitTable search={search} pagination={pagination} {...props} />
  ) : usePaginatedTable({ pagination, ...props }) ? (
    <PaginatedTable pagination={pagination} {...props} />
  ) : (
    <BaseTable {...props} />
  );
}

const { SearchBar } = Search;

// replace spaces with non-breaking spaces
function noBreakingSpaces(text) {
  return `${text}`.replace(/\s/g, '\u00A0');
}

export const renderPaginationBar = ({ paginationProps }) => {
  const {
    hidePageListOnlyOnePage,
    dataSize,
    sizePerPage,
  } = paginationProps;

  // hide if there is only only page
  if (hidePageListOnlyOnePage && dataSize <= sizePerPage) {
    return null;
  }
  // else return the component
  return (
    <div className="react-bootstrap-table2-pagination">
      <PaginationListStandalone { ...paginationProps }/>
    </div>
  );
};

const renderPaginationSize = ({ paginationProps, setPaginationSizePerPage: set }) => {
  // set max to the JS limit (Infinity causes an error :/)
  const max = Number.MAX_VALUE;
  const { sizePerPage } = paginationProps;
  const options = [5, 10, 25, 50, max];
  return (
    <Dropdown>
      <Dropdown.Toggle>
        Rows per page: {sizePerPage === max ? 'All' : sizePerPage}
      </Dropdown.Toggle>
      <Dropdown.Menu align="right">
        {options.map(value => (
          <Dropdown.Item
            key={value}
            onClick={() => set(value)}
            active={sizePerPage === value}
          >
            {value === max ? 'All' : value}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

const renderPaginationFooter = ({ paginationProps, setPaginationSizePerPage }) => (
  <Row className="mt-3">
    <Col xs="auto">
      {renderPaginationSize({ paginationProps, setPaginationSizePerPage })}
    </Col>
    <Col xs="auto" className="react-bootstrap-table2-pagination-align-right">
      {renderPaginationBar({ paginationProps })}
    </Col>
  </Row>
);

export const renderSearchBar = ({ searchProps }) => (
  <SearchBar
    className="align-middle d-inline-block react-bootstrap-table2-search-header"
    { ...searchProps }
  />
);

export const renderItemCountHeader = (props) => {
  return (
    <Row>
      <Col>
        <h6>
          {itemCount(props)}
        </h6>
      </Col>
    </Row>
  );
};

export function itemCount({ dataSizeProps={} }) {
  const {
    totalCount = 0,
    filteredCount = 0,
    itemName = 'row',
    itemsName = 'rows',
  } = dataSizeProps;

  if (totalCount !== filteredCount) {
    return noBreakingSpaces(`${filteredCount} of ${totalCount} ${itemsName}`);
  }
  else {
    // describe as plural if not 1
    return noBreakingSpaces(`${totalCount} ${totalCount !== 1 ? itemsName : itemName}`);
  }
}
