import React, { useState, useCallback } from 'react';
import { Container } from 'react-bootstrap';

import Title from '../../../components/Title';
import Private from '../../../components/Private';
import LoadingSpinner from '../../../components/LoadingSpinner';

export default function WhatsNew() {
  const [loading, setLoading] = useState(true);
  const onIframeLoad = useCallback(() => {
    setLoading(false);
  }, [setLoading]);
  return (
    // ensure only valid users can see this component
    <Private>
      <div className="d-flex flex-column position-absolute w-100 h-100">
        <div className="flex-grow-0">
          <Container fluid>
            <Title title="What's New"/>
          </Container>
        </div>
        <Container fluid className="d-block d-sm-none">
          <a
            href="https://portal.productboard.com/ghmeyjn8uc2jeqwugnvwkagc/tabs/9-released"
            target="_blank"
            rel="noopener noreferrer"
          >
            Click here to open the Roadmap in a new tab
          </a>
        </Container>
        {loading && (
          <div className="w-100 h-100 d-flex justify-content-center align-items-center">
            <LoadingSpinner />
          </div>
        )}
        <iframe
          title="Roadmap"
          className={`flex-grow-1 d-none d-sm-${!loading ? 'block' : 'none'} h-100 w-100`}
          frameBorder="0"
          src="https://portal.productboard.com/ghmeyjn8uc2jeqwugnvwkagc/tabs/9-released?hide_logo=1"
          onLoad={onIframeLoad}
        />
      </div>
    </Private>
  );
}
