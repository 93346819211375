import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';

import { Container, Button } from 'react-bootstrap';

import { fetchUsers } from '../actions';

import { LinkContainer } from 'react-router-bootstrap';

import {
  IoIosAddCircleOutline
} from "react-icons/io";

import Table, { itemCount } from '../../../components/Table';
import TableToolbar from '../../../components/TableToolbar';

import {
  userName,
  userEmail,
  userType,
  department,
  embeddedOrganisations,
  embeddedOrganisationsLastActive,
  createdAt,
} from '../columns';
import UserDropdown from '../components/UserDropdown';

import { getUsers, getUserListState } from '../selectors';

const defaultSorted = [{
  dataField: 'name',
  order: 'asc'
}];

const actions = {
  dataField: 'actions',
  text: 'Actions',
  formatter: (value, user) => (
    user && user.id ? (
      <div className="text-right">
        <UserDropdown userId={user && user.id} />
      </div>
    ) : "N/A"
  )
};

const columns = [
  userName,
  userEmail,
  userType,
  embeddedOrganisations,
  department,
  embeddedOrganisationsLastActive,
  createdAt,
  actions,
];

function UsersAdmin({ users=[], loading, lastFetch, error, fetchUsers }) {

  // fetch users on component mount
  useEffect(() => {
    fetchUsers();
  }, []);

  const [filteredUsers, setFilteredUsers] = useState(users);

  const search = useMemo(() => {
    return {
      afterSearch: setFilteredUsers,
    };
  }, [setFilteredUsers]);

  const ItemCount = useCallback(() => {
    return itemCount({
      dataSizeProps: {
        // count filtered (possible duplicate) users by number of unique ids
        filteredCount: [...new Set(filteredUsers.map(({ id }) => id))].length,
        totalCount: users.length,
        itemName: 'user',
        itemsName: 'users',
      },
    });
  }, [users, filteredUsers]); // recompute if any user objects are updated

  const Header = useCallback(props => {
    return (
      <TableToolbar
        searchable
        renderItemCount={ItemCount}
        title="Users Admin"
        loading={loading}
        lastFetch={lastFetch}
        error={error}
        tableProps={props}
        buttons={[
          <LinkContainer key="new" to={`/users/new`}>
            <Button variant="primary" className="pl-1">
              <IoIosAddCircleOutline className="mr-1" size="1.4em"/>Invite User
            </Button>
          </LinkContainer>
        ]}
      />
    );
  }, [loading, lastFetch, error, ItemCount]);

  const usersWithDuplicatesForOrganisations = useMemo(() => {
    return users
      // duplicate each user for each organisation that they are a apart of
      // so that we can show "User Memberships" data instead of just User data
      .reduce((list, user) => {
        const organisations = user._embedded && user._embedded.organisations;
        if (organisations && organisations.length > 1) {
          organisations.forEach(organisation => {
            list.push({
              ...user,
              // add key field to denote user memberships
              keyField: `${user.id}-${organisation.organisation_id}`,
              _embedded: {
                ...user._embedded,
                // show just one organisation per row
                organisations: [organisation],
              },
            });
          });
        }
        else {
          list.push({
            ...user,
            // add key field to denote user memberships
            keyField: user.id,
          });
        }
        return list;
      }, [])
      // add a duplicate embedded organisations_last_active for the last_active column to use
      .map(({ _embedded={}, ...user }={}) => ({
        ...user,
        _embedded: {
          ..._embedded,
          organisations_last_active: _embedded.organisations,
        },
      }));
  }, [users]); // recompute if any user objects are updated

  return (
    <Container fluid>
      <Table
        // key field is used to denote user memberships
        keyField="keyField"
        pagination
        search={search}
        renderHeader={Header}
        data={usersWithDuplicatesForOrganisations}
        defaultSorted={defaultSorted}
        columns={columns}
        noDataIndication={() => 'No users'}
        loading={loading}
      />
    </Container>
  );
}

const mapStateToProps = state => {
  const { loading, lastFetch, error } = getUserListState(state) || {};
  return {
    loading,
    lastFetch,
    error,
    users: getUsers(state),
  };
};
const mapDispatchToProps = { fetchUsers };

export default connect(mapStateToProps, mapDispatchToProps)(UsersAdmin);
