
import { CALL_API } from '../../lib/apiMiddleware';

export const ACTION_TYPES = {
  APP_LOAD: 'app/APP_LOAD',
  FOOTER_SIZE: 'app/FOOTER_SIZE',
  REQUEST_APP_VERSION_CHECK: 'app/REQUEST_APP_VERSION_CHECK',
  RECEIVE_APP_VERSION_CHECK: 'app/RECEIVE_APP_VERSION_CHECK',
  APP_VERSION_CHECK_FAILURE: 'app/APP_VERSION_CHECK_FAILURE',
  APP_EVENT: 'app/APP_EVENT',
  APP_PAGE: 'app/APP_PAGE',
};

// on app load
export function appLoad() {
  return {
    type: ACTION_TYPES.APP_LOAD,
  };
}

// cache last footer values
let lastFooterHeight;
let lastFooterWidth;
export function footerSize({ width, height }) {
  return dispatch => {
    if ((height !== lastFooterHeight) || (width !== lastFooterWidth)) {
      dispatch({
        type: ACTION_TYPES.FOOTER_SIZE,
        meta: {
          width,
          height,
        },
      });
      lastFooterHeight = height;
      lastFooterWidth = width;
    }
  };
}

export function trackEvent(name, properties) {
  return {
    type: ACTION_TYPES.APP_EVENT,
    eventName: name,
    // enforce snake case on all tracked event properties
    eventProperties: Object.entries(properties).reduce((acc, [key, value]) => {
      const snakeCaseKey = key
        .replace(/([A-Z]+)([a-z]|$)/g, '_$1$2') // add underscore
        .replace(/_+/g, '_') // remove duplicate underscores
        .toLowerCase(); // convert to lowercase
      acc[snakeCaseKey] = value;
      return acc;
    }, {}),
  };
}

export function trackPage(path) {
  return {
    type: ACTION_TYPES.APP_PAGE,
    path,
  };
}

// check app version

function requestAppVersionStatus(user) {
  return {
    type: ACTION_TYPES.REQUEST_APP_VERSION_CHECK,
    user,
  };
}

function receiveAppVersionStatus(user) {
  return {
    type: ACTION_TYPES.RECEIVE_APP_VERSION_CHECK,
    user,
  };
}

function appVersionStatusFailure(user) {
  return {
    type: ACTION_TYPES.APP_VERSION_CHECK_FAILURE,
    user,
  };
}

export function checkAppVersionStatus() {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      userMustBeAuthenticated: false,
      method: 'get',
      endpoint: `/appstatus/dashboard/${process.env.REACT_APP_MACHINECLOUD_DASH_VERSION}`,
      requestAction: requestAppVersionStatus(),
      successAction: receiveAppVersionStatus(),
      errorAction: appVersionStatusFailure(),
      errorToast: false,
    });
  };
}
