
import { ACTION_TYPES as APP_ACTION_TYPES } from '../modules/app/actions';
import { ACTION_TYPES as USER_ACTION_TYPES } from '../modules/user/actions';
import { ACTION_TYPES as ORGANISATION_ACTION_TYPES } from '../modules/organisation/actions';

import reducer from '../modules/reducers';
import { getUser } from '../modules/user/selectors';
import { getOrganisation } from '../modules/organisation/selectors';

// load Segment if available
if (window.analytics && process.env.REACT_APP_SEGMENT_KEY) {
  window.analytics.load(process.env.REACT_APP_SEGMENT_KEY);
  // add debugging only in dev
  window.analytics.debug(!!process.env.REACT_APP_SEGMENT_DEBUG);
}

// set default context
const app = {
  version: process.env.REACT_APP_MACHINECLOUD_DASH_VERSION,
  build: process.env.REACT_APP_TEAMCITY_BUILD_NUMBER,
};

function addContextToProperties(properties={}) {
  return {
    // add given properties
    ...properties,
    // override properties with app context
    app_version: app.version,
    app_build: app.build,
  };
}

function addContextToOptions(options={}) {
  return {
    ...options,
    // add context for Segment to follow
    // link: https://segment.com/docs/connections/spec/common/#context
    context: {
      ...options.context,
      app,
    },
  };
}

// determine whether Segment analytics is ready
const segmentIsReady = () => window.analytics && window.analytics.initialized;

// determine whether to send Segment events based on whether we are tracking this user
// link: https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/identity/
const userIsTrackable = () => segmentIsReady() && window.analytics.user().id();

// collect anonymous segment events in the background here
// which may be sent to sent to Segment later only if the user becomes known
const backlog = {
  identify: [],
  group: [],
  track: [],
  page: [],
};

// replicate Segment calls to hook in our custom functionality
// note: do *not* attempt to use window.analytics object directly: eg. const { analytics } = window
// Segment replaces the analytics property on the window global
const analytics = {
  // override identify to ensure correct user props are passed
  identify: function identify(userId, traits={}, options={}) {
    if (userId) {
      if (segmentIsReady()) {
        window.analytics.identify(userId,
          removeNullValues(addUserProps(whitelistProps(traits, whitelistedUserKeys))),
          removeNullValues(addContextToOptions(options))
        );
      }
      else {
        backlog.identify.push([userId, traits, { ...options, timestamp: new Date().toISOString() }]);
      }
    }
  },
  // override group to ensure correct org props are passed
  group: function group(organisationId, traits={}, options={}) {
    if (organisationId) {
      if (userIsTrackable()) {
        window.analytics.group(organisationId,
          removeNullValues(addOrgProps(whitelistProps(traits, whitelistedOrganisationKeys))),
          removeNullValues(addContextToOptions(options))
        );
      }
      else {
        backlog.group.push([organisationId, traits, { ...options, timestamp: new Date().toISOString() }]);
      }
    }
  },
  // override track to ensure correct context is passed
  track: function track(eventName, properties={}, options={}) {
    if (userIsTrackable()) {
      window.analytics.track(eventName,
        removeNullValues(addContextToProperties(properties)),
        removeNullValues(addContextToOptions(options))
      );
    }
    else {
      backlog.track.push([eventName, properties, { ...options, timestamp: new Date().toISOString() }]);
    }
  },
  // override page to ensure correct context is passed
  page: function page(pageName, properties={}, options={}) {
    if (userIsTrackable()) {
      window.analytics.page(pageName,
        removeNullValues(addContextToProperties(properties)),
        removeNullValues(addContextToOptions(options))
      );
    }
    else {
      backlog.page.push([pageName, properties, { ...options, timestamp: new Date().toISOString() }]);
    }
  },
  // pass reset method through
  reset: function reset() {
    if (segmentIsReady()) {
      window.analytics.reset();
    }
    // clear the Segment backlog, regardless of Segment ready status
    // run through each method
    Object.keys(backlog).forEach(method => {
      // and reset to an empty collection
      backlog[method] = [];
    });
  },
  // added method, flush collected Segment calls now that a userIsTrackable
  flush: function flush() {
    if (userIsTrackable()) {
      // run through each method
      Object.entries(backlog).forEach(([method, calls]) => {
        // run through each stored call of each method
        // and reset to an empty collection
        backlog[method] = calls.reduce((acc, args) => {
          // send segment event
          analytics[method](...args);
          return acc;
        }, []);
      });
    }
  }
};

// remove null values from payloads
// Totango in particular shows null as [object Object]
function removeNullValues(payload) {
  return Object.entries(payload).reduce((acc, [key, value]) => {
    // only add non-null values
    if (value !== null) {
      acc[key] = value;
    }
    return acc;
  }, {});
}

function whitelistProps(user={}, whitelistedKeys=[]) {
  return whitelistedKeys.reduce((acc, key) => {
    acc[key] = user[key];
    return acc;
  }, {});
}

// note: trait keys must not clash with reserved trait words in Segment:
// link: https://segment.com/docs/connections/spec/identify/#traits
const whitelistedUserKeys = [
  'id',                   // reserved trait: used as intended
  'email',                // reserved trait: used as intended
  'user_type',
  'name',                 // reserved trait: used as intended
  'last_sign_in_at',
  'created_at',           // reserved trait: used as intended
  'location',
  'receive_daily_email',
  'receive_alert_email',
  'receive_alert_sms',
  'mobile_phone',
  'country_code',
  'picture_url',
  // added reserved keys for Segment magic
  'phone',                // reserved trait: used as intended
  'avatar',               // reserved trait: used as intended
];

const addUserProps = user => {
  return {
    ...user,
    ...user['mobile_phone'] && {
      // add a normalised phone number
      'phone': `${
        // add a + sign if digits are present
        `${user['country_code'] || ''}`.replace(/[^\d]/g, '') ? '+' : ''
      }${
        // add the country code digits without spaces
        `${user['country_code'] || ''}`.trim().replace(/[^\d]/g, '')
      } ${
        // add the phone number digits with spaces, but remove duplicate spaces
        `${user['mobile_phone'] || ''}`.trim().replace(/\d /g, '')
      }`.trim(), // remove leading space if no country code is provided
    },
    ...user['picture_url'] && {
      'avatar': user['picture_url'],
    },
  };
};

// note: trait keys must not clash with reserved trait words in Segment:
// link: https://segment.com/docs/connections/spec/group/#traits
const whitelistedOrganisationKeys = [
  'id',                   // reserved trait: used as intended
  'name',                 // reserved trait: used as intended
  'sub_domain',
  'created_at',           // reserved trait: used as intended
  'updated_at',
  'parent_organisation_id',
  'is_parent',
  'logo_url',
  // added reserved keys for Segment magic
  'avatar',               // reserved trait: used as intended
];

const addOrgProps = organisation => {
  return {
    ...organisation,
    ...organisation['logo_url'] && {
      'avatar': organisation['logo_url'],
    },
  };
};

// check actions for relevant actions
const segmentReducers = {
  [APP_ACTION_TYPES.APP_LOAD](state) {
    // fire when app has loaded
    // note: app event is consistent with Segment auto generated mobile events
    // link: https://segment.com/docs/connections/spec/mobile/#application-installed
    analytics.track('Application Initialised');

    // if possible, identify the user upon opening the App
    // they may already be logged in and their state has just been rehydrated
    // link: https://segment.com/docs/guides/how-to-guides/best-practices-identify/#when-and-how-often-to-call-identify
    const user = getUser(state);
    // check if user exists on app load
    if (user && user.id) {
      // save user traits
      analytics.identify(user.id, user);
    }
    // check if org exists on app load
    const organisation = getOrganisation(state);
    if (organisation && organisation.id) {
      // save organisation traits
      analytics.group(organisation.id, organisation);
    }
  },
  // track page changes
  [APP_ACTION_TYPES.APP_PAGE](state, { path }) {
    analytics.page(path);
  },
  // track events
  [APP_ACTION_TYPES.APP_EVENT](state, { eventName, eventProperties }) {
    analytics.track(eventName, eventProperties);
  },
  [USER_ACTION_TYPES.RECEIVE_LOGIN]() {
    // be consistent make Segment semantic event
    // link: https://segment.com/docs/connections/spec/b2b-saas/#signed-in
    analytics.track('User Signed In');
  },
  [USER_ACTION_TYPES.LOGOUT]() {
    // be consistent make Segment semantic event
    // link: https://segment.com/docs/connections/spec/b2b-saas/#signed-out
    analytics.track('User Signed Out');
    // clear the Segment object of this user's context
    analytics.reset();
  },
  [USER_ACTION_TYPES.RECEIVE_USER](prevState, action) {
    // check if the user object is valid
    const nextState = reducer(prevState, action);
    const nextUser = getUser(nextState) || {};
    if (nextUser && nextUser.id) {
      // check if any relevant nextUser object values have changed
      const prevUser = getUser(prevState) || {};
      if (!whitelistedUserKeys.every(key => nextUser[key] === prevUser[key])) {
        // send relevant changes to segment
        analytics.identify(nextUser.id, nextUser);
      }
    }
  },
  [ORGANISATION_ACTION_TYPES.RECEIVE_ORGANISATION_LIST](prevState, action) {
    // check if the user object is valid
    const nextState = reducer(prevState, action);
    const nextOrganisation = getOrganisation(nextState) || {};
    if (nextOrganisation && nextOrganisation.id) {
      // check if any relevant organisation object values have changed
      const prevOrganisation = getOrganisation(prevState) || {};
      if (!whitelistedOrganisationKeys.every(key => {
        return nextOrganisation[key] === prevOrganisation[key];
      })) {
        // send relevant changes to segment
        analytics.group(nextOrganisation.id, nextOrganisation);
      }
    }
  },
};

// export redux middleware
export default store => next => async action => {

  const segmentReducer = segmentReducers[action.type];
  if (segmentReducer) {
    // ignore the persist key from redux-persist
    const { _persist, ...state } = store.getState();
    segmentReducer(state, action);
    // attempt to flush analytics backlog after every action
    // it won't flush if the user cannot be tracked yet
    analytics.flush();
  }

  // allow redux to continue
  return next(action);
};
