
import React, { useState } from 'react';
import { connect } from 'react-redux';

import { isAuthorised } from '../modules/user/selectors';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

let counter = 0;
function uniqueId() {
  return counter = counter >= Number.MAX_SAFE_INTEGER ? 0 : counter + 1;
}

// a consistent overlay for simple tooltips
function Overlay({ tooltip, children }) {
  // create a unique id for this component to use for the overlay popup control
  const [id] = useState(() => `private-tooltip-${uniqueId()}`, []);
  return (
    <OverlayTrigger
      placement="top"
      overlay={(
        <Tooltip id={id}>{tooltip}</Tooltip>
      )}
    >
      {children}
    </OverlayTrigger>
  );
}

// define that children are visible to only specific roles
function Private({
  children,
  isAuthorised,
  unauthorised=null,
  minUserType="User",
  className="d-inline-block",
}) {
  if (!isAuthorised) {
    return unauthorised;
  }
  return minUserType === 'Super Admin' ? (
    <Overlay tooltip={`Only visible to ${minUserType}s`}>
      <div className={className}>
        {children}
      </div>
    </Overlay>
  ) : children;
}

// isAuthorised is determined by the user, organisation, and ownProps
export default connect((state, { minUserType, minOrgType }) => {
  return {
    isAuthorised: isAuthorised(state, { minUserType, minOrgType })
  };
})(Private);
