import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import './appStatus.scss';

import { checkAppVersionStatus } from '../modules/app/actions';

import { addToast } from '../components/Toaster';

const appCheckPeriodMs = 1000 * 60 * 5; // 5 mins

function AppStatus({ children, checkAppVersionStatus }) {

  const [deprecated, setDeprecated] = useState(false);

  // check app version regularly
  useEffect(() => {

    let timeout;

    // check the status of the version according to the platform
    const checkAppStatus = async () => {

      // fetch platform answer (may be 404 meaning no status entry found for this version)
      const result = await checkAppVersionStatus()
        .then(result => result)
        .catch(error => (error && error.response) || { status: 404 });

      // found warning to display
      if (result.status === 200) {
        addToast({
          variant: 'default',
          header: (
            <div className="text-center">
              <p className="my-1">A new version of this dashboard is available.</p>
              <p className="my-1">Please refresh the page or click Update to use it.</p>
              <Button className="my-2" onClick={() => {
                // reload the current page
                window.location.reload();
                // return false so that onClick doesn't continue propagating the event
                // where this reload may end up being cancelled by the presence of any other action
                return false;
              }}>
                Update
              </Button>
            </div>
          ),
          timeout: false,
          dismissable: false,
        });
        // if this version is deprecated,
        // add a wall to block the user from interacting with the dashboard
        if (result && result.data && result.data.deprecated) {
          setDeprecated(true);
        }
      }
      // check again, and replace timeout on each recursion
      else {
        timeout = setTimeout(checkAppStatus, appCheckPeriodMs);
      }
    };

    // start the check
    checkAppStatus();

    // clean up function
    return () => clearTimeout(timeout);
  }, []);

  return (
    <Fragment>
      {children}
      {deprecated && (
        <div className="app-blocker" />
      )}
    </Fragment>
  );
}

const mapDispatchToProps = { checkAppVersionStatus };

export default connect(null, mapDispatchToProps)(AppStatus);
