import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Card, ListGroup, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ReactEcharts from 'echarts-for-react';

import moment from 'moment';

import { fetchDevices } from '../../equipment/actions';
import { fetchAlarms } from '../../alarm/actions';

import "./home.scss";

import Title from '../../../components/Title';
import Private from '../../../components/Private';
import Table from '../../../components/Table';
import { useColumnsWithVisibility } from '../../../components/table/utils';
import { getDeviceConditionColour } from '../../equipment/components/StatusIndicator';

import AlarmActivity from '../../alarm/components/AlarmActivity';
import { ChartContext } from '../../../components/charts/DeviceSamplesContext';
import { BaseChartBody } from '../../../components/charts/BaseChart';
import {
  GroupUtilisationChart,
  OrganisationUtilisationChart,
} from '../../../components/charts/EquipmentUtilisationChart';

import {
  alarmTimestamp,
} from '../../alarm/columns';

import {
  rms,
  mm2,
  temperature,
  equipmentName,
  staleness,
  customAlarmThresholds,
  conditionOverall,
  running,
  fmLastHeardHoursDanger,
  fmLastHeardHoursWarning,
} from '../../equipment/columns';

import { getDeviceListState, getDevices } from '../../equipment/selectors';
import { getAlarmListState, getAlarms } from '../../alarm/selectors';
import {
  getOrganisationRmsAvailablePreference,
  getOrganisationMm2AvailablePreference,
  getActiveSubGroupId,
} from '../../organisation/selectors';

const echartStyle = { width: '100%' };
const echartSeriesStyle = {
  type: 'pie',
  radius: ['30%', '70%'],
  center: ['50%', '60%'],
  avoidLabelOverlap: false,
  label: {
    normal: {
      show: false,
      position: 'center',
    },
  },
  labelLine: {
    normal: {
      show: false,
    }
  },
  color: ['#81c784', '#fff176', '#e57373', '#dddddd'],
};

// define these tables to have small default page sizes
const paginationOptions = {
  sizePerPage: 5,
};

function Home(props) {
  const {
    activeSubGroupId,
    devices = [],
    deviceListState = {},
    alarms = [],
    alarmListState = {},
    fetchDevices,
    fetchAlarms,
    rmsAvailable,
    mm2Available,
  } = props;

  // switch between organisation and group utilisation depending on active selection
  const UtilisationChart = activeSubGroupId ? GroupUtilisationChart : OrganisationUtilisationChart;

  useEffect(() => {
    fetchAlarms();
  }, []);

  // refresh devices if the active sub group changes
  useEffect(() => {
    fetchDevices();
  }, [activeSubGroupId]);

  const alarmsWithRms = alarms.map(fields => ({ ...fields, rms_ai: fields.rms }));
  const onboardedDevices = devices
    // split out conditional_overall into duplicate attributes on each item
    .map(({ condition_overall, ...deviceAttrs }) => {
      return {
        ...deviceAttrs,
        status: condition_overall,
        condition_overall,
        degradation: condition_overall,
      };
    });

  const onboardedDevicesArray = Object.values(onboardedDevices);
  const hasDevices = onboardedDevices.length > 0;

  const devicesHeardFromWithinWarning = onboardedDevicesArray
    // filter to "now is before _ hours after the last point"
    .filter(device => moment().isBefore(moment(device.fitmachine_last_heard).add({ hours: fmLastHeardHoursWarning })));

  const devicesHeardFromWithinDanger = onboardedDevicesArray
    // filter to "now is after _ hours after the last point"
    // and "now is before __ hours after the last point"
    .filter(device => moment().isBetween(
      moment(device.fitmachine_last_heard).add({ hours: fmLastHeardHoursWarning }),
      moment(device.fitmachine_last_heard).add({ hours: fmLastHeardHoursDanger })
    ));

  const devicesHeardFromNotWithinDanger = onboardedDevicesArray
    // filter to "now is after __ hours after the last point"
    .filter(device => moment().isAfter(moment(device.fitmachine_last_heard).add({ hours: fmLastHeardHoursDanger })));

  // count the colours
  const deviceConditionsCount = devices.map(getDeviceConditionColour).reduce((acc, colour) => {
    return {
      ...acc,
      [colour]: (acc[colour] || 0) + 1,
    };
  }, {});

  return (
    <div className="home">
      <Container fluid>
        <Title
          title="Home"
          loading={deviceListState.loading || alarmListState.loading}
          lastFetch={deviceListState.lastFetch}
          error={deviceListState.error}
        />
        <Row>
          <Col xl="auto" className="flow-col">
            <Card className="text-center shadow-sm">
              <Card.Header>
                <h2 className="mt-1">Fleet condition</h2>
                <Link to="/equipment/list">
                  <Button className="mb-0">All equipment</Button>
                </Link>
              </Card.Header>
              <ReactEcharts
                style={echartStyle}
                option={{
                  tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b}: {c} ({d}%)',
                  },
                  legend: {
                    orient: 'vertical',
                    x: 'left',
                    data: [
                      'Healthy',
                      'Advisory',
                      'Alert',
                      'Unknown',
                    ],
                  },
                  series: [
                    {
                      ...echartSeriesStyle,
                      name: 'Condition',
                      data: [
                        {
                          value: deviceConditionsCount['green'],
                          name: 'Healthy',
                        },
                        {
                          value: deviceConditionsCount['yellow'],
                          name: 'Advisory',
                        },
                        {
                          value: deviceConditionsCount['red'],
                          name: 'Alert',
                        },
                        {
                          value: deviceConditionsCount['grey'],
                          name: 'Unknown',
                        },
                      ],
                    },
                  ],
                }}
              />
              <p className="lead">
                {
                  devices.length
                    ? Math.round(100 * deviceConditionsCount['green']/devices.length)
                    : 0
                }% of devices are healthy
              </p>
            </Card>
          </Col>
          <Col xl="auto" className="flow-col-2x">
            <Card className="text-center shadow-sm">
              <Card.Header>
                <h2 className="mt-1">
                  {activeSubGroupId ? 'Group' : 'Organisation'} Utilisation
                </h2>
                <Link to="/equipment/list">
                  <Button className="mb-0">All equipment</Button>
                </Link>
              </Card.Header>
              <ChartContext>
                {props => (
                  <UtilisationChart
                    // render with custom body
                    CustomBaseChart={BaseChartBody}
                    // show full range when data loads
                    startWithFullRange={true}
                    {...props}
                  />
                )}
              </ChartContext>
            </Card>
          </Col>
          <Col xl="auto" className="flow-col">
            <Card className="shadow-sm">
              <Card.Header>
                <h2 className="mt-1">Worst condition equipment</h2>
                <Link to="/equipment/list" >
                  <Button className="mb-0">All equipment</Button>
                </Link>
              </Card.Header>
              <ListGroup className="list-group-flush">
                <Table
                  paginationOptions={paginationOptions}
                  data={onboardedDevices}
                  defaultSorted={[{
                    dataField: 'condition_overall',
                    order: 'desc'
                  }]}
                  columns={useColumnsWithVisibility([
                    equipmentName,
                    staleness,
                    customAlarmThresholds,
                    conditionOverall,
                    running,
                  ], {
                    'threshold': !!onboardedDevices.find(customAlarmThresholds.hasValue),
                  })}
                  noDataIndication={() => 'No equipment'}
                  loading={deviceListState.loading}
                  renderFooter={null}
                  refreshHandler={fetchDevices}
                />
              </ListGroup>
            </Card>
          </Col>
          <Col xl="auto" className="flow-col">
            <Card className="shadow-sm">
              <Card.Header>
                <h2 className="mt-1">Most recent alarms</h2>
                <Link to="/alarms" >
                  <Button className="mb-0">All alarms</Button>
                </Link>
              </Card.Header>
              <ListGroup className="list-group-flush">
                <Table
                  paginationOptions={paginationOptions}
                  data={alarmsWithRms}
                  defaultSorted={[{
                    dataField: 'alarm_timestamp',
                    order: 'desc'
                  }]}
                  columns={useColumnsWithVisibility([
                    alarmTimestamp,
                    equipmentName,
                    customAlarmThresholds,
                    conditionOverall,
                  ], {
                    'threshold': !!alarmsWithRms.find(customAlarmThresholds.hasValue),
                  })}
                  noDataIndication={() => 'No alarms'}
                  loading={alarmListState.loading}
                  renderFooter={null}
                  refreshHandler={fetchAlarms}
                />
              </ListGroup>
            </Card>
          </Col>
          <Col xl="auto" className="flow-col">
            <Card className="shadow-sm">
              <Card.Header>
                <h2 className="mt-1">Highest vibrating equipment</h2>
                <Link to="/equipment/list" >
                  <Button className="mb-0">All equipment</Button>
                </Link>
              </Card.Header>
              <Table
                paginationOptions={paginationOptions}
                data={onboardedDevices}
                defaultSorted={[{
                  dataField: 'rms',
                  order: 'desc'
                }]}
                columns={useColumnsWithVisibility([
                  equipmentName,
                  staleness,
                  customAlarmThresholds,
                  rms,
                  mm2,
                  running,
                ], {
                  'rms': !!rmsAvailable,
                  'rms2': !!mm2Available,
                  'threshold': !!onboardedDevices.find(customAlarmThresholds.hasValue),
                })}
                noDataIndication={() => 'No equipment'}
                loading={deviceListState.loading}
                renderFooter={null}
              />
            </Card>
          </Col>
          <Col xl="auto" className="flow-col">
            <Card className="shadow-sm">
              <Card.Header>
                <h2 className="mt-1">Highest temperature equipment</h2>
                <Link to="/equipment/list" >
                  <Button className="mb-0">All equipment</Button>
                </Link>
              </Card.Header>
              <Table
                paginationOptions={paginationOptions}
                data={onboardedDevices}
                defaultSorted={[{
                  dataField: 'temperature',
                  order: 'desc'
                }]}
                columns={useColumnsWithVisibility([
                  equipmentName,
                  staleness,
                  customAlarmThresholds,
                  temperature,
                  running,
                ], {
                  'threshold': !!onboardedDevices.find(customAlarmThresholds.hasValue),
                })}
                noDataIndication={() => 'No equipment'}
                loading={deviceListState.loading}
                renderFooter={null}
              />
            </Card>
          </Col>
          <Col xl="auto" className="flow-col">
            <Card className="text-center shadow-sm">
              <Card.Header>
                <h2 className="mt-1">Devices reporting</h2>
                <Private minUserType="Admin">
                  <Link to="/devices/admin">
                    <Button className="mb-0">Device admin</Button>
                  </Link>
                </Private>
              </Card.Header>
              <ReactEcharts
                style={echartStyle}
                option={{
                  tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b}: {c} ({d}%)',
                  },
                  legend: {
                    orient: 'vertical',
                    x: 'left',
                    data: [
                      `Within last ${fmLastHeardHoursWarning} hrs`,
                      `Between ${fmLastHeardHoursWarning} & ${fmLastHeardHoursDanger} hrs ago`,
                      `More than ${fmLastHeardHoursDanger} hrs ago`,
                      'Never',
                    ],
                  },
                  series: [
                    {
                      ...echartSeriesStyle,
                      name: 'Devices Reporting',
                      data: !hasDevices ? [] : [
                        {
                          value: devicesHeardFromWithinWarning.length,
                          name: `Within last ${fmLastHeardHoursWarning} hrs`,
                        },
                        {
                          value: devicesHeardFromWithinDanger.length,
                          name: `Between ${fmLastHeardHoursWarning} & ${fmLastHeardHoursDanger} hrs ago`,
                        },
                        {
                          value: devicesHeardFromNotWithinDanger.length,
                          name: `More than ${fmLastHeardHoursDanger} hrs ago`,
                        },
                        {
                          // ensure this value can never be negative
                          value: Math.max(0, onboardedDevicesArray.length - (
                            devicesHeardFromWithinWarning.length +
                            devicesHeardFromWithinDanger.length +
                            devicesHeardFromNotWithinDanger.length
                          )),
                          name: 'Never',
                        },
                      ],
                    },
                  ],
                }}
              />
              <p className="lead">
                {devicesHeardFromWithinWarning.length}/
                {onboardedDevicesArray.length} devices reported in the last {fmLastHeardHoursWarning} hours
              </p>
            </Card>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col xs={12}>
            <AlarmActivity listId={activeSubGroupId} />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    activeSubGroupId: getActiveSubGroupId(state),
    deviceListState: getDeviceListState(state),
    alarmListState: getAlarmListState(state),
    devices: getDevices(state),
    alarms: getAlarms(state),
    rmsAvailable: getOrganisationRmsAvailablePreference(state),
    mm2Available: getOrganisationMm2AvailablePreference(state),
  };
};
const mapDispatchToProps = { fetchDevices, fetchAlarms };

export default connect(mapStateToProps, mapDispatchToProps)(Home);
