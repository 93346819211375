
import React, { useState, useEffect } from 'react';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

// import defaults from single date picker for consistency
import { dateFormat } from './SingleDatePicker';
import './singleDatePicker.scss';

// startDate and endDate can be specified in ms or be undefined
export default function DateRangePickerWrapper(props) {

  const {
    startDate: startTime,
    endDate: endTime,
    onDatesChange,
    ...otherProps
  } = props;

  const [focusedInput, setFocusedInput] = useState(null);
  const [dateRange, setDateRange] = useState({
    startDate: moment(startTime),
    endDate: moment(endTime),
  });

  useEffect(() => {
    setDateRange({
      startDate: moment(startTime),
      endDate: moment(endTime),
    });
  }, [startTime, endTime]);

  return (
    <DateRangePicker
      // defaults
      minimumNights={0}
      // given
      {...otherProps}
      // overrides
      readOnly={true} // prevent users from typing invalid strings
      focusedInput={focusedInput}
      onFocusChange={setFocusedInput}
      displayFormat={dateFormat}
      startDate={dateRange.startDate}
      endDate={dateRange.endDate}
      startDatePlaceholderText={startTime ? moment(startTime).format(dateFormat) : undefined}
      endDatePlaceholderText={endTime ? moment(endTime).format(dateFormat) : undefined}
      onDatesChange={({ startDate, endDate }) => {
        // react-dates passes `null` if a value is undefined
        const newRange = { ...dateRange };
        if (startDate) {
          newRange.startDate = startDate;
        }
        if (endDate) {
          newRange.endDate = endDate;
        }
        // modify current date range
        setDateRange(newRange);

        // pass values up if a callback was specified and dates have changed
        if (onDatesChange && (
          // if start differs
          !dateRange.startDate.isSame(newRange.startDate) ||
          // or if end differs
          !dateRange.endDate.isSame(newRange.endDate)
        )) {
          onDatesChange(newRange);
        }
      }}
    />
  );
}