import React, { Component } from 'react';
import './AlarmsList.scss';
import volImage1 from '../../images/Vibration/vib_1.png';
import volImage2 from '../../images/Vibration/vib_2.png';
import volImage3 from '../../images/Vibration/vib_3.png';
import volImage4 from '../../images/Vibration/vib_4.png';
import volImage5 from '../../images/Vibration/vib_5.png';
import volImage6 from '../../images/Vibration/vib_6.png';
import volImage7 from '../../images/Vibration/vib_7.png';
import volImage8 from '../../images/Vibration/vib_8.png';
import volImage9 from '../../images/Vibration/vib_9.png';
import volImage10 from '../../images/Vibration/vib_10.png';

export function calculateLevel(rms_value, isoClass) {
  switch(isoClass) {
    case 'Class I':
      if (rms_value === 0) {
        return 1;
      } else if (rms_value < 2.8) {
        return ((rms_value / 2.8) * 3) + 1;
      } else if (rms_value < 7.1) {
        return (((rms_value - 2.8) / (7.1 - 2.8)) * 3) + 4;
      } else if (rms_value < 45) {
        return (((rms_value - 7.1) / (45 - 7.1)) * 3) + 7;
      } else {
        return 10;
      }
    case 'Class II':
      if (rms_value === 0) {
        return 1;
      } else if (rms_value < 4.5) {
        return ((rms_value / 4.5) * 3) + 1;
      } else if (rms_value < 11.2) {
        return (((rms_value - 4.5) / (11.2 - 4.5)) * 3) + 4;
      } else if (rms_value < 45) {
        return (((rms_value - 11.2) / (45 - 11.2)) * 3) + 7;
      } else {
        return 10;
      }
    case 'Class III':
      if (rms_value === 0) {
        return 1;
      } else if (rms_value < 7.1) {
        return ((rms_value / 7.1) * 3) + 1;
      } else if (rms_value < 18) {
        return (((rms_value - 7.1) / (18 - 7.1)) * 3) + 4;
      } else if (rms_value < 45) {
        return (((rms_value - 18) / (45 - 18)) * 3) + 7;
      } else {
        return 10;
      }
    case 'Class IV':
      if (rms_value === 0) {
        return 1;
      } else if (rms_value < 11.2) {
        return ((rms_value / 11.2) * 3) + 1;
      } else if (rms_value < 28) {
        return (((rms_value - 11.2) / (28 - 11.2)) * 3) + 4;
      } else if (rms_value < 45) {
        return (((rms_value - 28) / (45 - 28)) * 3) + 7;
      } else {
        return 10;
      }
    default:
      return 1;
  }
}

class VolumeImage extends Component {
  constructor(props) {
    super(props);
    this.getImage = this.getImage.bind(this);
  }

  getImage(calculatedValue) {
    switch (calculatedValue) {
      case 1:
        return volImage1;
      case 2:
        return volImage2;
      case 3:
        return volImage3;
      case 4:
        return volImage4;
      case 5:
        return volImage5;
      case 6:
        return volImage6;
      case 7:
        return volImage7;
      case 8:
        return volImage8;
      case 9:
        return volImage9;
      case 10:
        return volImage10;
      default:
        return volImage1;
    }
  }

  render() {
    var {rms_value, isoClass} = this.props;

    if ( isNaN(rms_value) ) {
      return "N/A";
    } else {
      var calculatedImageValue = Math.ceil(calculateLevel(rms_value, isoClass));
      return (
        <img src={this.getImage(calculatedImageValue)} alt={this.props.rms_value} id="volumeImage" />
      );
    }
  }
}

export default VolumeImage;
