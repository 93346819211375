import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Container } from 'react-bootstrap';
import { IoIosNotificationsOutline } from "react-icons/io";

import { fetchAlarms } from '../actions';

import { calculateLevel } from '../VolumeImage';

import Table from '../../../components/Table';
import TableToolbar from '../../../components/TableToolbar';
import { useColumnsWithVisibility } from '../../../components/table/utils';
import EmptyCard from '../../../components/EmptyCard';

import {
  rms,
  mm2,
  temperature,
  siteName,
  subAreaName,
  equipmentName,
  organisationName,
  conditionOverall,
  conditionVolume,
  conditionVibration,
  conditionTemperature,
  customAlarmThresholdsExpanded,
} from '../../equipment/columns';

import {
  alarmTimestamp,
} from '../columns';

import { getAlarms, getAlarmListState } from '../selectors';
import {
  getOrganisationMm2AvailablePreference,
  getOrganisationRmsAvailablePreference,
  getActiveSubGroupId,
} from '../../organisation/selectors';
import { fetchDevices } from '../../equipment/actions';

function NoDataIndication() {
  return (
    <EmptyCard Icon={IoIosNotificationsOutline}>
      <EmptyCard.Body>
        <EmptyCard.Title>Your equipment hasn’t triggered any alarms yet.</EmptyCard.Title>
        <EmptyCard.Text>
          To begin triggering alarms,
          ensure an admin has completed deploying all devices onto the equipment.
        </EmptyCard.Text>
        <EmptyCard.Text>
          Once you start receiving alarms,
          they will be listed here in the Alarms Log.
        </EmptyCard.Text>
      </EmptyCard.Body>
      <EmptyCard.UniversityFooter />
    </EmptyCard>
  );
}

const defaultSorted = [{
  dataField: 'alarm_timestamp',
  order: 'desc'
}];

const columns = [
  alarmTimestamp,
  siteName,
  { ...subAreaName, dataField: 'site_subarea_name' },
  equipmentName,
  organisationName,
  customAlarmThresholdsExpanded,
  conditionOverall,
  conditionVolume,
  conditionVibration,
  conditionTemperature,
  rms,
  mm2,
  temperature,
];

function Alarms({
  activeSubGroupId,
  alarms=[],
  fetchAlarms,
  fetchDevices,
  loading,
  lastFetch,
  error,
  rmsAvailable,
  mm2Available,
}) {

  useEffect(() => {
    fetchAlarms();
  }, []);

  // refresh devices if the active sub group changes
  useEffect(() => {
    if (activeSubGroupId) {
      fetchDevices();
    }
  }, [activeSubGroupId]);

  const refresh = useCallback(() => {
    fetchAlarms();
    fetchDevices();
  }, [fetchAlarms, fetchDevices]);

  const renderHeader = useCallback(props => {
    return (
      <TableToolbar
        searchable
        title="Alarms"
        loading={loading}
        lastFetch={lastFetch}
        error={error}
        tableProps={props}
      />
    );
  }, [loading, lastFetch, error]);

  const filteredColumns = useColumnsWithVisibility(columns, {
    'rms': !!rmsAvailable,
    'rms2': !!mm2Available,
    'threshold': !!alarms.find(customAlarmThresholdsExpanded.hasValue),
  });

  return (
    <Container fluid>
      <Table
        pagination
        renderHeader={renderHeader}
        defaultSorted={defaultSorted}
        noDataIndication={() => <NoDataIndication />}
        loading={loading}
        columns={filteredColumns}
        data={alarms.map(fields => ({
          ...fields,
          condition_volume: calculateLevel(fields.rms, fields.equipment_iso_class),
        }))}
        refreshHandler={refresh}
      />
    </Container>
  );
}

const mapStateToProps = state => {
  const alarms = getAlarms(state);
  const { loading, lastFetch, error } = getAlarmListState(state);
  return {
    alarms,
    loading,
    lastFetch,
    error,
    activeSubGroupId: getActiveSubGroupId(state),
    rmsAvailable: getOrganisationRmsAvailablePreference(state),
    mm2Available: getOrganisationMm2AvailablePreference(state),
  };
};

const mapDispatchToProps = { fetchAlarms, fetchDevices };

export default connect(mapStateToProps, mapDispatchToProps)(Alarms);
