import log from './log';

export const MAC_ADDRESS_REGEX = '([0-9A-F]{2}:){5}([0-9A-F]{2})';

export function capitaliseFirstChar(text='') {
  return text.substr(0, 1).toUpperCase() + text.substr(1);
}

export function isFloatEqual(a, b) {
  // convert both numbers to floating points before comparing
  // if one is the conversion to and from of the other then this should be equal:
  // e.g. 0.9 !== 0.9*0.01/0.01 (0.9000000000000001)
  // but: Math.fround(0.9) === Math.fround(0.9*0.01/0.01)
  // as both are 0.8999999761581421 as floating points
  // use parseFloat to deal with empty strings consistently (NaN instead of 0)
  return Math.fround(parseFloat(a)) === Math.fround(parseFloat(b));
}

// from https://davidwalsh.name/javascript-debounce-function
// from underscore.js
// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
export function debounce(func, wait, immediate) {
  let timeout;
  return function(...args) {
    const later = () => {
      timeout = null;
      if (!immediate) {
        func.apply(this, args);
      }
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) {
      func.apply(this, args);
    }
  };
};

/*
 * Takes a form submit event,
 * cancels the default submission,
 * and returns the form values as a keyed object
 * with all the form's input values
 * {
 *   [input.name]: input.value
 * }
 */
export function getFormValues(e, form = e.target.form || e.target) {
  e.preventDefault();
  e.stopPropagation();
  // if there are no form elements this should return undefined
  // (because the target is probably not a form)
  return form.elements && [...form.elements].reduce((acc, element ) => {
    const { name, value, type } = element;
    if (name) {
      if (type === "checkbox") {
        acc[name] = !!element.checked; // checkbox values should be boolean
      } else if (type === "radio") {
        if (element.checked) {
          acc[name] = element.value; // radio values should be strings
        }
      } else if (element.localName === "select" && element.multiple) {
        acc[name] = [...element.selectedOptions].map(option => option.value);
      } else {
        acc[name] = value;
      }
    }
    return acc;
  }, {});
}

/*
 * get or create and get a cache namespaced under 'movus':
 * eg getUserCacheNamespace(1, 'device/1')
 */
export async function getUserCacheNamespace(userId, namespace) {

  if (!userId) {
    throw new Error('User ID needed for caching');
  }

  if ('caches' in window) {
    try {
      // remove leading and trailing slashes
      const cleanNamespace = namespace.split('/').filter(v => v).join('/');

      // return cacheAPI or a mock cacheAPI
      return namespace
        // this operation may be insecure in Firefox private mode
        ? await caches.open(`movus/user/${userId}/${cleanNamespace}`)
        : null;
    }
    catch(e) {
      log.error(new Error(`Cannot read cache: ${e && e.message}`), e);
    }
  }
};

/*
 * Drop all caches under a namespace:
 * eg dropCacheNamespace('movus/user/1/device/1')
 * or dropCacheNamespace('movus')
 */
async function dropCacheNamespace(partialNamespace) {
  if ('caches' in window) {
    try {
      // this operation may be insecure in Firefox private mode
      const cacheKeys = await caches.keys();
      const matchingCacheKeys = cacheKeys.filter(cacheKey => {
        return cacheKey.startsWith(partialNamespace);
      });
      return Promise.all(matchingCacheKeys.map(cacheKey => {
        return caches.delete(cacheKey);
      }));
    }
    catch(e) {
      log.error(new Error(`Cannot remove cache: ${e && e.message}`), e);
    }
  }
};

/*
 * Drop all caches under a user namespace:
 * eg dropUserCacheNamespace(1, 'device/1')
 */
export async function dropUserCacheNamespace(userId, namespace) {

  if (!userId) {
    throw new Error('User ID needed for caching');
  }

  return namespace
    ? dropCacheNamespace(`movus/user/${userId}/${namespace}`)
    : dropCacheNamespace(`movus/user/${userId}`);
};

/*
 * Drop entire movus cache
 */
export async function dropCache() {
  return dropCacheNamespace('movus');
};
