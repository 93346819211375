import { CALL_API } from '../../lib/apiMiddleware';

import {
  getOrganisation,
  getOrganisationStaleState,
} from './selectors';

// action types
export const ACTION_TYPES = {

  SELECT_ACTIVE_GROUP: 'organisations/SELECT_ACTIVE_GROUP',

  REQUEST_BEGIN: 'organisations/REQUEST_BEGIN',
  REQUEST_FAIL: 'organisations/REQUEST_FAIL',

  RECEIVE_ORGANISATION_LIST: 'organisations/RECEIVE_ORGANISATION_LIST',

  REQUEST_ORGANISATION_GROUPS: 'organisations/REQUEST_ORGANISATION_GROUPS',
  RECEIVE_ORGANISATION_GROUPS: 'organisations/RECEIVE_ORGANISATION_GROUPS',
  ORGANISATION_GROUPS_FAILURE: 'organisations/ORGANISATION_GROUPS_FAILURE',

  REQUEST_GROUP: 'organisations/REQUEST_GROUP',
  RECEIVE_GROUP: 'organisations/RECEIVE_GROUP',
  GROUP_FAILURE: 'organisations/GROUP_FAILURE',

  REQUEST_CREATE_GROUP: 'organisations/REQUEST_CREATE_GROUP',
  RECEIVE_CREATE_GROUP: 'organisations/RECEIVE_CREATE_GROUP',
  CREATE_GROUP_FAILURE: 'organisations/CREATE_GROUP_FAILURE',

  REQUEST_UPDATE_GROUP: 'organisations/REQUEST_UPDATE_GROUP',
  RECEIVE_UPDATE_GROUP: 'organisations/RECEIVE_UPDATE_GROUP',
  UPDATE_GROUP_FAILURE: 'organisations/UPDATE_GROUP_FAILURE',

  REQUEST_DELETE_GROUP: 'organisations/REQUEST_DELETE_GROUP',
  RECEIVE_DELETE_GROUP: 'organisations/RECEIVE_DELETE_GROUP',
  DELETE_GROUP_FAILURE: 'organisations/DELETE_GROUP_FAILURE',

  REQUEST_GROUP_MEMBERS: 'organisations/REQUEST_GROUP_MEMBERS',
  RECEIVE_GROUP_MEMBERS: 'organisations/RECEIVE_GROUP_MEMBERS',
  GROUP_MEMBERS_FAILURE: 'organisations/GROUP_MEMBERS_FAILURE',

  REQUEST_GROUP_MEMBERS_AVAILABLE: 'organisations/REQUEST_GROUP_MEMBERS_AVAILABLE',
  RECEIVE_GROUP_MEMBERS_AVAILABLE: 'organisations/RECEIVE_GROUP_MEMBERS_AVAILABLE',
  GROUP_MEMBERS_AVAILABLE_FAILURE: 'organisations/GROUP_MEMBERS_AVAILABLE_FAILURE',

  REQUEST_GROUP_RUNTIME: 'organisations/REQUEST_GROUP_RUNTIME',
  RECEIVE_GROUP_RUNTIME: 'organisations/RECEIVE_GROUP_RUNTIME',
  GROUP_RUNTIME_FAILURE: 'organisations/GROUP_RUNTIME_FAILURE',

  REQUEST_ORGANISATION_RUNTIME: 'organisations/REQUEST_ORGANISATION_RUNTIME',
  RECEIVE_ORGANISATION_RUNTIME: 'organisations/RECEIVE_ORGANISATION_RUNTIME',
  ORGANISATION_RUNTIME_FAILURE: 'organisations/ORGANISATION_RUNTIME_FAILURE',

  REQUEST_ORGANISATION_TAGS: 'organisations/REQUEST_ORGANISATION_TAGS',
  RECEIVE_ORGANISATION_TAGS: 'organisations/RECEIVE_ORGANISATION_TAGS',
  ORGANISATION_TAGS_FAILURE: 'organisations/ORGANISATION_TAGS_FAILURE',

  REQUEST_ORGANISATION_TOKENS: 'organisations/REQUEST_ORGANISATION_TOKENS',
  RECEIVE_ORGANISATION_TOKENS: 'organisations/RECEIVE_ORGANISATION_TOKENS',
  ORGANISATION_TOKENS_FAILURE: 'organisations/ORGANISATION_TOKENS_FAILURE',

  REQUEST_ORGANISATION_TOKENS_CREATE: 'organisations/REQUEST_ORGANISATION_TOKENS_CREATE',
  RECEIVE_ORGANISATION_TOKENS_CREATE: 'organisations/RECEIVE_ORGANISATION_TOKENS_CREATE',
  ORGANISATION_TOKENS_CREATE_FAILURE: 'organisations/ORGANISATION_TOKENS_CREATE_FAILURE',

  REQUEST_ORGANISATION_TOKENS_DELETE: 'organisations/REQUEST_ORGANISATION_TOKENS_DELETE',
  RECEIVE_ORGANISATION_TOKENS_DELETE: 'organisations/RECEIVE_ORGANISATION_TOKENS_DELETE',
  ORGANISATION_TOKENS_DELETE_FAILURE: 'organisations/ORGANISATION_TOKENS_DELETE_FAILURE',

  REQUEST_ORGANISATION_TOKENS_EDIT: 'organisations/REQUEST_ORGANISATION_TOKENS_EDIT',
  RECEIVE_ORGANISATION_TOKENS_EDIT: 'organisations/RECEIVE_ORGANISATION_TOKENS_EDIT',
  ORGANISATION_TOKENS_EDIT_FAILURE: 'organisations/ORGANISATION_TOKENS_EDIT_FAILURE',

  REQUEST_STREAMING_CONFIGURATIONS: 'organisations/REQUEST_STREAMING_CONFIGURATIONS',
  RECEIVE_STREAMING_CONFIGURATIONS: 'organisations/RECEIVE_STREAMING_CONFIGURATIONS',
  STREAMING_CONFIGURATIONS_FAILURE: 'organisations/STREAMING_CONFIGURATIONS_FAILURE',

  REQUEST_STREAMING_CONFIGURATIONS_EDIT: 'organisations/REQUEST_STREAMING_CONFIGURATIONS_EDIT',
  RECEIVE_STREAMING_CONFIGURATIONS_EDIT: 'organisations/RECEIVE_STREAMING_CONFIGURATIONS_EDIT',
  STREAMING_CONFIGURATIONS_EDIT_FAILURE: 'organisations/STREAMING_CONFIGURATIONS_EDIT_FAILURE',

  REQUEST_STREAMING_CONFIGURATIONS_TEST: 'organisations/REQUEST_STREAMING_CONFIGURATIONS_TEST',
  RECEIVE_STREAMING_CONFIGURATIONS_TEST: 'organisations/RECEIVE_STREAMING_CONFIGURATIONS_TEST',
  STREAMING_CONFIGURATIONS_TEST_FAILURE: 'organisations/STREAMING_CONFIGURATIONS_TEST_FAILURE',

  REQUEST_STREAMING_CONFIGURATIONS_DELETE: 'organisations/REQUEST_STREAMING_CONFIGURATIONS_DELETE',
  RECEIVE_STREAMING_CONFIGURATIONS_DELETE: 'organisations/RECEIVE_STREAMING_CONFIGURATIONS_DELETE',
  STREAMING_CONFIGURATIONS_DELETE_FAILURE: 'organisations/STREAMING_CONFIGURATIONS_DELETE_FAILURE',

  REQUEST_ORGANISATION_INFO: 'organisations/REQUEST_ORGANISATION_INFO',
  RECEIVE_ORGANISATION_INFO: 'organisations/RECEIVE_ORGANISATION_INFO',
  ORGANISATION_INFO_FAILURE: 'organisations/ORGANISATION_INFO_FAILURE',

  REQUEST_CREATE_ORGANISATION: 'organisations/REQUEST_CREATE_ORGANISATION',
  RECEIVE_CREATE_ORGANISATION: 'organisations/RECEIVE_CREATE_ORGANISATION',
  CREATE_ORGANISATION_FAILURE: 'organisations/CREATE_ORGANISATION_FAILURE',

  REQUEST_CREATE_CHILD_ORGANISATION: 'organisations/REQUEST_CREATE_CHILD_ORGANISATION',
  RECEIVE_CREATE_CHILD_ORGANISATION: 'organisations/RECEIVE_CREATE_CHILD_ORGANISATION',
  CREATE_CHILD_ORGANISATION_FAILURE: 'organisations/CREATE_CHILD_ORGANISATION_FAILURE',

  REQUEST_UPDATE_ORGANISATION: 'organisations/REQUEST_UPDATE_ORGANISATION',
  RECEIVE_UPDATE_ORGANISATION: 'organisations/RECEIVE_UPDATE_ORGANISATION',
  UPDATE_ORGANISATION_FAILURE: 'organisations/UPDATE_ORGANISATION_FAILURE',

  REQUEST_ARCHIVE_ORGANISATION: 'organisations/REQUEST_ARCHIVE_ORGANISATION',
  RECEIVE_ARCHIVE_ORGANISATION: 'organisations/RECEIVE_ARCHIVE_ORGANISATION',
  ARCHIVE_ORGANISATION_FAILURE: 'organisations/ARCHIVE_ORGANISATION_FAILURE',

  REQUEST_ORGANISATION_LOGO: 'organisations/REQUEST_ORGANISATION_LOGO',
  RECEIVE_ORGANISATION_LOGO: 'organisations/RECEIVE_ORGANISATION_LOGO',
  ORGANISATION_LOGO_FAILURE: 'organisations/ORGANISATION_LOGO_FAILURE',

  REQUEST_ORGANISATION_LOGO_UPLOAD_S3_FILE_URL: 'organisations/REQUEST_ORGANISATION_LOGO_UPLOAD_S3_FILE_URL',
  RECEIVE_ORGANISATION_LOGO_UPLOAD_S3_FILE_URL: 'organisations/RECEIVE_ORGANISATION_LOGO_UPLOAD_S3_FILE_URL',
  ORGANISATION_LOGO_UPLOAD_S3_FILE_URL_FAILURE: 'organisations/ORGANISATION_LOGO_UPLOAD_S3_FILE_URL_FAILURE',
};

export function selectActiveGroup(group) {
  return dispatch => {
    return dispatch({
      type: ACTION_TYPES.SELECT_ACTIVE_GROUP,
      payload: group.id,
      meta: { group },
    });
  };
}

export function requestBegin(response) {
  return {
    type: ACTION_TYPES.REQUEST_BEGIN,
    response
  };
}

export function requestFail(response) {
  return {
    type: ACTION_TYPES.REQUEST_FAIL,
    response
  };
}

export function receiveOrganisationList() {
  return {
    type: ACTION_TYPES.RECEIVE_ORGANISATION_LIST
  };
}

export function fetchOrganisations() {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: '/organisations',
      requestAction: requestBegin(),
      successAction: receiveOrganisationList(),
      errorAction: requestFail()
    });
  };
}

export function requestOrganisationGroups(organisation) {
  return {
    type: ACTION_TYPES.REQUEST_ORGANISATION_GROUPS,
    organisation,
  };
}

export function receiveOrganisationGroups(organisation) {
  return {
    type: ACTION_TYPES.RECEIVE_ORGANISATION_GROUPS,
    organisation,
  };
}

export function organisationGroupsFailure(organisation) {
  return {
    type: ACTION_TYPES.ORGANISATION_GROUPS_FAILURE,
    organisation,
  };
}

export function fetchOrganisationGroups(organisation) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: '/groups',
      requestAction: requestOrganisationGroups(organisation),
      successAction: receiveOrganisationGroups(organisation),
      errorAction: organisationGroupsFailure(organisation),
    });
  };
}

export function requestGroup(group) {
  return {
    type: ACTION_TYPES.REQUEST_GROUP,
    group,
  };
}

export function receiveGroup(group) {
  return {
    type: ACTION_TYPES.RECEIVE_GROUP,
    group,
  };
}

export function groupFailure(group) {
  return {
    type: ACTION_TYPES.GROUP_FAILURE,
    group,
  };
}

export function fetchGroup(group) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/groups/${group.id}`,
      requestAction: requestGroup(group),
      successAction: receiveGroup(group),
      errorAction: groupFailure(group),
    });
  };
}

export function requestCreateGroup(group) {
  return {
    type: ACTION_TYPES.REQUEST_CREATE_GROUP,
    group,
  };
}

export function receiveCreateGroup(group) {
  return {
    type: ACTION_TYPES.RECEIVE_CREATE_GROUP,
    group,
  };
}

export function createGroupFailure(group) {
  return {
    type: ACTION_TYPES.CREATE_GROUP_FAILURE,
    group,
  };
}

export function createGroup(group) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'post',
      endpoint: `/groups/`,
      data: group,
      requestAction: requestCreateGroup(group),
      successAction: receiveCreateGroup(group),
      errorAction: createGroupFailure(group),
      successToast: 'Group created',
      // don't add errorToast, allow validation errors to be seen
    });
  };
}

export function requestUpdateGroup(group) {
  return {
    type: ACTION_TYPES.REQUEST_UPDATE_GROUP,
    group,
  };
}

export function receiveUpdateGroup(group) {
  return {
    type: ACTION_TYPES.RECEIVE_UPDATE_GROUP,
    group,
  };
}

export function updateGroupFailure(group) {
  return {
    type: ACTION_TYPES.UPDATE_GROUP_FAILURE,
    group,
  };
}

export function updateGroup(group, data) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'put',
      endpoint: `/groups/${group.id}`,
      data,
      requestAction: requestUpdateGroup(group),
      successAction: receiveUpdateGroup(group),
      errorAction: updateGroupFailure(group),
      successToast: 'Group updated',
      // don't add errorToast, allow validation errors to be seen
    });
  };
}

export function requestDeleteGroup(group) {
  return {
    type: ACTION_TYPES.REQUEST_DELETE_GROUP,
    group,
  };
}

export function receiveDeleteGroup(group) {
  return {
    type: ACTION_TYPES.RECEIVE_DELETE_GROUP,
    group,
  };
}

export function deleteGroupFailure(group) {
  return {
    type: ACTION_TYPES.DELETE_GROUP_FAILURE,
    group,
  };
}

export function deleteGroup(group, { force }={}) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'delete',
      endpoint: `/groups/${group.id}`,
      data: { force },
      requestAction: requestDeleteGroup(group),
      successAction: receiveDeleteGroup(group),
      errorAction: deleteGroupFailure(group),
      successToast: 'Group removed',
      // don't add errorToast, allow validation errors to be seen
    });
  };
}

export function requestGroupMembers(group) {
  return {
    type: ACTION_TYPES.REQUEST_GROUP_MEMBERS,
    group,
  };
}

export function receiveGroupMembers(group) {
  return {
    type: ACTION_TYPES.RECEIVE_GROUP_MEMBERS,
    group,
  };
}

export function groupMembersFailure(group) {
  return {
    type: ACTION_TYPES.GROUP_MEMBERS_FAILURE,
    group,
  };
}

export function fetchGroupMembers(group) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/groups/${group.id}/members`,
      requestAction: requestGroupMembers(group),
      successAction: receiveGroupMembers(group),
      errorAction: groupMembersFailure(group),
    });
  };
}

export function requestGroupMembersAvailable(group) {
  return {
    type: ACTION_TYPES.REQUEST_GROUP_MEMBERS_AVAILABLE,
    group,
  };
}

export function receiveGroupMembersAvailable(group) {
  return {
    type: ACTION_TYPES.RECEIVE_GROUP_MEMBERS_AVAILABLE,
    group,
  };
}

export function groupMembersAvailableFailure(group) {
  return {
    type: ACTION_TYPES.GROUP_MEMBERS_AVAILABLE_FAILURE,
    group,
  };
}

export function fetchGroupMembersAvailable(group) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/groups/${group.id}/available`,
      requestAction: requestGroupMembersAvailable(group),
      successAction: receiveGroupMembersAvailable(group),
      errorAction: groupMembersAvailableFailure(group),
    });
  };
}

export function requestAddGroupDevices(group) {
  return {
    type: ACTION_TYPES.REQUEST_ADD_GROUP_DEVICES,
    group,
  };
}

export function receiveAddGroupDevices(group) {
  return {
    type: ACTION_TYPES.RECEIVE_ADD_GROUP_DEVICES,
    group,
  };
}

export function addGroupDevicesFailure(group) {
  return {
    type: ACTION_TYPES.ADD_GROUP_DEVICES_FAILURE,
    group,
  };
}

export function addGroupDevices(group, deviceIds) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'put',
      endpoint: `/groups/${group.id}/members`,
      // payload is a device id list
      data: { devices: deviceIds },
      requestAction: requestAddGroupDevices(group),
      successAction: receiveAddGroupDevices(group),
      errorAction: addGroupDevicesFailure(group),
    });
  };
}

export function requestDeleteGroupDevices(group) {
  return {
    type: ACTION_TYPES.REQUEST_DELETE_GROUP_DEVICES,
    group,
  };
}

export function receiveDeleteGroupDevices(group) {
  return {
    type: ACTION_TYPES.RECEIVE_DELETE_GROUP_DEVICES,
    group,
  };
}

export function deleteGroupDevicesFailure(group) {
  return {
    type: ACTION_TYPES.DELETE_GROUP_DEVICES_FAILURE,
    group,
  };
}

export function deleteGroupDevices(group, deviceIds=[]) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'delete',
      endpoint: `/groups/${group.id}/members`,
      // payload is a device id list
      data: { devices: deviceIds },
      requestAction: requestDeleteGroupDevices(group),
      successAction: receiveDeleteGroupDevices(group),
      errorAction: deleteGroupDevicesFailure(group),
    });
  };
}

export function requestGroupRuntime(group) {
  return {
    type: ACTION_TYPES.REQUEST_GROUP_RUNTIME,
    group
  };
}

export function receiveGroupRuntime(group) {
  return {
    type: ACTION_TYPES.RECEIVE_GROUP_RUNTIME,
    group
  };
}

export function groupRuntimeFailure(group) {
  return {
    type: ACTION_TYPES.GROUP_RUNTIME_FAILURE,
    group
  };
}

export function fetchGroupRuntime(group, { view='summary' }={}) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/groups/${group.id}/runtime?view=${view}`,
      requestAction: requestGroupRuntime(group),
      successAction: receiveGroupRuntime(group),
      errorAction: groupRuntimeFailure(group),
    });
  };
}

export function requestOrganisationRuntime(organisation) {
  return {
    type: ACTION_TYPES.REQUEST_ORGANISATION_RUNTIME,
    organisation
  };
}

export function receiveOrganisationRuntime(organisation) {
  return {
    type: ACTION_TYPES.RECEIVE_ORGANISATION_RUNTIME,
    organisation
  };
}

export function organisationRuntimeFailure(organisation) {
  return {
    type: ACTION_TYPES.ORGANISATION_RUNTIME_FAILURE,
    organisation
  };
}

export function fetchOrganisationRuntime(organisation, { view='summary' }={}) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/organisations/${organisation.id}/runtime?view=${view}`,
      requestAction: requestOrganisationRuntime(organisation),
      successAction: receiveOrganisationRuntime(organisation),
      errorAction: organisationRuntimeFailure(organisation),
    });
  };
}

export function requestCreateOrganisation() {
  return {
    type: ACTION_TYPES.REQUEST_CREATE_ORGANISATION
  };
}

export function receiveCreateOrganisation() {
  return {
    type: ACTION_TYPES.RECEIVE_CREATE_ORGANISATION
  };
}

export function createFailure() {
  return {
    type: ACTION_TYPES.CREATE_ORGANISATION_FAILURE
  };
}

export function submitNewOrganisationDetails(details) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'post',
      endpoint: '/organisations',
      data: details,
      requestAction: requestCreateOrganisation(),
      successAction: receiveCreateOrganisation(),
      errorAction: createFailure(),
      successToast: 'Organisation created',
      // don't add errorToast, allow validation errors to be seen
      // it's probably a subdomain already taken message
    });
  };
}

export function requestCreateChildOrganisation() {
  return {
    type: ACTION_TYPES.REQUEST_CREATE_CHILD_ORGANISATION
  };
}

export function receiveCreateChildOrganisation() {
  return {
    type: ACTION_TYPES.RECEIVE_CREATE_CHILD_ORGANISATION
  };
}

export function createChildFailure() {
  return {
    type: ACTION_TYPES.CREATE_CHILD_ORGANISATION_FAILURE
  };
}

export function submitNewChildOrganisationDetails(parent_organisation_id, payload) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'post',
      endpoint: `/organisations/${parent_organisation_id}/organisations`,
      data: payload,
      requestAction: requestCreateChildOrganisation(),
      successAction: receiveCreateChildOrganisation(),
      errorAction: createChildFailure(),
      successToast: 'Organisation created',
      // don't add errorToast, allow validation errors to be seen
      // it's probably a subdomain already taken message
    });
  };
}

function requestOrganisationTags(organisation) {
  return {
    type: ACTION_TYPES.REQUEST_ORGANISATION_TAGS,
    organisation,
  };
}

function receiveOrganisationTags(organisation) {
  return {
    type: ACTION_TYPES.RECEIVE_ORGANISATION_TAGS,
    organisation,
  };
}

function organisationTagsFailure(organisation) {
  return {
    type: ACTION_TYPES.ORGANISATION_TAGS_FAILURE,
    organisation,
  };
}

function requestOrganisationTokens(organisation) {
  return {
    type: ACTION_TYPES.REQUEST_ORGANISATION_TOKENS,
    organisation,
  };
}

function receiveOrganisationTokens(organisation) {
  return {
    type: ACTION_TYPES.RECEIVE_ORGANISATION_TOKENS,
    organisation,
  };
}

function organisationTokensFailure(organisation) {
  return {
    type: ACTION_TYPES.ORGANISATION_TOKENS_FAILURE,
    organisation,
  };
}

function requestOrganisationTokensCreate(organisation) {
  return {
    type: ACTION_TYPES.REQUEST_ORGANISATION_TOKENS_CREATE,
    organisation,
  };
}

function receiveOrganisationTokensCreate(organisation) {
  return {
    type: ACTION_TYPES.RECEIVE_ORGANISATION_TOKENS_CREATE,
    organisation,
  };
}

function organisationTokensCreateFailure(organisation) {
  return {
    type: ACTION_TYPES.ORGANISATION_TOKENS_CREATE_FAILURE,
    organisation,
  };
}

function requestOrganisationTokensEdit(organisation, token, description) {
  return {
    type: ACTION_TYPES.REQUEST_ORGANISATION_TOKENS_EDIT,
    organisation,
    token,
    description,
  };
}

function receiveOrganisationTokensEdit(organisation, token, description) {
  return {
    type: ACTION_TYPES.RECEIVE_ORGANISATION_TOKENS_EDIT,
    organisation,
    token,
    description,
  };
}

function organisationTokensEditFailure(organisation, token, description) {
  return {
    type: ACTION_TYPES.ORGANISATION_TOKENS_EDIT_FAILURE,
    organisation,
    token,
    description,
  };
}

function requestOrganisationTokensDelete(organisation, token) {
  return {
    type: ACTION_TYPES.REQUEST_ORGANISATION_TOKENS_DELETE,
    organisation,
    token,
  };
}

function receiveOrganisationTokensDelete(organisation, token) {
  return {
    type: ACTION_TYPES.RECEIVE_ORGANISATION_TOKENS_DELETE,
    organisation,
    token,
  };
}

function organisationTokensDeleteFailure(organisation, token) {
  return {
    type: ACTION_TYPES.ORGANISATION_TOKENS_DELETE_FAILURE,
    organisation,
    token,
  };
}

function requestStreamingConfigurations(organisation) {
  return {
    type: ACTION_TYPES.REQUEST_STREAMING_CONFIGURATIONS,
    organisation,
  };
}

function receiveStreamingConfigurations(organisation) {
  return {
    type: ACTION_TYPES.RECEIVE_STREAMING_CONFIGURATIONS,
    organisation,
  };
}

function streamingConfigurationsFailure(organisation) {
  return {
    type: ACTION_TYPES.STREAMING_CONFIGURATIONS_FAILURE,
    organisation,
  };
}

function requestStreamingConfigurationsEdit(organisation) {
  return {
    type: ACTION_TYPES.REQUEST_STREAMING_CONFIGURATIONS_EDIT,
    organisation,
  };
}

function receiveStreamingConfigurationsEdit(organisation) {
  return {
    type: ACTION_TYPES.RECEIVE_STREAMING_CONFIGURATIONS_EDIT,
    organisation,
  };
}

function streamingConfigurationsEditFailure(organisation) {
  return {
    type: ACTION_TYPES.STREAMING_CONFIGURATIONS_EDIT_FAILURE,
    organisation,
  };
}

function requestStreamingConfigurationsTest() {
  return {
    type: ACTION_TYPES.REQUEST_STREAMING_CONFIGURATIONS_TEST,
  };
}

function receiveStreamingConfigurationsTest() {
  return {
    type: ACTION_TYPES.RECEIVE_STREAMING_CONFIGURATIONS_TEST,
  };
}

function streamingConfigurationsTestFailure() {
  return {
    type: ACTION_TYPES.STREAMING_CONFIGURATIONS_TEST_FAILURE,
  };
}

function requestStreamingConfigurationsDelete(organisation, streamId) {
  return {
    type: ACTION_TYPES.REQUEST_STREAMING_CONFIGURATIONS_DELETE,
    organisation,
    streamId,
  };
}

function receiveStreamingConfigurationsDelete(organisation, streamId) {
  return {
    type: ACTION_TYPES.RECEIVE_STREAMING_CONFIGURATIONS_DELETE,
    organisation,
    streamId,
  };
}

function streamingConfigurationsDeleteFailure(organisation, streamId) {
  return {
    type: ACTION_TYPES.STREAMING_CONFIGURATIONS_DELETE_FAILURE,
    organisation,
    streamId,
  };
}

export function fetchOrganisationPreferences(organisation) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/organisations/${organisation.id}/tags`,
      requestAction: requestOrganisationTags(organisation),
      successAction: receiveOrganisationTags(organisation),
      errorAction: organisationTagsFailure(organisation),
    });
  };
}

export function fetchOrganisationTokens(organisation) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/organisations/${organisation.id}/tokens`,
      requestAction: requestOrganisationTokens(organisation),
      successAction: receiveOrganisationTokens(organisation),
      errorAction: organisationTokensFailure(organisation),
    });
  };
}

export function createOrganisationToken(organisation, description) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'post',
      endpoint: `/organisations/${organisation.id}/tokens`,
      data: { description },
      requestAction: requestOrganisationTokensCreate(organisation),
      successAction: receiveOrganisationTokensCreate(organisation),
      errorAction: organisationTokensCreateFailure(organisation),
      successToast: 'Token created',
      errorToast: 'Create failed',
    });
  };
}

export function editOrganisationToken(organisation, token, description) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'put',
      endpoint: `/organisations/${organisation.id}/tokens/${token}`,
      data: { description },
      requestAction: requestOrganisationTokensEdit(organisation, token, description),
      successAction: receiveOrganisationTokensEdit(organisation, token, description),
      errorAction: organisationTokensEditFailure(organisation, token, description),
      successToast: 'Token edited',
      errorToast: 'Edit failed',
    });
  };
}

export function deleteOrganisationToken(organisation, token) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'delete',
      endpoint: `/organisations/${organisation.id}/tokens/${token}`,
      requestAction: requestOrganisationTokensDelete(organisation, token),
      successAction: receiveOrganisationTokensDelete(organisation, token),
      errorAction: organisationTokensDeleteFailure(organisation, token),
      successToast: 'Token removed',
      errorToast: 'Remove failed',
    });
  };
}

export function fetchStreamingConfiguration(organisation) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/organisations/${organisation.id}/streams`,
      requestAction: requestStreamingConfigurations(organisation),
      successAction: receiveStreamingConfigurations(organisation),
      errorAction: streamingConfigurationsFailure(organisation),
    });
  };
}

export function createStreamingConfiguration(organisation, data) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'post',
      endpoint: `/organisations/${organisation.id}/streams`,
      data: data,
      requestAction: requestOrganisationTokensCreate(organisation),
      successAction: receiveOrganisationTokensCreate(organisation),
      errorAction: organisationTokensCreateFailure(organisation),
      successToast: 'Streaming Configuration created',
      errorToast: 'Create failed',
    });
  };
}

export function editStreamingConfiguration(organisation, streamId, data = {}) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'put',
      endpoint: `/organisations/${organisation.id}/streams/${streamId}`,
      data: data,
      requestAction: requestStreamingConfigurationsEdit(organisation),
      successAction: receiveStreamingConfigurationsEdit(organisation),
      errorAction: streamingConfigurationsEditFailure(organisation),
      successToast: 'Configuration edited',
      errorToast: 'Edit failed',
    });
  };
}

export function testStreamingConfiguration(organisation, streamId) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/organisations/${organisation.id}/streams/${streamId}/status`,
      requestAction: requestStreamingConfigurationsTest(),
      successAction: receiveStreamingConfigurationsTest(),
      errorAction: streamingConfigurationsTestFailure(),
      successToast: 'Configuration test submitted',
      errorToast: 'Test submission failed',
    });
  };
}

export function deleteStreamingConfiguration(organisation, streamId) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'delete',
      endpoint: `/organisations/${organisation.id}/streams/${streamId}`,
      requestAction: requestStreamingConfigurationsDelete(organisation, streamId),
      successAction: receiveStreamingConfigurationsDelete(organisation, streamId),
      errorAction: streamingConfigurationsDeleteFailure(organisation, streamId),
      successToast: 'Configuration removed',
      errorToast: 'Remove failed',
    });
  };
}

// add alias: preferences are tags
export const fetchOrganisationTags = fetchOrganisationPreferences;

function requestOrganisationInfo(organisation) {
  return {
    type: ACTION_TYPES.REQUEST_ORGANISATION_INFO,
    organisation
  };
}

function receiveOrganisationInfo(organisation) {
  return {
    type: ACTION_TYPES.RECEIVE_ORGANISATION_INFO,
    organisation
  };
}

export function organisationInfoFailure(organisation) {
  return {
    type: ACTION_TYPES.ORGANISATION_INFO_FAILURE,
    organisation
  };
}

export function fetchOrganisationIfStale(organisation={}) {
  return (dispatch, getState) => {
    const state = getState();
    // fetch org if it is currently stale
    if (getOrganisationStaleState(state)) {
      // pass full organisation object
      return fetchOrganisationWithId(getOrganisation(state, organisation.id))(dispatch);
    }
  };
}
export function fetchOrganisationWithId(organisation) {
  return dispatch => {
    dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/organisations/${organisation.id}`,
      requestAction: requestOrganisationInfo(organisation),
      successAction: receiveOrganisationInfo(organisation),
      errorAction: organisationInfoFailure(organisation),
    });
  };
}

// organisation details update

function requestUpdateOrganisation(organisation) {
  return {
    type: ACTION_TYPES.REQUEST_UPDATE_ORGANISATION,
    organisation
  };
}

function receiveUpdateOrganisation(organisation) {
  return {
    type: ACTION_TYPES.RECEIVE_UPDATE_ORGANISATION,
    organisation
  };
}

function updateFailure(organisation) {
  return {
    type: ACTION_TYPES.UPDATE_ORGANISATION_FAILURE,
    organisation
  };
}

export function submitOrganisationDetails(organisation, details) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'put',
      endpoint: `/organisations/${organisation.id}`,
      data: details,
      requestAction: requestUpdateOrganisation(organisation),
      successAction: receiveUpdateOrganisation(organisation),
      errorAction: updateFailure(organisation),
      successToast: 'Organisation updated',
      errorToast: 'Update failed'
    });
  };
}

function requestArchiveOrganisation(organisation) {
  return {
    type: ACTION_TYPES.REQUEST_ARCHIVE_ORGANISATION,
    organisation
  };
}

function receiveArchiveOrganisation(organisation) {
  return {
    type: ACTION_TYPES.RECEIVE_ARCHIVE_ORGANISATION,
    organisation
  };
}

function archiveFailure(organisation) {
  return {
    type: ACTION_TYPES.ARCHIVE_ORGANISATION_FAILURE,
    organisation
  };
}

export function archiveOrganisation(organisation) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'put',
      data: {
        archived: true,
      },
      endpoint: `/organisations/${organisation.id}`,
      requestAction: requestArchiveOrganisation(organisation),
      successAction: receiveArchiveOrganisation(organisation),
      errorAction: archiveFailure(organisation),
      successToast: "Organisation Archived",
      errorToast: "Archive Failed"
    });
  };
}

// upload organisation logo
export function getLogoS3FileUrl(organisation, data = {}) {
  const {
    file_name = new Date().toISOString(), // default filename to current time
  } = data;
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'put',
      endpoint: `/organisations/${organisation.id}/logo`,
      data: { file_name },
      requestAction: {
        organisation,
        type: ACTION_TYPES.REQUEST_ORGANISATION_LOGO_UPLOAD_S3_FILE_URL
      },
      successAction: {
        organisation,
        type: ACTION_TYPES.RECEIVE_ORGANISATION_LOGO_UPLOAD_S3_FILE_URL
      },
      errorAction: {
        organisation,
        type: ACTION_TYPES.ORGANISATION_LOGO_UPLOAD_S3_FILE_URL_FAILURE
      },
    });
  };
}

// fetch Organisation Logo
function requestOrganisationLogo(organisation) {
  return {
    type: ACTION_TYPES.REQUEST_ORGANISATION_LOGO,
    organisation
  };
}

function receiveOrganisationLogo(organisation) {
  return {
    type: ACTION_TYPES.RECEIVE_ORGANISATION_LOGO,
    organisation
  };
}

export function organisationLogoFailure(organisation) {
  return {
    type: ACTION_TYPES.ORGANISATION_LOGO_FAILURE,
    organisation
  };
}

export function fetchOrganisationLogo(organisation) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/organisations/${organisation.id}/logo`,
      requestAction: requestOrganisationLogo(organisation),
      successAction: receiveOrganisationLogo(organisation),
      errorAction: organisationLogoFailure(organisation),
    });
  };
}