import axios from 'axios';
import { getUserCacheNamespace } from './utils';
import { ACTION_TYPES } from '../modules/user/actions';
import { addToast } from '../components/Toaster';
import log from './log';
import { getUser, getUserToken } from '../modules/user/selectors';

export const CALL_API = 'api/CALL';

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const ALLOWED_METHODS = [ 'get', 'post', 'put', 'delete'];
const DATA_METHODS = [ 'put', 'post', 'patch', 'delete' ];

const API_TIMEOUT_MS = 60000;

function callApi(method, endpoint, authorisation, data) {
  if (ALLOWED_METHODS.indexOf(method) < 0) {
    throw new Error(`Method not allowed: ${method}`);
  }

  const requestConfig = {
    method,
    url: `${API_BASE_URL}${endpoint}`,
    // if this is a data method, use the data prop
    data: (DATA_METHODS.indexOf(method) >= 0 && data) || undefined,
    // if this is params method, use the params prop
    params: (DATA_METHODS.indexOf(method) < 0 && data) || undefined,
    // put the header auth in!
    headers: authorisation && {
      'Authorization': authorisation,
      'Accept': 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
    },
    // set a reasonable timeout time otherwise this
    // just spins forever
    timeout: API_TIMEOUT_MS
  };

  return axios.request(requestConfig);
}

function handleSuccess(next, successAction, request, response, toast) {

  // display toast if it is whitelisted with a message
  if (toast) {
    // add toast with a default success variant
    addToast({
      variant: 'success',
      ...(typeof toast === 'object' ? toast : { header: `${toast}` }),
    });
  }

  next(successAction && {
    ...successAction,
    request,
    response: response.data,
    responseHeaders: response.headers,
  });
}

async function checkTokenUnauthenticated(userToken) {
  // get response (2XX) or error response (4XX, 5XX, null)
  const response = await callApi('get', '/checkauth', userToken).catch(e => e.response);
  // return true only if explicitly unauthenticated
  return !!response && response.status === 403;
}

async function handleFailure(next, errorAction, request, error, toast, userToken) {

  // get error parts
  const { response: { status, headers, data }={} } = error;

  // if no CORS headers are seen, the network may have failed or the user is authenticated
  if (!status && await checkTokenUnauthenticated(userToken)) {

    // if the user is explicitly unauthenticated then log them out
    next({ type: ACTION_TYPES.TOKEN_EXPIRED });
    // add expiration toast
    addToast({
      variant: 'danger',
      header: 'Your session has expired. Log in again to continue.',
    });

  }
  else {

    // get message passed from frontend (error.message) or backend (data.message)
    const message = error.message || (data && data.message);

    // display a toast if they are not blacklisted from this response
    if (toast !== false) {
      // display toast if given
      if (toast) {
        // add given toast with a default header
        addToast({
          variant: 'danger',
          header: error.message,
          ...(typeof toast === 'object' ? toast : { header: `${toast}` }),
        });
      }
      // display validation error if it exists
      else if (data && data.validation_errors) {
        // display each validation error as its own toast
        Object.entries(data.validation_errors).forEach(([field, fieldErrors]) => {
          fieldErrors.forEach(fieldError => {
            addToast({
              variant: 'danger',
              header: `${field}: ${fieldError}`,
              timeout: 10000, // let validation errors hang around for a longer while
            });
          });
        });
      }
      // display default error
      else {
        // add toast with a default header
        addToast({
          variant: 'danger',
          header: error.message,
        });
      }
    }

    next(errorAction && {
      ...errorAction,
      request,
      response: status ? ( message + " (Code: " + status + ")" ) : message,
      responseHeaders: headers,
    });
  }
}

export default store => next => async action => {
  if (action.type !== CALL_API) {
    return next(action);
  }

  const {
    method,
    endpoint,
    userMustBeAuthenticated = true,
    readCache = true,
    writeCache = true,
    userCacheNamespace,
    data,
    requestAction,
    successAction,
    errorAction,
    successToast,
    errorToast,
  } = action;
  const request = `${`${method}`.toUpperCase()}${endpoint}`;
  const state = store.getState();
  const user = getUser(state);
  const userId = user && user.id;
  const userToken = getUserToken(state);

  requestAction && store.dispatch(requestAction);

  // get cached response if appropriate
  const cache = userCacheNamespace && userId
    && await getUserCacheNamespace(userId, userCacheNamespace);
  const matchedResponse = cache && readCache && await cache.match(endpoint);

  const promise = matchedResponse
    ? matchedResponse.json()
    : callApi(method, endpoint, userToken, data);

  return promise.then(
    response => {
      try {
        // refetch the user to check if they are still logged in
        const state = store.getState();
        const userHasToken = !!getUserToken(state);
        // do not call reducers if the user has no authentication
        if (userMustBeAuthenticated && !userHasToken) {
          return undefined;
        }
        handleSuccess(next, successAction, data, response, successToast);
      }
      catch(e) {
        // log called reducer error
        log.error(new Error('API action success error'), { request }, { err: e });
      }
      if (cache && writeCache && !matchedResponse) {
        const { data, headers } = response;
        cache.put(endpoint, new Response(
          JSON.stringify({ data }), { headers })
        );
      }
      return Promise.resolve({ ...response, cache });
    },
    error => {
      try {
        handleFailure(next, errorAction, data, error, errorToast, userToken);
      }
      catch(e) {
        // log called reducer error
        log.error(new Error('API action failure error'), { request }, { err: e });
      }
      return Promise.reject(error);
    }
  );
};
