
import React, { useState, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { Col, Row, Nav, Button, ButtonGroup } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { IoIosArrowBack, IoIosArrowForward, IoIosChatboxes } from 'react-icons/io';
import { Icon } from '@blueprintjs/core';
import withNavigationDeviceProps from './withNavigationDeviceProps';

// Other
import log from '../../../lib/log';
import { addToast } from '../../../components/Toaster';
import LoadingSpinner from '../../../components/LoadingSpinner';

import EquipmentDropdown from './EquipmentDropdown';
import {
  fetchDeviceInteractiveFFT,
  fetchDeviceWaterfallPlot,
} from '../actions';
import {
  getDevices,
  getDevice,
  getDeviceHasTimeseriesData,
} from '../selectors';
import {
  getOrganisationHasHDFFT,
  getOrganisationHasWaterfallPlot,
} from '../../organisation/selectors';

function EquipmentNav({
  deviceId,
  device={},
  devices=[],
  deviceHasTimeseriesData,
  hasHDFFT,
  hasWaterfallPlot,
  fetchDeviceInteractiveFFT,
  fetchDeviceWaterfallPlot,
}) {

  // compute links when currentDeviceId changes
  const links = useMemo(() => {
    const length = devices.length;
    const index = devices.findIndex(x => x.id === device.id);
    if (length > 1 && index >= 0) {
      return {
        // normalise to length (+ 1 length to ensure no negative numbers)
        prevLink: `/equipment/${devices[(length + index - 1) % length].id}`,
        nextLink: `/equipment/${devices[(length + index + 1) % length].id}`,
      };
    }
    else {
      return null;
    }
  }, [device.id, devices]);

  // handle InteractiveFFT interactivity
  const [loadingInteractiveFFT, setLoadingInteractiveFFT] = useState(false);
  const popupFFT = useCallback(() => {
    setLoadingInteractiveFFT(true);
    fetchDeviceInteractiveFFT({ id: deviceId })
      .then(result => {
        if (result && result.data && result.data.url) {
          // note that this is considered bad practice and may be blocked by some browsers
          // especially if users have anti-popup settings in their browsers
          window.open(result.data.url, '_blank');
        }
        else {
          const e = new Error('Unexpected Interactive FFT response');
          e.data = result && result.data;
          throw e;
        }
      })
      .catch(e => {
        if (deviceHasTimeseriesData === false) {
          addToast({
            variant: 'warning',
            header: `FFTs are not available from ${device.fitmachine_type} devices at the moment`,
          });
        }
        else {
          addToast({
            variant: 'danger',
            header: 'This FFT is not available at the moment',
          });
        }
        // log error
        log.error(new Error('Interactive FFT error'), e, {
          // add device ids
          device,
          // specifically pass through more device context (but not all)
          'error-context': {
            device: Object.entries(device).reduce((acc, [key, value]) => {
              // don't pass through moment object or relations,
              // which are a relatively huge amount of data
              if (typeof value !== 'object') {
                acc[key] = value;
              }
              return acc;
            }, {}),
            // pass request data
            data: e && e.data,
          },
        });
      })
      .then(() => setLoadingInteractiveFFT(false));
  }, [deviceId, device]);

  // handle Waterfall Plot interactivity
  const [loadingWaterfall, setLoadingWaterfall] = useState(false);
  const popupWaterfall = useCallback(() => {
    setLoadingWaterfall(true);
    fetchDeviceWaterfallPlot({ id: deviceId })
      .then(result => {
        if (result && result.data && result.data.url) {
          // note that this is considered bad practice and may be blocked by some browsers
          // especially if users have anti-popup settings in their browsers
          window.open(result.data.url, '_blank');
        }
        else {
          const e = new Error('Unexpected Waterfall Plot response');
          e.data = result && result.data;
          throw e;
        }
      })
      .catch(e => {
        if (deviceHasTimeseriesData === false) {
          addToast({
            variant: 'warning',
            header: `Waterfall plots are not available from ${device.fitmachine_type} devices at the moment`,
          });
        }
        else {
          addToast({
            variant: 'danger',
            header: 'This waterfall plot is not available at the moment',
          });
        }
        // log error
        log.error(new Error('Waterfall Plot error'), e, {
          // add device ids
          device,
          // specifically pass through more device context (but not all)
          'error-context': {
            device: Object.entries(device).reduce((acc, [key, value]) => {
              // don't pass through moment object or relations,
              // which are a relatively huge amount of data
              if (typeof value !== 'object') {
                acc[key] = value;
              }
              return acc;
            }, {}),
            // pass request data
            data: e && e.data,
          },
        });
      })
      .then(() => setLoadingWaterfall(false));
  }, [deviceId, device]);

  return (
    <Nav as="ul" className="flex-grow-1">
      <Row className="small-gutters flex-grow-1">
        <Col xs="auto">
          {device.serial && !device.archived && (
            <Row className="small-gutters">
              <Col xs="auto" className="mb-1">
                <LinkContainer to={`/equipment/${device.id}/activity`} exact>
                  <Button size="md" variant="outline-secondary">
                    <IoIosChatboxes size="1.2em" /> <span>Activity</span>
                  </Button>
                </LinkContainer>
              </Col>
              <Col xs="auto" className="mb-1">
                <LinkContainer to={`/equipment/${device.id}`} exact>
                  <Button size="md" variant="outline-secondary">
                    <Icon
                      iconSize="1.2em"
                      icon="timeline-line-chart"
                      title="Charts"
                      style={styles.navBlueprintIcon}
                    /> <span>Charts</span>
                  </Button>
                </LinkContainer>
              </Col>
              {hasHDFFT && (
                <Col xs="auto" className="mb-1">
                  <Button
                    size="md"
                    variant="outline-secondary"
                    disabled={loadingInteractiveFFT}
                    onClick={popupFFT}
                  >
                    {loadingInteractiveFFT ? (
                      <LoadingSpinner size={1.2} inline />
                    ) : (
                      <Icon
                        iconSize="1.2em"
                        icon="series-search"
                        title="Interactive FFT"
                        style={styles.navBlueprintIcon}
                      />
                    )} <span>Latest FFT</span>
                  </Button>
                </Col>
              )}
              {hasWaterfallPlot && (
                <Col xs="auto" className="mb-1">
                  <Button
                    size="md"
                    variant="outline-secondary"
                    disabled={loadingWaterfall}
                    onClick={popupWaterfall}
                  >
                    {loadingWaterfall ? (
                      <LoadingSpinner size={1.2} inline />
                    ) : (
                      <Icon
                        iconSize="1.2em"
                        icon="series-search"
                        title="Waterfall Plot"
                        style={styles.navBlueprintIcon}
                      />
                    )} <span>Waterfall</span>
                  </Button>
                </Col>
              )}
            </Row>
          )}
        </Col>
        {/*  when this column overflows, ensure is is right-aligned using ml-auto */}
        <Col xs="auto" className="ml-auto">
          <Row className="small-gutters">
            {links && (
              <Col xs="auto" className="mb-1 ml-auto">
                <ButtonGroup>
                  <LinkContainer to={links.prevLink}>
                    <Button variant="outline-secondary" size="md">
                      <IoIosArrowBack />
                    </Button>
                  </LinkContainer>
                  <LinkContainer to={links.nextLink}>
                    <Button variant="outline-secondary" size="md">
                      <IoIosArrowForward />
                    </Button>
                  </LinkContainer>
                </ButtonGroup>
              </Col>
            )}
            {!device.archived && (
              <Col xs="auto" className="mb-1 ml-auto">
                <EquipmentDropdown deviceId={device.id} />
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </Nav>
  );
}

const mapStateToProps = (state, { deviceId }) => {
  return {
    devices: getDevices(state),
    device: getDevice(state, deviceId),
    hasHDFFT: getOrganisationHasHDFFT(state),
    hasWaterfallPlot: getOrganisationHasWaterfallPlot(state),
    deviceHasTimeseriesData: getDeviceHasTimeseriesData(state, deviceId),
  };
};

const mapDispatchToProps = {
  fetchDeviceInteractiveFFT,
  fetchDeviceWaterfallPlot,
};

export default withNavigationDeviceProps(
  connect(mapStateToProps, mapDispatchToProps)(EquipmentNav)
);

const styles = {
  navBlueprintIcon: {
    width: '1.2em',
    verticalAlign: '-15%',
  },
};
