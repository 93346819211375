
import { getDevice } from "../equipment/selectors";
import {
  isTopLevelGroupActive,
  getGroupDevices,
  getActiveGroupId,
} from "../organisation/selectors";

export function getAlarms(state) {
  // return alarms filtered to group devices
  if (!isTopLevelGroupActive(state)) {
    const groupDevices = getGroupDevices(state, getActiveGroupId(state)) || [];
    const alarms = (state && state.alarm && state.alarm.alarms) || [];
    return alarms.filter(alarm => groupDevices.find(device => device.id === alarm.device_id));
  }
  return state && state.alarm && state.alarm.alarms;
}

export function getAlarmListState(state) {
  const { alarm: { loading, lastFetch, error }={} } = state;
  return { loading, lastFetch, error };
}

// get alarm from store with restrictions
export function getAlarm(state, id, { restrictedToGroup=true }={}) {
  // find alarm by id in alarm store
  const alarm = state &&
    state.alarm &&
    state.alarm.alarmsById &&
    state.alarm.alarmsById[id];

  // pass through without restrictions
  if (!restrictedToGroup) {
    return alarm;
  }
  // add restriction
  // return if alarm is from a device available inside
  // the currently selected user's group device members
  else if (alarm && getDevice(state, alarm.device_id)) {
    return alarm;
  }
}

export function getAlarmDevice(state, id) {
  const alarm = getAlarm(state, id);
  return getDevice(state, alarm && alarm.device_id);
}

export function getAlarmImages(state, id) {
  // find alarm image by alarm id in list
  const alarm = getAlarm(state, id);
  return alarm && alarm._embedded && alarm._embedded.images;
}

export function getAlarmSounds(state, id) {
  // find alarm sound by alarm id in list
  const alarm = getAlarm(state, id);
  // note: audio samples are in the images list for now
  // this may change in the future
  // remember to filter this list inside each component
  return alarm && alarm._embedded && alarm._embedded.images;
}

// sort alarms in reverse chronological order
function sortAlarmsReverseChronological(a, b) {
  return new Date(b.alarm_timestamp) - new Date(a.alarm_timestamp);
}

// set an obvious default sort for alarms
export const sortAlarmsDefault = sortAlarmsReverseChronological;
