
import createReducer from '../../lib/createReducer';
import moment from 'moment';

import { ACTION_TYPES as USER_ACTION_TYPES } from '../user/actions';
import { ACTION_TYPES } from './actions';
import { BLUETOOTH_STATES } from './constants';
import { cleanTemplate, mapDeviceFromApi, isWifiOnlyFitMachine } from './utils';

import {
  incrementCounter,
  decrementCounter,
  upsertListItems,
  upsertItem,
} from '../../lib/reducerUtils';

export const DEFAULT_STATE = {
  loading: false, // the list of cloudDevices is being loaded
  lastFetch: null, // the last time we did a cloud fetch
  error: null, // any error loading the list

  // drafts is an array of device
  // details, keyed by index
  drafts: [],

  // idsActive lists of the active root-level device ids
  idsActive: [],
  // idsArchived lists the archived root-level device ids
  idsArchived: [],

  // cloudDevices is a dictionary
  // keyed by the API id
  // containing all known devices (eg. active and archived devices)
  cloudDevices: {},

  // templates is a dictionary
  // keyed by the name
  templates: {},
};

// ["rms", "id", "device_id", "sample_time", "equipment_running",
// "temperature", "calibration", "condition_temperature",
// "condition_vibration", "condition_overall", "_units", "_links"]
export const DEFAULT_SAMPLE_STATE = {
  id: null,
  rms: null,
  device_id: null,
  sample_time: null,
  equipment_running: null,
  temperature: null,
  calibration: null,
  condition_temperature: null,
  condition_vibration: null,
  condition_overall: null
};

export const DEFAULT_DEVICE_STATE = {
  // these properties come from the cloud
  id: null, // platform/cloud ID
  serial: null, // Wifi Mac Address
  bt_address: null, // BLE Mac Address
  latitude: null,
  longitude: null,
  site_name: null,
  sub_area_name: null,
  installation_date: null,
  note: null,
  iso_class: null,
  equipment_name: null,
  equipment_number: null,
  equipment_brand: null,
  equipment_model: null,
  equipment_capacity: null,
  equipment_type: null,
  max_rpm: null,
  variable_speed_drive: null,
  confined_space: null,
  power_rating: null,
  running: null,
  running_cutoff: null,

  // this section is only required by the dashboard,
  // not the mobile app
  organisation_id: null,
  onboarded_by: null,
  organisation_sub_domain: null,
  onboarded_name: null,
  mute_advisory_for: null,
  battery_voltage: null,
  wifi_signal: null,
  rms: null,
  baseline_temperature: null,
  temperature: null,
  calibration: null,
  condition_overall: null,
  condition_vibration: null,
  condition_temperature: null,
  utilisation_month: null,
  power_usage_month: null,
  rate_overall: null,
  fitmachine_last_heard: null,
  fitmachine_last_sample_date: null,
  is_calibrating: null,

  images: [],

  // the way the device is connected - only relevant
  // to draft devices as the API does not store this
  connectionType: null,

  // the last time we did a cloud fetch
  lastFetch: null,

  // something is loading - could be cloud or BLE
  loading: 0,

  // are we connected? is it nearby? etc.
  bluetoothState: BLUETOOTH_STATES.UNKNOWN,

  // this is an error message (or null) -
  // could be cloud or BLE
  error: null,

  // these properties currently come from BLE
  // - but one day MAY also come from the cloud
  firmwareVersion: null, // a string like 20180101
  wifiProfiles: null, // an array of profiles
  signalStrength: null, // some kind of number...
  wifiConnectivity: null // do we have WLan access etc + RSSI
};

function cloneStateAndDeviceById(state, device) {
  const newState = {
    ...state,
    cloudDevices: { ...state.cloudDevices }
  };

  const newDevice = { ...newState.cloudDevices[device.id] };
  newState.cloudDevices[device.id] = newDevice;
  if (isNaN(newDevice.loading)) {
    newDevice.loading = 0;
  }

  return { newState, newDevice };
}

function cloneStateAndDeviceBySerial(state, serial) {
  const newState = {
    ...state,
    cloudDevices: { ...state.cloudDevices }
  };

  const foundDevice = Object.values(newState.cloudDevices).find(device => {
    return device.serial === serial;
  });

  if (foundDevice) {
    const newDevice = { ...foundDevice };
    newState.cloudDevices[foundDevice.id] = newDevice;
    if (isNaN(newDevice.loading)) {
      newDevice.loading = 0;
    }
    return { newState, newDevice };
  } else {
    return null;
  }
}

function cloneStateAndDraftBySerial(state, serial) {
  const newState = {
    ...state,
    drafts: [ ...state.drafts ]
  };

  const foundDraftIndex = newState.drafts.findIndex(d => d.serial === serial);

  if (foundDraftIndex >= 0) {
    const foundDraft = newState.drafts[foundDraftIndex];
    const newDraft = { ...foundDraft };
    newState.drafts[foundDraftIndex] = newDraft;
    if (isNaN(newDraft.loading)) {
      newDraft.loading = 0;
    }
    return { newState, newDevice: newDraft };
  } else {
    return null;
  }
}

function cloneStateAndDevice(state, device, { isCloudDevice } = {}) {

  // if not declared in options,
  // check if we know that the device is in the cloud devices
  if (!isCloudDevice) {
    isCloudDevice = state.cloudDevices.hasOwnProperty(device.id);
  }

  if (isCloudDevice) {
    return cloneStateAndDeviceById(state, device);
  } else {
    let cloned = cloneStateAndDeviceBySerial(state, device.serial);
    if (!cloned) {
      cloned = cloneStateAndDraftBySerial(state, device.serial);
    }
    return cloned;
  }
}

export default createReducer(DEFAULT_STATE, {
  [USER_ACTION_TYPES.LOGOUT]: () => ({ ...DEFAULT_STATE }),
  [USER_ACTION_TYPES.TOKEN_EXPIRED]: () => ({ ...DEFAULT_STATE }),
  [ACTION_TYPES.REQUEST_BEGIN](state, { device }) {
    var newStateAndNewDevice;
    if (device &&
      (newStateAndNewDevice = cloneStateAndDevice(state, device)) !== null) {
      const { newState, newDevice } = newStateAndNewDevice;
      newDevice.loading = incrementCounter(newDevice.loading);
      return newState;
    } else {
      // no device - set root loading flag
      return {
        ...state,
        loading: true
      };
    }
  },

  [ACTION_TYPES.REQUEST_FAIL](state, { device, response }) {
    var newStateAndNewDevice;
    if (device &&
      (newStateAndNewDevice = cloneStateAndDevice(state, device)) !== null) {
      const { newState, newDevice } = newStateAndNewDevice;
      newDevice.loading = decrementCounter(newDevice.loading);
      newDevice.error = response;
      return newState;
    } else {
      // no device - set root loading flag
      return {
        ...state,
        loading: false,
        error: response
      };
    }
  },

  [ACTION_TYPES.RECEIVE_CLOUD_DEVICE_LIST](state, { response, archived }) {
    const { cloudDevices = {} } = state;
    const { _embedded: { devices=[] } = {} } = response;
    const mappedDevices = devices.map(mapDeviceFromApi);
    return {
      ...state,
      // store a reference to all devices in the root-level list
      ...!archived ? {
        // for active ids
        idsActive: mappedDevices.map(({ id }) => id),
      } : {
        // and archived ids
        idsArchived: mappedDevices.map(({ id }) => id),
      },
      // create a copy of the devices as an array, then use the reducer helpers
      // to upsert the relevant device props, filtered to either the active or archived devices
      // then return the result in the expected object keyed by id format
      // todo: change this to a list when we come to removing technical debt
      // and combining the app and dashboard redux states
      cloudDevices: upsertListItems(Object.values(cloudDevices), mappedDevices, {
        // don't filter items out of the root entity object
        filter: false,
        // overwrite any previous data, and fallback to default state props if no match is found
        merge: (previousDevice = DEFAULT_DEVICE_STATE, device) => {
          return {
            ...previousDevice,
            ...device,
            // if the serial has changed, then set the bluetooth state to unknown
            ...(previousDevice.serial && previousDevice.serial !== device.serial) && {
              bluetoothState: BLUETOOTH_STATES.UNKNOWN,
            },
          };
        },
      }).reduce((acc, item) => {
        // return devices keyed by id
        acc[item.id] = item;
        return acc;
      }, {}),
      drafts: state.drafts.filter(draft => {
        // filter out drafts that have a corresponding cloud device
        return !mappedDevices.find(({ serial }) => serial === draft.serial);
      }),
      // todo: separate out loading state for active vs archived device lists
      loading: false,
      error: null,
      lastFetch: moment(),
    };
  },

  // allow group devices to add to the root-level device map
  [ACTION_TYPES.RECEIVE_GROUP_MEMBERS](state, { response }) {
    const { cloudDevices = {} } = state;
    const devices = (response._embedded && response._embedded.devices) || [];
    const mappedDevices = devices.map(mapDeviceFromApi);
    return {
      ...state,
      cloudDevices: upsertListItems(Object.values(cloudDevices), mappedDevices, {
        // don't remove any existing devices
        filter: false,
        // overwrite any previous data, and fallback to default state props if no match is found
        merge: (previousDevice = DEFAULT_DEVICE_STATE, device) => {
          return {
            ...previousDevice,
            ...device,
          };
        },
      }).reduce((acc, item) => {
        // return devices keyed by id
        acc[item.id] = item;
        return acc;
      }, {}),
    };
  },

  [ACTION_TYPES.RECEIVE_DEVICE_INFO](state, { device, response }) {
    // apply changes onto the found device or the default device state
    const { [device.id]: previousDevice = {} } = state.cloudDevices;
    return {
      ...state,
      cloudDevices: {
        ...state.cloudDevices,
        [device.id]: {
          ...DEFAULT_DEVICE_STATE,
          ...previousDevice,
          // add new device props
          ...mapDeviceFromApi(response),
          // add other things about state that shouldn't be lumped in here but are
          // todo: add these props under a specific namespace: "_state"
          loading: decrementCounter(previousDevice.loading),
          error: null,
          lastFetch: moment(),
        },
      },
    };
  },

  [ACTION_TYPES.RECEIVE_DEVICE_OVERVIEW](state, { device, response={} }) {
    // apply changes onto the found device or the default device state
    const { [device.id]: previousDevice = {} } = state.cloudDevices;
    return {
      ...state,
      cloudDevices: {
        ...state.cloudDevices,
        [device.id]: upsertItem({
          ...DEFAULT_DEVICE_STATE,
          ...device,
          ...previousDevice,
        }, {
          // merge embedded list items by id field by default
          _embedded: {
            overview: (response._embedded && response._embedded.overview) || [],
            // add embedded metadata object
            overviewMetadata: {
              timezone: response.timezone,
            },
          },
        }),
      },
    };
  },

  [ACTION_TYPES.RECEIVE_DEVICE_RUNTIME](state, { device, response={} }) {
    // apply changes onto the found device or the default device state
    const { [device.id]: previousDevice = {} } = state.cloudDevices;
    return {
      ...state,
      cloudDevices: {
        ...state.cloudDevices,
        [device.id]: upsertItem({
          ...DEFAULT_DEVICE_STATE,
          ...device,
          ...previousDevice,
        }, {
          // merge embedded list items by id field by default
          _embedded: {
            runtime: (response._embedded && response._embedded.runtime) || [],
            // add embedded metadata object
            runtimeMetadata: {
              timezone: response.timezone,
            },
          },
        }, {
          upsertRelated: {
            runtime: (
              previousDevice._embedded &&
              previousDevice._embedded.runtimeMetadata &&
              previousDevice._embedded.runtimeMetadata.timezone
            ) === response.timezone
              ? {
                // timezone is equivalent, merge data
                match: (oldItem, newItem) => oldItem.date === newItem.date, // merge by date
              } : {
                // timezone is different, replace data
                match: () => false,
              },
          },
        }),
      },
    };
  },

  [ACTION_TYPES.RECEIVE_DEVICE_EVENTS](state, { device, response={} }) {
    // apply changes onto the found device or the default device state
    const { [device.id]: previousDevice = {} } = state.cloudDevices;
    return {
      ...state,
      cloudDevices: {
        ...state.cloudDevices,
        [device.id]: upsertItem({
          ...DEFAULT_DEVICE_STATE,
          ...device,
          ...previousDevice,
        }, {
          // merge embedded list items by id field by default
          _embedded: {
            events: (response._embedded && response._embedded.events) || [],
          },
        }, {
          // do not filter logs (merge multiple filtered lists here)
          // we don't expect any entries here to be removed
          upsertRelated: {
            events: {
              filter: false,
            },
          },
        }),
      },
    };
  },

  [ACTION_TYPES.REQUEST_DEVICE_ALARMS](state, { device={} }) {
    // apply changes onto the found device or the default device state
    const { [device.id]: previousDevice = {} } = state.cloudDevices;
    return {
      ...state,
      cloudDevices: {
        ...state.cloudDevices,
        [device.id]: upsertItem({
          ...DEFAULT_DEVICE_STATE,
          ...device,
          ...previousDevice,
        }, {
          // add flag to read fetching status
          _embedded: {
            alarms_state: {
              loading: true,
            },
          },
        }),
      },
    };
  },

  [ACTION_TYPES.DEVICE_ALARMS_FAILURE](state, { device={}, response='' }) {
    // apply changes onto the found device or the default device state
    const { [device.id]: previousDevice = {} } = state.cloudDevices;
    return {
      ...state,
      cloudDevices: {
        ...state.cloudDevices,
        [device.id]: upsertItem({
          ...DEFAULT_DEVICE_STATE,
          ...device,
          ...previousDevice,
        }, {
          // add flag to read fetching status change
          _embedded: {
            alarms_state: {
              loading: false,
              error: `${response}`,
            },
          },
        }),
      },
    };
  },

  [ACTION_TYPES.RECEIVE_DEVICE_ALARMS](state, { device={}, response={} }) {
    // apply changes onto the found device or the default device state
    const { [device.id]: previousDevice = {} } = state.cloudDevices;
    return {
      ...state,
      cloudDevices: {
        ...state.cloudDevices,
        [device.id]: upsertItem({
          ...DEFAULT_DEVICE_STATE,
          ...device,
          ...previousDevice,
        }, {
          // merge embedded list items by id field by default
          _embedded: {
            alarms: (response._embedded && response._embedded.alarms) || [],
            // add flag to read fetching status change
            alarms_state: {
              loading: false,
              error: null,
              lastFetch: Date.now(),
            },
          },
        }),
      },
    };
  },

  [ACTION_TYPES.RECEIVE_DEVICE_IMAGES](state, { device, response }) {

    const newState = {
      ...state,
      cloudDevices: {
        ...state.cloudDevices
      }
    };

    const newDevice = {
      ...newState.cloudDevices[device.id],
      // todo: this key should not be here, it should be an _embedded relation
      // and inserted similarly to RECEIVE_DEVICE_ERRORS
      images: (
        response.count === 0
          ? []
          : response._embedded.images
      )
    };
    newState.cloudDevices[device.id] = newDevice;

    return newState;
  },

  [ACTION_TYPES.RECEIVE_DEVICE_IMAGE_DELETE](state, { device={}, image={} }) {

    // skip if device is not found
    if (!device.id || !state.cloudDevices[device.id]) {
      return state;
    }

    return {
      ...state,
      cloudDevices: {
        ...state.cloudDevices,
        [device.id]: {
          ...state.cloudDevices[device.id],
          // filter the current device images to not include this device
          images: state.cloudDevices[device.id].images.filter(({ id }) => id !== image.id),
        },
      },
    };
  },

  [ACTION_TYPES.RECEIVE_DEVICE_ERRORS](state, { device={}, response: { _embedded }={} }) {
    // skip if device is not found
    if (!device.id || !state.cloudDevices[device.id]) {
      return state;
    }
    return {
      ...state,
      cloudDevices: {
        ...state.cloudDevices,
        [device.id]: upsertItem(state.cloudDevices[device.id], { _embedded }),
      },
    };
  },

  [ACTION_TYPES.RECEIVE_DEVICE_MUTE_STATUS_UPDATE](state, { device, mute_advisory_for }) {
    return {
      ...state,
      cloudDevices: {
        ...state.cloudDevices,
        [device.id]: {
          ...state.cloudDevices[device.id],
          mute_advisory_for: mute_advisory_for,
        },
      },
    };
  },

  [ACTION_TYPES.RECEIVE_FIRMWARE_VERSION](state, { device, response }) {
    const { newState, newDevice } = cloneStateAndDevice(state, device);

    newDevice.loading = decrementCounter(newDevice.loading);
    newDevice.error = null;
    newDevice.firmwareVersion = response;

    return newState;
  },

  [ACTION_TYPES.REQUEST_WIFI_PROFILES](state, { device }) {
    const { newState, newDevice } = cloneStateAndDevice(state, device);
    newDevice.loading = incrementCounter(newDevice.loading);
    newDevice.wifiProfiles = null;
    return newState;
  },

  [ACTION_TYPES.RECEIVE_WIFI_PROFILES](state, { device, response }) {
    const { newState, newDevice } = cloneStateAndDevice(state, device);

    newDevice.loading = decrementCounter(newDevice.loading);
    newDevice.error = null;
    newDevice.wifiProfiles = response.sortBy(p => p.priority);

    return newState;
  },

  [ACTION_TYPES.REQUEST_WIFI_CONNECTIVITY](state, { device }) {
    const { newState, newDevice } = cloneStateAndDevice(state, device);
    newDevice.loading = incrementCounter(newDevice.loading);
    newDevice.wifiConnectivity = null;
    return newState;
  },

  [ACTION_TYPES.RECEIVE_WIFI_CONNECTIVITY](state, { device, response }) {
    const { newState, newDevice } = cloneStateAndDevice(state, device);

    newDevice.loading = decrementCounter(newDevice.loading);
    newDevice.error = null;
    newDevice.wifiConnectivity = response;

    return newState;
  },

  [ACTION_TYPES.UPDATE_DRAFT](state, { index, draft, overwrite }) {
    const newState = {
      ...state,
      drafts: [ ...state.drafts ]
    };

    if (index === newState.drafts.length) {
      newState.drafts.push(draft);
    } else if (overwrite) {
      newState.drafts[index] = { ...draft };
    } else {
      newState.drafts[index] = { ...newState.drafts[index], ...draft };
    }

    return newState;
  },

  [ACTION_TYPES.DISCARD_DRAFT](state, { index }) {
    const newState = {
      ...state,
      drafts: [ ...state.drafts ]
    };

    if (index < newState.drafts.length) {
      newState.drafts.splice(index, 1);
    }

    return newState;
  },

  [ACTION_TYPES.CREATE_TEMPLATE](state, { name, template }) {
    const newState = {
      ...state,
      templates: { ...state.templates }
    };

    newState.templates[name] = cleanTemplate(template);

    return newState;
  },

  [ACTION_TYPES.DELETE_TEMPLATE](state, { name }) {
    const newState = {
      ...state,
      templates: { ...state.templates }
    };

    delete newState.templates[name];

    return newState;
  },

  [ACTION_TYPES.UPDATE_NEARBY](state, { devices }) {
    const newState = { ...state };

    const cloneAndAssignState = device => {
      const newDevice = { ...device };

      // check first if it's a non wifi
      if (
        // don't check this list over and over if we already know
        newDevice.bluetoothState === BLUETOOTH_STATES.UNKNOWN &&
        isWifiOnlyFitMachine(newDevice.serial)
      ) {
        newDevice.bluetoothState = BLUETOOTH_STATES.NOT_AVAILABLE;
      } else if (devices.hasOwnProperty(newDevice.serial)) {
        newDevice.bluetoothState = BLUETOOTH_STATES.NEARBY;
      } else if (device.bluetoothState !== BLUETOOTH_STATES.NOT_AVAILABLE) {
        newDevice.bluetoothState = BLUETOOTH_STATES.NOT_NEARBY;
      }

      return newDevice;
    };

    // update drafts
    newState.drafts = state.drafts.map(cloneAndAssignState);

    // update devices
    newState.cloudDevices = {};
    Object.keys(state.cloudDevices).forEach(id => {
      newState.cloudDevices[id] = cloneAndAssignState(state.cloudDevices[id]);
    });

    return newState;
  },

  [ACTION_TYPES.RECEIVE_ARCHIVE_DEVICE](state, { device }) {
    const {
      idsActive=[],
      idsArchived=[],
    } = state;
    return {
      ...state,
      // remove from active list
      idsActive: idsActive.filter(id => id !== device.id),
      // add to archived list
      idsArchived: [...idsArchived, device.id],
    };
  },

  [ACTION_TYPES.RECEIVE_UNARCHIVE_DEVICE](state, { device }) {
    const {
      idsActive=[],
      idsArchived=[],
    } = state;
    return {
      ...state,
      // add to active list
      idsActive: [...idsActive, device.id],
      // remove from archived list
      idsArchived: idsArchived.filter(id => id !== device.id),
    };
  },

});
