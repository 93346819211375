import React from 'react';

import withNavigationGroupProps from '../components/withNavigationGroupProps';
import EditGroupDevices from '../components/EditGroupDevices';

function EditGroupEquipment(props) {
  return (
    <div className="p-4">
      <EditGroupDevices {...props} />
    </div>
  );
}

export default withNavigationGroupProps(EditGroupEquipment);
