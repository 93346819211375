import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { Container, Button, Dropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { IoIosAddCircleOutline, IoIosCube } from 'react-icons/io';

import Table from '../../../components/Table';
import TableToolbar from '../../../components/TableToolbar';
import { useColumnsWithVisibility } from '../../../components/table/utils';
import EmptyCard from '../../../components/EmptyCard';
import Private from '../../../components/Private';

import AddFitMachineModal from '../components/AddFitmachineModal';

import {
  rms,
  mm2,
  temperature,
  status,
  calibration,
  siteName,
  subAreaName,
  equipmentName,
  stalenessExpanded,
  customAlarmThresholdsExpanded,
  conditionOverall,
  degradation,
  utilisationMonth,
  powerUsageMonth,
  running,
  muteAdvisoryFor,
  note,
} from '../columns';

import { fetchDevices } from '../actions';
import { getDevices, getDeviceListState } from '../selectors';
import { isAdmin } from '../../user/selectors';
import {
  getOrganisationRmsAvailablePreference,
  getOrganisationMm2AvailablePreference,
  getActiveSubGroupId,
} from '../../organisation/selectors';

const defaultSorted = [{
  dataField: 'status',
  order: 'desc'
}];

const defaultColumns = [
  status,
  calibration,
  siteName,
  subAreaName,
  equipmentName,
  stalenessExpanded,
  customAlarmThresholdsExpanded,
  conditionOverall,
  degradation,
  rms,
  mm2,
  temperature,
  utilisationMonth,
  powerUsageMonth,
  running,
  muteAdvisoryFor,
  note,
];

export function AddEquipmentButton() {
  return (
    <AddFitMachineModal key="add">
      <Button variant="primary" className="pl-1">
        <IoIosCube size="1.4em" /> <span>Add Equipment</span>
      </Button>
    </AddFitMachineModal>
  );
}

function AddButton() {
  return (
    <Dropdown>
      <Dropdown.Toggle variant="primary" className="pl-1">
        <IoIosAddCircleOutline size="1.4em" /> Add{' '}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <AddFitMachineModal key="add">
          <Dropdown.Item><IoIosCube size="1.4em" /> <span>Add Equipment</span></Dropdown.Item>
        </AddFitMachineModal>
        <Private minUserType="Admin">
          <LinkContainer to="/group/devices">
            <Dropdown.Item ><IoIosCube size="1.4em" /> <span>Manage Group Equipment</span></Dropdown.Item>
          </LinkContainer>
        </Private>
      </Dropdown.Menu>
    </Dropdown>
  );
}

function NoDataIndication({ isAdmin, hasActiveSubGroup }) {
  return isAdmin ? !hasActiveSubGroup ? (
    // admin on org group
    <EmptyCard Icon={IoIosCube}>
      <EmptyCard.Body>
        <EmptyCard.Title>Let’s get started adding your first piece of equipment.</EmptyCard.Title>
        <EmptyCard.Text>
          To add a piece of equipment, press the “Add Equipment” button.
          Once added, the Equipment List will display to admins the condition, running status, and more of all your organisation’s equipment.
          Users will only be able to see equipment that has been shared with them by admins.
        </EmptyCard.Text>
        <div className="text-center">
          <AddEquipmentButton />
        </div>
      </EmptyCard.Body>
      <EmptyCard.UniversityFooter />
    </EmptyCard>
  ) : (
    // admin on sub group
    <EmptyCard Icon={IoIosCube}>
      <EmptyCard.Body>
        <EmptyCard.Title>Let’s get started adding equipment to this group.</EmptyCard.Title>
        <EmptyCard.Text>
          To add equipment to this group, press the “Manage Group Equipment” button.
          Once added, the Equipment List will display to admins the condition, running status, and more of all your organisation’s equipment.
          Users will only be able to see equipment that has been shared with them by admins.
        </EmptyCard.Text>
        <div className="text-center">
          <LinkContainer to="/group/devices">
            <Button variant="primary">
              Manage Group Equipment
            </Button>
          </LinkContainer>
        </div>
      </EmptyCard.Body>
      <EmptyCard.UniversityFooter />
    </EmptyCard>
  ) : (
    // user
    <EmptyCard Icon={IoIosCube}>
      <EmptyCard.Body>
        <EmptyCard.Title>No equipment has been shared with your account yet.</EmptyCard.Title>
        <EmptyCard.Text>
          Ask an admin to add and share equipment with your account.
          The Equipment List will then display condition, running status, and more for equipment that has been shared with you.
        </EmptyCard.Text>
      </EmptyCard.Body>
      <EmptyCard.UniversityFooter />
    </EmptyCard>
  );
}

const ConnectedNoDataIndication = connect(state => ({ isAdmin: isAdmin(state) }))(NoDataIndication);

function EquipmentList(props) {

  const {
    activeSubGroupId,
    devices=[],
    loading,
    lastFetch,
    error,
    fetchDevices,
    rmsAvailable,
    mm2Available,
  } = props;

  const refresh = useCallback(() => {
    fetchDevices();
  }, [activeSubGroupId]);

  // fetch devices upon mount, or group change
  useEffect(() => {
    refresh();
  }, [refresh]);

  const [notesVisible, setNotesVisible] = useState(false);
  const toggleNotes = useCallback(() => {
    setNotesVisible(!notesVisible);
  }, [notesVisible]);

  const renderHeader = useCallback(props => {
    return (
      <TableToolbar
        searchable
        title="List"
        loading={loading}
        lastFetch={lastFetch}
        error={error}
        buttonGroups={[
          [<AddButton key={1} />],
          [
            <Button
              key={1}
              variant={notesVisible ? 'secondary' : 'outline-secondary'}
              onClick={toggleNotes}
            >
              Toggle notes {!notesVisible ? 'on' : 'off'}
            </Button>
          ],
        ]}
        tableProps={props}
      />
    );
  }, [loading, lastFetch, error, notesVisible]);

  const onboardedDevices = devices
    // split out conditional_overall into duplicate attributes on each item
    .map(({ condition_overall, ...deviceAttrs }) => {
      return {
        ...deviceAttrs,
        status: condition_overall,
        condition_overall,
      };
    });

  const columns = useColumnsWithVisibility(defaultColumns, {
    'rms': !!rmsAvailable,
    'rms2': !!mm2Available,
    'note': !!notesVisible,
    'threshold': !!onboardedDevices.find(customAlarmThresholdsExpanded.hasValue),
  });

  // allow different no data indication for a subgroup
  const noDataIndication = useCallback(() => {
    return (
      <ConnectedNoDataIndication hasActiveSubGroup={!!activeSubGroupId} />
    );
  }, [!!activeSubGroupId]);

  return (
    <Container fluid>
      <Table
        pagination
        renderHeader={renderHeader}
        data={onboardedDevices}
        defaultSorted={defaultSorted}
        columns={columns}
        noDataIndication={noDataIndication}
        loading={loading}
        refreshHandler={refresh}
      />
    </Container>
  );
}

const mapStateToProps = state => ({
  ...getDeviceListState(state), // includes { loading, lastFetch, error }
  activeSubGroupId: getActiveSubGroupId(state),
  devices: getDevices(state),
  rmsAvailable: getOrganisationRmsAvailablePreference(state),
  mm2Available: getOrganisationMm2AvailablePreference(state),
});
const mapDispatchToProps = { fetchDevices };

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentList);
