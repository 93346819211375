
import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import { Howl } from 'howler';
import { Icon } from '@blueprintjs/core';

import LoadingSpinner from '../../../components/LoadingSpinner';

import { useComponentViewTracking } from '../../app/hooks';

import { fetchAlarmSounds } from '../actions';
import { getAlarm, getAlarmSounds } from '../selectors';
import { getOrganisationHasFFT } from '../../organisation/selectors';

import { trackEvent } from '../../app/actions';

import './alarmSoundButton.scss';

const mp3FileExtension = 'mp3';
const defaultHowlOptions = {
  preload: false,
  format: [mp3FileExtension],
};

function AlarmSoundButton({
  hasFFT,
  alarmId,
  deviceId,
  soundType = 'alarm',
  sound,
  children,
  fetchAlarmSounds,
  fetchOrganisationIfStale,
  trackEvent,
  ...props
}) {

  const [fetchingURL, setFetchingURL] = useState(false);
  // reset upon changing alarm id
  useEffect(() => setFetchingURL(false), [alarmId]);

  const src = sound && sound.url;

  // define a 'playRequested' state as controlled by the user
  const [playRequested, setPlayRequested] = useState(false);
  const togglePlaying = useCallback(() => {
    setPlayRequested(playRequested => !playRequested);
  }, []);
  // is the audio actually playRequested a sound
  const [playing, setPlaying] = useState(false);

  // allow an alternative mode of audio file delivery
  // if howler.js has failed to play the audio:
  // attempt to allow the file to be downloaded
  const [hasAudioError, setHasAudioError] = useState(false);
  useEffect(() => {
    if (playRequested && hasAudioError && src) {
      // immediately reset the play button
      setPlayRequested(false);

      trackEvent('Alarm Audio File Downloaded', {
        id: alarmId,
        alarmId,
        deviceId,
        // add audio information
        audioType: soundType,
        // add product code
        productCode: 'fftchart',
      });

      // create a friendly sound file name for the user
      const filename = `MOVUS-equipment_${deviceId}-alarm_${alarmId}-${
        soundType
      }_audio.${
        // put file type
        src.split('.').pop() || mp3FileExtension
      }`;
      // open an open or save dialog in IE
      if (window.navigator.msSaveOrOpenBlob) {
        fetch(src)
          .then(r => r.blob())
          .then(blob => {
            // offer to save the file with the given audio file name
            window.navigator.msSaveOrOpenBlob(blob, filename);
          });
      }
      else {
        // last ditch attempt to give the user a file on not-IE
        window.open(src, filename);
      }
    }
  }, [src, playRequested, hasAudioError]);

  // set howl whenever src changes
  const [howl, setHowl] = useState(null);
  useEffect(() => {
    if (src) {
      const howl = new Howl({
        ...defaultHowlOptions,
        src,
        onplay: () => setPlaying(true),
        onstop: () => setPlaying(false),
        onend: () => {
          // stop audio playRequested
          setPlaying(false);
          // reset play UI
          setPlayRequested(false);
        },
        onloaderror: () => setHasAudioError(true),
        onplayerror: () => setHasAudioError(true),
      });
      setHowl(howl);
      // cleanup: destroy the howl object
      return () => howl.unload();
    }
  }, [src]);

  // consider playing to be 'opening' and !playing to be 'closed'
  useComponentViewTracking('Alarm Audio', !!playing && 'alarmId', {
    alarmId,
    deviceId,
    // add audio information
    audioType: soundType,
    audioSecondsDuration: howl && howl.duration(),
    audioMsDuration: howl && 1000*howl.duration(),
    // add product code
    productCode: 'fftchart',
  });

  // if the user has requested a sound, and the sound is available then play it
  // if the sound is not available, attempt to fetch it and then play it
  useEffect(() => {
    if (howl) {
      // start playback
      if (playRequested) {
        const howlState = howl.state();
        // start loading if not yet loaded
        if (howlState === 'unloaded') {
          howl.load();
        }
        // play loaded sound
        if (howlState === 'loaded') {
          howl.play();
        }
        // play after sound first loads
        else {
          howl.once('load', () => howl.play());
        }
      }
      // stop playback
      else {
        howl.stop();
      }
    }
    // fetch resources
    else if (playRequested && !howl) {
      (async () => {
        setFetchingURL(true);
        await fetchAlarmSounds({ id: alarmId }, soundType);
        setFetchingURL(false);
      })();
    }
  }, [playRequested, howl, soundType]);

  // don't show component if the organisation doesn't have this feature
  if (!hasFFT) {
    return null;
  }

  return (
    <Button
      {...props}
      className={
        ['alarm-sound-button', props.className].filter(Boolean).join(' ')
      }
      onClick={togglePlaying}
    >
      {playing ? (
        <Icon
          iconSize="1.2em"
          icon="stop"
          title="Stop"
        />
      ) : playRequested && fetchingURL ? (
        <LoadingSpinner inline size={1.2} delay={100} />
      ) : (
        <Icon
          iconSize="1.2em"
          icon="play"
          title="Play"
        />
      )} {children}
    </Button>
  );
}

// valid sound props are 'alarm' and 'baseline'
const mapStateToProps = (state, { alarmId, soundType='alarm' }) => {
  const alarm = getAlarm(state, alarmId);
  const sounds = getAlarmSounds(state, alarmId);
  return {
    deviceId: alarm && alarm.device_id,
    hasFFT: getOrganisationHasFFT(state),
    // fetch specific sound audio
    soundType,
    sound: sounds && sounds.find(({ name }) => name === `${soundType}_audio`),
  };
};

const mapDispatchToProps = { fetchAlarmSounds, trackEvent };

export default connect(mapStateToProps, mapDispatchToProps)(AlarmSoundButton);