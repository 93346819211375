
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import objectFitImages from 'object-fit-images';
import withNavigationDeviceProps from './withNavigationDeviceProps';

// Components
import NotFound from '../../../components/NotFound';

// Images
import placeholder from '../../../images/equipmentPlaceholder.jpg';

// Other
import { fetchDevices, fetchDeviceInfoThenImages } from '../actions';
import moment from 'moment';

import { capitaliseFirstChar } from '../../../lib/utils';

import Carousel, { Modal, ModalGateway } from 'react-images';

import ProfilePageLayout from '../../../components/ProfilePageLayout';
import EquipmentNav from '../components/EquipmentNav';
import EquipmentSidebar from '../components/EquipmentSidebar';
import UnarchiveDeviceModal from '../components/UnarchiveDeviceModal';
import LoadingSpinner from '../../../components/LoadingSpinner';

import { getDevices, getDevice, getDeviceListState } from '../selectors';

function getSortedImages(images=[]) {
  // try to find the latest image by name type
  const imageTypeOrder = [
    'overall_images',
    'fitmachine_images',
    'local_nameplate_image',
    'motor_nameplate_image',
    'gateway_images', // react dash uses gateway_images :(
    'gateway_image', // ruby dash uses gateway_image :(
  ];
  return [...images].filter(({ name }) => {
    // filter to relevant images
    return imageTypeOrder.includes(name);
  }).sort((a, b) => {
    // find image name type order
    const aIndex = imageTypeOrder.indexOf(a.name);
    const bIndex = imageTypeOrder.indexOf(b.name);

    // if image name type is the same then prioritise latest updated image
    if (aIndex === bIndex) {
      return new Date(b.updated_at) - new Date(a.updated_at);
    }
    else {
      // de-prioritise unfound name
      if (aIndex === -1) {
        return 1;
      }
      // de-prioritise unfound name
      else if (bIndex === -1) {
        return -1;
      }
      // sort by imageTypeOrder
      else {
        return aIndex - bIndex;
      }
    }
  });
};

class EquipmentPageLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isImagesModalOpen: false
    };

    this.fetchDeviceInfoAndImages = this.fetchDeviceInfoAndImages.bind(this);
  }

  fetchDeviceInfoAndImages() {
    const { fetchDeviceInfoThenImages, deviceId } = this.props;
    // fetch info and images if device id is known
    if (deviceId) {
      fetchDeviceInfoThenImages({ id: deviceId });
    }
  }

  componentDidMount() {
    this.fetchDeviceInfoAndImages();

    // if the cloud devices list has not been yet loaded
    // then try to fetch a list of other devices
    const { allActiveDevices=[], fetchDevices } = this.props;
    if (allActiveDevices.length < 1) {
      fetchDevices();
    }
    objectFitImages('img.img-thumbnail');
  }

  componentDidUpdate(prevProps) {
    if (this.props.deviceId !== prevProps.deviceId) {
      this.fetchDeviceInfoAndImages();
    }
    // update device profile image on IE if the images change
    if ((this.props.device || {}).images !== (prevProps.device || {}).images) {
      objectFitImages('img.img-thumbnail');
    }
  }

  toggleImagesModal = e => {
    e.preventDefault();
    this.setState(({ isImagesModalOpen }) => ({ isImagesModalOpen: !isImagesModalOpen }));
  }

  render() {
    const {
      devicesFetched,
      allActiveDevices = [],
      allArchivedDevices = [],
      device = {},
      deviceId,
      children,
    } = this.props;

    // show loading spinner if the device isn't found
    // but also the devices list hasn't been fetched yet
    // (only show NotFound if there is a list, and this device isn't in it)
    if (!device.id && !devicesFetched) {
      return (
        <div className="my-4 mx-auto">
          <LoadingSpinner />
        </div>
      );
    }

    // ensure that the device actually contains information in its props
    // if the images are loaded first, device will be { images: [...] }
    // without any other props
    if (!device || !device.id) {
      // if the current view cannot see this device, but the user can see it somehow
      const allAccessibleDevices = [...allActiveDevices, ...allArchivedDevices];
      if (allAccessibleDevices.find(({ id }) => id === deviceId)) {
        return (
          <Redirect to="/equipment/list" />
        );
      }
      else {
        // Does not exist
        return <NotFound />;
      }
    }

    const sortedImages = getSortedImages(device.images);
    return (
      <ProfilePageLayout
        image={(
          <div
            // add button accessibility props
            role="button"
            tabIndex="0"
            onKeyDown={e => e.key === 'i' && this.toggleImagesModal(e)}
            onClick={this.toggleImagesModal}
          >
            <img
              className="img-thumbnail img-fluid shadow-sm object-fit-cover"
              src={sortedImages.length > 0 ? sortedImages[0].url : placeholder}
              onError={e => e.target.src = placeholder}
              alt="Overall Location"
            />
          </div>
        )}
        navbar={<EquipmentNav />}
        sidebar={<EquipmentSidebar />}
      >
        {children}
        {device.archived && (
          <div className="my-4">
            <p>
              <UnarchiveDeviceModal
                deviceId={device.id}
                onSuccess={this.fetchDeviceInfoAndImages}
              />
            </p>
          </div>
        )}
        <ModalGateway>
          {sortedImages && sortedImages.length && this.state.isImagesModalOpen ? (
            <Modal
              closeOnBackdropClick={false}
              onClose={this.toggleImagesModal}
              allowFullscreen={true}
            >
              <Carousel
                hideControlsWhenIdle={false}
                views={sortedImages.map(({ url, name, updated_at }) => ({
                  source: url,
                  // remove underscores and trailing s chars from name, for a caption
                  caption: `Uploaded: ${
                    moment(updated_at).format('ll h:mm a')
                  }, Type: ${
                    capitaliseFirstChar(name.replace(/(_|s$)/g, ' '))
                  }`,
                }))}
                styles={carouselStyles}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </ProfilePageLayout>
    );
  }
}

const navigationStyle = (base, { isFullscreen }) => isFullscreen ? {
  ...base,
  // make fullscreen navigation buttons very obvious
  boxShadow: '0 0 6px -2px rgba(0, 0, 0, 7)',
  background: 'rgba(180, 180, 180, 0.6)',
  '&:active': {
    background: 'rgba(180, 180, 180, 0.7)',
  },
  '&:hover': {
    background: 'rgba(180, 180, 180, 0.8)',
  },
} : {
  ...base,
  // make non-fullscreen navigation buttons more obvious
  background: 'rgba(255, 255, 255, 0.3)',
};

const carouselStyles = {
  navigationPrev: navigationStyle,
  navigationNext: navigationStyle,
  header: base => ({ ...base, opacity: 1 }),
  footer: (base, { isFullscreen }) => isFullscreen ? {
    ...base,
    textShadow: '0px 1px 6px #000',
    color: '#fff',
    opacity: 1,
  } : {
    ...base,
    opacity: 1,
  },
};

const mapStateToProps = (state, { deviceId }) => {
  return {
    devicesFetched: (getDeviceListState(state) || {}).lastFetch,
    allActiveDevices: getDevices(state, { forOrg: true }),
    allArchivedDevices: getDevices(state, { forOrg: true, archived: true }),
    device: getDevice(state, deviceId),
    deviceId: deviceId, // device id may be available before the device has been fetched
  };
};
const mapDispatchToProps = {
  fetchDevices,
  fetchDeviceInfoThenImages,
};

export default withNavigationDeviceProps(
  connect(mapStateToProps, mapDispatchToProps)(EquipmentPageLayout)
);
