import React, { Component } from 'react';
import { Row, Col, Form, Button, Alert }  from 'react-bootstrap';
import { connect } from 'react-redux';
import  { fetchUserWithId, submitUserDetails, submitUserWithIdDetails } from './../actions';
import { getFormValues } from '../../../lib/utils';
import Title from '../../../components/Title';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { addToast } from '../../../components/Toaster';
import UserPageLayout from '../components/UserPageLayout';

import { getUser } from '../selectors';
import { getPathParamIntegerFromProps } from '../../selectors';

export function PasswordRequirements() {
  return (
    <ul className="my-0">
      <li>At least 1 upper case character</li>
      <li>At least 1 lower case character</li>
      <li>At least 1 number OR 1 special character</li>
      <li>At least 12 total characters</li>
    </ul>
  );
}

class UpdatePassword extends Component {

  state = {
    submit: {},
  }

  // fetch most recent details of the user
  fetchUserDetails = () => {
    const { fetchUserWithId, userToEdit, userToEditId } = this.props;
    fetchUserWithId(userToEdit || { id: userToEditId });
  }

  componentDidMount() {
    this.fetchUserDetails();
  }

  componentDidUpdate(prevProps) {
    // reset submit form if the user is changed
    if (prevProps.userToEditId !== this.props.userToEditId) {
      this.setState({ submit: {} });
      this.fetchUserDetails();
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    const { submitUserDetails, submitUserWithIdDetails, userToEdit, userIsSelf } = this.props;
    const { new_password, confirm_password } = getFormValues(e);
    if (new_password !== confirm_password) {
      return addToast({
        variant: 'danger',
        header: 'The given passwords do not match',
      });
    }

    const submittedAt = Date.now();
    this.setState({ submit: { submittedAt } });

    const submitDetails = userIsSelf ? submitUserDetails : submitUserWithIdDetails;

    // submit form then handle feedback
    userToEdit && submitDetails(userToEdit, { password: new_password }, { successToast: 'Password Updated' })
      .then(() => {
      // if still displaying the same submission then update with success
        this.setState(({ submit }) => {
          if (submit.submittedAt === submittedAt) {
            // clear the form
            form.reset();
            return { submit: { succeededAt: new Date() } };
          }
        });
      })
      .catch((error) => {
      // if still displaying the same submission then update with failure
        this.setState(({ submit }) => {
          if (submit.submittedAt === submittedAt) {
            return { submit: { error: error.message || 'Error' } };
          }
        });
      });
  }

  render() {
    const { userIsSelf, userToEdit } = this.props;
    const { submit } = this.state;
    return (
      <UserPageLayout>
        <Title
          title={userIsSelf
            ? 'Update My Password'
            : (userToEdit
              ? `Updating User Password: ${userToEdit.name}`
              : ""
            )
          }
          loading={submit.submittedAt}
          lastFetch={submit.succeededAt}
          error={submit.error}
        />
        {!userToEdit ? <LoadingSpinner /> : (
          <Form className="form-container" onSubmit={this.handleSubmit}>
            <Alert variant="info" className="mt-2">
              <p className="my-0">
                Use the form below to change the password. It must include:
              </p>
              <PasswordRequirements />
            </Alert>
            {!userIsSelf && <Form.Group as={Row}>
              <Form.Label column sm="2" xs="4">
                Email
              </Form.Label>
              <Col sm="8" xs="6">
                <Form.Control
                  plaintext
                  readOnly
                  value={ userToEdit.email || "" }
                />
              </Col>
            </Form.Group>}
            <Form.Group as={Row} controlId="form__update_password--new">
              <Form.Label column sm="2" xs="4">
                New Password
              </Form.Label>
              <Col sm="8" xs="6">
                <Form.Control
                  type="password"
                  placeholder="New Password"
                  name="new_password"
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="form__update_password--confirm">
              <Form.Label column sm="2" xs="4">
                Confirm Password
              </Form.Label>
              <Col sm="8" xs="6">
                <Form.Control
                  type="password"
                  placeholder="Confirm Password"
                  name="confirm_password"
                />
              </Col>
            </Form.Group>
            <Form.Group className="text-right mb-chat-widget">
              <Button variant="success" type="submit" size="lg">
                Update
              </Button>
            </Form.Group>
          </Form>
        )}
      </UserPageLayout>
    );
  }
}


const mapStateToProps = (state, props) => {
  const user = getUser(state);
  const currentUserId = user && user.id;
  const userToEditId = getPathParamIntegerFromProps(props, 'id') || currentUserId;
  const userIsSelf = userToEditId === currentUserId;
  return {
    userIsSelf,
    userToEditId,
    userToEdit: userIsSelf ? user : getUser(state, userToEditId),
  };
};
const mapDispatchToProps = { fetchUserWithId, submitUserDetails, submitUserWithIdDetails };

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePassword);
