
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';

import ActivityCard from '../../app/components/ActivityCard';

import { relativeDateFormatter } from '../../../components/table/formatters';

import { getDevice, getDevicePlaceholderImage } from '../selectors';
import { isSuperAdmin } from '../../user/selectors';

function DeviceActivityCard({
  device = {},
  name,
  image,
  timestamp,
  DeviceLink = 'span',
  children,
  ...props
}) {
  return (
    <ActivityCard
      {...props}
      title={(
        <Row className="small-gutters">
          {image && (
            <Col xs="auto">
              <img src={image} alt="Device" />
            </Col>
          )}
          <Col>
            <DeviceLink to={`/devices/${device.id}`}>
              {name}
            </DeviceLink> on <Link to={`/equipment/${device.id}`}>
              {device.equipment_name}
            </Link>
            <div className="subtitle">
              <DeviceLink to={`/devices/${device.id}`}>
                {device.serial}
              </DeviceLink>
            </div>
            {!!timestamp && (
              <div className="subtitle text-muted">
                {relativeDateFormatter(timestamp)}
              </div>
            )}
          </Col>
        </Row>
      )}
    >
      {children}
    </ActivityCard>
  );
}

const mapStateToProps = (state, { deviceId }) => {
  return {
    image: getDevicePlaceholderImage(state, deviceId),
    device: getDevice(state, deviceId),
    DeviceLink: isSuperAdmin(state) ? Link : 'span',
  };
};

const ConnectedDeviceCard = connect(mapStateToProps)(DeviceActivityCard);

export function FitMachineActivityCard(props) {
  return (
    <ConnectedDeviceCard name="FitMachine" {...props} />
  );
}
