
import { CALL_API } from '../../lib/apiMiddleware';

// todo: export actions individually
// it's too easy to reference an action that doesn't exist, like RECEIVE_LOGOUT
export const ACTION_TYPES = {

  REQUEST_PASSWORD_RESET_REQUEST: 'user/REQUEST_PASSWORD_RESET_REQUEST',
  RECEIVE_PASSWORD_RESET_REQUEST: 'user/RECEIVE_PASSWORD_RESET_REQUEST',
  PASSWORD_RESET_REQUEST_FAILURE: 'user/PASSWORD_RESET_REQUEST_FAILURE',

  REQUEST_PASSWORD_RESET_CONFIRM: 'user/REQUEST_PASSWORD_RESET_CONFIRM',
  RECEIVE_PASSWORD_RESET_CONFIRM: 'user/RECEIVE_PASSWORD_RESET_CONFIRM',
  PASSWORD_RESET_CONFIRM_FAILURE: 'user/PASSWORD_RESET_CONFIRM_FAILURE',

  REQUEST_LOGIN: 'user/REQUEST_LOGIN',
  RECEIVE_LOGIN: 'user/RECEIVE_LOGIN',
  LOGIN_FAILURE: 'user/LOGIN_FAILURE',

  REQUEST_USER: 'user/REQUEST_USER',
  RECEIVE_USER: 'user/RECEIVE_USER',
  USER_FAILURE: 'user/USER_FAILURE',

  REQUEST_USER_PICTURE_UPLOAD_S3_FILE_URL: 'user/REQUEST_USER_PICTURE_UPLOAD_S3_FILE_URL',
  RECEIVE_USER_PICTURE_UPLOAD_S3_FILE_URL: 'user/RECEIVE_USER_PICTURE_UPLOAD_S3_FILE_URL',
  USER_PICTURE_UPLOAD_S3_FILE_URL_FAILURE: 'user/USER_PICTURE_UPLOAD_S3_FILE_URL_FAILURE',

  REQUEST_USER_PICTURE: 'user/REQUEST_USER_PICTURE',
  RECEIVE_USER_PICTURE: 'user/RECEIVE_USER_PICTURE',
  USER_PICTURE_FAILURE: 'user/USER_PICTURE_FAILURE',

  REQUEST_USER_TAGS: 'user/REQUEST_USER_TAGS',
  RECEIVE_USER_TAGS: 'user/RECEIVE_USER_TAGS',
  USER_TAGS_FAILURE: 'user/USER_TAGS_FAILURE',

  RECEIVE_USER_INFO: 'user/RECEIVE_USER_INFO',

  REQUEST_USER_DEVICES: 'user/REQUEST_USER_DEVICES',
  RECEIVE_USER_DEVICES: 'user/RECEIVE_USER_DEVICES',
  USER_DEVICES_FAILURE: 'user/USER_DEVICES_FAILURE',

  REQUEST_ARCHIVED_USER_DEVICES: 'user/REQUEST_ARCHIVED_USER_DEVICES',
  RECEIVE_ARCHIVED_USER_DEVICES: 'user/RECEIVE_ARCHIVED_USER_DEVICES',
  ARCHIVED_USER_DEVICES_FAILURE: 'user/ARCHIVED_USER_DEVICES_FAILURE',

  SET_USER_LOCAL_PREFERENCE: 'user/SET_USER_LOCAL_PREFERENCE',
  SET_USER_OPTIMISTIC_REMOTE_PREFERENCE: 'user/SET_USER_OPTIMISTIC_REMOTE_PREFERENCE',
  UNSET_USER_OPTIMISTIC_REMOTE_PREFERENCE: 'user/UNSET_USER_OPTIMISTIC_REMOTE_PREFERENCE',

  REQUEST_UPDATE_USER_PREFERENCES: 'user/REQUEST_UPDATE_USER_PREFERENCES',
  RECEIVE_UPDATE_USER_PREFERENCES: 'user/RECEIVE_UPDATE_USER_PREFERENCES',
  UPDATE_USER_PREFERENCES_FAILURE: 'user/UPDATE_USER_PREFERENCES_FAILURE',

  REQUEST_UPDATE_USER_DEVICES: 'user/REQUEST_UPDATE_USER_DEVICES',
  RECEIVE_UPDATE_USER_DEVICES: 'user/RECEIVE_UPDATE_USER_DEVICES',
  UPDATE_USER_DEVICES_FAILURE: 'user/UPDATE_USER_DEVICES_FAILURE',

  TOKEN_EXPIRED: 'user/TOKEN_EXPIRED',

  LOGOUT: 'user/LOGOUT',

  REQUEST_USERS: 'users/REQUEST_USERS',
  RECEIVE_USERS: 'users/RECEIVE_USERS',
  USERS_FAILURE: 'users/USERS_FAILURE',

  REQUEST_CREATE_USER: 'user/REQUEST_CREATE_USER',
  RECEIVE_CREATE_USER: 'user/RECEIVE_CREATE_USER',
  CREATE_USER_FAILURE: 'user/CREATE_USER_FAILURE',

  REQUEST_UPDATE_USER: 'user/REQUEST_UPDATE_USER',
  RECEIVE_UPDATE_USER: 'user/RECEIVE_UPDATE_USER',
  UPDATE_USER_FAILURE: 'user/UPDATE_USER_FAILURE',

  REQUEST_USER_TYPES: 'user/REQUEST_USER_TYPES',
  RECEIVE_USER_TYPES: 'user/RECEIVE_USER_TYPES',
  USER_TYPES_FAILURE: 'user/USER_TYPES_FAILURE',

  // User archive action types
  REQUEST_ARCHIVE_USER: 'user/REQUEST_ARCHIVE_USER',
  RECEIVE_ARCHIVE_USER: 'user/RECEIVE_ARCHIVE_USER',
  ARCHIVE_USER_FAILURE: 'user/ARCHIVE_USER_FAILURE'
};

// user request a password reset

function requestPasswordResetRequest(user) {
  return {
    type: ACTION_TYPES.REQUEST_PASSWORD_RESET_REQUEST,
    user,
  };
}

function receivePasswordResetRequest(user) {
  return {
    type: ACTION_TYPES.RECEIVE_PASSWORD_RESET_REQUEST,
    user,
  };
}

function passwordResetRequestFailure(user) {
  return {
    type: ACTION_TYPES.PASSWORD_RESET_REQUEST_FAILURE,
    user,
  };
}

export function submitPasswordResetRequest({ email }) {
  const user = { email };
  return dispatch => {
    return dispatch({
      type: CALL_API,
      userMustBeAuthenticated: false,
      method: 'post',
      endpoint: `/users/password`,
      data: { email },
      requestAction: requestPasswordResetRequest(user),
      successAction: receivePasswordResetRequest(user),
      successToast: "Email sent",
      errorToast: "Email not sent",
      errorAction: passwordResetRequestFailure(user),
    });
  };
}

// user confirm a password reset

function requestPasswordResetConfirm() {
  return {
    type: ACTION_TYPES.REQUEST_PASSWORD_RESET_CONFIRM,
  };
}

function receivePasswordResetConfirm() {
  return {
    type: ACTION_TYPES.RECEIVE_PASSWORD_RESET_CONFIRM,
  };
}

function passwordResetConfirmFailure() {
  return {
    type: ACTION_TYPES.PASSWORD_RESET_CONFIRM_FAILURE,
  };
}

export function submitPasswordResetConfirm({ passwordResetToken }, { new_password }) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      userMustBeAuthenticated: false,
      method: 'post',
      endpoint: `/users/password/${passwordResetToken}`,
      data: {
        new_password,
      },
      requestAction: requestPasswordResetConfirm(),
      successAction: receivePasswordResetConfirm(),
      errorAction: passwordResetConfirmFailure(),
    });
  };
}

function requestLogin() {
  return {
    type: ACTION_TYPES.REQUEST_LOGIN
  };
}

function receiveLogin() {
  return {
    type: ACTION_TYPES.RECEIVE_LOGIN,
    meta: {
      crossTab: true,
    },
  };
}

function loginFailure() {
  return {
    type: ACTION_TYPES.LOGIN_FAILURE
  };
}

export function login(email, password, organisation) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      userMustBeAuthenticated: false,
      method: 'post',
      endpoint: '/tokens',
      data: {
        user_name: email,
        password,
        sub_domain: organisation
      },
      requestAction: requestLogin(),
      successAction: receiveLogin(),
      errorAction: loginFailure(),
      errorToast: 'Login failed',
    });
  };
}

export function requestUser() {
  return {
    type: ACTION_TYPES.REQUEST_USER
  };
}

function receiveUser() {
  return {
    type: ACTION_TYPES.RECEIVE_USER
  };
}

function userFailure() {
  return {
    type: ACTION_TYPES.USER_FAILURE
  };
}

export function fetchUser() {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: '/users/me',
      requestAction: requestUser(),
      successAction: receiveUser(),
      errorAction: userFailure()
    });
  };
}

// upload user picture
export function getPhotoS3FileUrl(user, data = {}) {
  const {
    file_name = new Date().toISOString(), // default filename to current time
  } = data;
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'put',
      endpoint: `/users/${user.id}/picture`,
      data: { file_name },
      requestAction: {
        user,
        type: ACTION_TYPES.REQUEST_USER_PICTURE_UPLOAD_S3_FILE_URL
      },
      successAction: {
        user,
        type: ACTION_TYPES.RECEIVE_USER_PICTURE_UPLOAD_S3_FILE_URL
      },
      errorAction: {
        user,
        type: ACTION_TYPES.USER_PICTURE_UPLOAD_S3_FILE_URL_FAILURE
      },
    });
  };
}

function requestUserPicture(user) {
  return {
    user,
    type: ACTION_TYPES.REQUEST_USER_PICTURE
  };
}

function receiveUserPicture(user) {
  return {
    user,
    type: ACTION_TYPES.RECEIVE_USER_PICTURE
  };
}

function userPictureFailure(user) {
  return {
    user,
    type: ACTION_TYPES.USER_PICTURE_FAILURE
  };
}

export function fetchUserPicture(user) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/users/${user.id}/picture`,
      requestAction: requestUserPicture(user),
      successAction: receiveUserPicture(user),
      errorAction: userPictureFailure(user),
      // note: currently there is no other way to check for a valid user picture
      // than to hit this endpoint and see if it returns a 200 with data
      // if a user has no picture, this will return a 403
      errorToast: false,
    });
  };
}

export function requestUserTags(user) {
  return {
    type: ACTION_TYPES.REQUEST_USER_TAGS,
    user,
  };
}

function receiveUserTags(user) {
  return {
    type: ACTION_TYPES.RECEIVE_USER_TAGS,
    user,
  };
}

function userTagsFailure(user) {
  return {
    type: ACTION_TYPES.USER_TAGS_FAILURE,
    user,
  };
}

export function fetchUserPreferences(user) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/users/${user.id}/tags`,
      requestAction: requestUserTags(user),
      successAction: receiveUserTags(user),
      errorAction: userTagsFailure(user),
    });
  };
}

function receiveUserInfo(user) {
  return {
    type: ACTION_TYPES.RECEIVE_USER_INFO,
    user
  };
}

export function fetchUserWithId(user) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/users/${user.id}`,
      requestAction: requestUser(),
      successAction: receiveUserInfo(user),
      errorAction: userFailure()
    });
  };
}


function requestUserDevices(user) {
  return {
    type: ACTION_TYPES.REQUEST_USER_DEVICES,
    user
  };
}
function receiveUserDevices(user) {
  return {
    type: ACTION_TYPES.RECEIVE_USER_DEVICES,
    user
  };
}
function userDevicesFailure(user) {
  return {
    type: ACTION_TYPES.USER_DEVICES_FAILURE,
    user
  };
}

function requestArchivedUserDevices(user) {
  return {
    type: ACTION_TYPES.REQUEST_ARCHIVED_USER_DEVICES,
    user
  };
}
function receiveArchivedUserDevices(user) {
  return {
    type: ACTION_TYPES.RECEIVE_ARCHIVED_USER_DEVICES,
    user
  };
}
function archivedUserDevicesFailure(user) {
  return {
    type: ACTION_TYPES.ARCHIVED_USER_DEVICES_FAILURE,
    user
  };
}

export function fetchUserDevices(user, { getArchived= true } ={}) {
  return dispatch => {
    // get active devices
    dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/users/${user.id}/devices`,
      requestAction: requestUserDevices(user),
      successAction: receiveUserDevices(user),
      errorAction: userDevicesFailure(user)
    });
    // get archived devices
    if (getArchived) {
      dispatch({
        type: CALL_API,
        method: 'get',
        endpoint: `/users/${user.id}/devices?filter=archived`,
        requestAction: requestArchivedUserDevices(user),
        successAction: receiveArchivedUserDevices(user),
        errorAction: archivedUserDevicesFailure(user)
      });
    }
  };
}

// user preferences update

export function setLocalPreference(user, key, value) {
  return {
    type: ACTION_TYPES.SET_USER_LOCAL_PREFERENCE,
    meta: { user },
    payload: { key, value },
  };
}

export function setRemotePreference(user, key, value) {
  return async dispatch => {

    // identify the update by a unique id
    const optimisticUpdate = { [key]: value };

    // make an optimistic update:
    // this should only exists for the duration of this function
    dispatch({
      type: ACTION_TYPES.SET_USER_OPTIMISTIC_REMOTE_PREFERENCE,
      meta: { user },
      payload: optimisticUpdate,
    });

    try {
      // request change on the server
      await submitUserPreferences(user, { [`preferences:${key}`]: value })(dispatch);
      // get updated preferences
      await fetchUserPreferences(user)(dispatch);
    }
    catch (e) {
      // ignore errors, the unsetting of the optimistic update will reveal the remote state
    }
    // unset the optimistic update, regardless of the outcome
    dispatch({
      type: ACTION_TYPES.UNSET_USER_OPTIMISTIC_REMOTE_PREFERENCE,
      meta: { user },
      payload: optimisticUpdate,
    });
  };
}

function requestUpdateUserPreferences(user) {
  return {
    type: ACTION_TYPES.REQUEST_UPDATE_USER_PREFERENCES,
    user
  };
}

function receiveUpdateUserPreferences(user) {
  return {
    type: ACTION_TYPES.RECEIVE_UPDATE_USER_PREFERENCES,
    user
  };
}

function updateUserPreferencesFailure(user) {
  return {
    type: ACTION_TYPES.UPDATE_USER_PREFERENCES_FAILURE,
    user
  };
}

export function submitUserPreferences(user, preferences) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'put',
      endpoint: `/users/${user.id}/tags`,
      data: preferences,
      requestAction: requestUpdateUserPreferences(user),
      successAction: receiveUpdateUserPreferences(user),
      errorAction: updateUserPreferencesFailure(user),
      errorToast: 'User preferences not updated',
    });
  };
}

// user devices update

function requestUpdateUserDevices(user) {
  return {
    type: ACTION_TYPES.REQUEST_UPDATE_USER_DEVICES,
    user
  };
}

function receiveUpdateUserDevices(user) {
  return {
    type: ACTION_TYPES.RECEIVE_UPDATE_USER_DEVICES,
    user
  };
}

function updateUserDevicesFailure(user) {
  return {
    type: ACTION_TYPES.UPDATE_USER_DEVICES_FAILURE,
    user
  };
}

export function submitUserDevices(user, devices) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'put',
      endpoint: `/users/${user.id}/devices`,
      data: { device_ids: devices },
      requestAction: requestUpdateUserDevices(user),
      successAction: receiveUpdateUserDevices(user),
      errorAction: updateUserDevicesFailure(user),
      successToast: 'User equipment updated',
      errorToast: 'User equipment not updated',
    });
  };
}

export function logout() {
  return {
    type: ACTION_TYPES.LOGOUT,
    meta: {
      crossTab: true,
    },
  };
}

export function setUserLocalPreference(key, value) {
  return {
    type: ACTION_TYPES.SET_USER_LOCAL_PREFERENCE,
    payload: { key, value },
  };
}

export function requestUsers() {
  return {
    type: ACTION_TYPES.REQUEST_USERS
  };
}

function receiveUsers() {
  return {
    type: ACTION_TYPES.RECEIVE_USERS
  };
}

function usersFailure() {
  return {
    type: ACTION_TYPES.USERS_FAILURE
  };
}

export function fetchUsers() {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: '/users',
      requestAction: requestUsers(),
      successAction: receiveUsers(),
      errorAction: usersFailure()
    });
  };
}

// fetch all user devices
export function fetchUsersThenUserDevices(organisationId) {
  return dispatch => {
    return organisationId && fetchUsers()(dispatch)
      .then(({ data }) => {
        const users = data && data._embedded && data._embedded.users;
        if (users && users.length) {
          // fetch all User active devices
          return Promise.all(users.map((user={}) => {
            if (user.user_type === 'User') {
              // find if the User is a part of the requested device's organisation
              const organisations = user._embedded && user._embedded.organisations;
              const organisationMatch = organisations.find(({ organisation_id }) => {
                return organisation_id === organisationId;
              });
              if (organisationMatch) {
                return fetchUserDevices(user, { getArchived: false })(dispatch);
              }
            }
            // skip all other types of users
            return null;
          }));
        }
      });
  };
}

// create new user

function requestCreateUser() {
  return {
    type: ACTION_TYPES.REQUEST_CREATE_USER
  };
}

function receiveCreateUser() {
  return {
    type: ACTION_TYPES.RECEIVE_CREATE_USER
  };
}

function createUserFailure() {
  return {
    type: ACTION_TYPES.CREATE_USER_FAILURE
  };
}

export function submitNewUserDetails(details) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'post',
      endpoint: "/users",
      data: details,
      requestAction: requestCreateUser(),
      successAction: receiveCreateUser(),
      errorAction: createUserFailure(),
      successToast: 'User invited',
    });
  };
}

// user details update

function requestUpdateUser(user) {
  return {
    type: ACTION_TYPES.REQUEST_UPDATE_USER,
    user
  };
}

function receiveUpdateUser(user) {
  return {
    type: ACTION_TYPES.RECEIVE_UPDATE_USER,
    user
  };
}

function updateFailure(user) {
  return {
    type: ACTION_TYPES.UPDATE_USER_FAILURE,
    user
  };
}

export function submitUserDetails(user, details, { successToast='Profile updated' }={}) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'put',
      endpoint: '/users/me',
      data: details,
      requestAction: requestUpdateUser(user),
      successAction: receiveUpdateUser(user),
      errorAction: updateFailure(user),
      successToast,
    });
  };
}

export function submitUserWithIdDetails(user, details, { successToast='User updated' }={}) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'put',
      endpoint: `/users/${user.id}`,
      data: details,
      requestAction: requestUpdateUser(user),
      successAction: receiveUpdateUser(user),
      errorAction: updateFailure(user),
      successToast,
    });
  };
}

// fetch user types options

function requestUserTypes() {
  return {
    type: ACTION_TYPES.REQUEST_USER_TYPES
  };
}

function receiveUserTypes() {
  return {
    type: ACTION_TYPES.RECEIVE_USER_TYPES
  };
}

function userTypesFailure() {
  return {
    type: ACTION_TYPES.USER_TYPES_FAILURE
  };
}

export function fetchUserTypeOptions() {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/domains/usertypes`,
      requestAction: requestUserTypes(),
      successAction: receiveUserTypes(),
      errorAction: userTypesFailure()
    });
  };
}

// user archive action creators
function requestArchiveUser(user) {
  return {
    type: ACTION_TYPES.REQUEST_ARCHIVE_USER,
    user
  };
}

function receiveArchiveUser(user) {
  return {
    type: ACTION_TYPES.RECEIVE_ARCHIVE_USER,
    user
  };
}

function archiveUserFailure(user) {
  return {
    type: ACTION_TYPES.ARCHIVE_USER_FAILURE,
    user
  };
}

export function archiveUser(user) {
  return dispatch => {
    return dispatch ({
      type: CALL_API,
      method: 'put',
      data: {
        archived: true,
      },
      endpoint: `/users/${user.id}`,
      requestAction: requestArchiveUser(user),
      successAction: receiveArchiveUser(user),
      errorAction: archiveUserFailure(user),
      successToast: "User Archived",
      errorToast: "Archiving Failed"
    });
  };
}
