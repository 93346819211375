import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { fetchOrganisations } from '../actions';

import {
  IoIosAddCircleOutline
} from "react-icons/io";

import Table from '../../../components/Table';
import TableToolbar from '../../../components/TableToolbar';

import Private from '../../../components/Private';
import OrganisationDropdown from '../components/OrganisationDropdown';

import { getOrganisations, getOrganisationListState } from '../selectors';

import {
  organisationName,
  subDomain,
  createdAt,
  parentOrganisation,
} from '../columns';

const defaultSorted = [{
  dataField: 'name',
  order: 'asc'
}];

const actions = {
  dataField: 'actions',
  text: 'Actions',
  formatter: (value, { id }={}) => (
    id ? (
      <div className="text-right">
        <OrganisationDropdown organisationId={id} />
      </div>
    ) : "N/A"
  )
};

const columns = [
  organisationName,
  subDomain,
  createdAt,
  parentOrganisation,
  actions,
];

class OrganisationsAdmin extends Component {

  componentDidMount() {
    const { fetchOrganisations } = this.props;
    fetchOrganisations();
  }

  renderHeader = props => {
    const { loading, lastFetch, error } = this.props;
    return (
      <TableToolbar
        searchable
        title="Organisations Admin"
        loading={loading}
        lastFetch={lastFetch}
        error={error}
        tableProps={props}
        buttons={[
          <Private key="new" minUserType="Partner Admin" minOrgType="Partner">
            <LinkContainer to={`/organisations/new`}>
              <Button variant="primary" className="pl-1">
                <IoIosAddCircleOutline className="mr-1" size="1.4em"/>Add Organisation
              </Button>
            </LinkContainer>
          </Private>,
        ]}
      />
    );
  }

  render() {
    const { organisations, loading } = this.props;
    const parentOrganisationsById = organisations.reduce((acc, org) => {
      if (org.is_parent) {
        acc[org.id] = org;
      }
      return acc;
    }, {});

    const organisationsWithParentNames = organisations.map(org => {
      const parentOrganisation = parentOrganisationsById[org.parent_organisation_id] || {};
      return {
        ...org,
        parent_organisation_name: parentOrganisation.name,
      };
    });

    return (
      <Container fluid>
        <Table
          pagination
          renderHeader={this.renderHeader}
          data={organisationsWithParentNames}
          defaultSorted={defaultSorted}
          columns={columns}
          noDataIndication={() => 'No organisations'}
          loading={loading}
        />
      </Container>
    );
  }
}

const mapStateToProps = state => {
  const { loading, lastFetch, error } = getOrganisationListState(state);
  return {
    loading,
    lastFetch,
    error,
    organisations: getOrganisations(state),
  };
};

const mapDispatchToProps = { fetchOrganisations };

export default connect(mapStateToProps, mapDispatchToProps)(OrganisationsAdmin);
