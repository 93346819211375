import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button, DropdownButton, ButtonGroup, Dropdown, Container, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IndexLinkContainer } from 'react-router-bootstrap';
import { withRouter } from 'react-router-dom';
import { Icon } from '@blueprintjs/core';

import './header.scss';

import {
  IoIosContact,
  IoIosHome,
  IoIosMap,
  IoIosList,
  IoIosNotificationsOutline,
  IoIosSchool,
  IoIosRadio,
  IoIosPeople,
  IoIosBriefcase,
  IoIosLaptop,
  IoIosLogOut,
  IoIosRocket,
  IoIosMenu,
  IoIosCodeWorking,
  IoIosPersonAdd,
} from "react-icons/io";

import { logout } from '../modules/user/actions';
import Private from './Private';
import { isUserLoggedIn } from '../modules/user/selectors';

const blueprintIconStyle = {
  width: '1.2em',
  marginRight: '0.3em',
  textAlign: 'center',
};

function HeaderButton({ tooltipText, to, Icon, children, ...buttonProps }) {
  const LinkContainer = to ? IndexLinkContainer : 'div';
  // create consistently styled buttons
  const button = (
    <LinkContainer to={to}>
      <Button size="sm" variant="outline-secondary" {...buttonProps}>
        {Icon && (
          <Icon size="2em" />
        )} {children && (
          <span>
            {children}
          </span>
        )}
      </Button>
    </LinkContainer>
  );

  // display button inside a tooltip trigger or not
  return !tooltipText ? button : (
    <OverlayTrigger
      placement="bottom"
      overlay={(
        <Tooltip>
          {tooltipText}
        </Tooltip>
      )}
    >
      {button}
    </OverlayTrigger>
  );
}

class UserHeader extends Component {

  render() {
    const { userIsLoggedIn, expanded, toggleExpanded } = this.props;
    if (!userIsLoggedIn) {
      return null;
    }
    return (
      <Container fluid>
        <Row className="small-gutters mt-1 pt-1 mb-1">
          <Col xs="auto">
            <Row className="small-gutters">
              <Col className="mb-1 d-md-none" xs="auto">
                <HeaderButton
                  tooltipText={!expanded ? 'Views' : ''}
                  Icon={IoIosMenu}
                  onClick={toggleExpanded}
                />
              </Col>
              <Col className="mb-1" xs="auto">
                <HeaderButton
                  tooltipText="Home"
                  Icon={IoIosHome}
                  to="/home"
                />
              </Col>
              <Col className="mb-1" xs="auto">
                <ButtonGroup size="sm" data-toggle="buttons">
                  <HeaderButton
                    tooltipText="Map"
                    Icon={IoIosMap}
                    to="/equipment/map"
                  />
                  <HeaderButton
                    tooltipText="List"
                    Icon={IoIosList}
                    to="/equipment/list"
                  />
                  <HeaderButton
                    tooltipText="Alarms"
                    Icon={IoIosNotificationsOutline}
                    to="/alarms"
                  />
                </ButtonGroup>
              </Col>
            </Row>
          </Col>
          {/*  when this column overflows, ensure is is right-aligned using ml-auto */}
          <Col className="ml-auto" xs="auto">
            {/* align items in this row to the right */}
            <Row className="small-gutters justify-content-end">
              <Col className="mb-1" xs="auto">
                <HeaderButton
                  tooltipText="Read what's new &amp; vote for what we build next"
                  to="/whats-new"
                  Icon={IoIosRocket}
                >
                  What's new
                </HeaderButton>
              </Col>
              <Private minUserType="Admin">
                <Col className="mb-1" xs="auto">
                  <HeaderButton
                    to="/users/new"
                    Icon={IoIosPersonAdd}
                  >
                    Invite User
                  </HeaderButton>
                </Col>
              </Private>
              <Col className="mb-1" xs="auto">
                <ButtonGroup>
                  <HeaderButton to="/users/me" Icon={IoIosContact}>
                    Me
                  </HeaderButton>
                  <DropdownButton as={ButtonGroup} menuAlign="right" variant="outline-secondary" title="">
                    <Private minUserType="Admin">
                      <Dropdown.Header>Admin Tools</Dropdown.Header>
                      <IndexLinkContainer to="/devices/admin">
                        <Dropdown.Item>
                          <IoIosRadio size="1.2em" /> <span>Devices</span>
                        </Dropdown.Item>
                      </IndexLinkContainer>
                      <IndexLinkContainer to="/gateways/admin">
                        <Dropdown.Item>
                          <Icon
                            iconSize="1em"
                            icon="graph"
                            title="Network"
                            style={blueprintIconStyle}
                          /> <span>Network</span>
                        </Dropdown.Item>
                      </IndexLinkContainer>
                      <IndexLinkContainer to="/users/admin">
                        <Dropdown.Item>
                          <IoIosPeople size="1.2em" /> <span>Users</span>
                        </Dropdown.Item>
                      </IndexLinkContainer>
                      <IndexLinkContainer to="/organisations/admin">
                        <Dropdown.Item>
                          <IoIosBriefcase size="1.2em" /> <span>Organisations</span>
                        </Dropdown.Item>
                      </IndexLinkContainer>
                      <IndexLinkContainer to="/developer/admin/tokens">
                        <Dropdown.Item>
                          <IoIosLaptop size="1.2em" /> <span>Developers</span>
                        </Dropdown.Item>
                      </IndexLinkContainer>
                      <Dropdown.Divider />
                    </Private>
                    <IndexLinkContainer to="/university">
                      <Dropdown.Item>
                        <IoIosSchool size="1.2em" /> <span>MOVUS University</span>
                      </Dropdown.Item>
                    </IndexLinkContainer>
                    <IndexLinkContainer to="/integrations">
                      <Dropdown.Item>
                        <IoIosCodeWorking size="1.2em" /> <span>Integrations Store</span>
                      </Dropdown.Item>
                    </IndexLinkContainer>
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={() => this.props.logout()}>
                      <IoIosLogOut size="1.2em" /> <span>Logout</span>
                    </Dropdown.Item>
                  </DropdownButton>
                </ButtonGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}

function Header(props) {
  const { userIsLoggedIn } = props;
  // show the header only if the user is logged in
  return userIsLoggedIn ? (
    <UserHeader {...props} />
  ) : null;
}

const mapStateToProps = state => {
  return {
    userIsLoggedIn: isUserLoggedIn(state),
  };
};

const mapDispatchToProps = { logout };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
