import React, { Component } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import {
  fetchOrganisationWithId,
  submitOrganisationDetails,
} from '../actions';
import { getFormValues } from '../../../lib/utils';
import Title from  '../../../components/Title';
import { connect } from 'react-redux';
import OrganisationPageLayout from '../components/OrganisationPageLayout';
import LoadingSpinner from '../../../components/LoadingSpinner';
import ParentOrganisationInput from '../components/form/ParentOrganisationInput';
import { getOrganisation } from '../selectors';
import { getPathParamIntegerFromProps } from '../../selectors';

class EditOrganisation extends Component {

  constructor(props) {
    super(props);
    this.state = {
      submit: {}
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  };

  // fetch most recent details of the organisation
  fetchOrganisationDetails = () => {
    const { fetchOrganisationWithId, organisationToEdit, organisationToEditId } = this.props;
    organisationToEditId && fetchOrganisationWithId(organisationToEdit || { id: organisationToEditId });
  }

  componentDidMount() {
    this.fetchOrganisationDetails();
  }

  componentDidUpdate(prevProps) {
    // reset submit form if the organisation is changed
    if (prevProps.organisationToEditId !== this.props.organisationToEditId) {
      this.setState({ submit: {} });
      this.fetchOrganisationDetails();
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    const formValues = getFormValues(e);
    const { submitOrganisationDetails, organisationToEdit } = this.props;

    formValues.parent_organisation_id = formValues.parent_organisation_id !== undefined
      ? formValues.parent_organisation_id
        ? parseInt(formValues.parent_organisation_id)
        : undefined // see DASH-454 for details: cannot PUT  "parent_organisation_id" = null
      : undefined;

    const submittedAt = Date.now();
    this.setState({ submit: { submittedAt } });

    // submit form then handle feedback
    organisationToEdit && submitOrganisationDetails(organisationToEdit, formValues)
      .then(() => {
      // if still displaying the same submission then update with success
        this.setState(({ submit }) => {
          if (submit.submittedAt === submittedAt) {
            return { submit: { succeededAt: new Date() } };
          }
        });
        // fetch new details to be propagated through redux
        this.fetchOrganisationDetails();
      })
      .catch((error) => {
      // if still displaying the same submission then update with failure
        this.setState(({ submit }) => {
          if (submit.submittedAt === submittedAt) {
            return { submit: { error: error.message || 'Error' } };
          }
        });
      });
  }

  render() {
    const {
      organisationToEdit: organisationObject={},
      organisationToEditId,
    } = this.props;
    const { submit } = this.state;

    return (
      <OrganisationPageLayout>
        <Title
          title="Edit Organisation"
          loading={submit.submittedAt}
          lastFetch={submit.submittedAt}
          error={submit.error}
        />
        {!organisationObject ? <LoadingSpinner /> : <Form
          className="form-container"
          onSubmit={ this.handleSubmit }
        >
          <Form.Group as={Row} controlId="form__edit_organisation--name">
            <Form.Label column sm="2" xs="4">
              Name
            </Form.Label>
            <Col sm="8" xs="6">
              <Form.Control
                key={`name-${organisationObject.name}`}
                type="text"
                placeholder="Name"
                name="name"
                defaultValue={ organisationObject.name || "" }
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="form__edit_organisation--sub_domain">
            <Form.Label column sm="2" xs="4">
              Subdomain
            </Form.Label>
            <Col sm="8" xs="6">
              <Form.Control
                key={`sub_domain-${organisationObject.sub_domain}`}
                type="text"
                placeholder="org.dash.movus"
                name="sub_domain"
                defaultValue={ organisationObject.sub_domain || "" }
              />
            </Col>
          </Form.Group>
          <ParentOrganisationInput
            organisationId={organisationToEditId}
            Container={({ children }) => (
              <Form.Group as={Row}>
                <Form.Label column sm="2" xs="4">
                  Parent Organisation
                </Form.Label>
                <Col sm="8" xs="6">
                  {children}
                </Col>
              </Form.Group>
            )}
          />
          <Form.Group>
            <Button variant="success" type="submit" size="lg" className="float-right mb-chat-widget">
              Update
            </Button>
          </Form.Group>
        </Form>}
      </OrganisationPageLayout>
    );
  }
}

const mapStateToProps = (state, props) => {
  const organisationToEditId = getPathParamIntegerFromProps(props, 'id');
  return {
    organisationToEditId,
    organisationToEdit: getOrganisation(state, organisationToEditId),
  };
};
const mapDispatchToProps = {
  fetchOrganisationWithId,
  submitOrganisationDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditOrganisation);
