import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container } from 'react-bootstrap';

import { fetchDevices } from '../actions';

import Table from '../../../components/Table';

import { DeviceAdminTableToolbar } from './DevicesAdmin';

import {
  siteName,
  subAreaName,
  equipmentName,
  serial,
  organisationName,
} from '../columns';

import { getArchivedDevices, getDeviceListState } from '../selectors';
import { getActiveSubGroupId } from '../../organisation/selectors';
import { Router, Redirect } from 'react-router-dom';
import history from '../../../history';

const defaultSorted = [{
  dataField: 'equipment_name',
  order: 'asc'
}];

const columns = [
  siteName,
  subAreaName,
  equipmentName,
  serial,
  organisationName,
];

class DevicesAdminArchived extends Component {

  componentDidMount() {
    const { fetchDevices } = this.props;

    fetchDevices({ filter: 'archived' });
  }

  renderHeader = props => {
    return (
      <DeviceAdminTableToolbar
        tableProps={props}
      />
    );
  }

  render() {
    const { loading, activeSubGroupId, archivedDevices=[] } = this.props;

    // todo: redo if archived devices become available on group members
    if (activeSubGroupId) {
      return (
        <Router history={history}>
          <Redirect to="/devices/admin" />
        </Router>
      );
    }

    return (
      <Container fluid>
        <Table
          pagination
          renderHeader={this.renderHeader}
          data={archivedDevices}
          defaultSorted={defaultSorted}
          columns={columns}
          loading={loading}
          noDataIndication={() => 'No archived devices'}
        />
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  activeSubGroupId: getActiveSubGroupId(state),
  archivedDevices: getArchivedDevices(state),
  // todo: separate out archived device fetching into its own loading state
  loading: getDeviceListState(state).loading,
});
const mapDispatchToProps = { fetchDevices };

export default connect(mapStateToProps, mapDispatchToProps)(DevicesAdminArchived);
