import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { login } from '../actions';

import { Form, Button } from 'react-bootstrap';
import LoadingSpinner from '../../../components/LoadingSpinner';

import RootUnauthenticated from '../../../components/RootUnauthenticated';

import { getFormValues } from '../../../lib/utils';

import { CopyrightText } from '../../../components/Footer';
import { addToast } from '../../../components/Toaster';
import { getUserLastLogin } from '../selectors';
import { getQueryParamFromProps } from '../../selectors';

class Login extends Component {

  state = {
    loggingIn: false,
  }

  checkAllFormFields = (email='', password='', organisation='') => {
    // Check for email field
    if (email.length === 0) {
      addToast({
        variant: 'danger',
        header: 'Please enter a valid email address',
      });
      return false;
    }

    // Check for password Field
    if (password.length === 0) {
      addToast({
        variant: 'danger',
        header: 'Please enter a valid password',
      });
      return false;
    }

    // Check for Organisation field
    if (organisation.length === 0) {
      addToast({
        variant: 'danger',
        header: 'Please enter a valid organisation',
      });
      return false;
    }

    return true;
  };

  handleSubmit = async (e, dev) => {
    const { email, password, organisation } = getFormValues(e);
    const { login } = this.props;

    try {
      // login as dev
      if (dev && process.env.NODE_ENV !== "production") {
        if (window.confirm('This will sign you in with: ' +
          '\n- email: ' + (email || process.env.REACT_APP_MACHINECLOUD_DEV_ADMIN_EMAIL) +
          '\n- password: ' + ("*".repeat((password || process.env.REACT_APP_MACHINECLOUD_DEV_ADMIN_PASSWORD).length)) +
          '\n- organisation: ' + (organisation || process.env.REACT_APP_MACHINECLOUD_DEV_ADMIN_ORG) +
          "\n\nContinue? \n\n(We use default dev credentials for any empty fields)"
        )) {
          this.setState({ loggingIn: true });
          await login(
            email || process.env.REACT_APP_MACHINECLOUD_DEV_ADMIN_EMAIL,
            password || process.env.REACT_APP_MACHINECLOUD_DEV_ADMIN_PASSWORD,
            organisation || process.env.REACT_APP_MACHINECLOUD_DEV_ADMIN_ORG
          );
        }
      }
      // login as normal
      else if (this.checkAllFormFields(email, password, organisation)) {
        this.setState({ loggingIn: true });
        await login(email, password, organisation);
      }
    }
    catch(e) {
      this.setState({ loggingIn: false });
    }
  }

  render() {
    const {
      lastLogin = {},
      queryEmail,
      queryOrganisation,
    } = this.props;
    const { loggingIn } = this.state;

    return (
      <RootUnauthenticated title="Sign in to MachineCloud">
        <Form className="form-floating-labels" onSubmit={this.handleSubmit}>

          <Form.Group>
            {/* eslint-disable-next-line jsx-a11y/no-autofocus */}
            <Form.Control disabled={loggingIn} type="email" name="email" defaultValue={lastLogin.email || queryEmail || ''} id="inputEmail" placeholder="Email address" autoFocus autoComplete="email" />
            <Form.Label htmlFor="inputEmail">Email address</Form.Label>
          </Form.Group>

          <Form.Group>
            <Form.Control disabled={loggingIn} type="password" name="password" id="inputPassword" placeholder="Password" autoComplete="current-password" />
            <Form.Label htmlFor="inputPassword">Password</Form.Label>
          </Form.Group>

          <Form.Group>
            <Form.Control disabled={loggingIn} type="text" name="organisation" defaultValue={lastLogin.organisation || queryOrganisation || ''} id="inputOrganisation" placeholder="Organisation (e.g. org.dash.movus)" />
            <Form.Label htmlFor="inputOrganisation">Organisation (e.g. org.dash.movus)</Form.Label>
          </Form.Group>

          <p className="text-muted"><small>Clicking "Sign in" means you accept our <a
            href="https://www.movus.com.au/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >Privacy Policy</a>.</small></p>

          <Button size="lg" variant="info" block type="submit" disabled={loggingIn}>{
            loggingIn
              ? <LoadingSpinner size="1.5" />
              : "Sign in"
          }</Button>

          {
            (process.env.NODE_ENV !== "production") && (
              <Button size="lg" variant="info" block type="submit" name="test" value="true" disabled={loggingIn} onClick={e => this.handleSubmit(e, true)}>{
                loggingIn
                  ? <LoadingSpinner size="1.5" />
                  : "DEV SIGN IN"
              }</Button>
            )
          }
        </Form>
        <br/>
        <Link to="/password/forgot">Forgot Password?</Link>
        <br/><br/>
        <p className="text-muted">
          <small>
            <CopyrightText />
          </small>
        </p>
      </RootUnauthenticated>
    );
  }
}

const mapStateToProps = (state, props) => ({
  lastLogin: getUserLastLogin(state),
  queryEmail: getQueryParamFromProps(props, 'email'),
  queryOrganisation: getQueryParamFromProps(props, 'org') || getQueryParamFromProps(props, 'organisation'),
});
const mapDispatchToProps = { login };

export default connect(mapStateToProps, mapDispatchToProps)(Login);
