
import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import withNavigationDeviceProps from '../components/withNavigationDeviceProps';

import {
  fetchDeviceInfo,
} from '../actions';

import Title from '../../../components/Title';

import NotFound from '../../../components/NotFound';
import LoadingSpinner from '../../../components/LoadingSpinner';
import EquipmentPageLayout from '../components/EquipmentPageLayout';
import EditDeviceUsers from '../../user/components/EditDeviceUsers';
import { getDevice } from '../selectors';

class EditEquipmentUsers extends Component {

  constructor(props) {
    super(props);
    this.state = {
      fetching: true,
      submit: {},
    };
    this.form = undefined;
  }

  async componentDidMount() {
    const { fetchDeviceInfo, deviceToEditId } = this.props;
    this.setState({ fetchingDevice: true });
    try {
      await fetchDeviceInfo({ id: deviceToEditId });
    }
    catch(e) {
      // user probably doesn't have access to this device
    }
    this.setState({ fetchingDevice: false });
  }

  render() {
    const { deviceToEdit: deviceObject={} } = this.props;
    const { fetchingDevice, submit } = this.state;
    if (!deviceObject.id && !fetchingDevice) {
      return <NotFound />;
    }
    return (
      <Container style={{marginBottom: "50px"}}>
        <Title
          title={`Editing Equipment${
            deviceObject.equipment_name ? `: ${deviceObject.equipment_name}` : ''
          }`}
          loading={submit.submittedAt}
          lastFetch={submit.succeededAt}
          error={submit.error}
        />
        {deviceObject.id ? (
          <EditDeviceUsers deviceToEditId={deviceObject.id}/>
        ) : <LoadingSpinner />}
      </Container>
    );
  }
}

const mapStateToProps = (state, { deviceId }) => {
  return {
    deviceToEditId: deviceId,
    deviceToEdit: getDevice(state, deviceId),
  };
};

const mapDispatchToProps = { fetchDeviceInfo };

const ConnectedEditEquipmentUsers = withNavigationDeviceProps(
  connect(mapStateToProps, mapDispatchToProps)(EditEquipmentUsers)
);

export default function EditEquipmentUsersPage() {
  return (
    <EquipmentPageLayout>
      <div className="my-4">
        <ConnectedEditEquipmentUsers />
      </div>
    </EquipmentPageLayout>
  );
}
