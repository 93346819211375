
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Form } from 'react-bootstrap';

import FormModal from '../../../components/FormModal';
import { useFocusableRef } from '../../../components/form/useFocusableRef';

import { getOrganisation, getGroup, getGroupTreeNode } from '../selectors';
import { fetchGroup, fetchOrganisationGroups, updateGroup } from '../actions';
import { getFormValues } from '../../../lib/utils';

const confirmButtonProps = {
  variant: 'success',
};

function EditGroupFormModal({
  groupId,
  groupNode = {},
  // get most current data from group list, default to (presumably stale) data from group tree
  group = { id: groupNode.id, group_name: groupNode.name },
  organisation = {},
  fetchGroup,
  updateGroup,
  fetchOrganisationGroups,
  children,
  ...props
}) {

  const [name, setName] = useState(group.group_name);
  const [pristineName, setPristineName] = useState(true);
  const [valid, setValid] = useState(false);

  useEffect(() => {
    setName(group.group_name);
  }, [group.group_name]);

  const formRef = useRef(null);
  const [nameRef, setShown] = useFocusableRef(null);

  const handleSubmit = useCallback(async e => {
    const { group_name } = getFormValues(e, formRef.current) || {};
    if (group_name) {
      try {
        await updateGroup(group, { group_name });
        await fetchOrganisationGroups(organisation);
      }
      catch(err) {
        // allow error to prevent closing of the modal
        throw new Error(err);
      }
    }
  }, [updateGroup, formRef.current, organisation]);

  return (
    <FormModal
      // set defaults
      header="Rename group"
      confirmText="Rename group"
      confirmButtonProps={confirmButtonProps}
      // add given props
      {...props}
      // override given props
      size="md"
      valid={!pristineName && valid}
      onShow={useCallback(() => {
        setShown(true);
        fetchGroup(group);
      }, [group.id])}
      onClose={useCallback(() => {
        setShown(false);
        setName(group.group_name);
      }, [group.group_name])}
      form={(
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Form.Group as={Row} controlId="group_name">
            <Form.Label column sm={5}>
              Name
            </Form.Label>
            <Col sm={7}>
              <Form.Control
                ref={nameRef}
                type="text"
                name="group_name"
                isInvalid={!pristineName && !valid}
                value={name}
                onChange={e => {
                  const name = e.target.value;
                  setPristineName(name === group.group_name);
                  setName(name);
                  setValid(!!name);
                }}
              />
            </Col>
          </Form.Group>
        </Form>
      )}
    >
      {children}
    </FormModal>
  );
}

const mapStateToProps = (state, { groupId }) => {
  return {
    groupId,
    group: getGroup(state, groupId),
    groupNode: getGroupTreeNode(state, groupId),
    organisation: getOrganisation(state),
  };
};
const mapDispatchToProps = {
  fetchGroup,
  fetchOrganisationGroups,
  updateGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditGroupFormModal);
