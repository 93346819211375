
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import withNavigationGatewayProps from './withNavigationGatewayProps';

// Components
import { InfoCard, InfoCardItem } from '../../../components/InfoCard';

// Other
import {
  latLngFormatter,
} from '../../../components/table/formatters';

import { getGateway } from '../selectors';
import {
  getOrganisationRmsAvailablePreference,
  getOrganisationMm2AvailablePreference,
} from '../../organisation/selectors';

function GatewaySidebar({ gateway={} }) {

  if (!gateway || !gateway.id) {
    return null;
  }

  return (
    <Fragment>
      <h5 className="montserrat" style={{ marginTop: 10, marginBottom: 1 }}>
        {gateway.mac}
      </h5>
      <p className="text-muted font-weight-bold">
        {gateway.type || ''}
      </p>
      <InfoCard collapsible heading="Access Point Details">
        <InfoCardItem heading="Name">
          {gateway.name}
        </InfoCardItem>
        <InfoCardItem heading="SSID">
          {gateway.ssid}
        </InfoCardItem>
        <InfoCardItem heading="MAC address">
          {gateway.mac}
        </InfoCardItem>
        <InfoCardItem heading="Last heard">
          {moment(gateway.fitmachine_last_heard).format("ll")}
        </InfoCardItem>
      </InfoCard>
      <InfoCard collapsible heading="Location Details" initialCollapsed>
        <InfoCardItem heading="Organisation name">
          {gateway.organisation_name}
        </InfoCardItem>
        <InfoCardItem heading="Site name">
          {gateway.site_name}
        </InfoCardItem>
        <InfoCardItem heading="Sub-area name">
          {gateway.sub_area_name}
        </InfoCardItem>
        <InfoCardItem heading="Latitude">
          {latLngFormatter(parseFloat(gateway.latitude))}
        </InfoCardItem>
        <InfoCardItem heading="Longitude">
          {latLngFormatter(parseFloat(gateway.longitude))}
        </InfoCardItem>
      </InfoCard>
    </Fragment>
  );
}

const mapStateToProps = (state, { gatewayId }) => ({
  gateway: getGateway(state, gatewayId),
  rmsAvailable: getOrganisationRmsAvailablePreference(state),
  mm2Available: getOrganisationMm2AvailablePreference(state),
});

export default withNavigationGatewayProps(
  connect(mapStateToProps)(GatewaySidebar)
);
