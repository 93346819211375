import React, { Fragment, useLayoutEffect, useRef } from 'react';
import { Row, Col, Tooltip, OverlayTrigger, Image, Container } from 'react-bootstrap';

import "./footer.scss";
import logoBlack from '../images/logo_black.svg';

import { footerSize } from '../modules/app/actions';
import { connect } from 'react-redux';

export function CopyrightText() {
  return (
    <Fragment>
      &copy; MOVUS Australia Pty Ltd.
      MOVUS is the owner of copyright in software underpinning all elements of its AI products,
      analytics dashboards and other screen displays.
    </Fragment>
  );
}

export function Footer({ floating, className, footerSize }) {

  const footerRef = useRef(null);

  useLayoutEffect(() => {
    const footer = footerRef.current;
    if (floating && footer && footerSize) {
      const sendFooterSizeAction = () => {
        footerSize({
          height: footer.offsetHeight,
          width: footer.offsetWidth,
        });
      };
      // send status now
      sendFooterSizeAction();
      // and send on window resize
      window.addEventListener('resize', sendFooterSizeAction);
      // and remove on window resize
      return () => {
        window.removeEventListener('resize', sendFooterSizeAction);
      };
    }
  }, [floating, footerRef.current, footerSize]);

  const versionTooltip = (
    <Tooltip>
      <strong>MachineCloud</strong> {
        // print the node env value if this is not a webpack production build
        process.env.NODE_ENV !== 'production'
          ? process.env.NODE_ENV
          : ''
      } v{
        process.env.REACT_APP_MACHINECLOUD_DASH_VERSION
      }{
        // include build number if it was included in the build process
        process.env.REACT_APP_TEAMCITY_BUILD_NUMBER && (
          <div>Build {
            process.env.REACT_APP_TEAMCITY_BUILD_NUMBER
          }</div>
        )
      }
    </Tooltip>
  );

  const copyrightTooltip = (
    <Tooltip>
      <CopyrightText />
    </Tooltip>
  );

  return (
    <div
      // add footer Ref to floating Footer
      ref={footerRef}
      // add appropriate class name
      className={[
        floating ? 'footer--floating' : 'footer--non-floating', className
      ].join(' ')}
    >
      <Container capture fluid>
        <Row className="my-2">
          <Col className="my-1" xs="auto">
            <OverlayTrigger placement="top" overlay={versionTooltip}>
              <span className="footer__copyright">
                <Image src={logoBlack} alt="MOVUS" />
              </span>
            </OverlayTrigger>
          </Col>
          <Col className="my-1 ml-auto" xs="auto">
            <a
              href="https://learn.movus.com.au/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Support
            </a> · <a
              href="https://www.movus.com.au/privacy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy
            </a> · <a
              href="https://www.movus.com.au/terms/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms
            </a>
          </Col>
          <Col xs={12}>
            <OverlayTrigger placement="top" overlay={copyrightTooltip}>
              <small className="footer__copyright">
                &copy; MOVUS Australia Pty Ltd.
              </small>
            </OverlayTrigger>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

const mapDispatchToProps = { footerSize };
const ConnectedFooter = connect(null, mapDispatchToProps)(Footer);

export default function Footers({ className }) {
  return (
    <Fragment>
      <Footer floating={false} className={[className, 'invisible'].join(' ')} />
      <ConnectedFooter floating={true} className={className} />
    </Fragment>
  );
}
