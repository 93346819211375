import { combineReducers } from 'redux';

import app from './app/reducers';
import device from './equipment/reducers';
import gateway from './gateway/reducers';
import alarm from './alarm/reducers';
import user from './user/reducers';
import organisation from './organisation/reducers';

export default combineReducers({
  app,
  device,
  gateway,
  alarm,
  user,
  organisation
});
