
import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Alert, ButtonGroup, Button } from 'react-bootstrap';
import Carousel, { Modal, ModalGateway } from 'react-images';
import { FullscreenEnter } from 'react-images/lib/components/svg';
import moment from 'moment';

import { useComponentViewTracking } from '../../app/hooks';

import { getAlarmDevice, getAlarmImages } from '../selectors';
import { getOrganisationHasFFT } from '../../organisation/selectors';

import { fetchAlarmImages } from '../actions';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { Image } from 'react-bootstrap';

function getImageTitle(image={}, index=0) {
  return image.maxfreq
    ? `${image.minfreq || 0} - ${image.maxfreq}Hz`
    : `Image ${index + 1}`;
}
function getImageZoomLevel(image={}) {
  const zoomMatch = `${image.name}`.match(/^fft_zoom_(\d+)x$/);
  return zoomMatch && parseInt(zoomMatch[1]);
}

function AlarmFFTImages({
  hasFFT = false,
  alarmId,
  deviceId,
  images: unfilteredImages = [],
  fetchAlarmImages,
}) {

  // filter images to only FFT images
  const images = unfilteredImages.filter(({ name }) => {
    return `${name}`.startsWith('fft_');
  });

  const [imageError, setImageError] = useState();

  // fetch alarm info and images if the id exists and has changed
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (hasFFT && alarmId) {
      setLoading(true);
      (async () => {
        try {
          await fetchAlarmImages({ id: alarmId });
        }
        catch {
          setImageError('Could not load images for this alarm');
        }
        setLoading(false);
      })();
    }
  }, [hasFFT, alarmId]);

  const [isFullScreen, setFullScreen] = useState(false);
  const toggleFullScreen = useCallback(() => setFullScreen(!isFullScreen), [isFullScreen]);

  const [firstimage] = images;
  const [selectedImage, setSelectedImage] = useState(firstimage);

  // set selected image on load and data load
  useEffect(() => {
    if (!selectedImage && firstimage) {
      setSelectedImage(firstimage);
    }
  }, [selectedImage, firstimage]);

  // attempt to reselect selected image on subsequent data loads (by matching id)
  useEffect(() => {
    if (selectedImage && images) {
      setSelectedImage(images.find(image => image.id === selectedImage.id) || firstimage);
    }
  }, [images]);

  const selectImage = useCallback(setSelectedImage, [setSelectedImage]);

  // don't track until loaded
  useComponentViewTracking('Alarm FFT', !loading && 'alarmId', {
    alarmId,
    deviceId,
    productCode: 'fftchart',
    imageCount: images.length,
  });

  useComponentViewTracking('Alarm FFT Image', selectedImage && 'imageId', {
    imageId: selectedImage && selectedImage.id,
    chartTitle: selectedImage && getImageTitle(selectedImage, images.indexOf(selectedImage)),
    chartHzMin: selectedImage && (selectedImage.minfreq || 0),
    chartHzMax: selectedImage && selectedImage.maxfreq,
    chartZoomLevel: selectedImage && getImageZoomLevel(selectedImage),
    alarmId,
    deviceId,
    productCode: 'fftchart',
  });

  if (!hasFFT) {
    return (
      <Alert variant="warning">
        Unable to view FFT images
      </Alert>
    );
  }

  if (loading) {
    return (
      <div className="my-3 text-center">
        <LoadingSpinner size={2} inline/>
      </div>
    );
  }

  if (imageError) {
    return (
      <Alert variant="danger">
        {imageError}
      </Alert>
    );
  }

  if (!images.length) {
    return (
      <Alert variant="warning">
        No FFT images were found for this alarm
      </Alert>
    );
  }

  return (
    <Row>
      <Col xs={12}>
        <ButtonGroup className="mb-2">
          {images.map((image, index) => {
            return (
              <Button
                key={image.id || index}
                variant="outline-secondary"
                size="md"
                // select active image by id, as the images are reloaded on page load
                // (and therefore the image objects won't be equal)
                active={selectedImage && selectedImage.id === image.id}
                onClick={() => selectImage(image)}
              >
                {getImageTitle(image, index)}
              </Button>
            );
          })}
        </ButtonGroup>
      </Col>
      <Col xs={12}>
        {selectedImage && (
          <div className="position-relative">
            <Image src={selectedImage.url} fluid />
            <div className="position-absolute" style={{ top: 5, right: 5 }}>
              <Button variant="outline-secondary" onClick={toggleFullScreen}>
                <FullscreenEnter />
              </Button>
            </div>
          </div>
        )}
        <ModalGateway>
          {images.length && selectedImage && isFullScreen ? (
            <Modal
              closeOnBackdropClick={false}
              onClose={toggleFullScreen}
              allowFullscreen={true}
            >
              <Carousel
                hideControlsWhenIdle={false}
                currentIndex={images.findIndex(image => image === selectedImage)}
                views={images.map(({ url, updated_at }) => ({
                  source: url,
                  caption: `Uploaded: ${
                    moment(updated_at).format('ll h:mm a')
                  }`,
                }))}
                trackProps={{
                  onViewChange: index => selectImage(images[index]),
                }}
                styles={carouselStyles}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </Col>
    </Row>
  );
}

const navigationStyle = (base, { isFullscreen }) => isFullscreen ? {
  ...base,
  // make fullscreen navigation buttons very obvious
  boxShadow: '0 0 6px -2px rgba(0, 0, 0, 7)',
  background: 'rgba(180, 180, 180, 0.6)',
  '&:active': {
    background: 'rgba(180, 180, 180, 0.7)',
  },
  '&:hover': {
    background: 'rgba(180, 180, 180, 0.8)',
  },
} : {
  ...base,
  // make non-fullscreen navigation buttons more obvious
  background: 'rgba(255, 255, 255, 0.3)',
};

const carouselStyles = {
  navigationPrev: navigationStyle,
  navigationNext: navigationStyle,
  header: base => ({ ...base, opacity: 1 }),
  footer: (base, { isFullscreen }) => isFullscreen ? {
    ...base,
    textShadow: '0px 1px 6px #000',
    color: '#fff',
    opacity: 1,
  } : {
    ...base,
    opacity: 1,
  },
};

const mapStateToProps = (state, { alarmId }) => {
  return {
    deviceId: (getAlarmDevice(state, alarmId) || {}).id,
    hasFFT: getOrganisationHasFFT(state),
    images: getAlarmImages(state, alarmId),
  };
};

const mapDispatchToProps = { fetchAlarmImages };

export default connect(mapStateToProps, mapDispatchToProps)(AlarmFFTImages);
