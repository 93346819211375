
import {
  isTopLevelGroupActive,
} from '../organisation/selectors';
import { getDevice } from '../equipment/selectors';

// Images
import gwPlaceholder from '../../images/gwPlaceholder.png';

function getGatewaysById(state) {
  return state &&
    state.gateway &&
    state.gateway.gatewaysById;
}

const connectionsCache = {
  connections: [],
};

export function getConnections(state) {
  // get all gateways
  const gateways = state && state.gateway && state.gateway.gateways;

  // exit early
  if (!gateways) {
    return undefined;
  }

  // derive connections from gateway relations and current devicePoints
  const connections = gateways.reduce((acc, gateway={}) => {

    // get each gateways device relation
    const deviceRelations = (
      gateway &&
      gateway._embedded &&
      gateway._embedded.devices &&
      gateway._embedded.devices.length > 0
        // return new relations collection
        ? gateway._embedded.devices
        // or replicate through legacy id array (using only the id field)
        // todo: revert this commit after legacy id array is no longer used
        // (when PD-898 is merged into Platform production)
        : (gateway.connected_devices || []).map(id => ({ id }))
    ) || [];

    // add connection
    return acc.concat(
      // add connections in one way for easier filtering by index later
      deviceRelations
        // embedded device relations have only 'id' and 'last_heard'
        .map(relation => {
          // find related device point in fetch device point list
          const device = getDevice(state, relation.id);
          return device && [gateway, device, relation];
        })
        // remove unfound device points (devices may not yet have been fetched)
        .filter(Boolean)
    );
  }, []);

  // if the connections array objects do not match the cached array objects
  // then recreate the cache
  if (connectionsCache.connections.length !== connections.length || (
    // test every object equivalence
    !connectionsCache.connections.every(cachedConnection => {
      return !!connections.find(newConnection => {
        // all objects were found by reference
        return newConnection.every(obj => cachedConnection.includes(obj));
      });
    })
  )) {
    connectionsCache.connections = connections;
  }

  // return cached value
  return connectionsCache.connections;
}

export function getGateways(state) {
  // return all gateways
  if (isTopLevelGroupActive(state)) {
    return state && state.gateway && state.gateway.gateways;
  }
  // filter the list if necessary
  else {
    // return only gateways that are connected to
    // at least one accessible device
    // todo: cache this
    const connections = getConnections(state);
    // use set to prevent duplicate objects
    return connections && [
      ...new Set(connections.map(([gateway]) => gateway))
    ];
  }
}

export function getGatewayListState(state) {
  const { gateway: { loading, lastFetch, error }={} } = state;
  return { loading, lastFetch, error };
}

// get gateway from store, restricted to the active organisation
export function getGateway(state, id) {
  // find gateway by id in the restricted gateway list
  const gateways = getGateways(state);
  if (gateways && gateways.find(gateway => gateway.id === id)) {
    // find gateway by id in gateway store
    const gatewaysById = getGatewaysById(state);
    return gatewaysById && gatewaysById[id];
  };
}

// get connections related to each gateway
// todo: cache the response as it is created as a new array each time
export function getGatewayConnections(state, id) {
  // return relevant connections for this gateway
  // todo: cache
  const connections = getConnections(state);
  // return matching connections by gateway id
  return connections && connections.filter(([gateway]) => gateway.id === id);
}

export function getGatewayPlaceholderImage() {
  return gwPlaceholder;
}
