
import createReducer from '../../lib/createReducer';

import { ACTION_TYPES as USER_ACTION_TYPES } from '../user/actions';
import { ACTION_TYPES } from './actions';

import {
  upsertListItems,
  upsertObjectItems,
  upsertObjectItem,
} from '../../lib/reducerUtils';

export const DEFAULT_STATE = {
  loading: false, // the list of gateways is being loaded
  lastFetch: null, // the last time we did a fetch
  error: null, // any error loading the list

  // list of gateways in list
  gateways: [],
  // gateways keyed by the API id, for appending relations to
  gatewaysById: {},
};

export default createReducer(DEFAULT_STATE, {
  [USER_ACTION_TYPES.LOGOUT]: () => ({ ...DEFAULT_STATE }),
  [USER_ACTION_TYPES.TOKEN_EXPIRED]: () => ({ ...DEFAULT_STATE }),

  [ACTION_TYPES.REQUEST_GATEWAY_LIST](state) {
    return {
      ...state,
      loading: true
    };
  },
  [ACTION_TYPES.GATEWAY_LIST_FAILURE](state, { response }) {
    return {
      ...state,
      loading: false,
      error: (response ? response : "Unknown error")
    };
  },
  [ACTION_TYPES.RECEIVE_GATEWAY_LIST](state, { response }) {

    const gateways = response && response._embedded && response._embedded.gateways;

    return {
      ...state,
      // update the gateways list
      gateways: upsertListItems(state.gateways, gateways),
      // update gateway objects by merging list into the gateway lookup object
      // (this object may have more gateways than the gateways list)
      gatewaysById: upsertObjectItems(state.gatewaysById, gateways),
      loading: false,
      error: null,
      lastFetch: new Date(),
    };
  },

  [ACTION_TYPES.RECEIVE_GATEWAY](state={}, { response={} }) {
    return {
      ...state,
      gatewaysById: upsertObjectItem(state.gatewaysById, response),
    };
  },

});
