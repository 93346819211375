import React, { Component, Fragment } from 'react';
import { Row, Col, Form, Button, Container }  from 'react-bootstrap';
import { connect } from 'react-redux';
import withNavigationUserProps from './withNavigationUserProps';

import history from '../../../history';
import { fetchOrganisations } from '../../organisation/actions';
import { getFormValues } from '../../../lib/utils';
import Title from '../../../components/Title';
import LoadingSpinner from '../../../components/LoadingSpinner';
import NotFound from '../../../components/NotFound';

import  {
  fetchUser,
  fetchUserWithId,
  fetchUserTypeOptions,
  fetchUserPreferences,
  fetchUserDevices,
  submitUserDetails,
  submitUserWithIdDetails,
  submitNewUserDetails,
  submitUserPreferences,
} from './../actions';

import {
  userPreferenceModels,
  getUser,
  getUserTags,
  isUserEditable,
} from '../selectors';

import {
  getTimezoneDescriptionFull,
  getTimezoneOffset,
} from '../../../components/values/Timezone';

const defaultUserType = 'User';

class EditUser extends Component {

  constructor(props) {
    super(props);
    const { userToEdit: { user_type = defaultUserType } = {} } = this.props;
    this.state = {
      fetchingUser: true,
      currentUserType: user_type,
      submit: {}
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // fetch most recent details of the user
  fetchUserDetails = async () => {
    const {
      fetchUser,
      fetchUserPreferences,
      fetchUserWithId,
      fetchUserDevices,
      userIsSelf,
      userToEdit,
      userToEditId,
    } = this.props;
    this.setState({ fetchingUser: true });
    // fetch self
    if (userIsSelf) {
      await Promise.all([
        fetchUser(),
        userToEdit && userToEdit.id && fetchUserPreferences(userToEdit),
      ]);
    }
    // or fetch other user
    else if (userToEditId) {
      try {
        const response = await fetchUserWithId(userToEdit || { id: userToEditId });
        // if the user is a User then fetch their devices list
        if (response && response.data && response.data.user_type === 'User') {
          fetchUserDevices(userToEdit || { id: userToEditId });
        }
      }
      catch(e) {
        // user probably does not have access to this user
      }
    }
    this.setState({ fetchingUser: false });
  }

  componentDidMount() {
    const { fetchOrganisations, fetchUserTypeOptions } = this.props;
    fetchOrganisations();
    fetchUserTypeOptions();
    this.fetchUserDetails();
  }

  componentDidUpdate(prevProps, prevState) {
    const { userToEditId, userToEdit: { user_type = defaultUserType } = {} } = this.props;
    const { userToEdit: { user_type: prev_user_type = defaultUserType } = {} } = prevProps;
    // update user type state if necessary
    // case: user's user_type from API was different than kept in redux
    if ((prev_user_type !== user_type) && (prevState.currentUserType !== user_type)) {
      this.setState({ currentUserType: user_type });
    }
    // reset submit form if the user is changed
    if (prevProps.userToEditId !== userToEditId) {
      this.setState({ submit: {}, currentUserType: user_type });
      this.fetchUserDetails();
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    const formValues = getFormValues(e);
    const {
      submitUserDetails,
      submitUserWithIdDetails,
      submitNewUserDetails,
      submitUserPreferences,
      userToEdit = {},
      userIsSelf,
      userIsNew,
      fetchUserWithId,
    } = this.props;

    // transform organisation ids
    if (formValues.organisation_ids && formValues.organisation_ids.length) {
      formValues.organisation_ids = formValues.organisation_ids.map(id => parseInt(id));
    }

    const submittedAt = Date.now();
    this.setState({ submit: { submittedAt } });

    // todo: put use a form library to handle this, this is a workaround
    // it transforms { "orgs[4]": true, "orgs[5]": false } to { "orgs": [4] }
    // pick out array fields from the payload and attach them as arrays
    const checkboxInputNameRegex = /^(\w+)\[(\d+)\]$/;
    Object.entries(formValues).forEach(([key, value]) => {
      // get the identifiers from the name format
      const [match, name, id] = key.match(checkboxInputNameRegex) || [];
      if (match) {
        if (value) {
          // initialise as array if not already an array
          formValues[name] = formValues[name] || [];
          // remove this key from the main formValues payload
          formValues[name].push(parseInt(id));
        }
        delete formValues[key];
      }
    });

    const preferenceForm = {};
    // todo: put use a form library to handle this, this is a workaround
    // strip out the user preferences
    const preferenceInputNameRegex = /^(preferences:\w+)$/;
    Object.entries(formValues).forEach(([key, value]) => {
      // get the identifiers from the name format
      const [match, name] = key.match(preferenceInputNameRegex) || [];
      if (match) {
        if (value) {
          preferenceForm[`${name}`] = value;
        }
        // remove this key from the main formValues payload
        delete formValues[key];
      }
    });

    // submit form then handle feedback
    userToEdit && (
      userToEdit.id
        ? userIsSelf
          ? submitUserDetails(userToEdit, formValues)
          : submitUserWithIdDetails(userToEdit, formValues)
        : submitNewUserDetails(formValues)
    )
      .then(async response => {

        if (userIsSelf && Object.keys(preferenceForm).length) {
          try {
            await submitUserPreferences(userToEdit, preferenceForm);
          }
          catch (err) {
            // allow user edit to pass even if edit preferences fails
            // they should get a toast
          }
        }

        if (userIsNew && response && response.headers && response.headers.location) {
          const matches = `${response.headers.location}`.match(/(\d+)$/);
          if (matches && matches[1]) {
            await fetchUserWithId({ id: matches[1] });
            history.push(`/users/${matches[1]}`);
          }
          else {
            history.push('/users/admin');
          }
        }
        else {
          // refetch the new user data
          this.fetchUserDetails();
          // if still displaying the same submission then update with success
          this.setState(({ submit }) => {
            if (submit.submittedAt === submittedAt) {
              return { submit: { succeededAt: new Date() } };
            }
          });
        }
      })
      .catch((error) => {
        // if still displaying the same submission then update with failure
        this.setState(({ submit }) => {
          if (submit.submittedAt === submittedAt) {
            return { submit: { error: error.message || 'Error' } };
          }
        });
      });
  }

  render() {
    const {
      userIsSelf = false,
      userIsEditable = false,
      userToEdit: userObject = {},
      userTags = {},
    } = this.props;

    const { fetchingUser } = this.state;

    if (!userIsEditable && !fetchingUser) {
      return (
        <NotFound />
      );
    }

    return (
      <Container>
        {!userObject || !userIsEditable ? <LoadingSpinner /> : <Form
          id="edit-user-form"
          className="form-container"
          onSubmit={ this.handleSubmit }
        >
          <Title title="Notification Preferences" loading={false} />
          <Form.Group controlId="form__edit_user--receive_daily_email">
            <Form.Check
              key={`receive_daily_email-${userObject.receive_daily_email}`}
              type="checkbox"
              label="Daily Email"
              name="receive_daily_email"
              defaultChecked={userObject.receive_daily_email}
            />
          </Form.Group>
          <Form.Group controlId="form__edit_user--receive_alert_email">
            <Form.Check
              key={`receive_alert_email-${userObject.receive_alert_email}`}
              type="checkbox"
              label="Alerts via Email"
              name="receive_alert_email"
              defaultChecked={userObject.receive_alert_email}
            />
          </Form.Group>
          <Form.Group controlId="form__edit_user--receive_alert_sms">
            <Form.Check
              key={`receive_alert_sms-${userObject.receive_alert_sms}`}
              type="checkbox"
              label="Alerts via SMS"
              name="receive_alert_sms"
              defaultChecked={userObject.receive_alert_sms}
            />
          </Form.Group>
          {userIsSelf && (
            <Fragment>
              <br/>
              <Title title="User Preferences" loading={false} />
              {userPreferenceModels.map(({ key, title, options }) => {
                const userPreferenceValue = userTags[`preferences:${key}`];
                return (
                  <Form.Group as={Row} key={key}>
                    <Form.Label column sm="2" xs="4">
                      {title}
                    </Form.Label>
                    <Col sm="8" xs="6">
                      {options.map(({ title, description, value, isDefaultOption }) => (
                        // note: this can easily become a UserPreferenceRadioInput component later
                        // if more input types are needed and this starts to become verbose
                        <Form.Check
                          key={value}
                          id={`${key}-${value}`}
                          type="radio"
                          label={`${title} (${description})`}
                          value={value}
                          name={`preferences:${key}`}
                          defaultChecked={userPreferenceValue === value || (
                            // if this is default option
                            // flag this option as true if no matching preference is found
                            !!isDefaultOption && !options.find(({ value }) => {
                              return userPreferenceValue === value;
                            })
                          )}
                        />
                      ))}
                    </Col>
                  </Form.Group>
                );
              })}
              <Form.Group as={Row} className="mb-0">
                <Form.Label column sm="2" xs="4">
                  Display Timezone
                </Form.Label>
                <Col sm="8" xs="6">
                  <Form.Control
                    plaintext={true}
                    readOnly={true}
                    key={`user_type-${getTimezoneDescriptionFull()}`}
                    defaultValue={ getTimezoneDescriptionFull() }
                  />
                  <Form.Text id={`user_type-${getTimezoneDescriptionFull()}_help`} className="text-muted">
                    The display timezone shown above is determined by the region or timezone set in your Operating System.
                  </Form.Text>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="2" xs="4">
                  Date/Time Format
                </Form.Label>
                <Col sm="8" xs="6">
                  <Form.Control
                    plaintext={true}
                    readOnly={true}
                    // show a date where it is impossible to confuse date with month
                    // or am for 24h time, eg. 13th day, 1pm
                    key={`user_type-${new Date('2020-01-13T13:00').toLocaleString()}`}
                    defaultValue={`Example: ${
                      new Date('2020-01-13T13:00').toLocaleString()
                    } (${getTimezoneOffset()})`}
                  />
                  <Form.Text id={`user_type-${new Date('2020-01-13T13:00').toLocaleString()}_help`} className="text-muted">
                    The date/time format shown above is determined by the region or locale set in your Operating System
                    (currently "{Intl.DateTimeFormat().resolvedOptions().locale}").
                  </Form.Text>
                </Col>
              </Form.Group>
            </Fragment>
          )}
          <Form.Group className="text-right mb-chat-widget">
            <Button variant="success" type="submit" size="lg">
              Update
            </Button>
          </Form.Group>
        </Form>}
      </Container>
    );
  }
}

const mapStateToProps = (state, { userIsSelf, userIsNew, userId }) => {
  return {
    userIsNew,
    userIsSelf,
    userIsEditable: isUserEditable(state, { userIsSelf, userIsNew, userId }),
    userToEditId: userId,
    userToEdit: getUser(state, userId),
    userTags: getUserTags(state, userId),
  };
};

const mapDispatchToProps = {
  fetchUser,
  fetchUserWithId,
  fetchOrganisations,
  fetchUserPreferences,
  fetchUserDevices,
  fetchUserTypeOptions,
  submitUserDetails,
  submitUserWithIdDetails,
  submitNewUserDetails,
  submitUserPreferences,
};

export default withNavigationUserProps(
  connect(mapStateToProps, mapDispatchToProps)(EditUser)
);
