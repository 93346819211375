import React, { Component } from 'react';

import "./bar.scss";
import { rgba0, rgba50, rgba100 } from "./bar.scss";

// gradientPoints format [stop%, r, g, b, a]
const gradientPoints = [
  [0, ...(rgba0 || '').split(',').map(Number)],
  [0.2666, ...(rgba0 || '').split(',').map(Number)],
  // interpolate green to yellow values between these values
  // start interpolation before 1/3 to indicate "high green" values
  [0.3333, ...(rgba50 || '').split(',').map(Number)],
  [0.6, ...(rgba50 || '').split(',').map(Number)],
  // interpolate yellow to red values between these values
  // start interpolation before 2/3 to indicate "high yellow" values
  [0.6666, ...(rgba100 || '').split(',').map(Number)],
  [1, ...(rgba100 || '').split(',').map(Number)],
];

function findGradientColourByte(percent, colourIndex) {
  return gradientPoints.reduce((acc, gradientPoint, index, gradientPoints) => {
    // find colour if not already found
    if ((acc === undefined) && index) {
      const [nextPercentStop, ...nextRgba] = gradientPoint;
      const [prevPercentStop, ...prevRgba] = gradientPoints[index - 1];
      if (percent >= prevPercentStop && percent <= nextPercentStop) {
        const prevValue = prevRgba[colourIndex];
        const nextValue = nextRgba[colourIndex];
        const x = (percent - prevPercentStop) / (nextPercentStop - prevPercentStop);
        // using linear interpolation (mx + c)
        // return an integer byte value for use in CSS rgba()
        // note: IE will fail silently if given non-integers
        return Math.round((nextValue - prevValue) * x + prevValue);
      }
    }
    return acc;
  }, undefined);
}

function findRgb(conditionValue) {
  return [0, 1, 2].map(index => findGradientColourByte(conditionValue, index) || 0);
}

export default class Bar extends Component {

  render() {
    const { conditionValue } = this.props;

    if (conditionValue === null || conditionValue === undefined ) {
      return <div className="bar-track" style={{background: "#999"}}></div>;
    } else {
      return (
        <div className="bar-track">
          <div className="bar-cover" />
          <div className="bar-spacer" style={{ flexGrow: conditionValue }} />
          <div className="bar-thumb" style={{
            backgroundColor: `rgba(${findRgb(conditionValue).join(', ')}, 1)`,
          }} />
          <div className="bar-spacer" style={{ flexGrow: 1 - conditionValue }} />
        </div>
      );
    }
  }

}
