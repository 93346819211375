import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import  { submitPasswordResetRequest } from './../actions';

import { Form, Button } from 'react-bootstrap';
import LoadingSpinner from '../../../components/LoadingSpinner';

import RootUnauthenticated from '../../../components/RootUnauthenticated';

import { getFormValues } from '../../../lib/utils';
import { getUserLastLogin } from '../selectors';

class ForgotPassword extends Component {

  state = {
    submit: {},
  }

  handleSubmit = e => {
    e.preventDefault();
    const { email } = getFormValues(e);
    const { submitPasswordResetRequest } = this.props;

    if (!email) {
      return;
    }

    const submittedAt = Date.now();
    this.setState({ submit: { submittedAt } });

    // submit form then handle feedback
    submitPasswordResetRequest({ email })
      .then(() => {
      // if still displaying the same submission then update with success
        this.setState(({ submit }) => {
          if (submit.submittedAt === submittedAt) {
            return { submit: { succeededAt: new Date() } };
          }
        });
      })
      .catch((error) => {
      // if still displaying the same submission then update with failure
        this.setState(({ submit }) => {
          if (submit.submittedAt === submittedAt) {
            return { submit: { error: error.message || 'Error' } };
          }
        });
      });
  }

  render() {
    const { lastLogin = {} } = this.props;
    const { email } = lastLogin;
    const { submit } = this.state;

    return (
      <RootUnauthenticated title="Send me password reset instructions">
        <Form className="form-floating-labels" onSubmit={this.handleSubmit}>
          <Form.Group controlId="form__forgot-password--email">
            <Form.Control
              type="email"
              name="email"
              placeholder="Email address"
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              autoComplete="email"
              defaultValue={email}
            />
            <Form.Label>Email address</Form.Label>
          </Form.Group>
          <Button size="lg" variant="info" block type="submit" disabled={submit.submittedAt}>
            {submit.submittedAt ? (
              <LoadingSpinner size="1.5" />
            ) : (
              "Reset Password"
            )}
          </Button>
        </Form>
        <br/>
        <Link to="/login">&larr; Back to login page</Link>
      </RootUnauthenticated>
    );
  }
}

const mapStateToProps = state => ({
  lastLogin: getUserLastLogin(state),
});
const mapDispatchToProps = { submitPasswordResetRequest };

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
