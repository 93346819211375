
import React, { useState, useEffect, useCallback } from 'react';
import { Icon } from '@blueprintjs/core';

import LoadingSpinner from './LoadingSpinner';

const isIE = !!window.navigator.msSaveOrOpenBlob;

// download data Link for data
export default function DownloadDataLink({
  // conditional params
  loading,
  blob,
  // props for the <a> link
  LinkComponent='a',
  LinkComponentAs,
  className,
  children,
  filename='file',
  // note that Firefox will not attempt to derive a file's extension
  // based on its blob MIME type. see: http://www.iana.org/assignments/media-types/media-types.xhtml
  // at this stage, the component should have an extension to append
  extension,
  onClick,
  ...linkProps
}) {

  // append filename with extension if found and not yet applied
  const filenameWithExtension = extension && !filename.endsWith(`.${extension}`)
    ? `${filename}.${extension}`
    : filename;

  // set temporary href state for the blob while it is generated
  const [href, setHref] = useState();
  // change href when blob is available
  useEffect(() => {
    if (blob) {
      // generate URL pointer to blob in memory
      const objectURL = URL.createObjectURL(blob);
      setHref(objectURL);
      // cleanup reference to created objectURL
      return () => {
        // cleanup reference *after* a tick:
        // the reference needs to exist until it is passed to the download
        // handler of the browser, otherwise it will be cleaned too early
        // and the browser will not be able to download the file
        setTimeout(() => URL.revokeObjectURL(objectURL), 0);
      };
    }
  }, [blob]);

  // define disabled state as loading or download link is not yet ready
  const isDisabled = !!loading || !href;

  return (
    <LinkComponent
      // add component customisation
      as={LinkComponentAs}
      // add other given props
      {...linkProps}
      // combine class names to surface disabled state
      className={[className, isDisabled ? 'disabled' : ''].join(' ')}
      aria-disabled={isDisabled}
      // add link download props
      download={filenameWithExtension}
      href={href}
      onClick={onClick}
      // override click behaviour on IE to include msSaveBlob method
      {...isIE && {
        href: '#',
        onClick: useCallback(event => {
          // add custom onClick behaviour if defined
          if (onClick) {
            onClick(event);
          }
          // save file Microsoft style
          window.navigator.msSaveBlob(blob, filenameWithExtension);
        }, [blob, filenameWithExtension, onClick]),
      }}
    >
      {loading ? (
        <span className="bp3-icon">
          <LoadingSpinner inline size={1} delay={100} />
        </span>
      ) : (
        <Icon
          iconSize="1em"
          icon="import"
          title="download"
          style={{ width: '1em' }}
        />
      )} {children}
    </LinkComponent>
  );
}

// build download link from dataURI
export function DownloadDataURILink({ name, dataURI, ...downloadProps }) {

  // set loading state for the blob while it is generated
  const [blob, setBlob] = useState();
  const [extension, setExtension] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // attempt to extract data from the given dataURI
    const dataURIregex = /^data:([a-z]+)\/([a-z]+);base64,([0-9a-zA-Z+/]+=*)$/;
    const dataURIparts = dataURI && dataURI.match(dataURIregex);
    const [fileType, fileExt, base64Data] = (dataURIparts || []).slice(1);
    const process = !!base64Data;

    // reset computed props
    setBlob();
    setExtension();
    setLoading(process);

    // process if data was found
    if (process) {
      const timeout = setTimeout(() => {
        // construct new blob from a byteArray of given data
        const byteArray = Uint8Array.from(
          atob(base64Data)
            .split('')
            .map(char => char.charCodeAt(0))
        );

        // note: there is some argument around best byteArray sizes to use
        // when creating new blob, with current guesses at around 512-1024KB
        // per byteArray slice. However I'm following specific advice around
        // slow usage in IE11 where its recommended to use larger slices.
        // I expect modern browsers will work well with this eventually,
        // and the current file sizes used do not appear to be too large.
        // link: https://web.archive.org/web/20201009131328/https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript/54037351#34354313
        const blob = new Blob([byteArray], { type: `${fileType}/${fileExt}` });
        setBlob(blob);
        setExtension(fileExt);
        setLoading(false);
      }, 0);
      return () => clearTimeout(timeout);
    }
  }, [dataURI]);

  return (
    <DownloadDataLink
      // set default filename and extension
      filename={name}
      extension={extension}
      // pass given props
      {...downloadProps}
      loading={loading || downloadProps.loading}
      // add calculated props
      blob={blob}
    />
  );
}

function createCSVString(headers=[], rows=[]) {

  // define a map to enclose and escape string data with quotes to ensure
  // there are no split columns or rows due to these user characters: ,"\n
  const escapeAndEncloseWithQuotes = cell => {
    return cell && typeof cell === 'string'
      // enclose non-empty strings
      ? `"${cell.replace(/"/g, '""')}"`
      : cell !== undefined
        // add non string values as strings
        ? `${cell}`
        // default to empty
        : '';
  };

  // join headers and rows into a CSV compatible string
  return `${
    // add headers
    headers
      .map(({ label }) => label)
      .map(escapeAndEncloseWithQuotes)
      .join(',')
  }\n${
    rows.map(row => {
      // construct row from given headers
      return headers
        .map(({ key }) => key)
        .map(key => row[key])
        .map(escapeAndEncloseWithQuotes)
        .join(',');
    }).join('\n')
  }`;
}

// build download link from CSV data
export function DownloadCSVLink({ name, headers, rows, ...downloadProps }) {

  // set loading state for the blob while it is generated
  const [loading, setLoading] = useState(true);
  const [blob, setBlob] = useState();

  // place blob generation in an effect to see both loading and loaded states
  useEffect(() => {
    const process = rows.length > 0;
    // reset blob
    setBlob();
    setLoading(process);
    // process rows asynchronously
    if (process) {
      // compute and save new blob
      // wait a tick (otherwise the loading state will never be rendered)
      const timeout = setTimeout(() => {
        const csvString = createCSVString(headers, rows);
        setBlob(new Blob([csvString], { type: 'text/csv' }));
        setLoading(false);
      }, 0);
      // cleanup timeout
      return () => clearTimeout(timeout);
    }
  }, [headers, rows]);

  return (
    <DownloadDataLink
      // set default filename
      filename={name}
      extension="csv"
      // pass given props
      {...downloadProps}
      // add calculated props
      // take loading state of this component, or above components
      loading={downloadProps.loading || loading}
      blob={blob && blob.size > 0 && blob}
    />
  );
}
