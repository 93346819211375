
import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { IoIosContact } from 'react-icons/io';
import withNavigationUserProps from './withNavigationUserProps';

// Components
import NotFound from '../../../components/NotFound';
import ProfilePageLayout from '../../../components/ProfilePageLayout';
import ImageUpload from '../../../components/form/ImageUpload';
import UserNavbar from './UserNavbar';
import UserSidebar from './UserSidebar';
import UserNoDevicesAlert from './UserNoDevicesAlert';

import { getUser, getUserPicture, getUsers } from '../selectors';
import { fetchUserWithId, fetchUserPicture, getPhotoS3FileUrl } from '../actions';

function UserPageLayout({
  user = {},
  users = [],
  userPictureUrl, // can be null if user has no picture, todo: denote in typescript
  userPictureStatus, // can be null if user has no picture, todo: denote in typescript
  userId,
  userIsSelf,
  userIsNew,
  children,
  fetchUserWithId,
  fetchUserPicture,
  getPhotoS3FileUrl,
}) {

  useEffect(() => {
    // if we are attempting to load a user page, but the user is not yet in redux state
    // then attempt to fetch this user
    if (userId && !user.id) {
      fetchUserWithId({ id: userId });
    }
  }, [userId && !user.id]);

  useEffect(() => {
    // get user picture with status field
    // todo: handle images with statuses that are not: "available"
    if (userId && userPictureUrl && !userPictureStatus) {
      fetchUserPicture({ id: userId });
    }
  }, [userId, userPictureUrl, userPictureStatus]);

  const getUploadS3FileUrlFromFile = useCallback(async file => {
    return await getPhotoS3FileUrl(user, {
      file_name: file.name,
    });
  }, [user]);

  if (!userIsNew && !userIsSelf) {
    if (!userId) {
      // Unfetchable, URL path is probably something like /users/mike
      return <NotFound />;
    }
    if (!user.id) {
      // userId makes sense, but user cannot see this user
      return <NotFound />;
    }
    if (!users.find(({ id }) => id === user.id)) {
      return (
        <Redirect to="/users/admin" />
      );
    }
  }

  return (
    <ProfilePageLayout
      image={userIsSelf || userPictureUrl ? (
        <div className="img-thumbnail object-fit-contain">
          <ImageUpload
            image={userPictureUrl && ({
              src: userPictureUrl,
              alt: userPictureUrl.split('/').pop()
            })}
            getUploadS3FileUrlFromFile={getUploadS3FileUrlFromFile}
            buttonText={userPictureUrl ? "Replace Picture" : "Add Picture"}
            canEdit={user && user.id && !!userIsSelf}
            canAdd={user && user.id && !!userIsSelf && !userPictureUrl}
            onSuccess={() => fetchUserPicture(user)}
          />
        </div>
      ) : (
        <IoIosContact className="img-thumbnail" />
      )}
      navbar={<UserNavbar />}
      sidebar={<UserSidebar />}
    >
      <div className="my-4">
        <UserNoDevicesAlert userId={userId} />
        {children}
      </div>
    </ProfilePageLayout>
  );
}

const mapStateToProps = (state, { userId }) => {
  const userPicture = userId && getUserPicture(state, userId);
  return {
    user: userId && getUser(state, userId),
    users: getUsers(state),
    userPictureUrl: userPicture && userPicture.url,
    userPictureStatus: userPicture && userPicture.status,
  };
};
const mapDispatchToProps = {
  fetchUserWithId,
  fetchUserPicture,
  getPhotoS3FileUrl,
};

export default withNavigationUserProps(
  connect(mapStateToProps, mapDispatchToProps)(UserPageLayout)
);
