import createReducer from '../../lib/createReducer';
import moment from 'moment';

import { ACTION_TYPES as USER_ACTION_TYPES } from '../user/actions';
import { ACTION_TYPES } from './actions';
import { upsertListItem, upsertListItems } from '../../lib/reducerUtils';
import { mapDeviceFromApi } from '../equipment/utils';

export const DEFAULT_STATE = {
  loading: false, // the list of organisations is being loaded
  lastFetch: null, // the last time we did a cloud fetch
  error: null, // any error loading the list

  // organisations is a dictionary
  // keyed by the API id
  organisations: [],
  groups: [],

  // stores the request state of group member requests by id
  groupRequestsById: {},
};


export default createReducer(DEFAULT_STATE, {
  [USER_ACTION_TYPES.LOGOUT]: () => ({ ...DEFAULT_STATE }),
  [USER_ACTION_TYPES.TOKEN_EXPIRED]: () => ({ ...DEFAULT_STATE }),
  [ACTION_TYPES.SELECT_ACTIVE_GROUP](state, { payload }) {
    return {
      ...state,
      activeGroupId: payload,
    };
  },
  [ACTION_TYPES.REQUEST_BEGIN](state) {
    return {
      ...state,
      loading: true
    };
  },

  [ACTION_TYPES.REQUEST_FAIL](state, {response}) {
    return {
      ...state,
      loading: false,
      error: response
    };
  },

  [ACTION_TYPES.RECEIVE_ORGANISATION_LIST](state, { response }) {

    const newOrgs = (
      response &&
      response._embedded &&
      response._embedded.organisations
    ) || [];

    const newState = {
      ...state,
      // for each returned item, upsert the filtered current list item
      organisations: upsertListItems(state.organisations, newOrgs),
      error: null,
      loading: false,
      lastFetch: moment()
    };

    return newState;
  },

  [ACTION_TYPES.RECEIVE_ORGANISATION_GROUPS](state, { organisation, response }) {
    return response && response._embedded && response._embedded.groups ? {
      ...state,
      organisations: upsertListItem(state.organisations, {
        id: organisation.id,
        _embedded: {
          groups: response._embedded.groups,
        },
      }),
    } : state;
  },

  [ACTION_TYPES.RECEIVE_GROUP](state, { group, response }) {
    return response ? {
      ...state,
      groups: upsertListItem(state.groups, {
        id: group.id,
        ...response,
      }),
    } : state;
  },

  [ACTION_TYPES.RECEIVE_DELETE_GROUP](state, { group, response }) {
    return response && group && group.id ? {
      ...state,
      // remove the group
      groups: state.groups.filter(({ id }) => id !== group.id),
    } : state;
  },

  [ACTION_TYPES.REQUEST_GROUP_MEMBERS](state, { group }) {
    return {
      ...state,
      groupRequestsById: {
        ...state.groupRequestsById,
        [group.id]: {
          ...state.groupRequestsById && state.groupRequestsById[group.id],
          loading: true,
        },
      },
    };
  },
  [ACTION_TYPES.RECEIVE_GROUP_MEMBERS](state, { group, response }) {
    return {
      ...state,
      groupRequestsById: {
        ...state.groupRequestsById,
        [group.id]: {
          ...state.groupRequestsById && state.groupRequestsById[group.id],
          loading: false,
          lastFetch: Date.now(),
        },
      },
      ...response && {
        groups: upsertListItem(state.groups, {
          id: group.id,
          _embedded: {
            // ensure that an empty array is written if 0 items are specifically found
            devices: ((response._embedded && response._embedded.devices) || []).map(mapDeviceFromApi),
            members: (response._embedded && response._embedded.members) || [],
          },
        }),
      },
    };
  },
  [ACTION_TYPES.GROUP_MEMBERS_FAILURE](state, { group, response }) {
    return {
      ...state,
      groupRequestsById: {
        ...state.groupRequestsById,
        [group.id]: {
          ...state.groupRequestsById && state.groupRequestsById[group.id],
          loading: false,
          error: response,
        },
      },
    };
  },

  [ACTION_TYPES.RECEIVE_GROUP_MEMBERS_AVAILABLE](state, { group, response }) {
    return response ? {
      ...state,
      groups: upsertListItem(state.groups, {
        id: group.id,
        _embedded: {
          // ensure that an empty array is written if 0 items are specifically found
          devices_available: ((response._embedded && response._embedded.devices) || []).map(mapDeviceFromApi),
        },
      }),
    } : state;
  },

  [ACTION_TYPES.RECEIVE_GROUP_RUNTIME](state, { group={}, response={} }) {
    return {
      ...state,
      groups: upsertListItem(state.groups, {
        id: group.id,
        _embedded: {
          runtime: (response._embedded && response._embedded.runtime) || [],
          // add embeded metadata object
          runtimeMetadata: {
            timezone: response.timezone,
          },
        },
      }),
    };
  },

  [ACTION_TYPES.RECEIVE_ORGANISATION_RUNTIME](state, { organisation={}, response={} }) {
    return {
      ...state,
      organisations: upsertListItem(state.organisations, {
        id: organisation.id,
        _embedded: {
          runtime: (response._embedded && response._embedded.runtime) || [],
          // add embedded metadata object
          runtimeMetadata: {
            timezone: response.timezone,
          },
        },
      }),
    };
  },

  [ACTION_TYPES.RECEIVE_ORGANISATION_TAGS](state, { organisation, response }) {
    return {
      ...state,
      organisations: upsertListItem(state.organisations, {
        id: organisation.id,
        // add selected logo details into object relations
        _embedded: {
          tags: response || {},
        },
      }),
    };
  },

  [ACTION_TYPES.RECEIVE_ORGANISATION_TOKENS](state, { organisation, response }) {
    return {
      ...state,
      organisations: upsertListItem(state.organisations, {
        id: organisation.id,
        // add selected token detail into object relations
        _embedded: {
          tokens: (response && response._embedded && response._embedded.tokens) || [],
        },
      }),
    };
  },

  [ACTION_TYPES.RECEIVE_ORGANISATION_TOKENS_DELETE](state, { organisation, token }) {
    const orgs = state.organisations.filter((org) => org.id === organisation.id);
    const org = orgs[0];
    const embedded = (org && org._embedded && org._embedded.tokens) || [];
    return {
      ...state,
      organisations: upsertListItem(state.organisations, {
        id: organisation.id,
        // update removed token detail into object relations
        _embedded: {
          tokens: embedded.filter((toCheck) => toCheck.token !== token),
        },
      }),
    };
  },

  [ACTION_TYPES.RECEIVE_STREAMING_CONFIGURATIONS](state, { organisation, response }) {
    return {
      ...state,
      organisations: upsertListItem(state.organisations, {
        id: organisation.id,
        // add selected token detail into object relations
        _embedded: {
          streams: (response && response._embedded && response._embedded.streams) || [],
        },
      }),
    };
  },

  [ACTION_TYPES.RECEIVE_STREAMING_CONFIGURATIONS_DELETE](state, { organisation, streamId }) {
    const orgs = state.organisations.filter((org) => org.id === organisation.id);
    const org = orgs[0];
    const embedded = (org && org._embedded && org._embedded.streams) || [];
    return {
      ...state,
      organisations: upsertListItem(state.organisations, {
        id: organisation.id,
        // update removed stream detail into object relations
        _embedded: {
          streams: embedded.filter((toCheck) => toCheck.id !== streamId),
        },
      }),
    };
  },

  [ACTION_TYPES.REQUEST_ORGANISATION_INFO](state, { organisation }) {
    return {
      ...state,
      organisations: upsertListItem(state.organisations, {
        ...organisation,
        // add flag to read fetching status
        _state_: {
          loading: true,
        },
      }),
    };
  },
  [ACTION_TYPES.ORGANISATION_INFO_FAILURE](state, { organisation, response='' }) {
    return {
      ...state,
      organisations: upsertListItem(state.organisations, {
        ...organisation,
        // add flag to read fetching status change
        _state_: {
          loading: false,
          error: `${response}`,
        },
      }),
    };
  },
  [ACTION_TYPES.RECEIVE_ORGANISATION_INFO](state, { response }) {
    return {
      ...state,
      organisations: upsertListItem(state.organisations, {
        ...response,
        // add selected logo details into object relations
        _embedded: {
          logo: {
            url: response.logo_url,
          },
        },
        // add flag so that it is possible to tell that the information
        // in this object contains at least one direct call to /organisations/[id]
        // rather than just information available on /organisations
        _state_: {
          loading: false,
          error: null,
          lastFetch: Date.now(),
        },
      }),
    };
  },

  // note: this reducer requires organisation property "id" only
  [ACTION_TYPES.RECEIVE_ARCHIVE_ORGANISATION](state, { organisation }) {
    return {
      ...state,
      organisations: state.organisations.filter(({id}) => id !== organisation.id)
    };
  },

  // note: this reducer requires organisation property "id" only
  [ACTION_TYPES.RECEIVE_ORGANISATION_LOGO](state, { organisation, response }) {
    const {
      url,
      status,
    } = response;
    return {
      ...state,
      organisations: upsertListItem(state.organisations, {
        ...organisation,
        // add logo_url on the organisation
        logo_url: response.url,
        // add selected logo details into object relations
        _embedded: {
          logo: {
            url,
            status,
          },
        },
      }),
    };
  },
});
