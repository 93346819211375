import { CALL_API } from '../../lib/apiMiddleware';
import { addToast } from '../../components/Toaster';

export const ACTION_TYPES = {
  REQUEST_BEGIN: 'alarms/REQUEST_BEGIN',
  REQUEST_FAIL: 'alarms/REQUEST_FAIL',

  RECEIVE_ALARMS_LIST: 'alarms/RECEIVE_ALARMS_LIST',

  REQUEST_ALARM: 'alarms/REQUEST_ALARM',
  RECEIVE_ALARM: 'alarms/RECEIVE_ALARM',
  ALARM_FAILURE: 'alarms/ALARM_FAILURE',

  REQUEST_ALARM_IMAGES: 'alarms/REQUEST_ALARM_IMAGES',
  RECEIVE_ALARM_IMAGES: 'alarms/RECEIVE_ALARM_IMAGES',
  ALARM_IMAGES_FAILURE: 'alarms/ALARM_IMAGES_FAILURE',

  REQUEST_ALARM_SOUNDS: 'alarms/REQUEST_ALARM_SOUNDS',
  RECEIVE_ALARM_SOUNDS: 'alarms/RECEIVE_ALARM_SOUNDS',
  ALARM_SOUNDS_FAILURE: 'alarms/ALARM_SOUNDS_FAILURE',
};

export function requestBegin(response) {
  return {
    type: ACTION_TYPES.REQUEST_BEGIN,
    response
  };
}

export function requestFail(response) {
  return {
    type: ACTION_TYPES.REQUEST_FAIL,
    response
  };
}

export function receiveAlarmsList(response) {
  return {
    type: ACTION_TYPES.RECEIVE_ALARMS_LIST,
    response
  };
}

export function fetchAlarms() {
  return dispatch => {
    dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: '/alarms',
      requestAction: requestBegin(),
      successAction: receiveAlarmsList(),
      errorAction: requestFail()
    });
  };
}

export function requestAlarm(alarm) {
  return {
    type: ACTION_TYPES.REQUEST_ALARM,
    alarm,
  };
}

export function receiveAlarm(alarm) {
  return {
    type: ACTION_TYPES.RECEIVE_ALARM,
    alarm,
  };
}

export function alarmFailure(alarm) {
  return {
    type: ACTION_TYPES.ALARM_FAILURE,
    alarm,
  };
}

export function fetchAlarm(alarm) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/alarms/${alarm.id}`,
      requestAction: requestAlarm(alarm),
      successAction: receiveAlarm(alarm),
      errorAction: requestFail(alarm)
    });
  };
}

export function requestAlarmImages(alarm) {
  return {
    type: ACTION_TYPES.REQUEST_ALARM_IMAGES,
    alarm,
  };
}

export function receiveAlarmImages(alarm) {
  return {
    type: ACTION_TYPES.RECEIVE_ALARM_IMAGES,
    alarm,
  };
}

export function alarmImagesFailure(alarm) {
  return {
    type: ACTION_TYPES.ALARM_IMAGES_FAILURE,
    alarm,
  };
}

export function fetchAlarmImages(alarm) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/alarms/${alarm.id}/images`,
      requestAction: requestAlarmImages(alarm),
      successAction: receiveAlarmImages(alarm),
      errorAction: alarmImagesFailure(alarm),
    });
  };
}

export function requestAlarmSounds(alarm) {
  return {
    type: ACTION_TYPES.REQUEST_ALARM_SOUNDS,
    alarm,
  };
}

export function receiveAlarmSounds(alarm) {
  return {
    type: ACTION_TYPES.RECEIVE_ALARM_SOUNDS,
    alarm,
  };
}

export function alarmSoundsFailure(alarm) {
  return {
    type: ACTION_TYPES.ALARM_SOUNDS_FAILURE,
    alarm,
  };
}

export function fetchAlarmSounds(alarm, soundType='alarm') {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      // alarm audio is in /images for now, but this might change in the future
      endpoint: `/alarms/${alarm.id}/images`,
      requestAction: requestAlarmSounds(alarm),
      successAction: receiveAlarmSounds(alarm),
      errorAction: alarmSoundsFailure(alarm),
    }).then(response => {

      // count all sounds in the images response
      const sound = response &&
        response.data &&
        response.data._embedded &&
        response.data._embedded.images &&
        response.data._embedded.images.length &&
        response.data._embedded.images.find(({ name }) => name === `${soundType}_audio`);

      // if no sound image is found, throw a toast
      if (!sound) {
        addToast({ header: `No ${soundType} audio found` });
      }

      return response;
    });
  };
}
