
// don't import anything here if it can be helped, these functions should be pure
// the migrations here run at the point in time a user accesses the app
// therefore an old function in an old migration may produce unwanted side-effects

// note: beware that multiple tabs will run multiple migrations
// however the final persisted state after all migrations should be the same for all tabs
// as long as these functions *remain pure*

// migrations happen inside of the persist/REHYDRATE action

export default {
  // clear the user if they happen to be in a bad state
  0: state => {
    if (
      // a user exists, but they are in a bad state:
      // user/RECEIVE_LOGIN payload has replaced the user object
      (state.user && state.user.user && !state.user.user.id) ||
      // a user does not exists, but somehow the organisations are loaded:
      // user/LOGIN_FAILURE has caused the user object to be null, but not cleared the rest of the app state
      (
        (state.user && !state.user.user) &&
        (state.organisation && state.organisation.organisations && state.organisation.organisations.length > 0)
      )
    ) {
      // reset their state
      return {
        // but preserve user login details
        user: state.user && {
          lastLogin: state.user.lastLogin,
        },
      };
    }
    else {
      return state;
    }
  },
  // separate out user login state from user object state
  1: state => {
    return {
      ...state,
      user: {
        ...state.user,
        // build new login state from old user state
        login: state.user && state.user.user && {
          email: state.user.user.email,
          organisation: state.user.user.organisation,
          token: state.user.user.token,
        },
        // remove old login state from user state
        user: state.user && state.user.user && {
          ...state.user.user,
          organisation: undefined,
          token: undefined,
        },
      },
    };
  },
  // reset initial loading state of redux state
  2: state => {
    return {
      ...state,
      device: {
        ...state.device,
        loading: false,
      },
      user: {
        ...state.user,
        loading: false,
      },
      organisation: {
        ...state.organisation,
        loading: false,
      },
      alarm: {
        ...state.alarm,
        loading: false,
      },
    };
  },
  // move alarm store into a high level object.
  // this is because the state of the alarms list and the device alarms lists
  // should be divorced from the state of the all available alarm information.
  // eg. image info should be accessible regardless of getting the alarm id
  // from the `alarm` list or `device[i]._embedded.alarms` list, and fetching
  // either of these lists should not affect the state of the alarm images
  // which should exists as embedded relations on the alarmsById items.
  3: state => {
    const alarms = (state && state.alarm && state.alarm.alarms) || [];
    return {
      ...state,
      alarm: {
        ...state.alarm,
        alarms,
        // add the current alarms keyed by id
        alarmsById: alarms.reduce((acc, alarm) => {
          acc[alarm.id] = alarm;
          return acc;
        }, {}),
      },
    };
  },
};
