
import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';

import Title from '../../../components/Title';
import ActivityList from '../../app/components/ActivityList';
import DeviceAlarmCard from '../../equipment/components/DeviceAlarmCard';

import { fetchAlarms } from '../actions';
import {
  getAlarms,
  getAlarmListState,
  sortAlarmsDefault,
} from '../selectors';

const AlarmActivityTitle = connect(getAlarmListState)(Title);

function AlarmActivityList({
  listId,
  alarms = [],
  fetchAlarms,
}) {

  // fetch alarms on load or change of list to fetch
  useEffect(() => fetchAlarms(), [fetchAlarms, listId]);

  return (
    <ActivityList
      listId={listId}
      title={<AlarmActivityTitle title="Activity" />}
    >
      {useMemo(() => alarms.sort(sortAlarmsDefault).map(alarm => (
        <DeviceAlarmCard
          key={alarm.id}
          alarmId={alarm.id}
        />
      )), [alarms])}
    </ActivityList>
  );
}

const mapStateToProps = state => {
  return {
    alarms: getAlarms(state),
  };
};
const mapDispatchToProps = { fetchAlarms };

export default connect(mapStateToProps, mapDispatchToProps)(AlarmActivityList);
