import React, { Fragment, useState, useCallback, useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Button, Dropdown, Row, Col, Form } from 'react-bootstrap';
import { IoIosResize, IoIosCog, IoIosWarning } from 'react-icons/io';

import useBaseChart, {
  defaultOptions,
  getSymbolOfIcon,
  convertToTimestamp,
} from './BaseChart.js';
import BaseChartEditToolbar from './BaseChartEditToolbar.js';
import { popperConfigUpdateOnChange } from './BaseChartToolbar.js';
import ConfirmModal from '../ConfirmModal.js';

import {
  getDevice,
  getDeviceEvents,
  getDeviceAlarms,
} from '../../modules/equipment/selectors';

import {
  fetchDeviceInfo,
  fetchDeviceEvents,
  fetchDeviceAlarms,
  submitEquipmentDetails,
} from '../../modules/equipment/actions.js';

import aiIcon from '../../images/aiImage.ico';
import { useComponentViewTracking } from '../../modules/app/hooks.js';

const IoIosResizePath = getSymbolOfIcon(IoIosResize);
const IoIosWarningPath = getSymbolOfIcon(IoIosWarning);

const colours = {
  black: '#434348', // black
  blue: '#7cb5ec', // blue
  grey: '#efefef', // grey
  darkGrey: '#cccccc', // slightly darker grey,
  green: '#2bae23', // green
  yellow: '#dfa907', // Bootstrap alert border yellow with boosted lightness
  yellowLight: '#f6c73c',
  red: 'red', // red
  redLight: '#ff6666',
};

const whitePointBackground = {
  symbol: 'circle',
  itemStyle: {
    color: 'white',
  },
  label: {
    formatter: '{normal|}', // empty rich text to enable width and height
    rich: {
      normal: {
        backgroundColor: 'white',
        height: 32,
        width: 32,
        borderRadius: 32,
        borderWidth: 0.75,
        borderColor: colours.red,
      }
    },
  },
  silent: true,
};

const whiteYellowPointBackground = {
  ...whitePointBackground,
  label: {
    ...whitePointBackground.label,
    rich: {
      normal: {
        ...whitePointBackground.label.rich.normal,
        borderColor: colours.yellow,
      }
    },
  },
};

const whiteRedPointBackground = {
  ...whitePointBackground,
  label: {
    ...whitePointBackground.label,
    rich: {
      normal: {
        ...whitePointBackground.label.rich.normal,
        borderColor: colours.red,
      }
    },
  },
};

const thresholdSeriesStyle = {
  // make style as unobtrusive as possible
  lineStyle: { width: 0 },
  symbol: 'none',
  showSymbol: false,
  hoverAnimation: false,
  silent: true,
  animation: false,
  // mark step data as the changes
  step: 'end', // end for the line turns up/down at the 'end' of this point
  // stack these backgrounds
  stack: true,
  // place behind grid
  z: -1,
};

function clipValue(value, [min, max]) {
  // return value between min and max, or min, or max
  return value > max ? max : value < min ? min : value;
}

function formatCondition(value) {
  switch (true) {
    case value >= 2/3: return 'Serious';
    case value >= 1/3: return 'Warning';
    case value > 0: return 'Healthy';
    default: return 'Not Running';
  }
}

function getInitialOptions(options=defaultOptions) {
  return {
    ...options,
    legend: {
      ...options.legend,
      data: [
        { name: 'Overall Condition', textStyle: { color: colours.green } },
        { name: 'Vibration/Noise', textStyle: { color: colours.blue } },
        { name: 'Temperature', textStyle: { color: colours.black } },
      ],
    },
    tooltip: {
      ...options.tooltip,
      formatter: function(series) {
        const overall = series.find(({ seriesId }) => seriesId === 'overall');
        const vibration = series.find(({ seriesId }) => seriesId === 'vibration');
        const temperature = series.find(({ seriesId }) => seriesId === 'temperature');
        return (
          `${moment(series[0].value[0]).format('LLLL')}${
            '<hr style="margin:2px 0 5px;border-top-color: #ccc;"/>'
          }${
            vibration
              ? `Vibration: ${formatCondition(vibration.value[1])}<br/>`
              : ''
          }${
            temperature
              ? `Temperature: ${formatCondition(temperature.value[1])}<br/>`
              : ''
          }${
            overall
              ? `Overall Condition: ${formatCondition(overall.value[1])}<br/>`
              : ''
          }`
        );
      },
    },
    yAxis: [
      {
        type: 'value',
        name: 'Overall Condition',
        nameLocation: 'center',
        nameRotate: 90,
        nameGap: 38,
        nameTextStyle: {
          color: colours.green,
        },
        min: 0,
        max: 1,
        minInterval: 1/3,
        axisLabel: {
          formatter: value => value.toFixed(2),
        },
      },
      {
        type: 'value',
        name: 'Vibration/Noise',
        nameLocation: 'center',
        nameRotate: -90,
        nameGap: 35,
        nameTextStyle: {
          color: colours.blue,
        },
        min: 0,
        max: 1,
        minInterval: 0.25,
      },
      {
        type: 'value',
        name: 'Temperature',
        nameLocation: 'center',
        nameRotate: -90,
        nameGap: 55,
        nameTextStyle: {
          color: colours.black,
        },
        min: 0,
        max: 1,
        minInterval: 0.25,
      },
    ],
    series: [
      {
        id: 'overall',
        name: 'Overall Condition',
        type: 'line',
        itemStyle: { color: colours.green },
        lineStyle: { color: colours.green, width: 1.5 },
        smooth: 0.25,
        smoothMonotone: 'x',
        showSymbol: false,
        z: 3, // bump the height of this series
      },
      {
        id: 'temperature',
        name: 'Temperature',
        type: 'line',
        itemStyle: { color: colours.black },
        lineStyle: { color: colours.black, width: 1.5 },
        smooth: 0.25,
        smoothMonotone: 'x',
        showSymbol: false,
        yAxisIndex: 2,
      },
      {
        id: 'vibration',
        name: 'Vibration/Noise',
        type: 'line',
        itemStyle: { color: colours.blue },
        lineStyle: { color: colours.blue, width: 1.5 },
        smooth: 0.25,
        smoothMonotone: 'x',
        showSymbol: false,
        yAxisIndex: 1,
      },
      {
        id: 'overall-healthy',
        name: 'Overall Condition Healthy',
        type: 'line',
        ...thresholdSeriesStyle,
        areaStyle: {
          color: 'green',
          opacity: 0.1,
        },
      },
      {
        id: 'overall-warning',
        name: 'Overall Condition Warning',
        type: 'line',
        ...thresholdSeriesStyle,
        areaStyle: {
          color: 'yellow',
          opacity: 0.1,
        },
        markLine: {
          silent: true,
          symbol: 'none',
          lineStyle: {
            color: colours.yellow,
            type: 'solid',
            width: 1,
          },
        },
        // add editable hint
        markPoint: {
          symbol: IoIosResizePath,
          // the resize icon is tilted diagonally, we rotate it back here
          symbolRotate: 45,
          symbolSize: 16,
          itemStyle: {
            color: colours.yellow,
          },
        },
      },
      {
        id: 'yellow-threshold-changes',
        name: 'Yellow alarm threshold changes',
        type: 'line',
        markLine: {
          silent: true,
          symbol: 'none',
          lineStyle: {
            color: colours.yellow,
            type: 'dotted',
            width: 1,
          },
        },
      },
      {
        id: 'yellow-alarms',
        name: 'Yellow alarms',
        type: 'line',
        lineStyle: { width: 0 },
        showSymbol: true,
        symbol: IoIosWarningPath,
        symbolSize: 15,
        symbolOffset: [0, -10],
        cursor: 'default',
        color: colours.yellow,
        label: {
          show: true,
          position: 'top',
          offset: [0, 20],
          backgroundColor: colours.yellow,
          color: '#fff',
          fontWeight: 'bold',
          fontSize: 11,
          formatter: '!',
        },
        emphasis: {
          itemStyle: {
            color: colours.yellowLight,
          },
          label: {
            backgroundColor: colours.yellowLight,
          },
        },
        markLine: {
          silent: true,
          symbol: 'none',
          lineStyle: {
            color: colours.yellow,
            type: 'solid',
            width: 1,
          },
        },
      },
      {
        id: 'overall-serious',
        name: 'Overall Condition Serious',
        type: 'line',
        ...thresholdSeriesStyle,
        areaStyle: {
          color: 'red',
          opacity: 0.1,
        },
        markLine: {
          silent: true,
          symbol: 'none',
          lineStyle: {
            color: 'red',
            type: 'solid',
            width: 1,
          },
        },
        // add editable hint
        markPoint: {
          symbol: IoIosResizePath,
          // the resize icon is tilted diagonally, we rotate it back here
          symbolRotate: 45,
          symbolSize: 16,
          itemStyle: {
            color: colours.red,
          },
        },
      },
      {
        id: 'red-threshold-changes',
        name: 'Red alarm threshold changes',
        type: 'line',
        markLine: {
          silent: true,
          symbol: 'none',
          lineStyle: {
            color: colours.red,
            type: 'dotted',
            width: 1,
          },
        },
      },
      {
        id: 'red-alarms',
        name: 'Red alarms',
        type: 'line',
        lineStyle: { width: 0 },
        showSymbol: true,
        symbol: IoIosWarningPath,
        symbolSize: 15,
        symbolOffset: [0, -10],
        cursor: 'default',
        color: colours.red,
        label: {
          show: true,
          position: 'top',
          offset: [0, 20],
          backgroundColor: colours.red,
          color: '#fff',
          fontWeight: 'bold',
          fontSize: 11,
          formatter: '!',
        },
        emphasis: {
          itemStyle: {
            color: colours.redLight,
          },
          label: {
            backgroundColor: colours.redLight,
          },
        },
        markLine: {
          silent: true,
          symbol: 'none',
          lineStyle: {
            color: colours.red,
            type: 'solid',
            width: 1,
          },
        },
      },
    ],
    graphic: {
      elements: [],
    },
  };
};

function AIChart({
  deviceId,
  events,
  alarms,
  samples = [],
  deviceAlarmThresholdYellow = null,
  deviceAlarmThresholdYellowIsDefault,
  deviceAlarmThresholdRed = null,
  deviceAlarmThresholdRedIsDefault,
  fetchDeviceInfo,
  fetchDeviceEvents,
  fetchDeviceAlarms,
  submitEquipmentDetails,
  ...props
}) {

  const [BaseChart, {
    getChart,
    useChartUpdateEffect,
    useChartUpdateOnChartEvent,
    useChartUpdateOnCustomEvent,
  }] = useBaseChart(getInitialOptions);

  // update samples
  const updateSamples = useCallback(() => {
    return {
      series: [
        {
          id: 'overall',
          data: samples.map(sample => [
            convertToTimestamp(sample.sample_time),
            sample.condition_overall || 0,
          ]),
        },
        {
          id: 'temperature',
          data: samples.map(sample => [
            convertToTimestamp(sample.sample_time),
            sample.condition_temperature || 0,
          ]),
        },
        {
          id: 'vibration',
          data: samples.map(sample => [
            convertToTimestamp(sample.sample_time),
            sample.condition_vibration || 0,
          ]),
        },
      ],
    };
  }, [samples]);

  // handle updates when dependencies change
  useChartUpdateEffect(updateSamples);

  // fetch device threshold logs for each device
  useEffect(() => {
    fetchDeviceEvents({ id: deviceId }, { event_type: 'threshold' });
  }, [deviceId, fetchDeviceEvents]);

  const historicThresholds = useMemo(() => {
    return (events || [])
      // filter to relevant events
      .filter(({ data={} }) => {
        return data.hasOwnProperty('new_red_threshold')
          || data.hasOwnProperty('new_yellow_threshold');
      })
      // sort chronologically
      .sort((a, b) => new Date(a.ref_timestamp) - new Date(b.ref_timestamp));
  }, [events]);

  const [editAlarmThresholdMode, setEditAlarmThresholdMode] = useState(false);
  const exitAlarmThresholdMode = useCallback(() => setEditAlarmThresholdMode(false), []);
  const toggleAlarmThresholdMode = useCallback(() => {
    setEditAlarmThresholdMode(editAlarmThresholdMode => !editAlarmThresholdMode);
  }, [setEditAlarmThresholdMode]);

  // track usage of the alarm threshold edit mode
  useComponentViewTracking('Device Chart Edit Alarm Threshold', editAlarmThresholdMode && 'deviceId', { deviceId });

  // modal value is the custom alarm threshold value: set to null if not custom
  const [modalAlarmThresholdYellow, setModalAlarmThresholdYellow] = useState(deviceAlarmThresholdYellow);
  useEffect(() => setModalAlarmThresholdYellow(deviceAlarmThresholdYellow), [deviceAlarmThresholdYellow]);

  const resetAlarmThresholdYellow = useCallback(() => {
    setModalAlarmThresholdYellow(null);
  }, [deviceAlarmThresholdYellow]);

  // display value is the alarm threshold value: always set to a number
  // regardless of whether it is customised or not
  const [displayAlarmThresholdYellow, setDisplayAlarmThresholdYellow] = useState(() => {
    return modalAlarmThresholdYellow !== null ? modalAlarmThresholdYellow : 1/3;
  });
  useEffect(() => {
    setDisplayAlarmThresholdYellow(modalAlarmThresholdYellow !== null ? modalAlarmThresholdYellow : 1/3);
  }, [modalAlarmThresholdYellow]);

  // modal value is the custom alarm threshold value: set to null if not custom
  const [modalAlarmThresholdRed, setModalAlarmThresholdRed] = useState(deviceAlarmThresholdRed);
  useEffect(() => setModalAlarmThresholdRed(deviceAlarmThresholdRed), [deviceAlarmThresholdRed]);

  const resetAlarmThresholdRed = useCallback(() => {
    setModalAlarmThresholdRed(null);
  }, [deviceAlarmThresholdRed]);

  // display value is the alarm threshold value: always set to a number
  // regardless of whether it is customised or not
  const [displayAlarmThresholdRed, setDisplayAlarmThresholdRed] = useState(() => {
    return modalAlarmThresholdRed !== null ? modalAlarmThresholdRed : 2/3;
  });
  useEffect(() => {
    setDisplayAlarmThresholdRed(modalAlarmThresholdRed !== null ? modalAlarmThresholdRed : 2/3);
  }, [modalAlarmThresholdRed]);

  // reset edit value if the edit mode is exited
  useEffect(() => {
    if (!editAlarmThresholdMode) {
      setModalAlarmThresholdYellow(deviceAlarmThresholdYellow);
      setModalAlarmThresholdRed(deviceAlarmThresholdRed);
    }
  }, [resetAlarmThresholdYellow, resetAlarmThresholdRed, editAlarmThresholdMode]);

  function getXMinPoint(chart=getChart()) {
    if (chart) {
      const options = chart.getOption();
      const grid = options && options.grid && options.grid[0];
      if (grid) {
        return grid.left;
      }
    }
  }

  function getXMidPoint(chart=getChart()) {
    if (chart) {
      const options = chart.getOption();
      const grid = options && options.grid && options.grid[0];
      if (grid) {
        // position x directly in the middle of the chart (average of left and right pixel)
        return (grid.left + chart.getWidth() - grid.right) / 2;
      }
    }
  }

  function getXMaxPoint(chart=getChart()) {
    if (chart) {
      const options = chart.getOption();
      const grid = options && options.grid && options.grid[0];
      if (grid) {
        return chart.getWidth() - grid.right;
      }
    }
  }

  function getDataFromX(x, chart=getChart()) {
    if (chart) {
      // position y as the current deviceRunning Cutoff
      return chart.convertFromPixel({ seriesId: 'overall' }, [x, 0])[0];
    }
  }

  function getYFromData(dataY, chart=getChart()) {
    if (chart) {
      // position y as the current deviceRunning Cutoff
      return chart.convertToPixel({ seriesId: 'overall' }, [0, dataY])[1];
    }
  }

  function convertAlarmThresholdFromPixel(pixel, chart = getChart()) {
    if (chart) {
      const point = chart.convertFromPixel({ seriesId: 'overall' }, pixel);
      const alarmThreshold = Math.round(100 * point[1])/100;
      // ensure no negative alarm threshold values are used
      // and that the value cannot be greater than 1
      return alarmThreshold > 0
        ? alarmThreshold > 1
          ? 1
          : alarmThreshold
        : 0;
    }
  }

  // update tooltip formatting
  const updateTooltip = useCallback(() => {

    return {
      tooltip: {
        formatter: function(series) {
          const overall = series.find(({ seriesId }) => seriesId === 'overall');
          const vibration = series.find(({ seriesId }) => seriesId === 'vibration');
          const temperature = series.find(({ seriesId }) => seriesId === 'temperature');
          const thresholdYellow = series.find(({ seriesId }) => seriesId === 'overall-warning');
          const thresholdRed = series.find(({ seriesId }) => seriesId === 'overall-serious');
          const alarmYellow = series.find(({ seriesId }) => seriesId === 'yellow-alarms');
          const alarmRed = series.find(({ seriesId }) => seriesId === 'red-alarms');

          const thresholdYellowChange = thresholdYellow && historicThresholds.find(event => {
            return (
              (event.data && (event.data.new_yellow_threshold || event.data.old_yellow_threshold)) &&
              thresholdYellow.value[0] === new Date(event.ref_timestamp).valueOf()
            );
          });
          const thresholdRedChange = thresholdRed && historicThresholds.find(event => {
            return (
              (event.data && (event.data.new_red_threshold || event.data.old_red_threshold)) &&
              thresholdRed.value[0] === new Date(event.ref_timestamp).valueOf()
            );
          });

          const alarmYellowEvent = alarmYellow && alarms.find(alarm => {
            return (
              alarm.alarm_type === 'yellow' &&
              alarmYellow.value[0] === new Date(alarm.alarm_trigger_timestamp).valueOf()
            );
          });
          const alarmRedEvent = alarmRed && alarms.find(alarm => {
            return (
              alarm.alarm_type === 'red' &&
              alarmRed.value[0] === new Date(alarm.alarm_trigger_timestamp).valueOf()
            );
          });

          const formatThreshold = value => {
            return value === null ? 'default' : `${Math.round(value*100)/100}`;
          };

          return (
            `${moment(series[0].value[0]).format('LLLL')}${
              '<hr style="margin:2px 0 5px;border-top-color: #ccc;"/>'
            }${
              vibration
                ? `Vibration: ${formatCondition(vibration.value[1])}<br/>`
                : ''
            }${
              temperature
                ? `Temperature: ${formatCondition(temperature.value[1])}<br/>`
                : ''
            }${
              overall
                ? `Overall Condition: ${formatCondition(overall.value[1])}<br/>`
                : ''
            }${
              thresholdYellowChange
                ? `Yellow advisory threshold change: ${
                  `from ${formatThreshold(thresholdYellowChange.data.old_yellow_threshold)} ` +
                  `to ${formatThreshold(thresholdYellowChange.data.new_yellow_threshold)}<br/>` +
                  (!thresholdRedChange ? `by: ${thresholdYellowChange.user_name}<br/>` : '')
                }`
                : ''
            }${
              thresholdRedChange
                ? `Red alarm threshold change: ${
                  `from ${formatThreshold(thresholdRedChange.data.old_red_threshold)} ` +
                  `to ${formatThreshold(thresholdRedChange.data.new_red_threshold)}<br/>` +
                  `by: ${thresholdRedChange.user_name}<br/>`
                }`
                : ''
            }${
              alarmYellowEvent
                ? `Yellow advisory event${
                  alarmYellowEvent.red_threshold_changed
                    ? ` (custom alarm threshold: ${formatThreshold(alarmYellowEvent.red_threshold)}`
                    : ''
                }: ${
                  [
                    (alarmYellowEvent.condition_vibration > 1/3) && 'vibration',
                    (alarmYellowEvent.condition_temperature > 1/3) && 'temperature',
                    (alarmYellowEvent.condition_overall > 1/3) && 'overall',
                  ].filter(Boolean).join(', ')
                }<br/>`
                : ''
            }${
              alarmRedEvent
                ? `Red alarm event${
                  alarmRedEvent.red_threshold_changed
                    ? ` (custom alarm threshold: ${formatThreshold(alarmRedEvent.red_threshold)}`
                    : ''
                }: ${
                  [
                    (alarmRedEvent.condition_vibration > 2/3) && 'vibration',
                    (alarmRedEvent.condition_temperature > 2/3) && 'temperature',
                    (alarmRedEvent.condition_overall > 2/3) && 'overall',
                  ].filter(Boolean).join(', ')
                }<br/>`
                : ''
            }`
          );
        },
      },
    };
  }, [historicThresholds, alarms]);

  // handle updates when dependencies change
  useChartUpdateEffect(updateTooltip);

  // update background series
  const updateThresholdBackground = useCallback(() => {

    const xMin = getDataFromX(getXMinPoint());
    const xMax = getDataFromX(getXMaxPoint());

    const { past, current } = [...historicThresholds].sort((a, b) => {
      // ensure threshold changes are chronologically sorted
      return new Date(a.ref_timestamp) - new Date(b.ref_timestamp);
    }).reduce((acc, event) => {
      const timestamp = new Date(event.ref_timestamp).valueOf();
      if (timestamp < xMin) {
        acc.past.push(event);
      }
      else if (timestamp > xMax) {
        acc.future.push(event);
      }
      else {
        acc.current.push(event);
      }
      return acc;
    }, { past: [], current: [], future: [] });

    // add default event to insert threshold defaults at earliest point in time
    const defaultEvent = {
      data: {
        new_yellow_threshold: null,
        new_red_threshold: null,
      },
    };
    const reversePast = [defaultEvent, ...past].reverse();
    const reverseCurrent = [defaultEvent, ...past, ...current].reverse();

    const thresholdSeries = [
      // add left-most point, pinned at the left of the graph
      [
        xMin,
        // get last defined value of property
        reversePast.find(event => {
          return event && event.data && event.data.new_yellow_threshold !== undefined;
        }).data.new_yellow_threshold,
        // get last defined value of property
        reversePast.find(event => {
          return event && event.data && event.data.new_red_threshold !== undefined;
        }).data.new_red_threshold,
      ],
      // add data within current date range
      ...current.map(event => {
        return [
          new Date(event.ref_timestamp).valueOf(),
          event.data.new_yellow_threshold,
          event.data.new_red_threshold
        ];
      }),
      // add right-most point, pinned at the left of the graph
      [
        xMax,
        // get last defined value of property
        reverseCurrent.find(event => {
          return event && event.data && event.data.new_yellow_threshold !== undefined;
        }).data.new_yellow_threshold,
        // get last defined value of property
        reverseCurrent.find(event => {
          return event && event.data && event.data.new_red_threshold !== undefined;
        }).data.new_red_threshold,
      ],
    ].reduce((series, [x, yellow, red], index) => {
      // replace undefined values with last known values
      // replace null values with default values
      series[index] = [
        x,
        yellow !== null && !isNaN(yellow)
          // value is fine
          ? yellow
          : yellow !== null && index
            // value should be previous value
            ? series[index - 1][1]
            // value should be default
            : 1/3,
        red !== null && !isNaN(red)
          // value is fine
          ? red
          : red !== null && index
            // value should be previous value
            ? series[index - 1][2]
            // value should be default
            : 2/3,
      ];
      return series;
    }, []);

    return {
      series: [
        {
          id: 'overall-healthy',
          data: thresholdSeries.map(([x, yellow]) => {
            return [x, yellow];
          }),
        },
        {
          id: 'overall-warning',
          data: thresholdSeries.map(([x, yellow, red]) => {
            return [x, red - yellow];
          }),
        },
        {
          id: 'yellow-threshold-changes',
          markLine: {
            data: historicThresholds
              .filter(event => !!event.data && !!(
                event.data.old_yellow_threshold ||
                event.data.new_yellow_threshold
              ))
              .map(event => {
                const timestamp = new Date(event.ref_timestamp).valueOf();
                return [
                  { coord: [timestamp, 0], name: '⬤' },
                  { coord: [timestamp, 1], },
                ];
              }),
          },
        },
        {
          id: 'overall-serious',
          data: thresholdSeries.map(([x, yellow, red]) => {
            return [x, 1 - (red - yellow)];
          }),
        },
        {
          id: 'red-threshold-changes',
          markLine: {
            data: historicThresholds
              .filter(event => !!event.data && !!(
                event.data.old_red_threshold ||
                event.data.new_red_threshold
              ))
              .map(event => {
                const timestamp = new Date(event.ref_timestamp).valueOf();
                return [
                  { coord: [timestamp, 0], name: '⬤' },
                  { coord: [timestamp, 1], },
                ];
              }),
          },
        },
      ],
    };
  }, [historicThresholds]);

  // handle updates when dependencies change
  useChartUpdateEffect(updateThresholdBackground);
  useChartUpdateOnChartEvent('dataZoom', updateThresholdBackground);
  useChartUpdateOnCustomEvent('dateRange', updateThresholdBackground);

  // fetch alarms
  useEffect(() => {
    fetchDeviceAlarms({ id: deviceId });
  }, [deviceId]);

  // update alarm series
  const updateAlarms = useCallback(() => {

    // translate alarms into a [x, y1, y2] data series
    const sortedAlarmSeries =  (alarms || [])
      // only show alarms with defined trigger timestamps:
      // new Date(undefined) defaults to "now", which we don't want here
      .filter(alarm => alarm.alarm_trigger_timestamp)
      // ensure all alarms have an alarm type to display
      .filter(alarm => alarm.alarm_type)
      .map(alarm => {
        return {
          timestamp: new Date(alarm.alarm_trigger_timestamp).valueOf(),
          alarm_type: alarm.alarm_type,
        };
      })
      // sort chronologically
      .sort((a, b) => a.timestamp - b.timestamp);

    const yellowAlarms = sortedAlarmSeries.filter(alarm => alarm.alarm_type === 'yellow');
    const redAlarms = sortedAlarmSeries.filter(alarm => alarm.alarm_type === 'red');

    return {
      series: [
        {
          id: 'yellow-alarms',
          data: yellowAlarms.map(({ timestamp }) => [timestamp, 1]),
          markLine: {
            data: yellowAlarms
              .map(({ timestamp }) => {
                return [
                  { coord: [timestamp, 0] },
                  { coord: [timestamp, 1] },
                ];
              }),
          },
        },
        {
          id: 'red-alarms',
          data: redAlarms.map(({ timestamp }) => [timestamp, 1]),
          markLine: {
            data: redAlarms
              .map(({ timestamp }) => {
                return [
                  { coord: [timestamp, 0] },
                  { coord: [timestamp, 1] },
                ];
              }),
          },
        },
      ],
    };
  }, [alarms]);

  // handle updates when dependencies change
  useChartUpdateEffect(updateAlarms);

  // allow mouseover or clicking alarm symbols to focus the
  // chart tooltip on the alarm
  const alarmTooltipFocus = useCallback((option, event) => {
    if (event.componentType === 'series') {
      const chart = getChart();
      if (chart) {
        if (event.seriesId === 'yellow-alarms') {
          const [x, y] = chart.convertToPixel({ seriesId: 'yellow-alarms' }, event.value);
          chart.dispatchAction({ type: 'showTip', x, y });
        }
        if (event.seriesId === 'red-alarms') {
          const [x, y] = chart.convertToPixel({ seriesId: 'red-alarms' }, event.value);
          chart.dispatchAction({ type: 'showTip', x, y });
        }
      }
    }
  }, []);

  useChartUpdateOnChartEvent('mousemove', alarmTooltipFocus);
  useChartUpdateOnChartEvent('click', alarmTooltipFocus);

  const updateYellowAlarmThreshold = useCallback(() => ({
    series: [
      {
        id: 'overall-warning',
        markLine: {
          data: editAlarmThresholdMode ? [
            [
              { x: getXMinPoint(), yAxis: displayAlarmThresholdYellow },
              { x: getXMaxPoint(), yAxis: displayAlarmThresholdYellow },
            ],
          ] : [],
        },
        // add editable hint
        markPoint: {
          data: editAlarmThresholdMode ? [
            { x: getXMidPoint(), yAxis: displayAlarmThresholdYellow, ...whiteYellowPointBackground },
            { x: getXMidPoint(), yAxis: displayAlarmThresholdYellow },
          ] : [],
        },
      }
    ]
  }), [
    editAlarmThresholdMode,
    displayAlarmThresholdYellow,
  ]);

  useChartUpdateEffect(updateYellowAlarmThreshold);
  useChartUpdateOnCustomEvent('resize', updateYellowAlarmThreshold);

  const updateRedAlarmThreshold = useCallback(() => ({
    series: [
      {
        id: 'overall-serious',
        markLine: {
          data: editAlarmThresholdMode ? [
            [
              { x: getXMinPoint(), yAxis: displayAlarmThresholdRed },
              { x: getXMaxPoint(), yAxis: displayAlarmThresholdRed },
            ],
          ] : [],
        },
        // add editable hint
        markPoint: {
          data: editAlarmThresholdMode ? [
            { x: getXMidPoint(), yAxis: displayAlarmThresholdRed, ...whiteRedPointBackground },
            { x: getXMidPoint(), yAxis: displayAlarmThresholdRed },
          ] : [],
        },
      }
    ]
  }), [
    editAlarmThresholdMode,
    displayAlarmThresholdRed,
  ]);

  useChartUpdateEffect(updateRedAlarmThreshold);
  useChartUpdateOnCustomEvent('resize', updateRedAlarmThreshold);

  const updateDraggablePoints = useCallback(() => {
    return {
      graphic: {
        elements: [
          editAlarmThresholdMode
            ? {
              id: 'new_alarm_threshold_red',
              type: 'circle',
              position: [getXMidPoint(), getYFromData(displayAlarmThresholdRed)],
              shape: { cx: 0, cy: 0, r: 16 },
              z: 100,
              invisible: true,
              draggable: true,
              ondrag: ({ offsetX, offsetY }) => {
                const chart = getChart();
                // only update if the drag point is still on the graph
                if (chart) {
                  const newAlarmThresholdRed = clipValue(
                    convertAlarmThresholdFromPixel([offsetX, offsetY]),
                    // limit to between 0 and red alarm
                    [Math.ceil(displayAlarmThresholdYellow*100)/100 + 0.01, 0.99],
                  );
                  chart.setOption({
                    series: [{
                      id: 'overall-serious',
                      markLine: {
                        data: [[
                          { x: getXMinPoint(), yAxis: newAlarmThresholdRed },
                          { x: getXMaxPoint(), yAxis: newAlarmThresholdRed },
                        ]],
                      },
                      markPoint: {
                        data: [
                          { x: getXMidPoint(), yAxis: newAlarmThresholdRed, ...whiteRedPointBackground },
                          { x: getXMidPoint(), yAxis: newAlarmThresholdRed },
                        ],
                      },
                    }],
                  });
                }
              },
              ondragend: ({ offsetX, offsetY }) => {
                const newAlarmThresholdRed = clipValue(
                  convertAlarmThresholdFromPixel([offsetX, offsetY]),
                  // limit to between 0 and red alarm
                  [Math.ceil(displayAlarmThresholdYellow*100)/100 + 0.01, 0.99],
                );
                setModalAlarmThresholdRed(newAlarmThresholdRed);
                // reset the draggable graphic
                const chart = getChart();
                if (chart) {
                  chart.setOption(updateDraggablePoints());
                }
              },
            } : {
              // or remove
              id: 'new_alarm_threshold_red',
              $action: 'remove',
            },
          editAlarmThresholdMode
            ? {
              id: 'new_alarm_threshold_yellow',
              type: 'circle',
              position: [getXMidPoint(), getYFromData(displayAlarmThresholdYellow)],
              shape: { cx: 0, cy: 0, r: 16 },
              z: 100,
              invisible: true,
              draggable: true,
              ondrag: ({ offsetX, offsetY }) => {
                const chart = getChart();
                // only update if the drag point is still on the graph
                if (chart) {
                  const newAlarmThresholdYellow = clipValue(
                    convertAlarmThresholdFromPixel([offsetX, offsetY]),
                    // limit to between 0 and red alarm
                    [0.01, Math.floor(displayAlarmThresholdRed*100)/100 - 0.01],
                  );
                  chart.setOption({
                    series: [{
                      id: 'overall-warning',
                      markLine: {
                        data: [[
                          { x: getXMinPoint(), yAxis: newAlarmThresholdYellow },
                          { x: getXMaxPoint(), yAxis: newAlarmThresholdYellow },
                        ]],
                      },
                      markPoint: {
                        data: [
                          { x: getXMidPoint(), yAxis: newAlarmThresholdYellow, ...whiteYellowPointBackground },
                          { x: getXMidPoint(), yAxis: newAlarmThresholdYellow },
                        ],
                      },
                    }],
                  });
                }
              },
              ondragend: ({ offsetX, offsetY }) => {
                const newAlarmThresholdYellow = clipValue(
                  convertAlarmThresholdFromPixel([offsetX, offsetY]),
                  // limit to between 0 and red alarm
                  [0.01, Math.floor(displayAlarmThresholdRed*100)/100 - 0.01],
                );
                setModalAlarmThresholdYellow(newAlarmThresholdYellow);
                // reset the draggable graphic
                const chart = getChart();
                if (chart) {
                  chart.setOption(updateDraggablePoints());
                }
              },
            } : {
              // or remove
              id: 'new_alarm_threshold_yellow',
              $action: 'remove',
            },
        ],
      },
    };
  }, [
    editAlarmThresholdMode,
    displayAlarmThresholdYellow,
    displayAlarmThresholdRed,
  ]);

  useChartUpdateEffect(updateDraggablePoints);
  useChartUpdateOnCustomEvent('resize', updateDraggablePoints);

  const saveNewThresholds = useCallback(() => {
    const payload = {
      id: deviceId,
      // add thresholds (both must be passed)
      yellow_threshold: modalAlarmThresholdYellow,
      red_threshold: modalAlarmThresholdRed,
    };
    return submitEquipmentDetails(payload)
      .then(() => Promise.all([
        // fetch new thresholds
        fetchDeviceInfo({ id: deviceId }),
        // fetch new threshold history
        fetchDeviceEvents({ id: deviceId }),
      ]))
      .then(() => {
        // exit editing
        exitAlarmThresholdMode();
        // update chart to include now, so user may see the new change plotted
        props.setDateRange({ endTime: Date.now() });
      });
  }, [
    deviceId,
    fetchDeviceEvents,
    submitEquipmentDetails,
    modalAlarmThresholdYellow,
    deviceAlarmThresholdYellow,
    modalAlarmThresholdRed,
    deviceAlarmThresholdRed,
    props.setDateRange,
  ]);

  const [hasClickedAgree, setHasClickedAgree] = useState(false);
  const [hasConfirmedAgree, setHasConfirmedAgree] = useState(false);
  const confirmModalBody = useMemo(() => {
    return (
      <div>
        <p>
          This feature allows you to modify the threshold at which a
          yellow advisory or red alarm is triggered on the overall
          AI condition chart.

          We recommend this for advanced users only and that most
          users keep the default settings. If you choose to continue,
          we will log your name and the time you chose to opt out of
          default settings, and this will be visible throughout MachineCloud.
        </p>
        <p>
          When deciding where to place the alarm thresholds, it is important
          that at least a few months of equipment running history
          is considered to make an informed choice. Setting the threshold
          too low may result in over alarming, and setting the threshold
          too high might result in missed alarms or equipment failures.

          If you decide to opt out of default settings,
          it is your responsibility to ensure you place the
          alarm thresholds correctly.
        </p>
        <Form.Group>
          <Form.Check
            type="checkbox"
            label={
              'I understand the risk of this advanced feature & ' +
              'want to use non-default alarm thresholds for this equipment'
            }
            onChange={e => setHasClickedAgree(e.target.checked)}
          />
        </Form.Group>
      </div>
    );
  }, []);

  return (
    <BaseChart
      header={<Fragment><img src={aiIcon} height="24" width="24" alt=""/> AI Output</Fragment>}
      namespace="ai-output"
      deviceId={deviceId}
      samples={samples}
      {...props}
      // pass memoised buttons
      toolbarButtons={useMemo(() => [
        <Dropdown>
          <Dropdown.Toggle
            variant="outline-dark"
            size="lg"
          >
            <IoIosCog size="1.3em" title="Edit" />
          </Dropdown.Toggle>
          <Dropdown.Menu
            align="right"
            popperConfig={popperConfigUpdateOnChange}
          >
            {!hasConfirmedAgree ? (
              <ConfirmModal
                body={confirmModalBody}
                confirmText="Continue"
                confirmButtonProps={{ disabled: !hasClickedAgree }}
              >
                <Dropdown.Item onClick={() => {
                  setHasConfirmedAgree(true);
                  setEditAlarmThresholdMode(true);
                }}>
                  Edit alarm threshold values
                </Dropdown.Item>
              </ConfirmModal>
            ) : (
              <Dropdown.Item onClick={toggleAlarmThresholdMode}>
                {!editAlarmThresholdMode ? (
                  'Edit'
                ) : (
                  'Cancel edit'
                )} alarm threshold values
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
      ], [hasClickedAgree, hasConfirmedAgree, editAlarmThresholdMode, toggleAlarmThresholdMode])}
      AboveChart={useCallback(() => (
        <Fragment>
          {editAlarmThresholdMode && (
            <BaseChartEditToolbar className="pb-2">
              <Row className="small-gutters d-flex align-items-center">
                <Col className="mb-1" xs="auto">
                  Drag the upper red slider to adjust when red alarms are triggered.
                </Col>
                {/* everything in the following column is right-aligned */}
                <Col xs="auto" className="ml-auto">
                  <Row className="small-gutters d-flex justify-content-end align-items-center">
                    <Col className="mb-1" xs="auto">
                      {Math.round(1000 * displayAlarmThresholdRed)/1000}
                    </Col>
                    <Col className="mb-1" xs="auto">
                      <Button
                        variant="outline-secondary"
                        size="sm"
                        disabled={modalAlarmThresholdRed === null}
                        onClick={resetAlarmThresholdRed}
                      >
                        {modalAlarmThresholdRed === null ? (
                          'Is'
                        ) : (
                          'Reset to'
                        )} default
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </BaseChartEditToolbar>
          )}
          {editAlarmThresholdMode && (
            <BaseChartEditToolbar className="pb-2">
              <Row className="small-gutters d-flex align-items-center">
                <Col className="mb-1" xs="auto">
                  Drag the lower yellow slider to adjust when yellow advisories are triggered.
                </Col>
                {/* everything in the following column is right-aligned */}
                <Col xs="auto" className="ml-auto">
                  <Row className="small-gutters d-flex justify-content-end align-items-center">
                    <Col className="mb-1" xs="auto">
                      {Math.round(1000 * displayAlarmThresholdYellow)/1000}
                    </Col>
                    <Col className="mb-1" xs="auto">
                      <Button
                        variant="outline-secondary"
                        size="sm"
                        disabled={modalAlarmThresholdYellow === null}
                        onClick={resetAlarmThresholdYellow}
                      >
                        {modalAlarmThresholdYellow === null ? (
                          'Is'
                        ) : (
                          'Reset to'
                        )} default
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </BaseChartEditToolbar>
          )}
          {editAlarmThresholdMode && (
            <BaseChartEditToolbar className="pb-2">
              <Row className="small-gutters d-flex align-items-center">
                {/* everything in the following column is right-aligned */}
                <Col xs="auto" className="ml-auto">
                  <Row className="small-gutters d-flex justify-content-end align-items-center">
                    <Col className="mb-1" xs="auto">
                      <Button
                        size="sm"
                        disabled={(
                          (deviceAlarmThresholdRed === modalAlarmThresholdRed) &&
                          (deviceAlarmThresholdYellow === modalAlarmThresholdYellow)
                        )}
                        onClick={saveNewThresholds}
                      >
                        Save
                      </Button>
                    </Col>
                    <Col className="mb-1" xs="auto">
                      <Button
                        variant="outline-secondary"
                        size="sm"
                        onClick={exitAlarmThresholdMode}
                      >
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </BaseChartEditToolbar>
          )}
        </Fragment>
      ), [
        editAlarmThresholdMode,
        displayAlarmThresholdYellow,
        deviceAlarmThresholdYellow,
        modalAlarmThresholdYellow,
        displayAlarmThresholdRed,
        deviceAlarmThresholdRed,
        modalAlarmThresholdRed,
        // shouldn't change
        exitAlarmThresholdMode,
      ])}
    />
  );
}

const mapStateToProps = (state, { deviceId }) => {
  const foundDevice = getDevice(state, deviceId) || {};
  return {
    deviceId,
    deviceAlarmThresholdYellowIsDefault: !foundDevice.yellow_threshold_changed,
    deviceAlarmThresholdRedIsDefault: !foundDevice.red_threshold_changed,
    // use null values inside the component to represent default values
    deviceAlarmThresholdYellow: foundDevice.yellow_threshold_changed
      ? foundDevice.yellow_threshold
      : null,
    deviceAlarmThresholdRed: foundDevice.red_threshold_changed
      ? foundDevice.red_threshold
      : null,
    events: getDeviceEvents(state, deviceId),
    alarms: getDeviceAlarms(state, deviceId),
  };
};
const mapDispatchToProps = {
  fetchDeviceInfo,
  fetchDeviceEvents,
  fetchDeviceAlarms,
  submitEquipmentDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(AIChart);
