
// configure sanitiser (undefined in production)
export default window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__({
  // do not log large sample payloads for graphs
  actionSanitizer: action => (
    action.type === 'devices/RECEIVE_DEVICE_SAMPLES' && action.response
      ? {
        ...action,
        response: {
          ...action.response,
          _embedded: `<<${action.response.count} SAMPLES>>`,
        },
      }
      : action
  ),
  // a stateSanitizer can go here
});
