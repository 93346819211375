import React, { useState, useCallback } from 'react';

import Header from '../components/Header.js';
import Sidebar from '../components/Sidebar.js';
import Footer from '../components/Footer.js';

import './root.scss';
import AccountStatus from './AccountStatus.js';

export default function Root({ userIsLoggedIn, header=true, children }) {
  const userIsLoggedInClassName = userIsLoggedIn ? 'user-is-logged-in' : '';

  // contain sidebar expansion logic
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = useCallback(() => setExpanded(!expanded));

  const expandedClassName = expanded ? 'expanded' : 'collapsed';

  // allow multiple AccountStatus components to be dismissed at once
  const [showAccountStatus, setShowAccountStatus] = useState(true);

  return (
    <div id="app" className={[userIsLoggedInClassName, expandedClassName].filter(Boolean).join(' ')}>
      <div id="sidebar">
        <AccountStatus show={showAccountStatus} floating />
        <Sidebar expanded={expanded} toggleExpanded={toggleExpanded} />
      </div>
      <div id="main">
        {/* add fixed and inline version of account status */}
        <AccountStatus show={showAccountStatus} setShow={setShowAccountStatus}/>
        <AccountStatus show={showAccountStatus} floating />
        <div id="header">
          {header && <Header expanded={expanded} toggleExpanded={toggleExpanded} />}
        </div>
        <div id="body">
          {children}
        </div>
        <div id="footer">
          {/* restrict display for logged in users on large screens */}
          <Footer className={userIsLoggedIn ? 'd-md-none' : 'd-block'} />
        </div>
      </div>
    </div>
  );
}
