
import React, { useLayoutEffect, useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import { IoIosContact } from 'react-icons/io';
import objectFitImages from 'object-fit-images';

// Components
import { getUser, getUserPicture } from '../selectors';

function UserPicture({
  user = {},
  userPictureUrl,
  size = '2.4em',
}) {

  const imageEl = useRef();

  // on DOM load (and element references becoming available),
  // and whenever the image source changes
  // fix image fit in IE before the element is painted
  // (will only cause an effect to elements that have an object-fit font-family attached)
  useLayoutEffect(() => {
    if (imageEl.current && userPictureUrl) {
      objectFitImages(imageEl.current);
    }
  }, [imageEl.current, userPictureUrl]);

  return (
    <div
      className="img-thumbnail object-fit-contain d-inline-block"
      style={useMemo(() => ({ width: size, height: size, verticalAlign: 'top' }), [size])}
    >
      {userPictureUrl ? (
        <img ref={imageEl} src={userPictureUrl} alt={user.name} className="image-upload__img"/>
      ) : (
        <IoIosContact size="100%" />
      )}
    </div>
  );
}

const mapStateToProps = (state, { userId, size }) => {
  const userPicture = userId && getUserPicture(state, userId);
  return {
    user: userId && getUser(state, userId),
    userPictureUrl: userPicture && userPicture.url,
    size,
  };
};

export default connect(mapStateToProps)(UserPicture);
