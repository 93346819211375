
import React from 'react';
import { connect } from 'react-redux';
import { Button, ButtonGroup } from 'react-bootstrap';
import { DropdownTopWindowButton } from '../../../components/DropdownButton';
import { LinkContainer } from 'react-router-bootstrap';
import { IoIosCreate } from 'react-icons/io';

import ArchiveOrganisationModal, {
  minViewUserType as minUserType,
  minViewOrgType as minOrgType,
} from '../screens/ArchiveOrganisationModal';

import { getOrganisation } from '../selectors';
import { isAuthorised } from '../../user/selectors';

function OrganisationDropdown({
  organisation={},
  currentOrganisation,
  userCanArchive,
  userCanEdit,
}) {
  // don't show this at all for regular users
  if (!userCanEdit) {
    return null;
  }
  // return dropdown
  return (
    <ButtonGroup>
      <LinkContainer to={`/organisations/${organisation.id}/edit`}>
        <Button variant="outline-secondary">
          <IoIosCreate size="1.2em" /> <span>Edit</span>
        </Button>
      </LinkContainer>
      {userCanArchive && organisation !== currentOrganisation && (
        <DropdownTopWindowButton as={ButtonGroup} menuAlign="right" variant="outline-secondary" title="">
          <ArchiveOrganisationModal
            organisationId={organisation.id}
          />
        </DropdownTopWindowButton>
      )}
    </ButtonGroup>
  );
}

const mapStateToProps = (state, { organisationId }) => {
  const organisation = organisationId && getOrganisation(state, organisationId);
  const currentOrganisation = getOrganisation(state);
  return {
    organisation,
    currentOrganisation,
    userCanArchive: isAuthorised(state, { minUserType, minOrgType }),
    userCanEdit: isAuthorised(state, { minUserType: 'Admin' }),
  };
};

export default connect(mapStateToProps)(OrganisationDropdown);
