import React, { Fragment, useState, useEffect, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { Form, Button, ButtonGroup, Alert } from 'react-bootstrap';
import { IoIosCheckbox } from 'react-icons/io';

import { fetchDevices } from '../../equipment/actions';
import {
  fetchGroup,
  fetchGroupMembers,
  fetchGroupMembersAvailable,
  addGroupDevices,
  deleteGroupDevices,
} from '../actions';

import Table from '../../../components/Table';
import {
  headerFormatter,
  nullFormatter,
} from '../../../components/table/formatters';

import Toolbar from '../../../components/TableToolbar';
import { useColumnsWithVisibility } from '../../../components/table/utils';
import { addToast } from '../../../components/Toaster';
import {
  archived,
  organisationName,
  siteName,
  subAreaName,
  equipmentName,
} from '../../equipment/columns';

import { isAdmin } from '../../user/selectors';
import { getDevices } from '../../equipment/selectors';
import {
  getGroup,
  getGroupDevices,
  getGroupDevicesAvailable,
  isTopLevelGroupActive,
  isStandardOrganisation,
  getNearestGroupOrganisationAncestor,
  getNearestOrganisationAncestorId,
} from '../selectors';

const defaultSorted = [{
  dataField: 'equipment_name',
  order: 'asc'
}];

// todo: move this to helper as it is repeated multiple times in codebase
function SelectAllButton(props) {
  return (
    <Button size="sm" variant="outline-primary" {...props} />
  );
}

// todo: move this to helper as it is repeated multiple times in codebase
export function showUnsavedChangesToast() {
  addToast({
    variant: 'warning',
    header: 'Remember to save your changes',
  });
}

function selectAllHeaderFormatter(props, ...rest) {
  const { formatExtraData: { active: userCanEdit, setAnnotatedDevices }={} } = props;
  return (
    <Fragment>
      {headerFormatter(props, ...rest)}
      {userCanEdit && <ButtonGroup>
        <SelectAllButton onClick={() => {
          setAnnotatedDevices(annotatedDevices => {
            return annotatedDevices.map(device => {
              return {
                // copy device
                ...device,
                // override value if editable
                ...device.active && {
                  display: true,
                },
              };
            });
          });
          showUnsavedChangesToast();
        }}>
          All
        </SelectAllButton>
        <SelectAllButton onClick={() => {
          setAnnotatedDevices(annotatedDevices => {
            return annotatedDevices.map(device => {
              return {
                // copy device
                ...device,
                // override value if editable
                ...device.active && {
                  display: false,
                },
              };
            });
          });
          showUnsavedChangesToast();
        }}>
          None
        </SelectAllButton>
      </ButtonGroup>}
    </Fragment>
  );
}

export function sortByActiveThenString(a, b, order, dataField, rowA, rowB) {
  const negative = order === 'asc' ? 1 : -1;
  return rowA.active !== rowB.active
    // prioritise sorting by activeness
    ? rowB.active - rowA.active
    // then sort by value
    : negative * a.localeCompare(b);
};

export function sortByActiveThenNumber(a=0, b=0, order, dataField, rowA, rowB) {
  const negative = order === 'asc' ? 1 : -1;
  return rowA.active !== rowB.active
    // prioritise sorting by activeness
    ? rowB.active - rowA.active
    // then sort by value
    : negative * (a - b);
};

const columns = [{
  ...organisationName,
  sortFunc: sortByActiveThenString,
}, {
  ...siteName,
  sortFunc: sortByActiveThenString,
}, {
  ...subAreaName,
  sortFunc: sortByActiveThenString,
}, {
  ...equipmentName,
  sortFunc: sortByActiveThenString,
}, {
  ...archived,
  sortFunc: sortByActiveThenNumber,
}, {
  dataField: 'display',
  text: 'Show',
  headerFormatter: selectAllHeaderFormatter,
  formatter: (value, { id, active }, ignore, extra) => (extra.active || !active) ? (
    <Form.Check
      key={`display-${id}`}
      type="checkbox"
      name={`display[${id}]`}
      defaultChecked={!!value}
      onChange={e => {
        // checked is the value which it will become (and has already changed to)
        const newValue = !!e.target.checked;
        // setInputValues(`display[${id}]`, newValue);
        extra.setAnnotatedDevices(annotatedDevices => {
          return annotatedDevices.map(device => {
            return {
              // copy device
              ...device,
              // override value
              ...device.id === id && {
                display: newValue,
              },
            };
          });
        });
        showUnsavedChangesToast();
      }}
      disabled={!active}
    />
  ) : (
    // show the value without making it editable
    value ? <IoIosCheckbox size="1.1em"/> : <Form.Check disabled style={{ marginLeft: 2 }} />
  ),
  filterValue: nullFormatter,
  sort: false,
}];

function EditGroupDevices({
  groupId,
  group = { id: groupId },
  topLevelGroupActive,
  userCanViewGroups,
  userCanEditGroups,
  organisationIsStandard,
  nearestOrganisationGroup = {},
  nearestOrganisationId,
  groupDevices,
  devices,
  archivedDevices,
  fetchGroup,
  fetchGroupMembers,
  fetchGroupMembersAvailable,
  fetchDevices,
  addGroupDevices,
  deleteGroupDevices,
}) {

  const [filter, setFilter] = useState('');
  const [submission, setSubmission] = useState({});

  // get devices filtered to current org
  const filteredDevices = useMemo(() => {
    return [...devices || [], ...archivedDevices || []]
      .filter(topLevelGroupActive ? Boolean : device => {
        return device.organisation_id === nearestOrganisationId;
      });
  }, [devices, archivedDevices, nearestOrganisationId, topLevelGroupActive]);

  // set devices to active/inactive state
  const activeDevices = useMemo(() => {
    return filteredDevices
      .map(device => {
        return {
          ...device,
          active: true,
        };
      });
  }, [filteredDevices]);

  // get devices as annotated by current backend state
  const defaultDevices = useMemo(() => {
    const groupDeviceIds = (groupDevices || []).map(({ id }) => id);
    return activeDevices.map(device => {
      return {
        ...device,
        // set checkbox
        display: groupDeviceIds.includes(device.id),
      };
    });
  }, [activeDevices, groupDevices]);

  // get annotated devices: the devices with front-end changes applied by this component
  const [annotatedDevices, setAnnotatedDevices] = useState(defaultDevices);

  // apply filter to annotated devices
  const applyFilter = useCallback(annotatedDevicesOverride => {
    // filter current devices state
    const lowercaseFilter = filter.toLowerCase().trim();
    setAnnotatedDevices(annotatedDevices => {
      return (annotatedDevicesOverride || annotatedDevices).map(device => {
        return {
          ...device,
          active: `${
            device.equipment_name
          } ${
            device.site_name
          } ${
            device.sub_area_name
          } ${
            !device.archived ? 'active' : 'archived'
          }`.toLowerCase().includes(lowercaseFilter),
        };
      });
    });
  }, [setAnnotatedDevices, filter]);

  // apply filter whenever the filter changes
  useEffect(() => applyFilter(), [applyFilter]);

  // reset these whenever the group changes
  useEffect(() => applyFilter(defaultDevices), [defaultDevices]);

  // load devices list
  useEffect(() => {
    // fetch group's devices if needed
    // add already fetching state
    async function loadDevicesLists() {
      const submittedAt = Date.now();
      setSubmission({ submittedAt });
      try {
        await fetchGroup(group);
        // this logic should follow that of the selector logic
        // if on top level, fetch all devices
        if (topLevelGroupActive) {
          await Promise.all([
            fetchDevices({ forOrg: true }),
            fetchDevices({ filter: 'archived', forOrg: true }),
          ]);
        }
        else {
          await Promise.all([
            fetchGroupMembers(group),
            fetchDevices({ filter: 'archived', forOrg: true }),
          ]);
        }
        // if the user can edit groups, fetch the available members
        if (userCanEditGroups) {
          await fetchGroupMembersAvailable(group);
        }
        // fetch the members on the nearest organisation group
        else if (!topLevelGroupActive && nearestOrganisationGroup.id) {
          // and the organisation group
          await Promise.all([
            fetchGroup({ id: nearestOrganisationGroup.id }),
            fetchGroupMembers({ id: nearestOrganisationGroup.id }),
          ]);
        }
        setSubmission(submission => {
          return submission.submittedAt === submittedAt
            ? { succeededAt: new Date() }
            : submission;
        });
      }
      catch (error) {
        setSubmission(submission => {
          return submission.submittedAt === submittedAt
            ? { error: error.message || 'Error' }
            : submission;
        });
      }
    }
    // execute async code
    if (group.id) {
      loadDevicesLists();
    }
  }, [group.id, topLevelGroupActive, nearestOrganisationGroup.id]);

  const handleSubmit = useCallback(async e => {
    e.preventDefault();

    // get devices list from current form state
    const newDeviceIds = annotatedDevices.filter(d => d.display).map(d => d.id);
    const oldDeviceIds = (groupDevices || []).map(d => d.id);
    const addIds = newDeviceIds.filter(id => !oldDeviceIds.includes(id));
    const delIds = oldDeviceIds.filter(id => !newDeviceIds.includes(id));

    const submittedAt = Date.now();
    setSubmission({ submittedAt });
    try {
      if (addIds.length || delIds.length) {
        await Promise.all([
          addIds.length && addGroupDevices(group, addIds),
          delIds.length && deleteGroupDevices(group, delIds),
        ]);
        addToast({ variant: 'success', header: 'Devices updated successfully' });
        await fetchGroupMembers(group);
      } else {
        addToast({ variant: 'warning', header: 'No changes made' });
      }
      setSubmission(submission => {
        return submission.submittedAt === submittedAt
          ? { succeededAt: new Date() }
          : submission;
      });
    }
    catch (error) {
      setSubmission(submission => {
        return submission.submittedAt === submittedAt
          ? { error: error.message || 'Error' }
          : submission;
      });
    }
  }, [submission, addGroupDevices, deleteGroupDevices, fetchGroupMembers, group, groupDevices, annotatedDevices]);

  const renderItemCount = useCallback(() => {
    return filter
      ? `${annotatedDevices.filter(({ active }) => !!active).length} of ${annotatedDevices.length} rows`
      : `${annotatedDevices.length} rows`;
  }, [filter, annotatedDevices]);

  const renderSearchBar = useCallback(() => {
    return (
      // copy style from output of default rendered search bar
      <label htmlFor="search-bar-group-devices" className="search-label">
        <input
          id="search-bar-group-devices"
          type="text"
          aria-label="enter text you want to search"
          className="form-control align-middle d-inline-block react-bootstrap-table2-search-header"
          placeholder="Search"
          // add custom onChange handler
          onChange={e => {
            // set text filter
            const filter = e.target.value;
            setFilter(filter);
          }}
        />
      </label>
    );
  }, [annotatedDevices]);

  const renderHeader = useCallback(props => {
    return (
      <Toolbar
        searchable
        renderItemCount={renderItemCount}
        renderSearchBar={renderSearchBar}
        title="Group Equipment"
        loading={submission.submittedAt}
        lastFetch={submission.succeededAt}
        error={submission.error}
        tableProps={props}
      />
    );
  }, [submission, renderItemCount, renderSearchBar]);

  const userCanEdit = !!userCanEditGroups && group.group_type === 'group';

  // add extra data for columns, also apply hidden status to hide organisation field for standard orgs
  const columnsWithExtraData = columns.map(column => ({
    ...column,
    formatExtraData: { active: !!userCanEdit, setAnnotatedDevices },
  }));

  const visibleColumns = useColumnsWithVisibility(columnsWithExtraData, {
    // apply hidden status to hide organisation field for standard orgs
    'organisation_name': !organisationIsStandard,
    // if at least one device is marked as archived, show the archive status column
    'archived': !!annotatedDevices.find(({ archived }) => archived),
  });

  if (!userCanViewGroups) {
    return null;
  }

  return (
    <Form
      id="edit-group-form"
      className="form-container"
      onSubmit={handleSubmit}
    >
      {!organisationIsStandard && (
        <Alert variant="warning">
          <p>
            You cannot modify child organisation groups as a parent organisation.
          </p>
          <p className="mb-0">
            Sign into the child organisation directly to modify a group.
          </p>
        </Alert>
      )}
      <Table
        rowClasses="text-muted"
        rowStyle={filter ? { backgroundColor: 'rgba(0,0,0,.05)' } : {}}
        keyField="id"
        submit={submission}
        renderHeader={renderHeader}
        data={(
          group.group_type === 'organisation'
            // force org groups to have all rows checked but disabled devices
            ? annotatedDevices.map(device => ({ ...device, display: true, active: false }))
            : annotatedDevices
        )}
        defaultSorted={defaultSorted}
        columns={visibleColumns}
        noDataIndication={() => 'No Devices'}
        loading={submission.submittedAt}
        striped={!filter}
        selectRow={{
          mode: 'checkbox',
          hideSelectColumn: true,
          selected: filter ? annotatedDevices.reduce((acc, { id, active }) => {
            if (active) {
              acc.push(id);
            }
            return acc;
          }, []) : annotatedDevices.map(({ id }) => id),
          classes: "text-reset",
          bgColor: filter ? 'white' : undefined,
        }}
      />
      <Form.Group className="text-right mt-3 mb-chat-widget">
        <Button variant="success" type="submit" size="lg" disabled={!userCanEdit}>
          Update
        </Button>
      </Form.Group>
    </Form>
  );
}


const mapStateToProps = (state, { groupId }) => {
  const topLevelGroupActive = isTopLevelGroupActive(state);
  const userIsAdmin = !!isAdmin(state);
  const organisationIsStandard = !!isStandardOrganisation(state);
  const nearestOrganisationGroup = getNearestGroupOrganisationAncestor(state, groupId);
  const nearestOrganisationId = getNearestOrganisationAncestorId(state, groupId);
  return {
    group: getGroup(state, groupId),
    topLevelGroupActive,
    userCanViewGroups: userIsAdmin,
    userCanEditGroups: userIsAdmin && organisationIsStandard,
    organisationIsStandard,
    nearestOrganisationGroup,
    nearestOrganisationId,
    ...topLevelGroupActive ? {
      groupDevices: getDevices(state),
    } : {
      groupDevices: getGroupDevices(state, groupId),
    },
    ...organisationIsStandard ? {
      devices: getGroupDevicesAvailable(state, groupId),
    } : {
      devices: getDevices(state, { forOrg: true }),
    },
    archivedDevices: getDevices(state, { forOrg: true, archived: true }),
  };
};
const mapDispatchToProps = {
  fetchDevices,
  fetchGroup,
  fetchGroupMembers,
  fetchGroupMembersAvailable,
  addGroupDevices,
  deleteGroupDevices,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditGroupDevices);
