
import React from 'react';
import { connect } from 'react-redux';
import { IoIosThermometer } from 'react-icons/io';

import { HeaderCell, RowCell } from '../../../components/table/formatters';
import { numericSortFunc } from '../../../components/table/utils';
import { getUserDisplayPreference } from '../../../components/values/Temperature';

const ConnectedHeaderCell = connect(getUserDisplayPreference)(HeaderCell);
const ConnectedRowCell = connect(getUserDisplayPreference)(RowCell);

// export a column definition
export const column = {
  dataField: 'temperature',
  text: 'Temperature',
  icon: <IoIosThermometer size="1.4em" />,
  headerFormatter: (columnProps, colIndex, components) => (
    <ConnectedHeaderCell {...columnProps} {...components} />
  ),
  formatter: value => <ConnectedRowCell value={value} />,
  filterValue: value => <ConnectedRowCell value={value} />,
  sort: true,
  sortFunc: numericSortFunc,
};
