import React from 'react';
import { Link } from 'react-router-dom';

import {
  headerFormatter,
  textFormatter,
  relativeDateFormatter,
  relativeDateFilter,
  lengthFormatter,
} from '../../components/table/formatters';

import {
  numericSortFunc,
  stringSortFunc,
  stringSortValueWithHumanNumbersFunc,
  timeSortFunc,
} from '../../components/table/utils';

export const gwLastHeardHoursWarning = 24;
export const gwLastHeardHoursDanger = 48;
export const gwLastHeard = {
  dataField: 'last_heard',
  text: 'Last used',
  headerFormatter,
  formatter: relativeDateFormatter,
  filterValue: relativeDateFilter,
  sort: true,
  sortFunc: timeSortFunc,
};

export const gwDeviceLastHeard = {
  dataField: '_related.device.last_heard',
  text: 'Last heard through Gateway',
  headerFormatter,
  formatter: relativeDateFormatter,
  filterValue: relativeDateFilter,
  sort: true,
  sortFunc: timeSortFunc,
};

export const serial = {
  dataField: 'mac',
  text: 'MAC address',
  headerFormatter,
  // when returned in relations gateway id may be keyed under gateway_id
  formatter: (value, { id }) => (
    <Link to={`/gateways/${id}`}>{value || "N/A"}</Link>
  ),
  filterValue: textFormatter,
  sort: true,
  sortFunc: stringSortFunc,
  sortValue: stringSortValueWithHumanNumbersFunc,
};

export const connectedDevices = {
  dataField: '_related.devices',
  text: 'Connected Devices',
  headerFormatter,
  formatter: lengthFormatter,
  filterValue: lengthFormatter,
  sort: true,
  sortFunc: numericSortFunc,
  sortValue: lengthFormatter,
};

export const name = {
  dataField: 'name',
  text: 'Name',
  headerFormatter,
  formatter: textFormatter,
  filterValue: textFormatter,
  sort: true,
  sortFunc: stringSortFunc,
};

export const organisationName = {
  dataField: 'organisation_name',
  text: 'Organisation',
  headerFormatter,
  formatter: (value, { organisation_id }) => (
    <Link to={`/organisations/${organisation_id}`}>{value || "N/A"}</Link>
  ),
  filterValue: textFormatter,
  sort: true,
  sortFunc: stringSortFunc,
};

export const ssid = {
  dataField: 'ssid',
  text: 'SSID',
  headerFormatter,
  formatter: textFormatter,
  filterValue: textFormatter,
  sort: true,
  sortFunc: stringSortFunc,
};

export const siteName = {
  dataField: 'site_name',
  text: 'Site name',
  headerFormatter,
  formatter: textFormatter,
  sort: true,
  sortFunc: stringSortFunc,
  sortValue: stringSortValueWithHumanNumbersFunc,
};

export const subAreaName = {
  dataField: 'sub_area_name',
  text: 'Sub-area name',
  headerFormatter,
  formatter: textFormatter,
  sort: true,
  sortFunc: stringSortFunc,
  sortValue: stringSortValueWithHumanNumbersFunc,
};

export const type = {
  dataField: 'type',
  text: 'Gateway Type',
  headerFormatter,
  formatter: textFormatter,
  filterValue: textFormatter,
  sort: true,
  sortFunc: stringSortFunc,
};
