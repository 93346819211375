
import React from 'react';
import { connect } from 'react-redux';
import { Col, Row, Nav, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { IoIosChatboxes, IoIosWarning } from 'react-icons/io';
import { Icon } from '@blueprintjs/core';
import withNavigationDeviceProps from './withNavigationDeviceProps';

// Other
import DeviceContextMenu from './DeviceContextMenu';
import Private from '../../../components/Private';
import { getDevices, getDevice } from '../selectors';

function EquipmentNav({ device={} }) {
  return (
    <Nav as="ul" className="flex-grow-1">
      <Row className="small-gutters flex-grow-1">
        <Col xs="auto">
          {device.serial && !device.archived && (
            <Row className="small-gutters">
              <Col xs="auto" className="mb-1">
                <LinkContainer to={`/devices/${device.id}/activity`} exact>
                  <Button size="md" variant="outline-secondary">
                    <IoIosChatboxes size="1.2em" /> <span>Activity</span>
                  </Button>
                </LinkContainer>
              </Col>
              <Col xs="auto" className="mb-1">
                <LinkContainer to={`/devices/${device.id}`} exact>
                  <Button size="md" variant="outline-secondary">
                    <Icon
                      iconSize="1.1em"
                      icon="graph"
                      title="Network"
                      style={{ width: '1.1em', verticalAlign: '-12%' }}
                    /> <span>Network</span>
                  </Button>
                </LinkContainer>
              </Col>
              <Col xs="auto" className="mb-1">
                <Private minUserType="Super Admin">
                  <LinkContainer to={`/devices/${device.id}/errors`} exact>
                    <Button size="md" variant="outline-secondary">
                      <IoIosWarning size="1.2em" /> Error Log
                    </Button>
                  </LinkContainer>
                </Private>
              </Col>
            </Row>
          )}
        </Col>
        {/*  when this column overflows, ensure is is right-aligned using ml-auto */}
        <Col xs="auto" className="ml-auto">
          <Row className="small-gutters">
            {!device.archived && (
              <Col xs="auto" className="mb-1 ml-auto">
                <DeviceContextMenu deviceId={device.id} />
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </Nav>
  );
}

const mapStateToProps = (state, { deviceId }) => {
  return {
    devices: getDevices(state),
    device: getDevice(state, deviceId),
  };
};

export default withNavigationDeviceProps(
  connect(mapStateToProps)(EquipmentNav)
);
