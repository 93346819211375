import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form, Button, ButtonGroup } from 'react-bootstrap';

import Table from '../../../components/Table';
import {
  headerFormatter,
  nullFormatter,
} from '../../../components/table/formatters';

import Toolbar from '../../../components/TableToolbar';

import {
  sortByActiveThenString,
  showUnsavedChangesToast,
} from './EditUserDevices';

import { fetchUsersThenUserDevices, submitUserDevices } from '../actions';

import { hasMultipleOrganisations } from '../../organisation/selectors';

import { getFormValues } from '../../../lib/utils';
import { addToast } from '../../../components/Toaster';
import {
  userName,
  userEmail,
  embeddedOrganisations,
} from '../columns';

import { getUsers } from '../selectors';
import { getDevice } from '../../equipment/selectors';

const defaultSorted = [{
  dataField: 'name',
  order: 'asc'
}];

// todo: move this to helper as it is repeated multiple times in codebase
function setInputValues(name, value) {
  const form = document.getElementById('devices-users-form');
  if (form) {
    const inputNameRegex = /^(\w+)\[(\d+)\]$/;
    [...form.elements].forEach(el => {
      if (!el.disabled && el.name.startsWith(name) && inputNameRegex.test(el.name)) {
        el.checked = value ? 'checked' : undefined;
      }
    });
  }
}

// todo: move this to helper as it is repeated multiple times in codebase
function SelectAllButton(props) {
  return (
    <Button size="sm" variant="outline-primary" {...props} />
  );
}

function selectAllHeaderFormatter(props, ...rest) {
  const { dataField } = props;
  return (
    <Fragment>
      {headerFormatter(props, ...rest)}
      <ButtonGroup>
        <SelectAllButton onClick={() => {
          setInputValues(dataField, true);
          if (dataField !== 'display') {
            setInputValues('display', true);
          }
          showUnsavedChangesToast();
        }}>
          All
        </SelectAllButton>
        <SelectAllButton onClick={() => {
          setInputValues(dataField, false);
          if (dataField === 'display') {
            setInputValues('daily_email', false);
            setInputValues('alert_email', false);
          }
          showUnsavedChangesToast();
        }}>
          None
        </SelectAllButton>
      </ButtonGroup>
    </Fragment>
  );
}

const columns = [{
  ...userName,
  sortFunc: sortByActiveThenString,
}, {
  ...userEmail,
  sortFunc: sortByActiveThenString,
}, {
  ...embeddedOrganisations,
  sortFunc: sortByActiveThenString,
}, {
  dataField: 'display',
  text: 'Display',
  headerFormatter: selectAllHeaderFormatter,
  formatter: (value, { id, active }) => (
    <Form.Check
      key={`display-${id}`}
      type="checkbox"
      name={`display[${id}]`}
      defaultChecked={!!value}
      onChange={e => {
        // checked is the value which it will become (and has already changed to)
        if (e.target.checked) {
          setInputValues(`display[${id}]`, true);
        } else {
          setInputValues(`display[${id}]`, false);
          setInputValues(`daily_email[${id}]`, false);
          setInputValues(`alert_email[${id}]`, false);
        }
        showUnsavedChangesToast();
      }}
      disabled={!active}
    />
  ),
  filterValue: nullFormatter,
  sort: false,
}, {
  dataField: 'daily_email',
  text: 'Daily email',
  headerFormatter: selectAllHeaderFormatter,
  formatter: (value, { id, active }) => (
    <Form.Check
      key={`daily_email-${id}`}
      type="checkbox"
      name={`daily_email[${id}]`}
      defaultChecked={!!value}
      onChange={e => {
        // checked is the value which it will become (and has already changed to)
        if (e.target.checked) {
          setInputValues(`display[${id}]`, true);
          setInputValues(`daily_email[${id}]`, true);
        } else {
          setInputValues(`daily_email[${id}]`, false);
        }
        showUnsavedChangesToast();
      }}
      disabled={!active}
    />
  ),
  filterValue: nullFormatter,
  sort: false,
}, {
  dataField: 'alert_email',
  text: 'Alert email',
  headerFormatter: selectAllHeaderFormatter,
  formatter: (value, { id, active }) => (
    <Form.Check
      key={`alert_email-${id}`}
      type="checkbox"
      name={`alert_email[${id}]`}
      defaultChecked={!!value}
      onChange={e => {
        // checked is the value which it will become (and has already changed to)
        if (e.target.checked) {
          setInputValues(`display[${id}]`, true);
          setInputValues(`alert_email[${id}]`, true);
        } else {
          setInputValues(`alert_email[${id}]`, false);
        }
        showUnsavedChangesToast();
      }}
      disabled={!active}
    />
  ),
  filterValue: nullFormatter,
  sort: false,
}];

const columnsWithOrganisations = columns;
const columnsWithoutOrganisations = columns.map(column => {
  // add a hidden flag to columns we want to filter, *do not* change the number of column passed to
  // react-bootstrap-table2, it will not re-render what it believes are "unaffected" rows correctly
  return { ...column, hidden: column.dataField === '_embedded.organisations' };
});

class EditUserDevices extends Component {

  state = {
    annotatedUsers: undefined,
    filteredUsers: [],
    submit: {},
    filter: '',
  }

  componentDidMount() {
    this.loadUsersList();
    this.updateFilteredUsers();
  }

  componentDidUpdate(prevProps) {
    const { users: prevUsers, deviceToEdit: { id: prevId }={} } = prevProps;
    const { users: propUsers, deviceToEdit: { id: propId }={} } = this.props;
    // update the user list if the device was updated
    if (prevId !== propId) {
      this.loadUsersList();
    }

    // update filtered users
    if (prevUsers !== propUsers) {
      this.updateFilteredUsers();
    }
  }

  updateFilteredUsers = () => {
    const { users=[], deviceToEdit={} } = this.props;
    const filteredUsers = users.filter((user={}) => {
      // filter out non-Users
      if (user.user_type !== 'User') {
        return false;
      }
      // find if the User is a part of the requested device's organisation
      const organisations = user._embedded && user._embedded.organisations;
      // only show users that are part of the organisation of the device
      return organisations.find(({ organisation_id }) => {
        return organisation_id === deviceToEdit.organisation_id;
      });
    });
    this.setState({ filteredUsers });
  }

  loadUsersList = async () => {

    const { fetchUsersThenUserDevices, deviceToEdit={} } = this.props;

    // don't fetch user devices for non-existent, still fetching, or archived devices
    if (!deviceToEdit.id || deviceToEdit.archived) {
      return null;
    }

    // skip with warning if the organisation id cannot be found
    // otherwise we will fetch all user devices which can make a browser unresponsive
    if (!deviceToEdit.organisation_id) {
      addToast({
        header: 'Could not find organisation that this device belongs to',
      });
      return null;
    }

    // fetch all user devices
    // fake state as a submission to prevent user interaction
    const submittedAt = Date.now();
    this.setState(({ submit: { submittedAt } }));
    try {
      // note: optimisation: this call is very heavy for a MOVUS admin
      await fetchUsersThenUserDevices(deviceToEdit.organisation_id);
      this.setState(({ submit }) => {
        if (submit.submittedAt === submittedAt) {
          return { submit: { succeededAt: new Date() } };
        }
      });
    }
    catch(e) {
      // this really shouldn't ever fail
      this.setState(({ submit }) => {
        if (submit.submittedAt === submittedAt) {
          return { submit: { error: (e && e.message) || 'Error' } };
        }
      });
    }
  }

  handleSubmit = async e => {

    e.preventDefault();
    const formValues = getFormValues(e, this.form);

    const { users=[], fetchUsersThenUserDevices, submitUserDevices, deviceToEdit={} } = this.props;

    if (!deviceToEdit.id) {
      return null;
    }

    // split out user device things from the main payload and format them
    // transform the checkbox input element name/value pairs into a devices array
    const deviceInputNameRegex = /^(display|alert_email|daily_email)\[(\d+)\]$/;
    const device_id = parseInt(deviceToEdit.id);
    const userDevicePayloadsByUserId = Object.entries(formValues).reduce((acc, [key, value]) => {
      // get the identifiers from the name format
      const [match, name, userId] = key.match(deviceInputNameRegex) || [];
      if (match) {
        const user = users.find(({ id }) => id === parseInt(userId));
        const userDevice = user && user._embedded && user._embedded.devices &&
          user._embedded.devices.find(({ device_id }) => device_id === deviceToEdit.id);
        // check if the value has changed (also convert given 0s and 1s to booleans)
        if ((userDevice && !!userDevice[name]) !== value) {
          // get or add an get device props by user id
          acc[userId] = acc[userId] || { device_id };
          // add the value into the device props
          acc[userId][name] = value;
        }
      }
      return acc;
    }, {});

    // if no updates were found then tell the user so
    const userDevicePayloadEntries = Object.entries(userDevicePayloadsByUserId);
    if (!userDevicePayloadEntries.length) {
      return addToast({
        header: 'No changes were found',
      });
    }

    // submit form then handle feedback
    const submittedAt = Date.now();
    this.setState({ submit: { submittedAt } });
    try {
      await Promise.all(userDevicePayloadEntries.map(([id, device]) => {
        return submitUserDevices({ id }, [device]);
      }));

      // refetch info
      await fetchUsersThenUserDevices(deviceToEdit.organisation_id);

      // update submitting status
      // (delay showing information to user until reducers have finished)
      this.setState(({ submit }) => {
        if (submit.submittedAt === submittedAt) {
          return { submit: { succeededAt: new Date() } };
        }
      });
    }
    catch (e) {
      // could not submit all user devices
      // if still displaying the same submission then update with failure
      this.setState(({ submit }) => {
        if (submit.submittedAt === submittedAt) {
          return { submit: { error: (e && e.message) || 'Error' } };
        }
      });
    }
  }

  renderItemCount = ({ customProps: annotatedUsers=[] }) => {
    const { filter } = this.state;
    return filter
      ? `${annotatedUsers.filter(({ active }) => !!active).length} of ${annotatedUsers.length} rows`
      : `${annotatedUsers.length} rows`;
  }

  renderSearchBar = ({ customProps: annotatedUsers=[] }) => {
    const { hasMultipleOrganisations } = this.props;
    return (
      // copy style from output of default rendered search bar
      <label htmlFor="search-bar-device-users" className="search-label">
        <input
          id="search-bar-device-users"
          type="text"
          aria-label="enter text you want to search"
          className="form-control align-middle d-inline-block react-bootstrap-table2-search-header"
          placeholder="Search"
          // add custom onChange handler
          onChange={e => {
            const filter = e.target.value;
            const lowercaseFilter = filter.toLowerCase().trim();
            this.setState({
              filter,
              annotatedUsers: annotatedUsers.map((user={}) => {
                const organisations = user._embedded && user._embedded.organisations;
                return {
                  ...user,
                  active: `${
                    user.name
                  } ${
                    user.email
                  } ${
                    hasMultipleOrganisations && organisations &&
                      organisations.map(org => org.organisation_name).join(' ')
                  }`.toLowerCase().includes(lowercaseFilter),
                };
              }),
            });
          }}
        />
      </label>
    );
  }

  renderHeader = props => {
    const { submit } = this.state;
    return (
      <Toolbar
        searchable
        renderItemCount={this.renderItemCount}
        renderSearchBar={this.renderSearchBar}
        title="Equipment Users"
        loading={submit.submittedAt}
        lastFetch={submit.succeededAt}
        error={submit.error}
        tableProps={props}
      />
    );
  }

  render() {
    const {
      deviceToEdit = {},
      hasMultipleOrganisations,
    } = this.props;

    if (!deviceToEdit.id) {
      return null;
    }

    if (deviceToEdit.archived) {
      return (
        <div>
          <p>
            Editing equipment users is not currently available for archived devices.
          </p>
          <p>
            You can vote for this feature on the <Link to="/whats-new">What's New page</Link>.
          </p>
        </div>
      );
    }

    const {
      submit,
      filter = '',
      filteredUsers,
      annotatedUsers = filteredUsers.map(user => {
        // selected the current device if found
        const currentDevice = user && user._embedded && user._embedded.devices &&
          user._embedded.devices.find(({ device_id }) => device_id === deviceToEdit.id);
        return {
          ...user,
          ...currentDevice,
          active: true,
        };
      }),
    } = this.state;

    return (
      <Form
        id="devices-users-form"
        onSubmit={this.handleSubmit}
        ref={el => this.form = el}
      >
        <Table
          rowClasses="text-muted"
          rowStyle={filter ? { backgroundColor: 'rgba(0,0,0,.05)' } : {}}
          keyField="id"
          submit={submit}
          renderHeader={this.renderHeader}
          data={annotatedUsers}
          defaultSorted={defaultSorted}
          columns={
            // if user can see multiple organisations then give them an organisations column
            hasMultipleOrganisations
              ? columnsWithOrganisations
              : columnsWithoutOrganisations
          }
          noDataIndication={() => submit.submittedAt ? 'Loading...' : 'No Users Editable'}
          loading={submit.submittedAt}
          striped={!filter}
          selectRow={{
            mode: 'checkbox',
            hideSelectColumn: true,
            selected: filter ? annotatedUsers.reduce((acc, { id, active }) => {
              if (active) {
                acc.push(id);
              }
              return acc;
            }, []) : annotatedUsers.map(({ id }) => id),
            classes: "text-reset",
            bgColor: filter ? 'white' : undefined,
          }}
          customProps={annotatedUsers}
        />
        <Form.Group className="text-right">
          <Button
            variant="success"
            size="lg"
            type="submit"
            disabled={submit.submittedAt}
          >
            Update
          </Button>
        </Form.Group>
      </Form>
    );
  }
}


const mapStateToProps = (state, { deviceToEditId }) => {
  return {
    users: getUsers(state),
    deviceToEdit: getDevice(state, deviceToEditId),
    hasMultipleOrganisations: hasMultipleOrganisations(state),
  };
};
const mapDispatchToProps = { fetchUsersThenUserDevices, submitUserDevices };

export default connect(mapStateToProps, mapDispatchToProps)(EditUserDevices);
