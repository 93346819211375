
import React, { useEffect, useCallback, useMemo } from 'react';
import withNavigationGatewayProps from '../components/withNavigationGatewayProps';

import GatewayPageLayout from '../components/GatewayPageLayout';
import { connect } from 'react-redux';
import Table from '../../../components/Table';
import Toolbar from '../../../components/TableToolbar';

import { renderGatewayAdminTimeColours } from './GatewaysAdmin';
import {
  columns as deviceColumns,
  renderDeviceAdminTimeColours,
} from '../../equipment/screens/DevicesAdmin';
import {
  fmGatewayLastHeard,
  siteName,
  subAreaName,
  equipmentName,
  type,
  serial,
  organisationName,
  onboardedName,
  fmLastHeard,
} from '../../equipment/columns';
import { useColumnsWithVisibility } from '../../../components/table/utils';

import { fetchDevices } from '../../equipment/actions';
import { fetchGateway } from '../actions';

import { getDeviceListState } from '../../equipment/selectors';
import { getGatewayConnections } from '../selectors';
import { isAuthorised } from '../../user/selectors';

// only allow admins to see columns that were made for the Devices Admin page
const columnsForAdmins = [
  {
    ...fmGatewayLastHeard,
    style: renderGatewayAdminTimeColours,
  },
  ...deviceColumns,
];

// whitelist columns allowed for non-admins
const columnsForUsers = [
  {
    ...fmGatewayLastHeard,
    style: renderGatewayAdminTimeColours,
  },
  siteName,
  subAreaName,
  equipmentName,
  type,
  serial,
  organisationName,
  onboardedName,
  // add styles to columns
  {
    ...fmLastHeard,
    style: renderDeviceAdminTimeColours,
  },
];

const defaultSorted = [{
  dataField: fmGatewayLastHeard.dataField,
  order: 'desc',
}];

const noDataIndication = () => 'This device has no current device connections';

function GatewayNetwork({
  gatewayId,
  loading,
  error,
  lastFetch,
  connections = [],
  isAdmin,
  fetchDevices,
  fetchGateway,
}) {

  const fetchThisGateway = useCallback(() => {
    if (gatewayId) {
      fetchGateway({ id: gatewayId });
    }
  }, [gatewayId]);

  // get gateway on id load/change
  useEffect(fetchThisGateway, [fetchThisGateway]);

  // fetch gateway list if it has not yet been fetched
  useEffect(() => {
    if (!lastFetch) {
      fetchDevices();
    }
  }, [lastFetch, fetchDevices]);

  const connectedDevices = useMemo(() => {
    return connections
      // derive devices with connections from this
      .map(([gateway, device, { last_heard }]) => {
        return { ...device, _related: { gateway: { ...gateway, last_heard } } };
      })
      // show only active devices
      .filter(device => !device.archived);
  }, [connections]);

  const columns = isAdmin ? columnsForAdmins : columnsForUsers;
  const visibleColumns = useColumnsWithVisibility(columns, {
    // only show organisation name if more than 1 organisations are present
    organisation_name: [...new Set(connectedDevices.map(g => g.organisation_id))].length > 1,
  });

  const renderHeader = useCallback(props => {
    return (
      <Toolbar
        searchable
        title="Connected Devices"
        loading={loading}
        error={error}
        lastFetch={lastFetch}
        tableProps={props}
      />
    );
  }, [loading, error, lastFetch]);

  return (
    <div className="my-4">
      <Table
        pagination
        data={connectedDevices}
        keyField="id"
        defaultSorted={defaultSorted}
        renderHeader={renderHeader}
        columns={visibleColumns}
        noDataIndication={noDataIndication}
        loading={loading}
        refreshHandler={fetchThisGateway}
      />
    </div>
  );
}

const mapStateToProps = (state, { gatewayId }) => {
  const { loading, error, lastFetch } = getDeviceListState(state);
  return {
    connections: getGatewayConnections(state, gatewayId),
    isAdmin: !!isAuthorised(state, { minUserType: 'Admin' }),
    loading,
    error,
    lastFetch,
  };
};
const mapDispatchToProps = { fetchDevices, fetchGateway };

const ConnectedGatewayNetwork = withNavigationGatewayProps(
  connect(mapStateToProps, mapDispatchToProps)(GatewayNetwork)
);

export default function GatewayNetworkPage() {
  return (
    <GatewayPageLayout>
      <ConnectedGatewayNetwork />
    </GatewayPageLayout>
  );
}
