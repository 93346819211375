
import React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';

import { isAuthorised } from '../modules/user/selectors';
import NotFound from './NotFound';

// define Route visible to only specific roles, else render NotFound
function PrivateRoute({ component: Component, isAuthorised, ...rest }) {
  return (
    <Route {...rest} render={props => {
      // if the user passes the minimum user type and organisation type
      // then render the component, other render not found
      return isAuthorised
        ? <Component {...props} />
        : <Route component={NotFound} />;
    }}/>
  );
}

// isAuthorised is determined by the user, organisation, and ownProps
export default connect((state, { minUserType, minOrgType }) => {
  return {
    isAuthorised: isAuthorised(state, { minUserType, minOrgType })
  };
})(PrivateRoute);
