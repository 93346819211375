
import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import BaseModal from './BaseModal';

import LoadingSpinner from './LoadingSpinner';

export default class ConfirmModal extends Component {

  constructor(props) {
    super(props);
    const { children } = props;
    if (children !== undefined && !React.isValidElement(children)) {
      throw new Error('Child is not a single element');
    }
    // onClick must be a function, or specifically set to `false`
    if (typeof children.props.onClick !== 'function' && children.props.onClick !== false) {
      throw new Error('Child button does not have an onClick attribute');
    }
  }

  state = {
    submitting: false,
  }

  confirm = async (e, close) => {
    e.preventDefault();
    this.setState({ submitting: true });
    // wait for a result if the action is asynchronous
    try {
      await this.props.children.props.onClick(e);
      !this.unmounted && this.setState({
        submitting: false,
      });
      close();
    } catch(e) {
      // if an error occurred, also close the modal
      // the error should for now be handled with a toast notification
      !this.unmounted && this.setState({
        submitting: false,
      });
      close();
    }
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  renderModal = ({ shown, close }) => {
    const { submitting } = this.state;
    const {
      header = 'Are you sure?',
      body,
      cancelText = 'Cancel',
      cancelButtonProps = {},
      confirmText = 'Confirm',
      confirmButtonProps = {},
      size,
      className,
    } = this.props;
    return (
      <Modal show={shown} onHide={close} size={size} className={className}>
        <Modal.Header closeButton>
          <Modal.Title>{header}</Modal.Title>
        </Modal.Header>
        {body && <Modal.Body>{body}</Modal.Body>}
        <Modal.Footer>
          {submitting && <LoadingSpinner inline size={1} />}
          <Button
            // defaults
            variant="outline-secondary"
            // given
            {...cancelButtonProps}
            // overridden
            disabled={submitting}
            onClick={close}
          >
            {cancelText}
          </Button>
          <Button
            // defaults
            variant="danger"
            // given
            {...confirmButtonProps}
            // overridden
            disabled={confirmButtonProps.disabled || submitting}
            onClick={e => this.confirm(e, close)}
          >
            {confirmText}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  render() {
    return (
      <BaseModal
        renderModal={this.renderModal}
        onShow={this.props.onShow}
        onClose={this.props.onClose}
      >
        {this.props.children}
      </BaseModal>
    );
  }
}
