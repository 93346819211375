export const BLUETOOTH_STATES = {
  // we haven't scanned yet and we
  // don't know if it's nearby or not
  UNKNOWN: 'UNKNOWN',

  // we have found it in a scan, but
  // we haven't attempted to connect
  NEARBY: 'NEARBY',

  // we have scanned, but it wasn't found
  NOT_NEARBY: 'NOT_NEARBY',

  // this is a wifi-only device and BLE
  // is not available at all
  NOT_AVAILABLE: 'NOT_AVAILABLE'
};

export const CONNECTION_TYPES = {
  // this device will connect via wifi
  // to a MOVUS gateway
  GATEWAY: 'GATEWAY',

  // this device will connect via wifi
  // to a customer's network
  CUSTOMER_WIFI: 'CUSTOMER_WIFI',

  // this device will not be connected
  // and will use offline data collection
  OFFLINE: 'OFFLINE'
};

// This is the hardcoded list of non-bluetooth FitMachines at 13/08/2018
export const WIFI_ONLY_FIT_MACHINES = {
  "F4:5E:AB": {
    "A2": [
      "C1:97"
    ],
    "96": [
      "27:10",
      "26:AA",
      "26:91",
      "26:8F",
      "25:E4",
      "22:BC"
    ]
  },
  "D4:F5:13": {
    "EA": [
      "EF:93",
      "EF:85",
      "EB:7D",
      "EB:51",
      "EB:43",
      "EB:39",
      "EB:34",
      "EB:30",
      "EB:22",
      "EB:1B",
      "EB:18",
      "E8:EF",
      "E8:CF",
      "E8:AD",
      "E5:76",
      "E5:52",
      "E5:4B",
      "E5:3E",
      "E5:32",
      "E5:2F",
      "E5:2D",
      "E5:28",
      "E5:1C",
      "E5:16",
      "E5:14",
      "E5:12",
      "E5:0D",
      "E5:0A",
      "E5:06",
      "E5:05",
      "E3:E6",
      "E3:E0",
      "E3:DE",
      "E3:DD",
      "E3:DC",
      "E3:D4",
      "E3:CA",
      "E3:C9",
      "E3:BE",
      "E3:BD",
      "E3:B8",
      "E3:B7",
      "E3:AF",
      "E3:AE",
      "E3:AA",
      "E3:A9",
      "E3:A4",
      "E3:9B",
      "E3:96",
      "E3:8F",
      "E0:67",
      "E0:63",
      "E0:5D",
      "E0:5B",
      "E0:5A",
      "E0:48",
      "E0:44",
      "E0:33",
      "E0:30",
      "E0:2E",
      "E0:26",
      "E0:1E",
      "E0:1D",
      "E0:1A",
      "E0:18",
      "E0:16",
      "E0:12",
      "E0:09",
      "E0:07",
      "E0:00",
      "DD:E1",
      "DD:DB",
      "DD:B6",
      "DD:A0",
      "DD:9C",
      "DD:96",
      "DD:85",
      "DB:67",
      "DB:3C",
      "DB:21",
      "DB:04",
      "D7:34",
      "D7:25",
      "D5:5E",
      "D5:4A",
      "D5:2F",
      "D2:46",
      "CE:DD",
      "CE:D9",
      "CE:D0",
      "CE:CB",
      "CE:C3",
      "CE:BB",
      "CE:AC",
      "CE:A1",
      "CE:93",
      "CC:A9"
    ],
    "DB": [
      "BB:1D",
      "BB:1B",
      "BB:15",
      "BB:04",
      "B6:FF",
      "B6:F3",
      "B6:F2",
      "B6:EF",
      "B6:E7",
      "B6:E4",
      "B6:DC",
      "B6:D8",
      "B6:D6",
      "B6:CC",
      "B6:C0",
      "B6:B2",
      "B6:B1",
      "B6:A4",
      "B6:9B",
      "B6:95",
      "B6:88",
      "B6:83",
      "B6:82",
      "B6:81",
      "B4:7D",
      "B4:71",
      "B4:6B",
      "B4:4B",
      "B4:48",
      "B4:35",
      "B4:34",
      "B4:2B",
      "B4:25",
      "B4:20",
      "B4:18",
      "B4:07",
      "B0:ED",
      "B0:CE",
      "B0:AB",
      "B0:A9",
      "B0:A8",
      "B0:9F",
      "B0:92",
      "B0:89",
      "AF:7F",
      "AF:7E",
      "AF:7D",
      "AF:74",
      "AF:6B",
      "AF:64",
      "AF:63",
      "AF:53",
      "AF:52",
      "AF:4E",
      "AF:45",
      "AF:3D",
      "AF:20",
      "AF:0B",
      "AD:FF",
      "AD:F2",
      "AD:EB",
      "AD:E4",
      "AD:DD",
      "AD:DC",
      "AD:D8",
      "AD:D6",
      "AD:BE",
      "AA:74",
      "AA:72",
      "AA:63",
      "AA:61",
      "AA:5F",
      "AA:2A",
      "AA:02",
      "A0:DF",
      "A0:D9",
      "A0:C7",
      "A0:BC",
      "A0:A4",
      "A0:9D",
      "A0:91",
      "A0:89",
      "A0:81",
      "9A:F6",
      "9A:F4",
      "9A:F0",
      "9A:DA",
      "9A:D9",
      "9A:D4",
      "9A:D3",
      "9A:D2",
      "9A:D0",
      "9A:CE",
      "9A:C3",
      "9A:BD",
      "9A:B9",
      "9A:AF",
      "9A:A5",
      "9A:98",
      "9A:95",
      "9A:8D",
      "9A:8A",
      "9A:87",
      "9A:83",
      "9A:82",
      "9A:80",
      "85:EC",
      "85:E4",
      "85:DF",
      "85:D8",
      "85:D7",
      "85:D4",
      "85:BD",
      "85:BB",
      "85:B8",
      "85:B3",
      "85:A5",
      "85:9A",
      "85:97",
      "85:82",
      "85:81",
      "83:69",
      "83:67",
      "83:65",
      "83:5F",
      "83:5A",
      "83:54",
      "83:52",
      "83:4D",
      "83:47",
      "83:3A",
      "83:37",
      "83:32",
      "83:31",
      "83:15",
      "83:0D",
      "83:06",
      "80:78",
      "80:75",
      "80:55",
      "80:28",
      "80:27",
      "80:25",
      "80:1A",
      "80:0B",
      "80:00",
      "7D:79",
      "7D:63",
      "7D:32",
      "7D:2E",
      "7D:2A",
      "35:76",
      "35:68",
      "35:67",
      "35:4E",
      "35:18",
      "35:11",
      "35:09",
      "31:EB",
      "31:EA",
      "31:E5",
      "31:E4",
      "31:DD",
      "31:DA",
      "31:BC",
      "31:B7",
      "31:AD",
      "31:AB",
      "31:AA",
      "31:A7",
      "31:9B",
      "31:99",
      "31:89",
      "31:88",
      "2F:6F",
      "2F:64",
      "2F:58",
      "2F:55",
      "2F:4D",
      "2F:3A",
      "2F:36",
      "2F:34",
      "2F:21",
      "2F:20",
      "2F:0B",
      "2F:07",
      "2F:06",
      "2B:DF",
      "2B:D5",
      "2B:D2",
      "2B:CA",
      "2B:C2",
      "2B:8E",
      "2B:89",
      "2B:87",
      "2B:83",
      "29:F1",
      "29:E4",
      "29:E3",
      "29:DF",
      "29:DB",
      "29:D8",
      "29:CF",
      "29:BA",
      "29:B6",
      "29:B3",
      "29:A8",
      "29:A0",
      "29:8B",
      "26:FE",
      "26:E4",
      "26:E0",
      "26:DD",
      "26:DB",
      "26:D3",
      "26:C3",
      "26:C0",
      "26:AB",
      "26:A6",
      "26:A4",
      "26:A1",
      "26:9F",
      "26:9D",
      "26:9A",
      "26:93",
      "26:8F",
      "26:8E",
      "26:89",
      "26:83",
      "24:C4",
      "24:BF",
      "24:B2",
      "24:A2",
      "24:9C",
      "24:8E",
      "24:8C",
      "24:8A",
      "21:75",
      "21:64",
      "21:5B",
      "21:4F",
      "21:3E",
      "21:39",
      "21:1F",
      "1F:7D",
      "1F:78",
      "1F:6C",
      "1F:5B",
      "1F:59",
      "1F:54",
      "1F:42",
      "1F:2F",
      "1F:17",
      "1F:06",
      "1D:71",
      "1D:6E",
      "1D:6A",
      "1D:52",
      "1D:25",
      "1D:1C",
      "1A:67",
      "1A:5A",
      "1A:56",
      "1A:4B",
      "1A:3C",
      "1A:30",
      "1A:24",
      "1A:0B",
      "1A:04",
      "1A:02",
      "17:EC",
      "17:DD",
      "17:D1",
      "17:C1",
      "17:B9",
      "17:B7",
      "17:A6",
      "17:A2",
      "17:94"
    ],
    "DA": [
      "1F:B3",
      "1F:B0",
      "1F:85",
      "1C:FA",
      "1C:EE",
      "1C:E9",
      "1C:E2",
      "1C:DE",
      "1C:DA",
      "1C:D8",
      "1C:BD",
      "1C:BA",
      "1C:A8",
      "1C:A7",
      "1C:A1",
      "1C:9C",
      "1C:86",
      "1A:7C",
      "1A:66",
      "1A:50",
      "1A:33",
      "1A:13",
      "1A:10",
      "17:F7",
      "17:F3",
      "17:F0",
      "17:D5",
      "17:D0",
      "17:B8",
      "17:AE",
      "17:AB",
      "17:84",
      "15:75",
      "15:49",
      "15:33",
      "15:2C",
      "15:07",
      "13:7F",
      "13:7E",
      "13:77",
      "13:6C",
      "13:67",
      "13:64",
      "13:51",
      "13:4E",
      "13:3A",
      "13:36",
      "13:2A",
      "13:26",
      "13:11",
      "0F:FB",
      "0F:F8",
      "0F:F5",
      "0F:D0",
      "0F:CC",
      "0F:C8",
      "0F:B9",
      "0F:AB",
      "0F:92",
      "0C:EE",
      "0C:DE",
      "0C:DB",
      "0C:D9",
      "0C:D8",
      "0C:D2",
      "0C:CA",
      "0C:C1",
      "0C:B1",
      "09:56",
      "09:3E",
      "09:32",
      "09:25",
      "09:08",
      "06:FC",
      "06:F1",
      "06:E4",
      "06:C1",
      "06:97",
      "06:8F",
      "02:F6",
      "02:EF",
      "02:DD",
      "02:BB",
      "02:A4",
      "02:9A",
      "00:72",
      "00:71",
      "00:5C",
      "00:56",
      "00:4E",
      "00:2C",
      "00:27",
      "00:22"
    ],
    "D9": [
      "47:E9",
      "47:A2",
      "45:DF",
      "43:3C",
      "40:7E",
      "40:55",
      "40:20",
      "40:16",
      "40:01",
      "3D:77",
      "3D:74",
      "3D:69",
      "3D:42",
      "3D:0B",
      "3D:07",
      "3A:FF",
      "3A:F5",
      "3A:E5",
      "3A:DD",
      "3A:CB",
      "37:22",
      "30:6D",
      "30:6A",
      "30:44",
      "2D:7C",
      "2D:65",
      "2D:33",
      "2D:2D",
      "2D:0B",
      "2D:0A",
      "2D:08",
      "2A:7D",
      "2A:72",
      "2A:65",
      "2A:61",
      "2A:3D"
    ],
  },
  "A8:1B:6A": {
    "7C": [
      "E5:26",
      "E5:16",
      "E5:11",
      "DF:33",
      "DC:A8"
    ]
  },
  "98:7B:F3": {
    "9B": [
      "D6:DB",
      "D6:C3",
      "D6:AE",
      "D6:AB",
      "D6:A5",
      "D6:A4",
      "D6:9B",
      "D6:93",
      "D6:90",
      "D6:8A",
      "D6:88",
      "D6:81",
      "D3:FF",
      "D3:FC",
      "D3:F4",
      "D3:EF",
      "D3:ED",
      "D3:EB",
      "D3:E9",
      "D3:DD",
      "D3:D7",
      "D3:D1",
      "D3:CB",
      "D3:C2",
      "D3:C1",
      "D3:BF",
      "D3:BA",
      "D3:B8",
      "D3:B1",
      "D3:A1",
      "D3:A0",
      "D3:9E",
      "D3:9B",
      "D3:8A",
      "D0:7F",
      "D0:7D",
      "D0:58",
      "D0:57",
      "D0:53",
      "D0:42",
      "D0:27",
      "D0:24",
      "D0:16",
      "D0:0B",
      "D0:04",
      "CD:E8",
      "CD:E0",
      "CD:DF",
      "CD:DB",
      "CD:D4",
      "CD:C3",
      "CD:BF",
      "CD:97",
      "CB:7B",
      "CB:50",
      "C8:B1",
      "C8:A4",
      "C8:A2",
      "C8:8B",
      "90:2A",
      "90:22",
      "90:1D",
      "8E:61",
      "8E:5C",
      "8E:51",
      "8E:50",
      "8E:4C",
      "8E:2B",
      "8E:25",
      "8E:21",
      "8E:1F",
      "8E:15",
      "8E:12",
      "8E:11",
      "8E:01",
      "8D:54",
      "8D:2F",
      "8D:01",
      "8A:5C",
      "8A:43",
      "67:CF",
      "67:CB",
      "67:C4",
      "67:C0",
      "67:BD",
      "67:B4",
      "67:AB",
      "67:AA",
      "67:A9",
      "67:A1",
      "67:86",
      "65:FC",
      "65:F4",
      "65:E9",
      "65:D8",
      "65:C8",
      "65:C7",
      "65:C6",
      "65:C4",
      "65:BD",
      "65:B8",
      "65:AC",
      "65:AA",
      "65:A5",
      "65:9F",
      "65:9D",
      "65:96",
      "65:95",
      "65:93",
      "65:84",
      "62:E9",
      "62:DC",
      "62:D5",
      "62:D1",
      "62:AC",
      "62:A7",
      "60:FC",
      "60:EA",
      "60:E9",
      "60:D3",
      "60:D1",
      "60:AC",
      "60:A7",
      "60:9A",
      "60:97",
      "60:91",
      "60:89",
      "60:86",
      "60:85",
      "60:81",
      "5F:FA",
      "5F:F3",
      "5F:D2",
      "5F:C6",
      "5F:BE",
      "5F:B8",
      "5F:A4",
      "5F:A0",
      "5F:92",
      "5F:83",
      "5C:66",
      "5C:60",
      "5C:4D",
      "5C:4C",
      "5C:4A",
      "5C:45",
      "5C:44",
      "5C:40",
      "5C:3B",
      "5C:37",
      "5C:32",
      "5C:2B",
      "5C:29",
      "5C:22",
      "5C:1C",
      "5C:18",
      "5C:14",
      "5C:05",
      "5A:74",
      "5A:70",
      "5A:64",
      "5A:57",
      "5A:31",
      "5A:17",
      "5A:0B",
      "57:FF",
      "57:FD",
      "57:FA",
      "57:F2",
      "57:EF",
      "57:E9",
      "57:DA",
      "57:B3",
      "57:AE",
      "57:A4",
      "57:A0",
      "57:9C",
      "57:94",
      "57:8C",
      "55:F0",
      "55:E9",
      "55:DC",
      "55:D0",
      "55:CB",
      "55:B9",
      "55:A1",
      "55:9B",
      "55:87",
      "55:82",
      "51:FA",
      "51:F8",
      "51:F3",
      "51:EF",
      "51:E8",
      "51:E2",
      "51:E1",
      "51:DD",
      "51:CB",
      "51:BE",
      "51:B6",
      "51:A6",
      "51:A2",
      "51:8C",
      "51:89",
      "51:88",
      "51:85",
      "4E:F9",
      "4E:F7",
      "4E:F0",
      "4E:D7",
      "4E:CB",
      "4E:C7",
      "4E:C1",
      "4E:BD",
      "4E:98",
      "4E:91",
      "14:02",
      "0F:FE",
      "0F:ED",
      "0F:E1",
      "0F:D2",
      "0F:CF",
      "0F:CE",
      "0F:BE",
      "0F:B1",
      "0F:AC",
      "0F:AA",
      "0F:A3",
      "0F:97",
      "0F:93",
      "0B:6A",
      "0B:69"
    ],
    "9A": [
      "ED:8E",
      "EB:77",
      "EB:5A",
      "EB:57",
      "EB:53",
      "EB:52",
      "EB:4E",
      "EB:4B",
      "EB:36",
      "EB:34",
      "EB:2B",
      "EB:22",
      "EB:20",
      "EB:18",
      "EB:11",
      "EB:0F",
      "EB:07",
      "EB:06",
      "EB:01",
      "E8:7F",
      "E8:7B",
      "E8:71",
      "E8:65",
      "E8:59",
      "E8:52",
      "E8:4F",
      "E8:36",
      "E8:33",
      "E8:28",
      "E8:0E",
      "E2:FB",
      "E2:D8",
      "E2:D6",
      "E2:8C",
      "E2:8A",
      "DF:69",
      "DF:4B",
      "DF:4A",
      "DF:48",
      "DF:40",
      "DF:38",
      "DF:27",
      "DF:1E",
      "DF:12",
      "DD:74",
      "DD:73",
      "DD:70",
      "DD:6A",
      "DD:57",
      "DD:53",
      "DD:39",
      "DD:37",
      "DD:36",
      "DD:19",
      "DD:16",
      "DA:79",
      "DA:75",
      "DA:73",
      "DA:6F",
      "DA:69",
      "DA:5F",
      "DA:59",
      "DA:51",
      "DA:4D",
      "DA:46",
      "DA:44",
      "DA:2B",
      "DA:1C",
      "DA:06",
      "DA:01",
      "D8:59",
      "D8:4A",
      "D8:30",
      "D8:25",
      "D8:1E",
      "D6:6D",
      "D6:60",
      "D6:5C",
      "D6:51",
      "D6:38",
      "D6:34",
      "D6:33",
      "D6:30",
      "D6:2D",
      "D6:2B",
      "D6:1A",
      "D6:12",
      "D3:7D",
      "D3:78",
      "D3:76",
      "D3:67",
      "D3:50",
      "D3:4E",
      "D3:31",
      "D3:1C",
      "D3:12",
      "D3:0A",
      "D3:08",
      "D0:F6",
      "D0:F2",
      "D0:F0",
      "D0:AD",
      "CD:FD",
      "CD:FB",
      "CD:F6",
      "CD:F5",
      "CD:E2",
      "CD:E1",
      "CD:D9",
      "CD:D5",
      "CD:CB",
      "CD:B4",
      "CD:A2",
      "CD:82",
      "CB:E4",
      "CB:E1",
      "CB:BF",
      "CB:B5",
      "CB:AE"
    ],
    "98": [
      "13:D9",
      "13:CD",
      "13:A1",
      "11:73",
      "11:67",
      "11:47",
      "11:3E",
      "11:3A",
      "11:31",
      "11:20",
      "11:0D",
      "0F:67",
      "0F:54",
      "0F:51",
      "0F:4C",
      "0F:4A",
      "0F:41",
      "0F:31",
      "0F:29",
      "0F:1B",
      "0F:11",
      "0F:0D",
      "0F:0C",
      "0F:09",
      "0F:04",
      "0F:00",
      "0C:F7",
      "0C:F4"
    ],
    "97": [
      "FF:F6",
      "FF:EF",
      "FF:CF",
      "FF:CB",
      "FF:BD",
      "FF:B1",
      "FF:90",
      "FF:84",
      "FF:83",
      "FE:7D",
      "FE:6F",
      "FE:68",
      "FE:64",
      "FE:61",
      "FE:53",
      "FE:4A",
      "FE:2B",
      "FE:28",
      "FE:26",
      "FE:22",
      "FE:1C",
      "FE:0B",
      "FE:08",
      "FE:06",
      "FE:04",
      "FC:FA",
      "FC:F6",
      "FC:E1",
      "FC:D7",
      "FC:D0",
      "FC:C5",
      "FC:BE",
      "FC:B5",
      "FC:A9",
      "FC:A8",
      "FC:A5",
      "FC:92",
      "FC:89",
      "FC:85",
      "FB:74",
      "FB:70",
      "FB:6C",
      "FB:65",
      "FB:2A",
      "FB:18",
      "FA:6B",
      "F8:A6",
      "F8:9C",
      "F6:F2",
      "F6:F0",
      "F6:BD",
      "F6:B4",
      "F6:9E",
      "F5:4A",
      "F5:42",
      "F5:33",
      "F5:28",
      "F3:EF",
      "F3:D9",
      "F3:D1",
      "F3:BA",
      "F3:B3",
      "F3:AF",
      "F3:8F",
      "F3:88",
      "E7:62",
      "E7:3E",
      "E7:1E",
      "E7:0B",
      "E4:6A",
      "E4:60",
      "E4:5E",
      "E4:41",
      "E4:27",
      "E4:1A",
      "E4:02",
      "E3:D3",
      "E3:C2",
      "E3:B7",
      "E3:B0",
      "E3:95",
      "E1:6F",
      "E1:69",
      "E1:5B",
      "E1:3F",
      "E1:2F",
      "E1:04",
      "DF:7F",
      "DF:71",
      "DF:5F",
      "DF:3C",
      "DF:3A",
      "DF:38",
      "DF:01",
      "A9:1A",
      "A7:61",
      "A7:2A",
      "A7:28",
      "A4:E6",
      "A4:DA",
      "A4:B9",
      "A4:9A",
      "A4:91",
      "A3:6F",
      "A3:66",
      "A3:64",
      "A3:63",
      "A3:41",
      "A3:33",
      "A3:23",
      "A3:13",
      "A3:10",
      "A0:7B",
      "A0:60",
      "A0:37",
      "A0:15",
      "A0:0B",
      "9D:F9",
      "9D:E7",
      "9D:E5",
      "9D:E3",
      "9D:CD",
      "9D:B4",
      "9D:AD",
      "9D:9D",
      "9D:9C",
      "9D:9A",
      "9D:8D",
      "91:F9",
      "91:F6",
      "91:E0",
      "91:DA",
      "91:BB"
    ],
    "13": [
      "31:29",
      "31:1C",
      "31:04",
      "2F:5C",
      "2F:4F",
      "2F:49",
      "2F:46",
      "2F:3D",
      "2F:2D",
      "2F:1E",
      "2F:0E",
      "2F:0C",
      "2F:09",
      "2F:01",
      "2C:74",
      "2C:72",
      "2C:71",
      "2C:6E",
      "2C:69",
      "2C:5F",
      "2C:52",
      "2C:4E",
      "2C:1A",
      "2C:11",
      "2C:09",
      "29:F5",
      "29:F4",
      "29:EE",
      "29:E0",
      "29:DF",
      "29:D8",
      "29:C8",
      "29:B7",
      "29:B4",
      "29:B0",
      "29:A6",
      "29:A4",
      "29:A2",
      "29:9E",
      "29:9A",
      "29:98",
      "29:95",
      "29:8C",
      "28:6D",
      "28:5D",
      "28:58",
      "28:55",
      "28:4F",
      "28:48",
      "28:45",
      "28:3B",
      "28:36",
      "28:30",
      "28:2F",
      "28:2D",
      "28:25",
      "28:1A",
      "28:17",
      "28:14",
      "28:13",
      "28:00",
      "25:75",
      "25:68",
      "25:5C",
      "25:5A",
      "25:59",
      "25:56",
      "25:50",
      "25:4D",
      "25:44",
      "25:14",
      "23:26",
      "23:02",
      "21:EE",
      "21:E8",
      "21:E5",
      "21:D6",
      "21:CF",
      "21:C5",
      "21:BD",
      "21:B7",
      "21:B4",
      "21:8E",
      "21:87",
      "21:86",
      "1F:69",
      "1F:58",
      "1F:4E",
      "1F:1E",
      "1F:0B",
      "1F:08",
      "1F:00",
      "1C:F2",
      "1C:D7",
      "1C:CE",
      "1C:C9",
      "1C:B9",
      "1C:B7",
      "1C:AC",
      "1C:A3",
      "1C:9C",
      "1C:99",
      "1C:97",
      "1C:96",
      "1C:94",
      "1C:8F",
      "1C:89",
      "1C:80",
      "1A:7B",
      "1A:72",
      "1A:70",
      "1A:60",
      "1A:56",
      "1A:4B",
      "1A:26",
      "17:74",
      "17:60",
      "17:5B",
      "17:4B",
      "17:45",
      "17:3D",
      "17:34",
      "17:2F",
      "17:29",
      "17:1D",
      "17:16",
      "17:12",
      "17:0D",
      "17:0B",
      "17:03",
      "17:02",
      "13:FC",
      "13:FA",
      "13:F1",
      "13:EB",
      "13:C5",
      "13:BB",
      "13:BA",
      "13:B8"
    ],
    "12": [
      "5F:C6",
      "5F:B1",
      "1B:57",
      "1B:52",
      "1B:4D",
      "1B:43",
      "1B:40",
      "1B:3C",
      "1B:1A",
      "1B:14",
      "1B:10",
      "1B:08",
      "19:7D",
      "19:71",
      "19:5F",
      "19:5A",
      "19:47",
      "19:35",
      "19:33",
      "19:32",
      "19:30",
      "19:28",
      "19:22",
      "19:21",
      "19:14",
      "19:0C",
      "19:03",
      "17:7F",
      "17:74",
      "17:72",
      "17:71",
      "17:6E",
      "17:6B",
      "17:68",
      "17:66",
      "17:51",
      "17:4D",
      "17:41",
      "17:37",
      "17:32",
      "17:0E",
      "15:E2",
      "15:D5",
      "15:C8",
      "15:C5",
      "15:B4",
      "15:AC",
      "15:A9",
      "15:A4",
      "15:89",
      "13:70",
      "13:6F",
      "13:69",
      "13:67",
      "13:57",
      "13:51",
      "13:3E",
      "13:34",
      "13:1B",
      "13:1A",
      "11:7E",
      "11:71",
      "11:57",
      "11:25",
      "0E:F8",
      "0E:E0",
      "0E:DB",
      "0E:C2",
      "0E:C0",
      "0E:B1",
      "0E:98",
      "0E:94",
      "0E:8C",
      "0E:8A",
      "0D:7A",
      "0D:6A",
      "0D:63",
      "0D:56",
      "0D:4A",
      "0D:49",
      "0D:45",
      "0D:3D",
      "0D:35",
      "0D:2E",
      "0D:2B",
      "0D:1A",
      "0A:E9",
      "0A:E4",
      "0A:D8",
      "0A:C1",
      "0A:A2",
      "0A:9D",
      "0A:9B",
      "0A:8A",
      "0A:83",
      "07:F6",
      "07:F3",
      "07:E9",
      "07:DC",
      "07:D3",
      "07:CA",
      "07:C0",
      "07:BF",
      "07:A2",
      "07:9F",
      "07:9C",
      "07:98",
      "07:8E",
      "07:85",
      "05:FF",
      "05:E5",
      "05:E1",
      "05:D3",
      "05:CA",
      "05:C7",
      "05:C3",
      "05:94",
      "05:8A"
    ],
    "0F": [
      "B7:88",
      "B5:EA",
      "B5:E4",
      "B2:94",
      "B2:8B",
      "AF:F2",
      "A7:33",
      "A4:DA",
      "A2:C0",
      "9D:D0"
    ]
  },
  "5C:31:3E": {
    "06": [
      "7D:05",
      "7B:DC",
      "7A:73",
      "7A:06",
      "7A:05",
      "79:5B",
      "79:39",
      "77:31",
      "77:12",
      "77:11",
      "77:0C",
      "76:79",
      "76:76",
      "76:6A",
      "73:68",
      "73:5A",
      "73:40",
      "73:3A",
      "73:1B",
      "72:74",
      "72:32",
      "6D:75",
      "6D:4A"
    ]
  }
};
