import React, { Component } from 'react';
import './AlarmsList.scss';
import toneImage1 from '../../images/Microphone/mic_1.png';
import toneImage2 from '../../images/Microphone/mic_2.png';
import toneImage3 from '../../images/Microphone/mic_3.png';
import toneImage4 from '../../images/Microphone/mic_4.png';
import toneImage5 from '../../images/Microphone/mic_5.png';
import toneImage6 from '../../images/Microphone/mic_6.png';
import toneImage7 from '../../images/Microphone/mic_7.png';
import toneImage8 from '../../images/Microphone/mic_8.png';
import toneImage9 from '../../images/Microphone/mic_9.png';
import toneImage10 from '../../images/Microphone/mic_10.png';

class ToneImage extends Component {
  constructor(props) {
    super(props);
    this.calculateValueOfImage = this.calculateValueOfImage.bind(this);
    this.getImage = this.getImage.bind(this);
  }

  calculateValueOfImage(vibration_condition) {
    if (!vibration_condition || vibration_condition === 0) {
      return 1;
    } else if (vibration_condition <= (1.0 / 3)) {
      return Math.ceil((vibration_condition / (1.0 / 3) * 3)) + 1;
    } else if (vibration_condition <= (2.0 / 3)) {
      return Math.ceil(((vibration_condition - (1.0 / 3)) / (1.0 / 3) * 3)) + 4;
    } else {
      return Math.ceil(((vibration_condition - (2.0 / 3)) / (1.0 / 3) * 3)) + 7;
    }
  }

  getImage(calculatedValue) {
    switch (calculatedValue) {
      case 1:
        return toneImage1;
      case 2:
        return toneImage2;
      case 3:
        return toneImage3;
      case 4:
        return toneImage4;
      case 5:
        return toneImage5;
      case 6:
        return toneImage6;
      case 7:
        return toneImage7;
      case 8:
        return toneImage8;
      case 9:
        return toneImage9;
      case 10:
        return toneImage10;
      default:
        return toneImage1;
    }
  }

  render() {
    const { vibration_condition } = this.props;

    if ( isNaN(vibration_condition) ) {
      return "N/A";
    } else {
      const calculatedValue = this.calculateValueOfImage(vibration_condition);
      return (
        <img src={this.getImage(calculatedValue)} alt={vibration_condition} id="toneImage" />
      );
    }
  }

}

export default ToneImage;
