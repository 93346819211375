import React, { Fragment, useEffect, useCallback } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { IoIosWarning } from 'react-icons/io';

import AppNotificationBar from './AppNotificationBar';
import './accountStatus.scss';

import { useComponentViewTracking } from '../modules/app/hooks';

import { fetchOrganisationTags } from '../modules/organisation/actions';
import {
  getOrganisationId,
  getOrganisationAccountPaymentStatus,
} from '../modules/organisation/selectors';
import { getUserId } from '../modules/user/selectors';
import ContactSupportLink from './ContactSupportLink';

const iconStyle = { verticalAlign: '-27%' };
const tagCheckPeriodMs = 1000 * 60 * 5; // 5 mins

function LearnMore() {
  return (
    <a
      className="learn-more"
      href="https://learn.movus.com.au/knowledge/billing-faq"
      rel="noopener noreferrer"
      target="_blank"
    >
      Learn more
    </a>
  );
}

function AccountStatus({
  floating,
  userId,
  organisationId,
  accountPaymentStatus,
  fetchOrganisationTags,
  show,
  setShow,
}) {

  // only track if an accountPaymentStatus is defined and shown
  // also, only track floating components as there should only be one per app
  useComponentViewTracking(`Account Status Warning`, !floating && accountPaymentStatus && show && 'userId', {
    userId,
    accountPaymentStatus,
  });

  // check organisation tags regularly
  useEffect(() => {
    if (organisationId) {
      // define check loop with a timeout
      let timeout;
      const checkTags = () => {
        fetchOrganisationTags({ id: organisationId });
        timeout = setTimeout(checkTags, tagCheckPeriodMs);
      };
      // start loop
      checkTags();
      // cleanup the timeout
      return () => clearTimeout(timeout);
    }
  }, [organisationId]);

  const close = useCallback(() => setShow(false), []);
  // when organisation changes or the account status changes
  // ensure the account status notification is re-opened
  useEffect(() => {
    if (setShow) {
      setShow(true);
    }
  }, [organisationId, accountPaymentStatus]);

  return (
    <Fragment>
      {accountPaymentStatus === 'due_soon' && show && (
        <AppNotificationBar
          floating={floating}
          className={[
            'account-payment',
            'account-payment--warning',
            'account-payment__notification-bar',
          ].join(' ')}
          dismissible
          onClose={close}
        >
          <IoIosWarning size="1.6em" style={iconStyle} />
          EXPIRING SUBSCRIPTION REMINDER:
          A subscription on your account is due for renewal within the next few weeks.
          Please make arrangements to ensure your account remains active. <LearnMore />.
        </AppNotificationBar>
      )}
      {accountPaymentStatus === 'due_very_soon' && (
        <AppNotificationBar
          floating={floating}
          className={[
            'account-payment',
            'account-payment--warning',
            'account-payment__notification-bar',
          ].join(' ')}
        >
          <IoIosWarning size="1.6em" style={iconStyle} />
          SUBSCRIPTION EXPIRY IMMINENT:
          A subscription on your account will expire within the next few days.
          Please make arrangements to ensure your account is not suspended
          &amp; equipment failures are not missed. <LearnMore />.
        </AppNotificationBar>
      )}
      {accountPaymentStatus === 'overdue' && (
        <AppNotificationBar
          floating={floating}
          className={[
            'account-payment',
            'account-payment--danger',
            'account-payment__notification-bar',
          ].join(' ')}
        >
          <IoIosWarning size="1.6em" style={iconStyle} />
          EXPIRED SUBSCRIPTION:
          A Subscription on your account has expired and no Purchase Order
          or Payment has been received.
          Your account will soon be suspended.
          Please act now to ensure no condition alerts are missed. <LearnMore />.
        </AppNotificationBar>
      )}
      {accountPaymentStatus === 'suspended' && !floating && (
        <Modal
          className={[
            'account-payment',
            'account-payment--danger',
            'account-payment__modal',
          ].join(' ')}
          // vertically center
          centered
          // always show
          show
          // never hide
          onHide={useCallback(() => {}, [])}
        >
          <Modal.Header>
            <h2 className="mb-0">
              <IoIosWarning size="1.4em" style={iconStyle} />
              <span> Account suspended</span>
            </h2>
          </Modal.Header>
          <Modal.Body>
            <p>
              Your account has been suspended due to an overdue subscription.
              No Purchase Order or Payment has been received and the subscription
              expired more than a week ago.
            </p>
            <p>
              Access to the MachineCloud Dashboard has been suspended for all users
              within your organisation,
              and condition alerts will no longer be issued for any assets,
              which may result in missed failures and potential downtime.
            </p>
            <p>
              All FitMachines are still collecting data,
              which will be accessible once this suspension is removed.
              Please contact us urgently to continue using
              the FitMachine service. <LearnMore />.
            </p>
            <p>
              <ContactSupportLink
                subject="Account suspended"
                className="btn btn-light btn-lg mt-2"
              >
                Contact support
              </ContactSupportLink>
            </p>
          </Modal.Body>
        </Modal>
      )}
    </Fragment>
  );
}

const mapStateToProps = state => {
  return {
    userId: getUserId(state),
    organisationId: getOrganisationId(state),
    accountPaymentStatus: getOrganisationAccountPaymentStatus(state),
  };
};
const mapDispatchToProps = { fetchOrganisationTags };

export default connect(mapStateToProps, mapDispatchToProps)(AccountStatus);
