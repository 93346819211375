import React, { Component } from 'react';
import { Image } from 'react-bootstrap';
import padlock from '../images/padlock.png';

export default class PadLock extends Component {
  render() {
    return (
      <Image src={padlock} style={{ height: "1.4em" }} title="Power Rating for equipment is missing"/>
    );
  }
};
