
import React, { Fragment, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Row} from 'react-bootstrap';

import withNavigationDeviceProps from '../components/withNavigationDeviceProps';

import Title from '../../../components/Title';
import EquipmentPageLayout from '../components/EquipmentPageLayout';
import ActivityList from '../../app/components/ActivityList';
import DeviceAlarmCard from '../components/DeviceAlarmCard';

import { fetchDeviceAlarms } from '../actions';
import {
  getDevice,
  getDeviceAlarms,
  getDeviceAlarmListState,
} from '../selectors';
import {
  sortAlarmsDefault,
} from '../../alarm/selectors';

const ActivityTitle = withNavigationDeviceProps(
  connect((state, { deviceId }) => {
    return getDeviceAlarmListState(state, deviceId) || {};
  })(Title)
);

function EquipmentActivity({
  deviceId,
  device = {},
  alarms = [],
  fetchDeviceAlarms,
}) {

  // ensure that the refresh handler calls the specific device in question
  const getAlarms = useCallback(() => {
    // fetch the alarms if the device is not archived
    if (deviceId && !device.archived) {
      fetchDeviceAlarms(device || { id: deviceId });
    }
  }, [deviceId, device.archived]);

  // when a change is found, fetch the alarms
  useEffect(getAlarms, [getAlarms]);

  // return archived status message
  if (device.archived) {
    return (
      <Fragment>
        <p>
          Activity is not currently available for archived equipment.
        </p>
        <p>
          You can vote for this feature on the <Link to="/whats-new">What's New page</Link>.
        </p>
      </Fragment>
    );
  }

  const sortedAlarms = alarms
    // sort by default for alarms
    .sort(sortAlarmsDefault);

  return (
    <Row>
      <Col xs={12}>
        <ActivityList
          title={<ActivityTitle title="Activity" />}
        >
          {sortedAlarms.map(alarm => {
            return (
              <DeviceAlarmCard
                key={alarm.id}
                alarmId={alarm.id}
              />
            );
          })}
        </ActivityList>
      </Col>
    </Row>
  );
}

const mapStateToProps = (state, { deviceId }) => {
  return {
    device: getDevice(state, deviceId),
    alarms: getDeviceAlarms(state, deviceId),
  };
};
const mapDispatchToProps = { fetchDeviceAlarms };

const ConnectedEquipmentActivity = withNavigationDeviceProps(
  connect(mapStateToProps, mapDispatchToProps)(EquipmentActivity)
);

export default function EquipmentActivityPage() {
  return (
    <EquipmentPageLayout>
      <div className="my-4">
        <ConnectedEquipmentActivity />
      </div>
    </EquipmentPageLayout>
  );
}
