import React, { Fragment, useState, useCallback, useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Button, OverlayTrigger, Tooltip, Dropdown, Row, Col } from 'react-bootstrap';
import { IoIosResize, IoIosMove, IoIosArrowRoundForward, IoIosCog } from 'react-icons/io';

import useBaseChart, {
  defaultOptions,
  getSymbolOfIcon,
  convertToTimestamp,
} from './BaseChart.js';
import BaseChartEditToolbar from './BaseChartEditToolbar.js';
import { popperConfigUpdateOnChange } from './BaseChartToolbar.js';
import RelearnModalForm from '../../modules/equipment/components/RelearnModalForm.js';

import { FaSignal } from 'react-icons/fa';
import { getFormattedValue } from '../values/utils/displayUtils.js';
import {
  getUserDisplayPreference as getUserTemperatureDisplayPreference,
} from '../values/Temperature.js';
import {
  getUserDisplayPreference as getUserRmsDisplayPreference,
} from '../values/Rms.js';

import { useComponentViewTracking } from '../../modules/app/hooks.js';

import { recalibrateAndRefetch } from '../../modules/equipment/actions.js';
import { getDevice } from '../../modules/equipment/selectors.js';

import {
  getOrganisationRmsAvailablePreference,
  getOrganisationMm2AvailablePreference,
  getOrganisationRmsTitle,
  getOrganisationMm2Title,
} from '../../modules/organisation/selectors';

const IoIosResizePath = getSymbolOfIcon(IoIosResize);
const IoIosMovePath = getSymbolOfIcon(IoIosMove);
const IoIosArrowRoundForwardPath = getSymbolOfIcon(IoIosArrowRoundForward);

const colours = {
  black: '#434348', // black
  blue: '#7cb5ec', // blue
  blueLight: '#d6e9f9', // blue but less
  blueHeavy: '#2667a9', // blue but more
  grey: '#efefef', // grey
  darkGrey: '#cccccc', // slightly darker grey
  red: '#ff0000', // red
};

const whitePointBackground = {
  symbol: 'circle',
  itemStyle: {
    color: 'white',
  },
  label: {
    formatter: '{normal|}', // empty rich text to enable width and height
    rich: {
      normal: {
        backgroundColor: 'white',
        height: 32,
        width: 32,
        borderRadius: 32,
        borderWidth: 0.75,
        borderColor: colours.red,
      }
    },
  },
  silent: true,
};

// times in ms
const oneHour = 1000 * 60 * 60;
const oneDay = oneHour * 24;
const oneWeek = oneDay * 7;

function clipValue(value, [min, max]) {
  // return value between min and max, or min, or max
  return value > max ? max : value < min ? min : value;
}

function getInitialOptions(options=defaultOptions) {
  return {
    ...options,
    yAxis: [
      {
        id: 'temperature',
        type: 'value',
        name: 'Temperature - Equipment',
        nameLocation: 'center',
        nameRotate: 90,
        nameGap: 42,
        nameTextStyle: {
          color: colours.black,
        },
      },
      {
        id: 'rms',
        type: 'value',
        name: 'RMS',
        nameLocation: 'center',
        nameRotate: -90,
        nameGap: 37,
        nameTextStyle: {
          color: colours.blue,
        },
      },
      {
        id: 'mm2',
        show: false, // off by default
        type: 'value',
        name: 'RMS2',
        nameLocation: 'center',
        nameRotate: -90,
        nameGap: 37,
        nameTextStyle: {
          color: colours.blueHeavy,
        },
      },
      {
        id: 'running_cutoff',
        type: 'value',
        name: 'Running Cut-Off',
        nameLocation: 'center',
        nameRotate: -90,
        nameGap: 55,
        nameTextStyle: {
          color: colours.red,
        },
        axisLabel: {
          show: false,
        },
      },
    ],
    series: [
      {
        id: 'temperature',
        yAxisId: 'temperature',
        name: 'Temperature',
        type: 'line',
        lineStyle: { width: 1.5 },
        smooth: 0.25,
        smoothMonotone: 'x',
        showSymbol: false,
        z: 2,
      },
      {
        id: 'rms',
        yAxisId: 'rms',
        name: 'RMS',
        type: 'line',
        lineStyle: { width: 1.5 },
        smooth: 0.25,
        smoothMonotone: 'x',
        showSymbol: false,
        z: 1,
        yAxisIndex: 1,
      },
      {
        id: 'mm2',
        yAxisId: 'mm2',
        name: 'RMS2',
        type: 'line',
        lineStyle: { width: 1.5 },
        smooth: 0.25,
        smoothMonotone: 'x',
        showSymbol: false,
        z: 1,
        yAxisIndex: 1,
      },
      {
        id: 'running_cutoff',
        yAxisId: 'running_cutoff',
        name: 'Running Cut-Off',
        type: 'line',
        yAxisIndex: 2,
        markLine: {
          silent: true,
          symbol: 'none',
          lineStyle: {
            color: 'red',
            type: 'solid',
            width: 1,
          },
        },
        // add editable hint
        markPoint: {
          symbol: IoIosResizePath,
          // the resize icon is tilted diagonally, we rotate it back here
          symbolRotate: 45,
          symbolSize: 16,
          itemStyle: {
            color: colours.red,
          },
        },
      },
    ],
    color: [colours.black, colours.blue, colours.blueHeavy, colours.red],
    graphic: {
      elements: [],
    },
  };
}

function MeasuredDataChart({
  deviceId,
  deviceLearningStart,
  deviceRunningCutOff,
  displayTemperature,
  displayRms,
  rmsAvailable,
  mm2Available,
  rmsTitle = '', // by default hide the RMS data series to avoid name clash
  mm2Title = '',
  samples = [],
  recalibrateAndRefetch,
  dateRange,
  setDateRange,
  ...props
}) {

  const [editRunningCutoffMode, setEditRunningCutoffMode] = useState(false);
  const exitEnterRunningCutoffMode = useCallback(() => setEditRunningCutoffMode(false), []);
  const toggleRunningCutoffMode = useCallback(() => {
    setEditRunningCutoffMode(editRunningCutoffMode => !editRunningCutoffMode);
  }, []);

  const [modalRunningCutoff, setModalRunningCutoff] = useState(deviceRunningCutOff);
  useEffect(() => setModalRunningCutoff(deviceRunningCutOff), [deviceRunningCutOff]);

  // reset edit running cut-off value if the edit mode is exited
  useEffect(() => {
    if (!editRunningCutoffMode) {
      setModalRunningCutoff(deviceRunningCutOff);
    }
  }, [deviceRunningCutOff, editRunningCutoffMode]);

  const [editLearningStartMode, setEditLearningStartMode] = useState(false);
  const exitEnterLearningStartMode = useCallback(() => setEditLearningStartMode(false), []);
  const toggleLearningStartMode = useCallback(() => {
    setEditLearningStartMode(editLearningStartMode => !editLearningStartMode);
  }, [setEditLearningStartMode]);

  const [modalLearningStart, setModalLearningStart] = useState(deviceLearningStart);
  useEffect(() => setModalLearningStart(deviceLearningStart), [deviceLearningStart]);

  // reset edit learning start value if the edit mode is exited
  useEffect(() => {
    if (!editLearningStartMode) {
      setModalLearningStart(deviceLearningStart);
    }
  }, [deviceLearningStart, editLearningStartMode]);

  const exitAllEditModes = useCallback(() => {
    setEditRunningCutoffMode(false);
    setEditLearningStartMode(false);
  }, []);

  // track usage
  useComponentViewTracking('Device Chart Edit Running Cutoff', editRunningCutoffMode && 'deviceId', { deviceId });
  useComponentViewTracking('Device Chart Edit Learning Start', editLearningStartMode && 'deviceId', { deviceId });

  // if the learning start mode is started, ensure the chart can see that date value
  useEffect(() => {
    if (editLearningStartMode && modalLearningStart) {
      if (modalLearningStart - oneWeek < dateRange.startTime) {
        setDateRange({ startTime: modalLearningStart - oneWeek });
      }
      else if (modalLearningStart + oneWeek > dateRange.endTime) {
        setDateRange({ endTime: modalLearningStart + oneWeek });
      }
    }
  }, [
    dateRange.startTime,
    dateRange.endTime,
    setDateRange,
    editLearningStartMode,
    modalLearningStart,
  ]);

  const [showRunningCutoffModal, setShowRunningCutoffModal] = useState(false);
  const openRunningCutoffModal = useCallback(() => setShowRunningCutoffModal(true), []);
  const [showLearningStartModal, setShowLearningStartModal] = useState(false);
  const openLearningStartModal = useCallback(() => setShowLearningStartModal(true), []);
  const openAllModals = useCallback(() => {
    setShowRunningCutoffModal(true);
    setShowLearningStartModal(true);
  }, []);

  const closeModal = useCallback(success => {
    // close all modals
    setShowRunningCutoffModal(false);
    setShowLearningStartModal(false);
    // but also exit edit mode if closed with a successful response
    if (success) {
      setEditRunningCutoffMode(false);
      setEditLearningStartMode(false);
    }
  }, []);

  const [BaseChart, {
    getChart,
    useChartUpdateEffect,
    useChartUpdateOnCustomEvent,
  }] = useBaseChart(getInitialOptions);

  const updateSeriesAndUnits = useCallback(chartOptions => {
    return {
      legend: {
        data: [
          { name: 'Temperature', textStyle: { color: colours.black } },
          rmsAvailable && { name: rmsTitle, textStyle: { color: colours.blue } },
          mm2Available && { name: mm2Title, textStyle: { color: colours.blueHeavy } },
          { name: 'Running Cut-Off', textStyle: { color: colours.red } },
        ].filter(v => v),
        selected: {
          // set default option if both displays are available
          ...rmsAvailable && mm2Available && {
            RMS: !mm2Available
          },
          // apply current chart options
          ...chartOptions.legend &&
            chartOptions.legend[0] &&
            chartOptions.legend[0].selected,
        }
      },
      tooltip: {
        formatter: function(series) {
          const temperature = series.find(({ seriesId }) => seriesId === 'temperature');
          const rms = series.find(({ seriesId }) => seriesId === 'rms');
          const mm2 = series.find(({ seriesId }) => seriesId === 'mm2');
          return (
            `${moment(series[0].value[0]).format('LLLL')}${
              '<hr style="margin:2px 0 5px;border-top-color: #ccc;"/>'
            }${
              temperature
                ? `Temperature: ${
                  getFormattedValue(temperature.value[1], displayTemperature)
                }<br/>`
                : ''
            }${
              rms
                ? `RMS: ${
                  getFormattedValue(rms.value[1], displayRms)
                }<br/>`
                : ''
            }${
              mm2 && mm2.value[1]
                ? `${mm2Title}: ${
                  mm2.value[1].toFixed(2)
                }<br/>`
                : ''
            }`
          );
        },
      },
      yAxis: [
        {
          id: 'temperature',
          axisLabel: {
            formatter: value => getFormattedValue(value, {
              ...displayTemperature,
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
              units: displayTemperature.units.trim(),
            }),
          },
        },
        {
          show: !!rmsAvailable,
          id: 'rms',
          name: `RMS (${displayRms.units.trim()})`,
          nameTextStyle: {
            padding: [0, 0, 0, mm2Available ? 90 : 0],
          },
          axisLabel: {
            formatter: value => getFormattedValue(value, {
              ...displayRms,
              // there is only space for two digits here :/ dictated by nameGap
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
              displayUnits: false,
            }),
          },
        },
        {
          show: !!mm2Available,
          id: 'mm2',
          name: mm2Title,
          nameTextStyle: {
            padding: [0, rmsAvailable ? 80 : 0, 0, 0],
          },
          axisLabel: !rmsAvailable ? {
            formatter: value => getFormattedValue(value, {
              // mm2 is in "mm/s" but we don't display that
              // units: ' mm/s',
              digits: 2,
              // there is only space for two digits here :/ dictated by nameGap
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
              displayUnits: false,
            }),
          } : {
            show: false,
          },
        },
      ].filter(Boolean),
      series: [
        {
          id: 'rms',
          name: rmsTitle,
        },
        {
          id: 'mm2',
          name: mm2Title,
        },
      ],
    };
  }, [
    rmsAvailable,
    mm2Available,
    rmsTitle,
    mm2Title,
    ...Object.values(displayRms),
    ...Object.values(displayTemperature),
  ]);

  // update when series and units change
  useChartUpdateEffect(updateSeriesAndUnits);

  const updateSamples = useCallback(() => {
    const minTemp = Math.min(...samples.map(s => s.temperature));
    const maxTemp = Math.max(...samples.map(s => s.temperature));
    const maxRms = Math.max(
      ...samples.map(s => s.rms),
      ...samples.map(s => s.rms2),
      // if no samples, show running cut-off on the chart (but not on top line)
      // or a small non-zero backup value
      (modalRunningCutoff || 0) * 1.1 || 0.1,
    );
    return {
      yAxis: [
        {
          id: 'temperature',
          // allow minimum to go below 0, but default to 0 otherwise
          min: Math.min(0, Math.floor(minTemp)),
          max: Math.ceil(maxTemp),
        },
        rmsAvailable && {
          id: 'rms',
          min: 0,
          max: Math.ceil(maxRms),
        },
        {
          id: 'mm2',
          show: !!mm2Available,
          min: 0,
          max: Math.ceil(maxRms),
        },
        {
          id: 'running_cutoff',
          min: 0,
          max: Math.ceil(maxRms),
        },
      ].filter(v => v),
      series: [
        {
          id: 'temperature',
          yAxisId: 'temperature',
          data: samples.map(sample => [
            convertToTimestamp(sample.sample_time),
            sample.temperature,
          ]),
        },
        {
          id: 'rms',
          yAxisId: 'rms',
          data: !rmsAvailable ? [] : samples.map(sample => [
            convertToTimestamp(sample.sample_time),
            sample.rms,
          ]),
        },
        {
          id: 'mm2',
          data: !mm2Available ? [] : samples.map(sample => [
            convertToTimestamp(sample.sample_time),
            sample.rms2,
          ]),
        },
      ],
    };
  }, [
    rmsAvailable,
    mm2Available,
    // recompute when samples or running cutoff changes
    samples,
    modalRunningCutoff,
  ]);

  // update when samples change
  useChartUpdateEffect(updateSamples);

  function getXMinPoint(chart=getChart()) {
    if (chart) {
      const options = chart.getOption();
      const grid = options && options.grid && options.grid[0];
      if (grid) {
        return grid.left;
      }
    }
  }

  function getXMidPoint(chart=getChart()) {
    if (chart) {
      const options = chart.getOption();
      const grid = options && options.grid && options.grid[0];
      if (grid) {
        // position x directly in the middle of the chart (average of left and right pixel)
        return (grid.left + chart.getWidth() - grid.right) / 2;
      }
    }
  }

  function getXMaxPoint(chart=getChart()) {
    if (chart) {
      const options = chart.getOption();
      const grid = options && options.grid && options.grid[0];
      if (grid) {
        return chart.getWidth() - grid.right;
      }
    }
  }

  function getYMidPoint(chart=getChart()) {
    if (chart) {
      const options = chart.getOption();
      const grid = options && options.grid && options.grid[0];
      if (grid) {
        // position x directly in the middle of the chart (average of left and right pixel)
        return (grid.top + chart.getHeight() - grid.bottom) / 2;
      }
    }
  }

  function getXFromData(dataX, chart=getChart()) {
    if (chart) {
      // position y as the current deviceRunning Cutoff
      return chart.convertToPixel({ seriesId: 'running_cutoff' }, [dataX, 0])[0];
    }
  }

  function getYFromData(dataY, chart=getChart()) {
    if (chart) {
      // position y as the current device Running Cutoff
      return chart.convertToPixel({ seriesId: 'running_cutoff' }, [0, dataY])[1];
    }
  }

  function convertRunningCutoffFromPixel(pixel, chart = getChart()) {
    if (chart) {
      const point = chart.convertFromPixel({ seriesId: 'running_cutoff' }, pixel);
      const runningCutoff = Math.round(100 * point[1])/100;
      // ensure no negative running cutoff values are used
      return runningCutoff > 0 ? runningCutoff : 0;
    }
  }

  function convertLearningStartFromPixel(pixel, chart = getChart()) {
    if (chart) {
      const point = chart.convertFromPixel({ seriesId: 'running_cutoff' }, pixel);
      return Math.round(point[0]);
    }
  }

  const updateRunningCutoff = useCallback(() => {
    return {
      yAxis: [
        {
          id: 'running_cutoff',
          name: `Running Cut-Off${
            modalRunningCutoff >= 0
              ? `: ${getFormattedValue(modalRunningCutoff, {
                ...displayRms,
                // add more significant digits if relevant
                minimumFractionDigits: 1,
                maximumFractionDigits: displayRms.maximumFractionDigits + 3,
              })}`
              : ''
          }`,
        },
      ],
      series: [
        {
          id: 'running_cutoff',
          markLine: {
            data: modalRunningCutoff >= 0 ? [
              editLearningStartMode && [
                { xAxis: modalLearningStart, yAxis: 'min' },
                { xAxis: modalLearningStart, yAxis: 'max' },
              ],
              [
                { xAxis: 'min', yAxis: modalRunningCutoff },
                { xAxis: 'max', yAxis: modalRunningCutoff },
              ],
            ].filter(Boolean) : [],
          },
          // add editable hint
          markPoint: {
            data: [
              // add lowest priority points first for a lower z-index
              ...editRunningCutoffMode && editLearningStartMode ? [
                {
                  coord: [modalLearningStart, modalRunningCutoff],
                  symbol: IoIosMovePath,
                  symbolRotate: 0,
                  symbolSize: 20,
                  ...whitePointBackground,
                },
                {
                  coord: [modalLearningStart, modalRunningCutoff],
                  symbol: IoIosMovePath,
                  symbolRotate: 0,
                  symbolSize: 20,
                },
              ] : [],
              ...editLearningStartMode ? [
                { xAxis: modalLearningStart, y: getYMidPoint(), symbolRotate: -45, ...whitePointBackground },
                { xAxis: modalLearningStart, y: getYMidPoint(), symbolRotate: -45 },
              ] : [],
              ...editRunningCutoffMode ? [
                { x: getXMidPoint(), yAxis: modalRunningCutoff, ...whitePointBackground },
                { x: getXMidPoint(), yAxis: modalRunningCutoff },
              ] : [],
            ].filter(Boolean),
          },
        },
      ],
    };
  }, [
    ...Object.values(displayRms),
    editRunningCutoffMode,
    modalRunningCutoff,
    editLearningStartMode,
    modalLearningStart,
  ]);

  // update when running cut-off changes
  useChartUpdateEffect(updateRunningCutoff);
  // and when chart is resized
  useChartUpdateOnCustomEvent('resize', updateRunningCutoff);

  const updateDraggablePoints = useCallback(() => {
    return {
      graphic: {
        elements: [
          editRunningCutoffMode
            ? {
              id: 'new_running_cutoff',
              type: 'circle',
              position: [getXMidPoint(), getYFromData(modalRunningCutoff)],
              shape: { cx: 0, cy: 0, r: 16 },
              z: 100,
              invisible: true,
              draggable: true,
              ondrag: ({ offsetX, offsetY }) => {
                const chart = getChart();
                // only update if the drag point is still on the graph
                if (chart.containPixel({ seriesId: 'running_cutoff' }, [offsetX, offsetY])) {
                  const newRunningCutOff = convertRunningCutoffFromPixel([offsetX, offsetY]);
                  chart.setOption({
                    yAxis: [{
                      id: 'running_cutoff',
                      name: `Running Cut-Off: ${getFormattedValue(newRunningCutOff, displayRms)}`,
                    }],
                    series: [{
                      id: 'running_cutoff',
                      markLine: {
                        data: [[
                          { x: getXMinPoint(), yAxis: newRunningCutOff },
                          { x: getXMaxPoint(), yAxis: newRunningCutOff },
                        ]],
                      },
                      markPoint: {
                        data: editRunningCutoffMode ? [
                          { x: getXMidPoint(), yAxis: newRunningCutOff, ...whitePointBackground },
                          { x: getXMidPoint(), yAxis: newRunningCutOff },
                        ] : [],
                      },
                    }],
                  });
                }
              },
              ondragend: ({ offsetX, offsetY }) => {
                const chart = getChart();
                // reset the draggable graphic
                if (chart) {
                  // update (retarget) draggable points
                  chart.setOption(updateDraggablePoints());
                  // clip running cut-off value, it should never be negative
                  const newRunningCutOff = clipValue(
                    convertRunningCutoffFromPixel([offsetX, offsetY]),
                    [0, Number.Infinity],
                  );
                  setModalRunningCutoff(newRunningCutOff);
                }
              },
            } : {
              // or remove
              id: 'new_running_cutoff',
              $action: 'remove',
            },
          editLearningStartMode
            ? {
              id: 'new_learning_start',
              type: 'circle',
              position: [getXFromData(modalLearningStart), getYMidPoint()],
              shape: { cx: 0, cy: 0, r: 16 },
              z: 100,
              invisible: true,
              draggable: true,
              ondrag: ({ offsetX, offsetY }) => {
                const chart = getChart();
                // only update if the drag point is still on the graph
                if (chart.containPixel({ seriesId: 'running_cutoff' }, [offsetX, offsetY])) {
                  const newLearningStart = convertLearningStartFromPixel([offsetX, offsetY]);
                  chart.setOption({
                    series: [{
                      id: 'running_cutoff',
                      markLine: {
                        data: [[
                          // learning-start line
                          { xAxis: newLearningStart, yAxis: 'max' },
                          { xAxis: newLearningStart, yAxis: 'min' },
                        ], [
                          // old running-cutoff line
                          { xAxis: newLearningStart, yAxis: modalRunningCutoff },
                          { x: getXMaxPoint(), yAxis: modalRunningCutoff },
                        ]],
                      },
                      markPoint: {
                        data: [
                          {
                            x: offsetX,
                            y: getYMidPoint(),
                            symbolRotate: -45,
                            ...whitePointBackground,
                          },
                          {
                            x: offsetX,
                            y: getYMidPoint(),
                            symbolRotate: -45,
                          },
                        ],
                      },
                    }],
                  });
                }
              },
              ondragend: ({ offsetX, offsetY }) => {
                const chart = getChart();
                // reset the draggable graphic
                if (chart) {
                  // update (retarget) draggable points
                  chart.setOption(updateDraggablePoints());
                  // clip value: value should not be negative or after now
                  const newLearningStart = clipValue(
                    convertLearningStartFromPixel([offsetX, offsetY]),
                    [0, Date.now()]
                  );
                  setModalLearningStart(newLearningStart);
                }
              },
            } : {
              // or remove
              id: 'new_learning_start',
              $action: 'remove',
            },
          editRunningCutoffMode && editLearningStartMode
            ? {
              id: 'new_running_cutoff_and_learning_start',
              type: 'circle',
              position: [getXFromData(modalLearningStart), getYFromData(modalRunningCutoff)],
              shape: { cx: 0, cy: 0, r: 16 },
              z: 100,
              invisible: true,
              draggable: true,
              ondrag: ({ offsetX, offsetY }) => {
                const chart = getChart();
                // only update if the drag point is still on the graph
                if (chart.containPixel({ seriesId: 'running_cutoff' }, [offsetX, offsetY])) {
                  const newRunningCutOff = convertRunningCutoffFromPixel([offsetX, offsetY]);
                  const newLearningStart = convertLearningStartFromPixel([offsetX, offsetY]);
                  chart.setOption({
                    yAxis: [{
                      id: 'running_cutoff',
                      name: `Running Cut-Off: ${getFormattedValue(newRunningCutOff, displayRms)}`,
                    }],
                    series: [{
                      id: 'running_cutoff',
                      markLine: {
                        data: [[
                          // learning-start line
                          { xAxis: newLearningStart, yAxis: 'max' },
                          { xAxis: newLearningStart, yAxis: 'min' },
                        ], [
                          // old running-cutoff line
                          { xAxis: newLearningStart, yAxis: newRunningCutOff },
                          { x: getXMaxPoint(), yAxis: newRunningCutOff },
                        ]],
                      },
                      markPoint: {
                        data: [
                          // x,y-axes point
                          {
                            x: offsetX,
                            y: offsetY,
                            symbol: IoIosArrowRoundForwardPath,
                            symbolRotate: 0,
                            symbolSize: [18, 12],
                            // position the arrow to point towards the cursor
                            symbolOffset: [-12, 0],
                          },
                        ],
                      },
                    }],
                  });
                }
              },
              ondragend: ({ offsetX, offsetY }) => {
                const chart = getChart();
                // reset the draggable graphic
                if (chart) {
                  // update (retarget) draggable points
                  chart.setOption(updateDraggablePoints());
                  // clip running cut-off value, it should never be negative
                  const newRunningCutOff = clipValue(
                    convertRunningCutoffFromPixel([offsetX, offsetY]),
                    [0, Number.Infinity],
                  );
                  setModalRunningCutoff(newRunningCutOff);
                  // clip value: value should not be negative or after now
                  const newLearningStart = clipValue(
                    convertLearningStartFromPixel([offsetX, offsetY]),
                    [0, Date.now()]
                  );
                  setModalLearningStart(newLearningStart);
                }
              },
            } : {
              // or remove
              id: 'new_running_cutoff_and_learning_start',
              $action: 'remove',
            },
        ],
      },
    };
  }, [
    editRunningCutoffMode,
    modalRunningCutoff,
    editLearningStartMode,
    modalLearningStart,
    ...Object.values(displayRms),
  ]);

  // handle updates when values change
  useChartUpdateEffect(updateDraggablePoints);
  // update after samples have changed (the scale of the RMS axis may have changed)
  useChartUpdateEffect(updateDraggablePoints, [samples]);
  // and when chart is resized
  useChartUpdateOnCustomEvent('resize', updateDraggablePoints);
  // on date range change the draggable points will need an update
  // (from the auto-date-range adjust effect earlier in this component)
  // if the learning start mode is started, ensure the chart can see that date value
  useChartUpdateOnCustomEvent('dateRange', updateDraggablePoints);

  return (<>
    <BaseChart
      header={<Fragment><FaSignal /> Measured Data</Fragment>}
      namespace="measured-data"
      deviceId={deviceId}
      samples={samples}
      dateRange={dateRange}
      setDateRange={setDateRange}
      {...props}
      // pass memoised buttons
      toolbarButtons={useMemo(() => [
        <Dropdown>
          <Dropdown.Toggle
            variant="outline-dark"
            size="lg"
          >
            <IoIosCog size="1.3em" title="Edit" />
          </Dropdown.Toggle>
          <Dropdown.Menu
            align="right"
            popperConfig={popperConfigUpdateOnChange}
          >
            <Dropdown.Item
              onClick={toggleRunningCutoffMode}
            >
              {!editRunningCutoffMode ? (
                'Edit'
              ) : (
                'Cancel edit'
              )} running cut-off value
            </Dropdown.Item>
            <Dropdown.Item
              onClick={toggleLearningStartMode}
            >
              {!editLearningStartMode ? (
                'Edit'
              ) : (
                'Cancel edit'
              )} learning start date
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ], [
        editRunningCutoffMode,
        toggleRunningCutoffMode,
        editLearningStartMode,
        toggleLearningStartMode,
      ])}
      AboveChart={useCallback(() => (
        <Fragment>
          {editRunningCutoffMode && (
            <BaseChartEditToolbar className="pt-2 pb-1">
              <Row className="small-gutters d-flex align-items-center">
                <Col className="mb-1" xs="auto">
                Drag the vertical slider to adjust the running cut-off. <a href="https://learn.movus.com.au/knowledge/adjusting-the-running-cut-off" target="_blank" rel="noopener noreferrer">Learn more</a>.
                </Col>
                {/* everything in the following column is right-aligned */}
                <Col xs="auto" className="ml-auto">
                  <Row className="small-gutters d-flex justify-content-end align-items-center">
                    <Col className="mb-1" xs="auto">
                      {getFormattedValue(modalRunningCutoff, displayRms)}
                    </Col>
                    {!editLearningStartMode && (
                      <Col className="mb-1" xs="auto">
                        <Button
                          size="sm"
                          disabled={deviceRunningCutOff === modalRunningCutoff}
                          onClick={openRunningCutoffModal}
                        >
                          Save
                        </Button>
                      </Col>
                    )}
                    {!editLearningStartMode && (
                      <Col className="mb-1" xs="auto">
                        <Button
                          variant="outline-secondary"
                          size="sm"
                          onClick={exitEnterRunningCutoffMode}
                        >
                          Cancel
                        </Button>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </BaseChartEditToolbar>
          )}
          {editLearningStartMode && (
            <BaseChartEditToolbar className="pt-2 pb-1">
              <Row className="small-gutters d-flex align-items-center">
                <Col className="mb-1" xs="auto">
                  Drag the horizontal slider to adjust the learning start date.
                </Col>
                {/* everything in the following column is right-aligned */}
                <Col xs="auto" className="ml-auto">
                  <Row className="small-gutters d-flex justify-content-end align-items-center">
                    <Col className="mb-1" xs="auto">
                      {new Date(modalLearningStart).toLocaleString()}
                    </Col>
                    {!editRunningCutoffMode && (
                      <Col className="mb-1" xs="auto">
                        <Button
                          size="sm"
                          disabled={deviceLearningStart === modalLearningStart}
                          onClick={openLearningStartModal}
                        >
                          Save
                        </Button>
                      </Col>
                    )}
                    {!editRunningCutoffMode && (
                      <Col className="mb-1" xs="auto">
                        <Button
                          variant="outline-secondary"
                          size="sm"
                          onClick={exitEnterLearningStartMode}
                        >
                          Cancel
                        </Button>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </BaseChartEditToolbar>
          )}
          {editRunningCutoffMode && editLearningStartMode && (
            <BaseChartEditToolbar className="pt-2 pb-1">
              <Row className="small-gutters d-flex align-items-center">
                {/* everything in the following column is right-aligned */}
                <Col xs="auto" className="ml-auto">
                  <Row className="small-gutters d-flex justify-content-end align-items-center">
                    <Col className="mb-1" xs="auto">
                      <Button
                        size="sm"
                        disabled={(
                          (deviceRunningCutOff === modalRunningCutoff) &&
                          (deviceLearningStart === modalLearningStart)
                        )}
                        onClick={openAllModals}
                      >
                        Save
                      </Button>
                    </Col>
                    <Col className="mb-1" xs="auto">
                      <Button
                        variant="outline-secondary"
                        size="sm"
                        onClick={exitAllEditModes}
                      >
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </BaseChartEditToolbar>
          )}
        </Fragment>
      ), [
        editRunningCutoffMode,
        deviceRunningCutOff,
        modalRunningCutoff,
        editLearningStartMode,
        deviceLearningStart,
        modalLearningStart,
        ...Object.values(displayRms),
        // shouldn't change
        openRunningCutoffModal,
        openLearningStartModal,
        openAllModals,
        exitEnterRunningCutoffMode,
        exitEnterLearningStartMode,
        exitAllEditModes,
      ])}
    />
    {(showRunningCutoffModal || showLearningStartModal) && (
      <RelearnModalForm
        deviceId={deviceId}
        showPreviousValue={true}
        // add customised recalibration action to refetch RCO value
        recalibrate={recalibrateAndRefetch}
        header="Are you sure you want to restart learning?"
        // pass learning start date only if it is being edited
        {...showRunningCutoffModal && ({
          runningCutoff: modalRunningCutoff,
          body: (<>
            <p>
              Changing the running cutoff from {
                getFormattedValue(deviceRunningCutOff, displayRms)
              } to {
                getFormattedValue(modalRunningCutoff, displayRms)
              }
            </p>
            <p>
              Changing the running cutoff requires the device to relearn.
              {' '}
              <OverlayTrigger
                placement="auto"
                overlay={(
                  <Tooltip>
                    The running status of historical data may have changed.
                  </Tooltip>
                )}
              >
                <Button variant="light" size="sm">
                  Why?
                </Button>
              </OverlayTrigger>
            </p>
            <p>
              By default, MachineCloud will start learning from the same date &amp; time
              this device used previously.
              Learning may take some time, but alarms can still be raised.
              Historical equipment data will remain available after learning completes.
            </p>
          </>),
          confirmText: "Update running cut-off and restart learning",
        })}
        // pass learning start date only if it is being edited
        {...showLearningStartModal && ({
          initialDisplayOption: 'custom',
          learningStart: modalLearningStart,
        })}
        onClose={closeModal}
      />
    )}
  </>);
}

const mapStateToProps = (state, { deviceId }) => {
  const foundDevice = getDevice(state, deviceId);
  return {
    deviceId,
    deviceLearningStart: foundDevice &&
      foundDevice.calibration_start &&
      foundDevice.calibration_start.valueOf(),
    deviceRunningCutOff: foundDevice && foundDevice.running_cutoff,
    displayTemperature: getUserTemperatureDisplayPreference(state),
    displayRms: getUserRmsDisplayPreference(state),
    rmsAvailable: getOrganisationRmsAvailablePreference(state),
    mm2Available: getOrganisationMm2AvailablePreference(state),
    rmsTitle: getOrganisationRmsTitle(state),
    mm2Title: getOrganisationMm2Title(state),
  };
};
const mapDispatchToProps = {
  recalibrateAndRefetch,
};

export default connect(mapStateToProps, mapDispatchToProps)(MeasuredDataChart);
