import React, { useCallback, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Row, Col, Form, ToggleButtonGroup, ToggleButton, Card, Collapse } from 'react-bootstrap';

import FormModal from '../../../components/FormModal';

import SingleDatePicker from '../../../components/form/SingleDatePicker';
import { getTimezoneOffset } from '../../../components/values/Timezone';

import { recalibrate } from '../actions';
import { getDevice } from '../selectors';

const timeFormat = 'hh:mm A';

// return dateTimeMoment or false
function getDateTimeMoment(dateMoment, timeString) {
  const timeMoment = moment(timeString, timeFormat);
  return dateMoment.isValid() &&
    timeMoment.isValid() &&
    dateMoment.clone().startOf('day').add({
      ms: timeMoment.diff(timeMoment.clone().startOf('day')),
    });
}

function RelearnModalForm({
  device = {},
  recalibrate,
  showPreviousValue = false,
  initialDisplayOption = showPreviousValue ? 'previous': 'now',
  initialValue = showPreviousValue ? device.calibration_start : undefined,
  learningStart,
  runningCutoff,
  children,
  ...props
}) {

  const initialMoment = moment(initialValue);
  const [displayOption, setDisplayOption] = useState(initialDisplayOption);
  const [valid, setValid] = useState(true);
  const [advanced, setAdvanced] = useState(!!learningStart);
  const showDropdown = displayOption === initialDisplayOption;
  const showAdvanced = advanced || !showDropdown;
  const toggleAdvanced = () => showDropdown && setAdvanced(!advanced);

  const [state, setState] = useState(() => {
    const customMoment = learningStart && moment(learningStart);
    return {
      // use absolute start of days
      dateMoment: (customMoment || initialMoment).clone().startOf('day'),
      // need to be able to keep invalid dates in the field
      timeString: (customMoment || initialMoment).format(timeFormat),
    };
  });

  const handleSubmit = useCallback(e => {
    e.preventDefault();
    if (valid) {
      const dateTimeMoment = getDateTimeMoment(state.dateMoment, state.timeString);
      return recalibrate(device, {
        fitmachine_onboard_date: dateTimeMoment.toISOString(),
        rms_running_cutoff: runningCutoff,
      }, e); // pass event for advanced usage
    }
  }, [device, state.dateMoment, state.timeString, valid]);

  const handleDisplayOption = useCallback(value => {
    // set toggle status
    setDisplayOption(value);
    // set the time
    if (value !== 'custom') {
      const nextMoment = value === 'previous' ? initialMoment : moment();
      setState({
        dateMoment: nextMoment.clone().startOf('day'),
        timeString: nextMoment.format(timeFormat),
      });
    }
  }, []);

  const dateTimeMoment = getDateTimeMoment(state.dateMoment, state.timeString);
  return (
    <FormModal
      // set defaults
      header="Are you sure you want to restart learning?"
      body={`This action will restart learning on "${device.equipment_name}"`}
      confirmText="Restart learning"
      // add given props
      {...props}
      // override given props
      size="md"
      valid={valid}
      form={(
        <Form onSubmit={handleSubmit}>
          <Card className="original-card shadow-sm" style={{ marginTop: '-1rem' }}>
            <Card.Header
              onClick={toggleAdvanced}
              className={`drop${showAdvanced ? 'up' : 'down'}`}
              // declare accessibility attributes as given in examples given in
              // https://getbootstrap.com/docs/4.2/components/dropdowns/
              data-toggle="dropdown"
              aria-haspopup="false"
              aria-expanded={showAdvanced}
              style={showDropdown ? { cursor: 'pointer' } : undefined}
            >
              Advanced Options
              {showDropdown && (
                <span className="dropdown-toggle float-right"></span>
              )}
            </Card.Header>
            <Collapse in={showAdvanced}>
              <Card.Body>
                <Form.Group as={Row} controlId="fitmachine_onboard_option">
                  <Form.Label column sm="5">
                    Learning start
                  </Form.Label>
                  <Col sm="7">
                    <ToggleButtonGroup
                      type="radio"
                      name="Learning Date Type"
                      value={displayOption}
                      onChange={handleDisplayOption}
                    >
                      {showPreviousValue && device.calibration_start && (
                        <ToggleButton value="previous">Unchanged</ToggleButton>
                      )}
                      <ToggleButton value="now">Now</ToggleButton>
                      <ToggleButton value="custom">Custom</ToggleButton>
                    </ToggleButtonGroup>
                  </Col>
                </Form.Group>
                {displayOption === 'custom' && (
                  <Fragment>
                    <Form.Group as={Row} controlId="fitmachine_onboard_date">
                      <Form.Label column sm="5">
                        Drag the horizontal slider to adjust the learning start date.
                      </Form.Label>
                      <Col sm="7">
                        <SingleDatePicker
                          key={`fitmachine_onboard_date-${state.dateMoment.toISOString()}`}
                          date={state.dateMoment}
                          onDateChange={dateMoment => {
                            const { timeString } = state;
                            const newDateTime = getDateTimeMoment(dateMoment, timeString);
                            setState({
                              ...state,
                              dateMoment: dateMoment.clone().startOf('day'),
                            });
                            setValid(newDateTime && !newDateTime.isAfter());
                          }}
                          showDefaultInputIcon={true}
                          id="fitmachine_onboard_date"
                          placeholder="Learning date"
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="fitmachine_onboard_time">
                      <Form.Label column sm="5">
                        Learning start time ({getTimezoneOffset()})
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          type="text"
                          name="fitmachine_onboard_time"
                          isInvalid={!valid}
                          value={state.timeString}
                          onChange={e => {
                            const { dateMoment } = state;
                            const timeString = e.target.value;
                            const dateTimeMoment = getDateTimeMoment(dateMoment, timeString);
                            setState({ ...state, timeString });
                            setValid(dateTimeMoment && !dateTimeMoment.isAfter());
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Fragment>
                )}
              </Card.Body>
            </Collapse>
          </Card>
          <br/>
          {dateTimeMoment && (
            <Form.Group>
              <div>Would you like to trigger relearning starting from</div>
              <div>{dateTimeMoment.format("LLLL")} ({getTimezoneOffset()})?</div>
            </Form.Group>
          )}
        </Form>
      )}
    >
      {children}
    </FormModal>
  );
}

const mapStateToProps = (state, { deviceId }) => ({
  device: getDevice(state, deviceId),
});
const mapDispatchToProps = { recalibrate };
// use recalibrate from actions (dispatchProps), but let it be overridden by ownProps if specified
const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(RelearnModalForm);
