import createReducer from '../../lib/createReducer';
import moment from 'moment';

import { ACTION_TYPES as USER_ACTION_TYPES } from '../user/actions';
import { ACTION_TYPES as DEVICE_ACTION_TYPES } from '../equipment/actions';
import { ACTION_TYPES } from './actions';
import {
  upsertListItems,
  upsertObjectItem,
  upsertObjectItems,
} from '../../lib/reducerUtils';
import { sortAlarmsDefault } from './selectors';

export const DEFAULT_STATE = {
  alarms: [],
  alarmsById: {},
  loading: false,
  lastFetch: null,
  error: null
};

export default createReducer(DEFAULT_STATE, {
  [USER_ACTION_TYPES.LOGOUT]: () => ({ ...DEFAULT_STATE }),
  [USER_ACTION_TYPES.TOKEN_EXPIRED]: () => ({ ...DEFAULT_STATE }),
  [ACTION_TYPES.REQUEST_BEGIN](state, { response }) {
    return {
      ...state,
      loading: true
    };
  },

  [ACTION_TYPES.REQUEST_FAIL](state, { response }) {
    return {
      ...state,
      loading: false,
      error: (response ? response : "Unknown error")
    };
  },

  [ACTION_TYPES.RECEIVE_ALARMS_LIST](state, { response }) {

    const alarms = response && response._embedded && response._embedded.alarms;

    return {
      ...state,
      // update the alarms list (which is currently limited to 500 most recent)
      alarms: upsertListItems(state.alarms, alarms).sort(sortAlarmsDefault),
      // update alarm objects by merging list into the alarm lookup object
      // (this object may have more alarms than the alarms list)
      alarmsById: upsertObjectItems(state.alarmsById, alarms),
      loading: false,
      error: null,
      lastFetch: moment()
    };
  },

  // add device alarm lists into the alarm store
  [DEVICE_ACTION_TYPES.RECEIVE_DEVICE_ALARMS](state, { response }) {
    const alarms = response && response._embedded && response._embedded.alarms;
    return {
      ...state,
      alarmsById: upsertObjectItems(state.alarmsById, alarms),
    };
  },

  [ACTION_TYPES.RECEIVE_ALARM](state={}, { response={} }) {
    return {
      ...state,
      alarmsById: upsertObjectItem(state.alarmsById, response),
    };
  },

  [ACTION_TYPES.RECEIVE_ALARM_IMAGES](state={}, { alarm={}, response={} }) {
    return {
      ...state,
      alarmsById: upsertObjectItem(state.alarmsById, {
        id: alarm.id,
        _embedded: response._embedded,
      }),
    };
  },

  [ACTION_TYPES.RECEIVE_ALARM_SOUNDS](state={}, { alarm={}, response={} }) {
    return {
      ...state,
      alarmsById: upsertObjectItem(state.alarmsById, {
        id: alarm.id,
        _embedded: response._embedded,
      }),
    };
  },

});
