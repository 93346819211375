
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Alert } from 'react-bootstrap';

import Title from '../../../components/Title';
import BasicModal from '../../../components/BasicModal';
import AlarmFFTImages from '../../alarm/components/AlarmFFTImages';
import ContactSupportLink from '../../../components/ContactSupportLink';

import { fetchOrganisationIfStale } from '../../organisation/actions';

import { getAlarm, getAlarmDevice } from '../selectors';
import { relativeDateFormatter } from '../../../components/table/formatters';
import {
  getOrganisationHasFFT,
  getOrganisationId,
} from '../../organisation/selectors';

function AlarmFFTModal({
  alarmId,
  device = {},
  alarm = {},
  hasFFT = false,
  organisationId,
  fetchOrganisationIfStale,
  children,
}) {

  useEffect(() => {
    fetchOrganisationIfStale({ id: organisationId });
  }, [organisationId]);

  // show nothing if the organisation has no access to FFT Charts
  if (!hasFFT) {
    return null;
  }

  return (
    <BasicModal
      size="lg"
      header={(
        <Title title="Alarm FFT" />
      )}
      subHeader={(
        <div>
          <p>
            {[
              alarm.site_name,
              alarm.site_subarea_name,
              alarm.equipment_name
            ].filter(Boolean).join(' - ')}
          </p>
          <p>
            <Link to={`/equipment/${alarm.device_id}`}>
              {device.serial}
            </Link>
            {' - '}
            {relativeDateFormatter(alarm.alarm_timestamp)}
          </p>
          <Alert variant="info">
            The red or yellow coloured line is the FFT of acceleration
            at the time of the alarm. The black shaded area represents
            the FitMachine learning data.
            We're actively improving this feature. <ContactSupportLink
              subject="FFT images"
            >
              Click here to give feedback
            </ContactSupportLink>.
          </Alert>
        </div>
      )}
      body={(
        <AlarmFFTImages alarmId={alarmId} />
      )}
    >
      {children}
    </BasicModal>
  );
}

const mapStateToProps = (state, { alarmId }) => {
  return {
    alarm: getAlarm(state, alarmId),
    hasFFT: getOrganisationHasFFT(state),
    device: getAlarmDevice(state, alarmId),
    organisationId: getOrganisationId(state),
  };
};

const mapDispatchToProps = { fetchOrganisationIfStale };

export default connect(mapStateToProps, mapDispatchToProps)(AlarmFFTModal);