import React, { Fragment, useCallback, useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { GoDashboard } from 'react-icons/go';

import useBaseChart, { defaultOptions } from './BaseChart.js';

import { fetchDeviceRuntime } from '../../modules/equipment/actions.js';
import {
  getDeviceRuntimeTimezone,
  getDevice,
  getDeviceRuntime,
} from '../../modules/equipment/selectors';

import { fetchGroupRuntime } from '../../modules/organisation/actions.js';
import {
  getGroupRuntimeTimezone,
  getGroupRuntime,
  getActiveGroupId,
} from '../../modules/organisation/selectors';

import { fetchOrganisationRuntime } from '../../modules/organisation/actions.js';
import {
  getOrganisationRuntimeTimezone,
  getOrganisationRuntime,
  getOrganisationId,
} from '../../modules/organisation/selectors';

const seconds = 1000;
const minutes = 60 * seconds;
const hours = 60 * minutes;
const days = 24 * hours;
const weeks = 7 * days;

const getDateString = time => new Date(time).toISOString().split('T')[0];

const dayLabels = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const colours = {
  black: '#434348', // black
  blue: '#7cb5ec', // blue
  grey: '#e5e5e5', // grey
  darkGrey: '#cccccc', // slightly darker grey
  darkerGrey: '#888888',
};

function getInitialOptions(options=defaultOptions) {

  const now = Date.now();
  return {
    ...options,
    grid: {
      top: 150,
      bottom: 90,
      left: 55,
      right: 72,
    },
    xAxis: [{
      ...options.xAxis,
      name: 'Day',
    }, {
      id: 'xAxisDays',
      type: 'category',
      position: 'bottom',
      // dynamically change the date labels
      data: [],
      axisTick: {
        show: true,
        alignWithLabel: true,
      },
      axisLabel: {
        show: true,
        fontSize: 12,
        padding: [0, 4],
      },
      splitLine: {
        show: true,
        interval: 0,
        lineStyle: {
          // make lines a little transparent
          color: '#00000011',
          width: 1,
        },
      },
      // put lines over data
      z: 3,
    }],
    calendar: {
      id: 'months',
      top: 20,
      height: 80,
      left: 55,
      right: 72,
      range: [
        // show at least a month
        getDateString(now - 32 * days),
        // until now
        getDateString(now),
      ],
      dayLabel: {
        show: true,
        fontSize: 12,
        firstDay: 0, // ensure Sunday is the first day
        margin: 10,
      },
      monthLabel: {
        show: true,
        fontSize: 12,
        margin: 5,
      },
      yearLabel: {
        show: true,
        fontSize: 18,
        margin: 30,
      },
      itemStyle: {
        borderWidth: 1,
        // make lines a little transparent
        borderColor: '#00000011',
      },
    },
    // visual map is a gradient legend of the value
    // and is used to set the colour range values
    visualMap: {
      min: 0,
      max: 100,
      show: false,
      // colour gradient for heatmap 0-3
      color: [
        '#2bae23', // 100
        '#eadf6c', // 50
        '#ff7878', // 0
      ],
    },
    legend: {
      type: 'scroll',
      top: 120,
      left: 50,
      right: 50,
    },
    yAxis: [
      {
        id: 'utilisation',
        type: 'value',
        name: 'Average Equipment Utilisation (%)',
        nameLocation: 'center',
        nameRotate: 90,
        nameGap: 35,
        nameTextStyle: {
          color: colours.blue,
        },
        min: 0,
        max: 100,
        minInterval: 25,
      },
      {
        id: 'hours',
        type: 'value',
        name: 'Running & Available Hours',
        nameLocation: 'center',
        nameRotate: -90,
        nameGap: 7.5,
        offset: 42.5,
        min: 0,
        max: 24,
        minInterval: 6,
        nameTextStyle: {
          color: colours.black,
        },
        show: false,
      },
      {
        id: 'equipment_count',
        type: 'value',
        name: 'Equipment Count',
        nameLocation: 'center',
        nameRotate: -90,
        nameGap: 35,
        min: 0,
        max: 1,
        // allow seeing more integer tick marks
        minInterval: 1,
        // but don't show them as gridlines
        // as this can make the chart look too busy
        // upgrade to eCharts v4.6.0 for minorTick options
        axisTick: {
          interval: 10,
        },
        splitLine: {
          show: false,
        },
        nameTextStyle: {
          color: colours.black,
        },
        show: false,
      },
    ],
    series: [
      {
        id: 'utilisation_average',
        name: 'Average Equipment Utilisation %',
        type: 'line',
        lineStyle: { width: 1.5, color: colours.blue },
        smooth: 0.25,
        smoothMonotone: 'x',
        showSymbol: false,
        z: 3,
        yAxisIndex: 0,
      },
      {
        id: 'equipment_count',
        name: 'Equipment Count',
        type: 'line',
        lineStyle: { width: 0, color: colours.grey },
        showSymbol: false,
        areaStyle: {
          color: colours.grey,
          opacity: 1,
        },
        symbol: 'none',
        step: 'middle',
        z: -1,
        yAxisIndex: 2,
      },
      // add raw data after other series to avoid in being shown on the data slider
      {
        id: 'utilisation',
        name: 'Equipment Utilisation %',
        type: 'line',
        lineStyle: { width: 0 },
        showSymbol: false,
        yAxisIndex: 0,
      },
      {
        id: 'running_hours',
        name: 'Running Hours',
        type: 'line',
        lineStyle: { width: 0, color: colours.darkerGrey },
        showSymbol: false,
        symbol: 'none',
        step: 'middle',
        z: 2,
        yAxisIndex: 1,
      },
      {
        id: 'available_hours',
        name: 'Available Hours',
        type: 'line',
        lineStyle: { width: 0, color: colours.darkGrey },
        showSymbol: false,
        symbol: 'none',
        step: 'middle',
        z: 1,
        yAxisIndex: 1,
      },
      {
        id: 'days',
        type: 'heatmap',
        coordinateSystem: 'calendar',
        tooltip: {
          trigger: 'item',
          position: 'top',
          formatter: function({ seriesId, data=[] }={}) {
            if (seriesId === 'days') {
              const [dateString, raw_utilisation, utilisation] = data;
              return `${dateString} (${
                dayLabels[new Date(dateString).getDay()]
              })${
                '<hr style="margin:2px 0 5px;border-top-color: #ccc;"/>'
              }Average Utilisation: : ${Number(utilisation).toFixed(1)}%${
                '<br/>'
              }Day's Utilisation: : ${Number(raw_utilisation).toFixed(1)}%`;
            }
          },
        },
        xAxisId: 'xAxisDays',
      },
    ],
    tooltip: {
      trigger: 'axis',
      formatter: function(series) {
        const utilisation = series.find(({ seriesId }) => seriesId === 'utilisation');
        const utilisation_average = series.find(({ seriesId }) => seriesId === 'utilisation_average');
        const running_hours = series.find(({ seriesId }) => seriesId === 'running_hours');
        const available_hours = series.find(({ seriesId }) => seriesId === 'available_hours');
        const equipment_count = series.find(({ seriesId }) => seriesId === 'equipment_count');
        const dateString = getDateString(series[0].value[0]);
        return (
          `${dateString} (${
            dayLabels[new Date(dateString).getDay()]
          })${
            '<hr style="margin:2px 0 5px;border-top-color: #ccc;"/>'
          }${
            utilisation_average
              ? `Average Utilisation: ${utilisation_average.value[1] ? utilisation_average.value[1].toFixed(1) : '0'}%<br/>`
              : ''
          }${
            utilisation
              ? `Day's Utilisation: ${utilisation.value[1] ? utilisation.value[1].toFixed(1) : '0'}%<br/>`
              : ''
          }${
            running_hours
              ? `Running Hours: ${Math.round(running_hours.value[1] || 0)}<br/>`
              : ''
          }${
            available_hours
              ? `Available Hours: ${Math.round(available_hours.value[1] || 0)}<br/>`
              : ''
          }${
            equipment_count
              ? `Equipment Count: ${equipment_count.value[1]}<br/>`
              : ''
          }`
        );
      },
    },
    color: [colours.blue, colours.darkGrey],
    graphic: {
      elements: [{
        id: 'timezone_text',
        type: 'text',
        right: 0,
        top: 1,
        style: {
          textAlign: 'center',
          // additional options documented in:
          // link: https://ecomfe.github.io/zrender-doc/public/api.html#zrenderdisplayable
          fontSize: 12,
          textLineHeight: 16,
        },
      }],
    },
  };
}

function EquipmentUtilisationChart({
  deviceId,
  groupId,
  organisationId,
  startWithFullRange = false,
  maximumViewedDateRange,
  dateRange = {},
  setDateRange,
  deviceRuntime = [],
  deviceRuntimeTimezone,
  deviceCreatedAtTimestamp,
  CustomBaseChart,
  fetchDeviceRuntime,
  fetchGroupRuntime,
  fetchOrganisationRuntime,
  ...props
}) {

  const [fetching, setFetching] = useState(false);

  // fetch runtime on device load or change
  useEffect(() => {
    if (deviceId) {
      (async () => {
        try {
          setFetching(true);
          await fetchDeviceRuntime({ id: deviceId });
          setFetching(false);
        }
        catch(e) {
          setFetching(false);
        }
      })();
    }
  }, [deviceId]);

  // fetch runtime on group load or change
  useEffect(() => {
    if (groupId) {
      (async () => {
        try {
          setFetching(true);
          await fetchGroupRuntime({ id: groupId });
          setFetching(false);
        }
        catch(e) {
          setFetching(false);
        }
      })();
    }
  }, [groupId]);

  // fetch runtime on organisation load or change
  useEffect(() => {
    if (organisationId) {
      (async () => {
        try {
          setFetching(true);
          await fetchOrganisationRuntime({ id: organisationId });
          setFetching(false);
        }
        catch(e) {
          setFetching(false);
        }
      })();
    }
  }, [organisationId]);

  const [BaseChart, {
    getChart,
    useChartUpdateEffect,
  }] = useBaseChart(getInitialOptions, { CustomBaseChart });

  const updateTimezone = useCallback(() => {
    return {
      // update the xAxis timezone if required
      xAxis: {
        id: 'xAxisMain',
        type: 'time',
        name: `Day (${deviceRuntimeTimezone})`,
      },
      graphic: {
        elements: [{
          id: 'timezone_text',
          style: {
            text: `\nTimezone:\n\n${
              deviceRuntimeTimezone
                ? deviceRuntimeTimezone
                  .replace(/\//g, '/\n') // break on slash
                  .replace(/_/g, '\n') // new line for each word
                : 'unknown'
            }`,
          },
        }]
      },
    };
  }, [deviceRuntimeTimezone]);

  // handle updates when dependencies change
  useChartUpdateEffect(updateTimezone);

  const updateRuntime = useCallback(() => {

    // filter to valid date runtimes
    const validRuntimes = deviceRuntime
      .filter(({ date }) => !isNaN(new Date(date)));

    // find maximum values for the chart
    const maxCount = Math.max(...validRuntimes.map(({ equipment_count }) => equipment_count));
    // if no singular device id is present, then display the second yAxis
    const showSecondAxis = !deviceId;

    return {
      legend: {
        data: [
          { name: 'Average Equipment Utilisation %', textStyle: { color: colours.blue } },
          showSecondAxis && { name: 'Equipment Count', textStyle: { color: colours.darkerGrey } },
        ].filter(Boolean),
      },
      yAxis: [
        {
          id: 'equipment_count',
          show: showSecondAxis,
          max: maxCount,
        },
      ],
      series: [
        // map calendar properties
        {
          id: 'days',
          data: validRuntimes
            // last value is the value that gets displayed on chart
            .map(({ date, utilisation, raw_utilisation }) => [date, raw_utilisation, utilisation]),
        },
        // map time axis series
        {
          id: 'utilisation_average',
          data: validRuntimes
            .map(({ date, utilisation }) => [new Date(date).getTime(), utilisation]),
        },
        {
          id: 'utilisation',
          data: validRuntimes
            .map(({ date, raw_utilisation }) => [new Date(date).getTime(), raw_utilisation]),
        },
        {
          id: 'running_hours',
          data: validRuntimes
            .map(({ date, running_hours }) => [new Date(date).getTime(), running_hours]),
        },
        {
          id: 'available_hours',
          data: validRuntimes
            .map(({ date, available_hours }) => [new Date(date).getTime(), available_hours]),
        },
        showSecondAxis && {
          id: 'equipment_count',
          data: validRuntimes
            .map(({ date, equipment_count }) => [new Date(date).getTime(), equipment_count]),
        },
      ].filter(Boolean),
    };
  }, [deviceRuntime, deviceId]);

  // handle updates when dependencies change
  useChartUpdateEffect(updateRuntime);

  // find earliest date that should be used on the chart
  const startTime = useMemo(() => {
    if (deviceCreatedAtTimestamp) {
      return deviceCreatedAtTimestamp;
    }
    else if (deviceRuntime.length) {
      const validRuntimes = deviceRuntime
        .filter(({ date }) => !isNaN(new Date(date)));
      const minimumDataDate = validRuntimes.length && Math.min(
        ...validRuntimes.map(({ date }) => new Date(date).valueOf())
      );
      if (minimumDataDate) {
        return minimumDataDate;
      }
    }
  }, [
    deviceCreatedAtTimestamp,
    !deviceRuntime.length && deviceRuntime,
  ]);

  // when a new startTime is available, set the date range to the maximum
  useEffect(() => {
    if (startWithFullRange) {
      setDateRange(previous => {
        if (startTime && startTime !== previous.startTime) {
          return { ...previous, startTime };
        }
        else {
          return previous;
        }
      });
    }
  }, [startTime, startWithFullRange]);

  // update calendar range
  const updateCalendarDateRange = useCallback(({ calendar: [calendar] }) => {
    const chart = getChart();
    const chartWidth = chart && chart.getWidth();
    if (chartWidth && startTime) {
      // compute how much room the month labels have
      const calendarWidth = chartWidth - calendar.left - calendar.right;
      const calendarWeeks = Math.ceil((Date.now() - startTime) / weeks);
      return {
        calendar: [{
          id: 'months',
          itemStyle: {
            // make calendar day borders smaller when then are more days to display
            // but with maximum width of 1
            borderWidth: Math.min(1, Math.round(25 * calendarWidth / calendarWeeks )/100),
          },
          monthLabel: {
            nameMap: calendarWidth / calendarWeeks > 7.5
              ? ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
              : ['Jan', '', '', 'Apr', '', '', 'Jul', '', '', 'Oct', '', '']
          },
          range: [
            // show at least the day of device creation
            getDateString(startTime),
            // until now
            getDateString(Date.now()),
          ],
        }],
      };
    }
  }, [getChart, startTime]);

  useChartUpdateEffect(updateCalendarDateRange);

  // update dataZoom range
  const [altMaximumViewedDateRange, setMaximumViewedDateRange] = useState(maximumViewedDateRange);
  useEffect(() => {
    if (startTime) {
      setMaximumViewedDateRange(previous => {
        const newStartTime =  Math.min(maximumViewedDateRange.startTime, startTime);
        if (previous.startTime !== newStartTime) {
          return {
            startTime: newStartTime,
            endTime: maximumViewedDateRange.endTime,
          };
        }
        // or don't update state
        else {
          return previous;
        }
      });
    }
  }, [startTime, maximumViewedDateRange.startTime, maximumViewedDateRange.endTime]);

  return (
    <BaseChart
      // colour here is FitMachine-like red
      header={<Fragment><GoDashboard /> Equipment Utiliisation</Fragment>}
      namespace="health-runtime"
      dateRange={dateRange}
      maximumViewedDateRange={altMaximumViewedDateRange}
      // set 'has more data' indicator to false on this chart if data is available
      // as there is no pagination on the device runtime endpoint at present
      hasMore={deviceRuntime.length > 0}
      // ensure that the setDateRange throttle mode
      // doesn't fire the update continuously while dragging the dataZoom
      // why? this dataZoom can have more data (runtimes) than other charts (samples)
      // and firing a date change should trigger those other charts to downloads more data
      // so we should do this sparingly
      setDateThrottleMode="at_end"
      // pass through original props
      deviceId={deviceId}
      setDateRange={setDateRange}
      {...props}
      // override loading indicator
      stillFetching={fetching}
    />
  );
}

const mapStateToProps = (state, { deviceId }) => {
  const device = getDevice(state, deviceId);
  return {
    deviceCreatedAtTimestamp: device && device.created_at && new Date(device.created_at).getTime(),
    deviceRuntimeTimezone: getDeviceRuntimeTimezone(state, deviceId),
    deviceRuntime: getDeviceRuntime(state, deviceId),
  };
};
const mapDispatchToProps = {
  fetchDeviceRuntime,
};

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentUtilisationChart);

// export a version for Group runtime
export const GroupUtilisationChart = connect(state => {
  const groupId = getActiveGroupId(state);
  return {
    groupId,
    deviceRuntimeTimezone: getGroupRuntimeTimezone(state, groupId),
    deviceRuntime: getGroupRuntime(state, groupId),
  };
}, { fetchGroupRuntime })(EquipmentUtilisationChart);

// export a version for Organisation runtime
export const OrganisationUtilisationChart = connect(state => {
  const organisationId = getOrganisationId(state);
  return {
    organisationId,
    deviceRuntimeTimezone: getOrganisationRuntimeTimezone(state, organisationId),
    deviceRuntime: getOrganisationRuntime(state, organisationId),
  };
}, { fetchOrganisationRuntime })(EquipmentUtilisationChart);
