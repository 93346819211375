
import React, { useLayoutEffect, useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import { IoIosBriefcase } from 'react-icons/io';
import objectFitImages from 'object-fit-images';

// Components
import { getOrganisation, getOrganisationLogo } from '../selectors';

function OrganisationLogo({
  organisation = {},
  organisationLogoUrl,
  size = '2.4em',
}) {

  const imageEl = useRef();

  // on DOM load (and element references becoming available),
  // and whenever the image source changes
  // fix image fit in IE before the element is painted
  // (will only cause an effect to elements that have an object-fit font-family attached)
  useLayoutEffect(() => {
    if (imageEl.current && organisationLogoUrl) {
      objectFitImages(imageEl.current);
    }
  }, [imageEl.current, organisationLogoUrl]);

  return (
    <div
      className="img-thumbnail object-fit-contain d-inline-block"
      style={useMemo(() => ({ width: size, height: size, verticalAlign: 'top' }), [size])}
    >
      {organisationLogoUrl ? (
        <img ref={imageEl} src={organisationLogoUrl} alt={organisation.name} className="image-upload__img"/>
      ) : (
        <IoIosBriefcase size="100%" />
      )}
    </div>
  );
}

const mapStateToProps = (state, { organisationId, size }) => {
  const organisationPicture = organisationId && getOrganisationLogo(state, organisationId);
  return {
    organisation: organisationId && getOrganisation(state, organisationId),
    organisationLogoUrl: organisationPicture && organisationPicture.url,
    size,
  };
};

export default connect(mapStateToProps)(OrganisationLogo);
