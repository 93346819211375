
import React, { Fragment } from 'react';
import { Container, Col, Row, Navbar } from 'react-bootstrap';
import './profileLayout.scss';

export default function ProfilePageLayout({ image, navbar, sidebar, children: body }) {
  return (
    <Fragment>
      <div className="profile-page__header">
        <Container>
          <Row>
            <Col lg={4} xl={3}>
              <div className="position-relative">
                {image}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Navbar bg="light" className="profile-page__navbar-row shadow-sm p-0 pt-2 pb-1">
        <Container className="p-0">
          <Col lg={{ span: 8, offset: 4 }} xl={{ span: 9, offset: 3 }}>
            <div className="flex-grow-1 justify-content-end profile-page__navbar">
              {navbar}
            </div>
          </Col>
        </Container>
      </Navbar>
      <Container>
        <Row>
          <Col sm={9} lg={4} xl={3} className="profile-page__sidebar">
            {sidebar}
          </Col>
          <Col lg={8} xl={9}>
            {body}
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}
