import { Component, useState } from 'react';
import { connect } from 'react-redux';

import { convertToTimestamp } from './BaseChart';
import { fetchDeviceSamples } from '../../modules/equipment/actions';
import { getFirstSampleDateFromDevice, getDevice } from '../../modules/equipment/selectors';

// get first element in a sparse array;
function getArrayBounds(arr) {
  let firstIndex;
  for (const i in arr) {
    firstIndex = i;
    break;
  }
  const lastIndex = arr.length - 1;
  return [firstIndex, lastIndex];
}

// the custom throttle will execute like a trailing edge throttle
// executing a minimum of throttleMs after first call
// executing a maximum of ~throttleMs after first call (can be pushed out by blocking UI)
// executing at most once inside each throttleMs window
const customThrottleCreator = func => {
  let throttleEngaged = false;
  let throttleThresholdMs = Date.now();
  let debounceTimeout = false;

  return (throttleMs=1000) => {
    return function() {

      const nowMs = Date.now();

      // clear the current debounce timeout
      clearTimeout(debounceTimeout);

      // start throttle timer if it is not already running
      if (!throttleEngaged) {
        throttleEngaged = true;
        throttleThresholdMs = nowMs;
      }

      // execute now or later?
      if (throttleThresholdMs < nowMs - throttleMs) {
        // execute now
        func.apply(this, arguments);
        throttleEngaged = false;
      }
      else {
        // add debounced call to execute later
        const timeLeftInThrottleWindow = throttleMs - (nowMs - throttleThresholdMs);
        debounceTimeout = setTimeout(() => {
          func.apply(this, arguments);
          throttleEngaged = false;
        }, timeLeftInThrottleWindow > 0 ? timeLeftInThrottleWindow : 0);
      }
    };
  };
};

// times in ms
const oneHour = 1000 * 60 * 60;
const oneDay = oneHour * 24;
const oneWeek = oneDay * 7;

const defaultMinimumDataPeriod = 2 * oneWeek;

const getInitialState = () => {
  const now = Date.now();
  return {
    _mutatedSortedSamplesByEpoch: [],
    samples: [],
    // after a device is loaded, this will be replaced with
    // the extents of the data of this device
    // this is temporarily showed if that data is not yet available
    selectableDateRange: {
      startTime: now - oneWeek,
      endTime: now,
    },
    // allow the component to save the state of maximum dates seen
    // so that the dataZoom window has a constant maximum range
    maximumViewedDateRange: {
      startTime: now - oneWeek,
      endTime: now,
    },
  };
};

export function ChartContext({ children: contextFunction }) {
  const now = Date.now();

  const [dateRange, setDateRange] = useState({
    startTime: now - oneWeek,
    endTime: now,
    minimumDataPeriod: defaultMinimumDataPeriod,
  });

  const [maximumViewedDateRange] = useState({
    startTime: now - oneWeek,
    endTime: now,
  });

  const [selectableDateRange] = useState({
    startTime: now - oneWeek,
    endTime: now,
  });

  return contextFunction({
    dateRange,
    selectableDateRange,
    maximumViewedDateRange,
    setDateRange,
  });
}

class DeviceSamplesContext extends Component {

  state = {
    ...getInitialState(),
    // set sane defaults
    dateRange: {
      startTime: Date.now() - oneWeek,
      endTime: Date.now(),
      minimumDataPeriod: defaultMinimumDataPeriod,
    },
    stillFetching: false,
  }

  isOnDeviceId = id => this.currentlyFetchingDeviceId === id

  getMaximumViewedDateRange = ({ startTime, endTime }) => {
    const { maximumViewedDateRange } = this.state;
    return {
      startTime: Math.min(startTime, maximumViewedDateRange.startTime),
      endTime: Math.max(endTime, maximumViewedDateRange.endTime),
    };
  }

  onData = ({ id: deviceId }, data=[], {
    stillFetching = false,
    hasMore = false,
  }={}) => {
    // check that we are on the correct device before setting state
    if (!this.isOnDeviceId(deviceId)) {
      return null;
    }

    // mutate state on each data payload:
    // sample data for each device is stored in _mutatedSortedSamplesByEpoch
    // this is a sparse array with integer indexes representing epochs in seconds
    // to ensure correct ordering and uniqueness for each timestamp.
    // seconds are used as the maximum array index integer appears to be 2^32-2 (tested in IE 11)
    // and this will allow us to order data from 1970-01-01 to 2106-02-07 correctly.
    // this object appears to not change relative to the components it in used in due to mutation
    // we will use state.samples to give sorted samples to the components when we are ready
    let newSamplesFound = false;
    const nowEpoch = Math.floor(Date.now() / 1000);
    data.reduce((acc, sample) => {
      // add to store if key doesn't exist
      const epoch = Math.floor(new Date(sample.sample_time)/1000);
      // add samples that haven't been collected yet
      // and filter out samples that are in the future
      if (!acc[epoch] && (epoch <= nowEpoch)) {
        acc[epoch] = sample;
        // flag that new samples were found, and an update could be done
        newSamplesFound = true;
      }
      return acc;
    }, this.state._mutatedSortedSamplesByEpoch);

    // it is quite common for the new samples to not be found:
    // for example when the user changes the dates of a chart we fetch the most recent samples,
    // new samples usually only come in every 30 minutes or so, so all the samples in the payload
    // are probably already stored inside _mutatedSortedSamplesByEpoch from previous API calls
    if (newSamplesFound || (
      this.state.stillFetching !== stillFetching
    ) || (
      this.state.hasMore !== hasMore
    )) {
      // update now or maybe update based on throttle flush
      if (!stillFetching) {
        this.flushUpdateStateOfDeviceId(deviceId, { stillFetching, hasMore });
      }
      else {
        // flag that this throttle should flush on next call
        this.throttledUpdateStateOfDeviceId(deviceId, { stillFetching, hasMore });
      }
    }
  }

  updateStateOfDeviceId(deviceId, { stillFetching, hasMore }) {

    // set state
    this.setState(state => {
      // check that we are on the correct device before adding sample data
      if (!this.isOnDeviceId(deviceId)) {
        return null;
      }

      // collect and compare value equality by the ids of the first and last sample ids
      // of the sparse and dense arrays in the state
      const [firstIndex, lastIndex] = getArrayBounds(state._mutatedSortedSamplesByEpoch);
      const firstInSortedId = (state._mutatedSortedSamplesByEpoch[firstIndex] || {}).id;
      const lastInSortedId = (state._mutatedSortedSamplesByEpoch[lastIndex] || {}).id;
      const firstInStateId = (state.samples[0] || {}).id;
      const lastInStateId = (state.samples[state.samples.length - 1] || {}).id;

      // check sample state is well formatted
      if (typeof firstInSortedId === typeof lastInSortedId &&
        typeof firstInStateId === typeof lastInStateId) {
        // if the sorted states differ, then update the samples state for usage in charts
        if ((firstInSortedId !== firstInStateId) || (lastInSortedId !== lastInStateId)) {

          // add new sorted samples to the state
          // note: the following line is expensive when the stored samples array is very large
          const sortedSamples = Object.values(state._mutatedSortedSamplesByEpoch);
          const newState = {
            stillFetching,
            hasMore,
            samples: sortedSamples,
            maximumViewedDateRange: this.getMaximumViewedDateRange({
              startTime: convertToTimestamp(sortedSamples[0].sample_time),
              endTime: convertToTimestamp(sortedSamples[sortedSamples.length - 1].sample_time),
            }),
          };

          // todo: remove this when the platform can reliably tell us the first sample date
          // if there are samples that exist before the earliest dates found,
          // then 'unlock' the selectable date range
          if (newState.maximumViewedDateRange.startTime < state.selectableDateRange.startTime) {
            newState.selectableDateRange = {
              ...state.selectableDateRange,
              startTime: new Date('2015-01-01').valueOf(), // MOVUS founded year
            };
          }

          return newState;
        }
      }
      // update stillFetching if necessary
      if ((
        state.stillFetching !== stillFetching
      ) || (
        state.hasMore !== hasMore
      )) {
        return { stillFetching, hasMore };
      }
      // else do not update
      return null;
    });
  }

  setCustomThrottle = customThrottleCreator(this.updateStateOfDeviceId);
  throttledUpdateStateOfDeviceId = this.setCustomThrottle(2000);
  flushUpdateStateOfDeviceId = this.setCustomThrottle(0);

  // only allow one request chain at a time
  currentRequestId = 0

  setDateRange = ({
    // default arguments to the state values
    startTime = this.state.dateRange.startTime,
    endTime = this.state.dateRange.endTime,
    minimumDataPeriod = this.state.dateRange.minimumDataPeriod,
    fetchData = false,
    updateFromNamespace,
  } = {}) => {
    const { onData } = this;
    const { fetchDeviceSamples, deviceId: id } = this.props;
    if (fetchData && this.isOnDeviceId(id)) {
      // define a callback to inform that the current recursive page loading should be cancelled
      // this request should be cancelled if we switch devices, or start a newer request chain
      const thisRequestId = this.currentRequestId = Date.now();
      const isCancelled = () => this.currentRequestId !== thisRequestId || !this.isOnDeviceId(id);
      fetchDeviceSamples({ id }, {
        isCancelled,
        onData,
        dateRange: {
          startTime,
          endTime,
          minimumDataPeriod,
        },
      });
      // flag the context as currently fetching data
      this.setState({
        stillFetching: true,
      });
    }
    // regardless of whether new data is being fetched,
    // update the rest of the state
    this.setState({
      dateRange: {
        updateFromNamespace,
        startTime,
        endTime,
        minimumDataPeriod,
      },
      maximumViewedDateRange: this.getMaximumViewedDateRange({
        startTime,
        endTime,
      }),
    });
  }

  onDeviceLoad = (prevProps={}) => {
    const { deviceId, deviceFirstSampleDate } = this.props;
    if (deviceId && deviceId !== prevProps.deviceId) {
      this.currentlyFetchingDeviceId = deviceId;
      const selectableDateRange = {
        startTime: deviceFirstSampleDate.valueOf(),
        endTime: Date.now(),
      };
      this.setState({ ...getInitialState(), selectableDateRange }, () => {
        this.setDateRange({ fetchData: true });
      });
    }
  }

  componentDidMount() {
    this.onDeviceLoad();
  }

  componentDidUpdate(prevProps) {
    this.onDeviceLoad(prevProps);
  }

  componentWillUnmount() {
    this.currentlyFetchingDeviceId = false;
  }

  render() {
    const { children: contextFunction } = this.props;
    const {
      stillFetching,
      hasMore,
      samples,
      dateRange,
      selectableDateRange,
      maximumViewedDateRange,
    } = this.state;
    const { setDateRange } = this;
    return contextFunction({
      stillFetching,
      hasMore,
      samples,
      dateRange,
      selectableDateRange,
      maximumViewedDateRange,
      setDateRange,
    });
  }
}

const mapStateToProps = (state, { deviceId }) => {
  const foundDevice = getDevice(state, deviceId);
  return {
    deviceId: foundDevice && foundDevice.id,
    deviceFirstSampleDate: getFirstSampleDateFromDevice(foundDevice),
  };
};
const mapDispatchToProps = { fetchDeviceSamples };

export default connect(mapStateToProps, mapDispatchToProps)(DeviceSamplesContext);
