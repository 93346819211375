import React, { Fragment, useCallback } from 'react';
import moment from 'moment';

import useBaseChart, { convertToTimestamp, defaultOptions } from './BaseChart.js';

import { GoDashboard } from 'react-icons/go';

const colours = {
  black: '#434348', // black
  blue: '#7cb5ec', // blue
  grey: '#efefef', // grey
  darkGrey: '#cccccc', // slightly darker grey
};

function getInitialOptions(options=defaultOptions) {
  return {
    ...options,
    legend: {
      data: [
        { name: 'Power Usage/day', textStyle: { color: colours.black } },
        { name: 'Equipment Usage/day %', textStyle: { color: colours.blue } },
        { name: 'Equipment Not Running', textStyle: { color: colours.darkGrey } },
      ],
    },
    tooltip: {
      ...options.tooltip,
      formatter: function(series) {
        const power = series.find(({ seriesIndex }) => seriesIndex === 0);
        const usage = series.find(({ seriesIndex }) => seriesIndex === 1);
        const notRunning = series.find(({ seriesIndex }) => seriesIndex === 2);
        return (
          `${moment(series[0].value[0]).format('LLLL')}${
            '<hr style="margin:2px 0 5px;border-top-color: #ccc;"/>'
          }${
            power
              ? `Power Usage: ${power.value[1] ? power.value[1].toFixed(2) : '0'} kWh / day<br/>`
              : ''
          }${
            usage
              ? `Equipment Usage: ${Math.round(usage.value[1] || 0)} % / day<br/>`
              : ''
          }${
            notRunning
              ? `Equipment: ${notRunning.value[1] ? 'Not Running' : 'Running'}<br/>`
              : ''
          }`
        );
      },
    },
    yAxis: [
      {
        id: 'power_usage',
        type: 'value',
        name: 'Power Usage/day (kWh)',
        nameLocation: 'center',
        nameRotate: 90,
        nameGap: 30,
        nameTextStyle: {
          color: colours.black,
        },
      },
      {
        id: 'equipment_usage',
        type: 'value',
        name: 'Equipment Usage (%)',
        nameLocation: 'center',
        nameRotate: -90,
        nameGap: 35,
        nameTextStyle: {
          color: colours.blue,
        },
        min: 0,
        max: 100,
        minInterval: 25,
      },
      {
        id: 'equipment_not_running',
        type: 'value',
        name: 'Equipment Not Running',
        nameLocation: 'center',
        nameRotate: -90,
        nameGap: 55,
        nameTextStyle: {
          color: colours.darkGrey,
        },
        min: 0,
        max: 1,
        minInterval: 25,
      },
    ],
    series: [
      {
        id: 'power_usage',
        name: 'Power Usage/day',
        type: 'line',
        lineStyle: { width: 1.5, color: colours.black },
        smooth: 0.25,
        smoothMonotone: 'x',
        showSymbol: false,
        z: 2,
      },
      {
        id: 'equipment_usage',
        name: 'Equipment Usage/day %',
        type: 'line',
        lineStyle: { width: 1.5, color: colours.blue },
        smooth: 0.25,
        smoothMonotone: 'x',
        showSymbol: false,
        z: 1,
        yAxisIndex: 1,
      },
      {
        id: 'equipment_not_running',
        name: 'Equipment Not Running',
        type: 'line',
        lineStyle: { width: 1, color: colours.grey },
        areaStyle: {
          color: colours.grey,
          opacity: 1,
        },
        symbol: 'none',
        step: true,
        z: -1,
        yAxisIndex: 2,
      },
    ],
    color: [colours.black, colours.blue, colours.grey],
  };
}

export default function EquipmentUsageChart({ samples=[], ...props }) {

  const [BaseChart, { useChartUpdateEffect }] = useBaseChart(getInitialOptions);

  const updateSamples = useCallback(() => {
    const maxPower = Math.max(...samples.map(s => s.equipment_power_usage));
    return {
      yAxis: [
        {
          id: 'power_usage',
          min: 0,
          max: Math.ceil(maxPower),
        },
      ],
      series: [
        {
          id: 'power_usage',
          data: samples.map(sample => [
            convertToTimestamp(sample.sample_time),
            sample.equipment_power_usage,
          ]),
        },
        {
          id: 'equipment_usage',
          data: samples.map(sample => [
            convertToTimestamp(sample.sample_time),
            sample.equipment_utilisation,
          ]),
        },
        {
          id: 'equipment_not_running',
          data: samples.map(sample => [
            convertToTimestamp(sample.sample_time),
            sample.equipment_running ? 0 : 1,
          ]),
        },
      ],
      color: [colours.black, colours.blue, colours.grey],
    };
  }, [samples]);

  // handle updates when dependencies change
  useChartUpdateEffect(updateSamples);

  return (
    <BaseChart
      header={<Fragment><GoDashboard /> Equipment Usage</Fragment>}
      namespace="equipment-usage"
      samples={samples}
      {...props}
    />
  );
}
