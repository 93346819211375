
import { useEffect } from 'react';
import { store } from '../../store/configureStore';

import { trackEvent } from './actions';

// keep track of active and inactive time of the user counted by time between
// defined user activity actions
function useUserActivity(cleanupFunction, userIdleCutoffMs=60000) {

  // define the events we will listen for
  const eventNamesToListenFor = [
    'scroll',
    'keydown',
    'mousedown',
    'mousemove',
    'touchstart',
  ];

  // keep track of time
  const time = {
    active: 0,
    inactive: 0,
  };
  let lastTimeStamp = Date.now();
  function userActionCallback() {
    const now = Date.now();
    // only track time if time has moved forward
    // note: avoids cases where the timestamp hasn't moved yet and also
    // link: https://infiniteundo.com/post/25326999628/falsehoods-programmers-believe-about-time
    if (now > lastTimeStamp) {
      const timePassedMs = now - lastTimeStamp;
      lastTimeStamp = now;
      if (timePassedMs > userIdleCutoffMs) {
        time.inactive += timePassedMs;
      }
      else {
        time.active += timePassedMs;
      }
    }
  }

  // listen for any of these events for user activity
  eventNamesToListenFor.forEach(name => {
    document.addEventListener(name, userActionCallback, true);
  });

  // cleanup function
  return () => {
    // clean up the event listeners
    // listen for any of these events for user activity
    eventNamesToListenFor.forEach(name => {
      document.removeEventListener(name, userActionCallback, true);
    });

    // run higher level cleanup function if defined
    return cleanupFunction && cleanupFunction(time);
  };
}

// allow components to be tracked consistently
// todo: use TypeScript to ensure that properties argument is never `null`
// idKey should be a string or a falsy value (to indicate disabled)
export const useComponentViewTracking = (componentName, idKey=null, properties={}) => {

  // derive id from properties given
  const id = typeof idKey === 'string' ? properties[idKey] : undefined;

  // track the 'component view' event
  useEffect(() => {
    // track only if the ID is defined
    if (id !== undefined) {

      // construct properties for the event
      const eventProperties = {
        // add properties
        ...properties,
        // add id
        id,
      };

      // track event
      store.dispatch(trackEvent(`${componentName} Opened`, eventProperties));

      // return cleanup event (with user activity tracking)
      return useUserActivity(({ active, inactive }) => {
        store.dispatch(trackEvent(`${componentName} Closed`, {
          ...eventProperties,
          // append the user-active time that the component was opened for
          viewed_seconds_active: Math.round(active / 1000),
          viewed_seconds_inactive: Math.round(inactive / 1000),
          viewed_ms_active: active,
          viewed_ms_inactive: inactive,
        }));
      });
    }
    // return empty hook so the React hook ordering is not violated
    else {
      return useUserActivity();
    }
  }, [id, ...Object.values(properties)]);

};
