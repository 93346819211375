
import React from 'react';
import { ButtonGroup, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import {
  IoIosCreate,
  IoIosPeople,
} from "react-icons/io";

// Other
import Private from '../../../components/Private';
import DeviceContextMenu from './DeviceContextMenu';

export default function EquipmentDropdown({ deviceId }) {

  // skip if device has not yet been loaded
  if (!deviceId) {
    return null;
  }

  return (
    <ButtonGroup>
      <Private minUserType="Admin">
        <LinkContainer to={`/devices/${deviceId}/edit`}>
          <Button size="md" variant="outline-secondary">
            <IoIosCreate size="1.2em" /> <span>Edit</span>
          </Button>
        </LinkContainer>
        <LinkContainer to={`/devices/${deviceId}/users`}>
          <Button size="md" variant="outline-secondary">
            <IoIosPeople size="1.2em" /> <span>Users</span>
          </Button>
        </LinkContainer>
      </Private>
      <DeviceContextMenu deviceId={deviceId} />
    </ButtonGroup>
  );
}
