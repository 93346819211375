import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  IoIosCog,
  IoIosFlash,
  IoIosSchool,
  IoIosNotifications,
  IoIosNotificationsOff,
  IoIosBatteryCharging,
  IoIosWifi,
  IoIosPulse,
  IoIosTime,
  IoIosAlarm,
} from 'react-icons/io';

import {
  headerFormatter,
  textFormatter,
  nullFormatter,
  percentageNumberFormatter,
  zeroDigitsNumberFormatter,
  twoDigitsNumberFormatter,
  numberFormatterCreator,
  calibrationFormatter,
  relativeDateFormatter,
  relativeDateFilter,
} from '../../components/table/formatters';

import {
  numericSortFunc,
  stringSortFunc,
  stringSortValueWithHumanNumbersFunc,
  booleanSortFunc,
  timeSortFunc,
} from '../../components/table/utils';

import { getPlaceholderImageForDevice } from './utils';

import VolumeImage from '../../modules/alarm/VolumeImage';
import ToneImage from '../../modules/alarm/ToneImage';
import TemperatureImage from '../../modules/alarm/TemperatureImage';

import UserPicture from '../user/components/UserPicture';
import Bar from '../../components/Bar';
import PadLock from '../../components/PadLock';
import RunningStatus from './components/RunningStatus';
import StatusIndicator from './components/StatusIndicator';
import {
  getStaleValue,
  StaleIndicatorBadge,
  ArchivedIndicatorBadge,
  CustomAlarmBadge,
  CustomYellowAlarmBadge,
  CustomRedAlarmBadge,
} from './components/StatusIndicatorBadges';

import {
  getOrganisationMm2AvailablePreference,
  getOrganisationRmsAvailablePreference,
} from '../organisation/selectors';

// export connected columns
export { column as rms } from './columns/rms';
export { column as temperature } from './columns/temperature';

function Mm2Title({ rmsAvailable, mm2Available }) {
  return rmsAvailable && mm2Available
    ? 'Vibration (RMS < 160 Hz)'
    : 'Vibration (RMS)';
}

// connect the mm2 title to show to the currently logged in user
const ConnectedMm2Title = connect(state => ({
  mm2Available: getOrganisationMm2AvailablePreference(state),
  rmsAvailable: getOrganisationRmsAvailablePreference(state),
}))(Mm2Title);

// export constant columns
export const mm2 = {
  dataField: 'rms2',
  text: <ConnectedMm2Title />,
  icon: <IoIosPulse size="1.4em" />,
  headerFormatter,
  formatter: twoDigitsNumberFormatter,
  filterValue: twoDigitsNumberFormatter,
  sort: true,
  sortFunc: numericSortFunc,
};

// export constant columns
export const archived = {
  dataField: 'archived',
  text: 'Archived',
  headerFormatter,
  formatter: (value, device) => (
    <ArchivedIndicatorBadge device={device} />
  ),
  filterValue: archived => !archived ? 'active' : 'archived',
  sort: true,
};

export const status = {
  dataField: 'status',
  text: 'Status',
  headerFormatter,
  formatter: value => (
    <span className="text-nowrap">
      <StatusIndicator conditionValue={value}/>
    </span>
  ),
  filterValue: nullFormatter,
  sort: true,
  sortFunc: numericSortFunc,
};

export const calibration = {
  dataField: 'calibration',
  text: 'Learning progress (%)',
  icon: <IoIosSchool size="1.4em" />,
  headerFormatter,
  formatter: calibrationFormatter,
  filterValue: calibrationFormatter,
  sort: true,
  sortFunc: numericSortFunc,
};

export const siteName = {
  dataField: 'site_name',
  text: 'Site name',
  headerFormatter,
  formatter: textFormatter,
  sort: true,
  sortFunc: stringSortFunc,
  sortValue: stringSortValueWithHumanNumbersFunc,
};

export const subAreaName = {
  dataField: 'sub_area_name',
  text: 'Sub-area name',
  headerFormatter,
  formatter: textFormatter,
  sort: true,
  sortFunc: stringSortFunc,
  sortValue: stringSortValueWithHumanNumbersFunc,
};

export const equipmentName = {
  dataField: 'equipment_name',
  text: 'Equipment name',
  headerFormatter,
  // when returned in relations device id may be keyed under device_id
  formatter: (value, { device_id, id }) => (
    <Link to={`/equipment/${device_id || id}`}>{value || "N/A"}</Link>
  ),
  filterValue: textFormatter,
  sort: true,
  sortFunc: stringSortFunc,
  sortValue: stringSortValueWithHumanNumbersFunc,
};

export const staleness = {
  dataField: 'staleness',
  icon: <IoIosTime size="1.4em" />,
  text: 'Data age status',
  headerFormatter,
  formatter: (ignore, device) => (
    <StaleIndicatorBadge device={device} />
  ),
  style: { textAlign: 'right' },
  filterValue: nullFormatter,
  sort: true,
  sortValue: (ignore, device) => getStaleValue(device),
};

export const stalenessExpanded = {
  ...staleness,
  formatter: (ignore, device) => (
    <StaleIndicatorBadge device={device} expanded />
  ),
};

export const customAlarmThresholds = {
  dataField: 'threshold',
  icon: <IoIosAlarm size="1.4em" />,
  text: 'Custom alarm thresholds',
  headerFormatter,
  formatter: (ignore, device) => (
    <CustomAlarmBadge device={device} />
  ),
  style: { textAlign: 'right' },
  filterValue: nullFormatter,
  sort: true,
  sortValue: (ignore, device) => {
    // sort by number of custom thresholds
    return (
      device.yellow_threshold_changed ? 1 : 0
    ) + (
      device.red_threshold_changed ? 1 : 0
    );
  },
  // helper method for tables
  hasValue: device => (
    device.red_threshold_changed || device.yellow_threshold_changed
  ),
};

export const customAlarmThresholdsExpanded = {
  ...customAlarmThresholds,
  formatter: (ignore, device) => (
    <Fragment>
      <CustomYellowAlarmBadge device={device} expanded />
      <CustomRedAlarmBadge device={device} expanded />
    </Fragment>
  ),
};

export const conditionOverall = {
  dataField: 'condition_overall',
  text: 'Condition',
  tooltip: 'Condition (AI computed)',
  headerFormatter,
  formatter: value => (
    <Bar conditionValue={value} />
  ),
  filterValue: nullFormatter,
  sort: true,
  sortFunc: numericSortFunc,
};

export const degradation = {
  dataField: 'rate_overall',
  text: 'Degradation',
  tooltip: 'Degradation (AI computed)',
  headerFormatter,
  formatter: value => (
    <Bar conditionValue={value} />
  ),
  filterValue: nullFormatter,
  sort: true,
  sortFunc: numericSortFunc,
};

export const utilisationMonth = {
  dataField: 'utilisation_month',
  text: 'Utilisation (4 weeks)',
  icon: <IoIosCog size="1.4em" />,
  headerFormatter,
  formatter: percentageNumberFormatter,
  filterValue: percentageNumberFormatter,
  sort: true,
  sortFunc: numericSortFunc,
};

export const powerUsageMonth = {
  dataField: 'power_usage_month',
  text: 'Inferred power (4 weeks)',
  iconText: '(kWh)',
  icon: <IoIosFlash size="1.4em" />,
  headerFormatter,
  formatter: numberFormatterCreator(<PadLock />, 0),
  filterValue: zeroDigitsNumberFormatter,
  sort: true,
  sortFunc: numericSortFunc,
};

export const running = {
  dataField: 'running',
  text: 'Running status',
  icon: <RunningStatus value={false} />,
  headerFormatter,
  formatter: value => (
    <RunningStatus value={value} />
  ),
  // allow searching the word 'running'
  filterValue: running => running === true ? 'running' : null,
  sort: true,
  sortFunc: booleanSortFunc,
};

export const muteAdvisoryFor = {
  dataField: 'mute_advisory_for',
  text: 'Notifications status',
  icon: <IoIosNotifications size="1.4em" />,
  headerFormatter,
  formatter: value => (
    value === 0
      ? <IoIosNotifications size="1.4em" />
      : <IoIosNotificationsOff size="1.4em" />
  ),
  // allow searching the word 'muted'
  filterValue: value => value !== 0 ? 'muted' : null,
  sort: true,
  sortFunc: booleanSortFunc,
};

export const note = {
  dataField: 'note',
  text: 'Notes',
  headerFormatter,
  formatter: textFormatter,
  sort: true,
  sortFunc: stringSortFunc,
};

export const serial = {
  dataField: 'serial',
  text: 'MAC address',
  headerFormatter,
  // when returned in relations device id may be keyed under device_id
  formatter: (value, { device_id, id }) => (
    <Link to={`/devices/${device_id || id}`}>{value || "N/A"}</Link>
  ),
  filterValue: textFormatter,
  sort: true,
  sortFunc: stringSortFunc,
  sortValue: stringSortValueWithHumanNumbersFunc,
};

const fmIconStyle = {
  width: '1.3em',
  verticalAlign: '-20%',
  display: 'inline-block',
};

export const type = {
  dataField: 'fitmachine_type',
  text: 'Device Type',
  headerFormatter,
  // when returned in relations device id may be keyed under device_id
  formatter: (value, device={}) => {
    return (
      <span className="text-nowrap">
        <img
          src={getPlaceholderImageForDevice(device)}
          style={fmIconStyle}
          alt={value || "FitMachine"}
        /> {value || "FitMachine"}
      </span>
    );
  },
  filterValue: textFormatter,
  sort: true,
  sortFunc: stringSortFunc,
  sortValue: stringSortValueWithHumanNumbersFunc,
};

export const organisationName = {
  dataField: 'organisation_name',
  text: 'Organisation',
  headerFormatter,
  formatter: (value, { organisation_id }) => (
    <Link to={`/organisations/${organisation_id}`}>{value || "N/A"}</Link>
  ),
  filterValue: textFormatter,
  sort: true,
  sortFunc: stringSortFunc,
};

export const onboardedName = {
  dataField: 'onboarded_name',
  text: 'Onboarded by',
  headerFormatter,
  formatter: (value, { onboarded_by }) => onboarded_by && value ? (
    <Fragment>
      <Link to={`/users/${onboarded_by}`}><UserPicture userId={onboarded_by} /></Link>
      {' '}
      <Link to={`/users/${onboarded_by}`}>{value || "N/A"}</Link>
    </Fragment>
  ) : "N/A",
  filterValue: textFormatter,
  sort: true,
  sortFunc: stringSortFunc,
};

export const fmLastHeardHoursWarning = 24;
export const fmLastHeardHoursDanger = 48;
export const fmLastHeard = {
  dataField: 'fitmachine_last_heard',
  text: 'Last data processed',
  headerFormatter,
  formatter: relativeDateFormatter,
  filterValue: relativeDateFilter,
  sort: true,
  sortFunc: timeSortFunc,
};

export const fmGatewayLastHeard = {
  dataField: '_related.gateway.last_heard',
  text: 'Last heard through Gateway',
  headerFormatter,
  formatter: relativeDateFormatter,
  filterValue: relativeDateFilter,
  sort: true,
  sortFunc: timeSortFunc,
};

export const batteryVoltage = {
  dataField: 'battery_voltage',
  text: 'Battery voltage',
  tooltip: 'Battery voltage (V)',
  icon: <IoIosBatteryCharging size="1.4em" />,
  headerFormatter,
  formatter: twoDigitsNumberFormatter,
  filterValue: twoDigitsNumberFormatter,
  sort: true,
  sortFunc: numericSortFunc,
};

export const wifiSignal = {
  dataField: 'wifi_signal',
  text: 'WiFi signal',
  tooltip: 'WiFi signal',
  icon: <IoIosWifi size="1.4em" />,
  headerFormatter,
  formatter: zeroDigitsNumberFormatter,
  filterValue: zeroDigitsNumberFormatter,
  sort: true,
  sortFunc: numericSortFunc,
};

export const conditionVolume = {
  dataField: 'condition_volume',
  text: 'AI Volume',
  tooltip: 'Volume (AI computed)',
  headerFormatter,
  formatter: (ignore, { equipment_iso_class, rms }) => (
    <VolumeImage rms_value={rms} isoClass={equipment_iso_class}/>
  ),
  filterValue: nullFormatter,
  sort: true,
  sortFunc: numericSortFunc,
};

export const conditionVibration = {
  dataField: 'condition_vibration',
  text: 'AI Tone',
  tooltip: 'Tone (AI computed)',
  headerFormatter,
  formatter: value => (
    <ToneImage vibration_condition={value} />
  ),
  filterValue: nullFormatter,
  sort: true,
  sortFunc: numericSortFunc,
};

export const conditionTemperature = {
  dataField: 'condition_temperature',
  text: 'AI Temperature',
  tooltip: 'Temperature (AI computed)',
  headerFormatter,
  formatter: value => (
    <TemperatureImage temperature_condition={value} />
  ),
  filterValue: nullFormatter,
  sort: true,
  sortFunc: numericSortFunc,
};
