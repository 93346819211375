import React, { Fragment, useState, useEffect, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import { Tree } from '@blueprintjs/core';
import { Row, Col, Container, Image, Dropdown, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { withRouter } from 'react-router-dom';
import objectFitImages from 'object-fit-images';
import {
  IoIosClose,
  IoIosCreate,
  IoIosMenu,
  IoIosAddCircle,
  IoIosAddCircleOutline,
  IoIosCube,
  IoIosTrash,
} from 'react-icons/io';

import './sidebar.scss';
import defaultLogo from '../images/logo.svg';

import history from '../history';

import { logout } from '../modules/user/actions';
import {
  selectActiveGroup,
  fetchGroup,
  fetchOrganisations,
  fetchOrganisationWithId,
  fetchOrganisationGroups,
}  from '../modules/organisation/actions';
import {
  getOrganisations,
  getOrganisation,
  getOrganisationGroup,
  getNearestGroupOrganisationAncestor,
  getActiveGroupId,
  isStandardOrganisation,
  getGroup,
} from '../modules/organisation/selectors';
import {
  isUserLoggedIn,
  isAdmin,
} from '../modules/user/selectors';

import { Footer } from './Footer';

import AddGroupFormModal from '../modules/organisation/components/AddGroupFormModal';
import EditGroupFormModal from '../modules/organisation/components/EditGroupFormModal';
import RemoveGroupConfirmModal from '../modules/organisation/components/RemoveGroupConfirmModal';

function SidebarLogo({ src }) {

  // fit images
  useEffect(() => {
    objectFitImages('img.sidebar__logo');
  }, [src]);

  // return the sidebar with just the logo
  // mainly for the purposes of navigation
  return (
    <LinkContainer to="/home" style={{ cursor: 'pointer' }}>
      <Image
        className="sidebar__logo object-fit-contain w-auto"
        src={src || defaultLogo}
      />
    </LinkContainer>
  );
}

function GroupDropdownButton({ bsPrefix, ...selectedProps }) {
  // pass all generated props except bsPrefix:
  // should contain: className, onClick, aria-haspopup, aria-expanded
  // (may contain other aria props in the future)
  return <IoIosMenu size="1.2em" {...selectedProps} />;
};

// fix the position of the group dropdown menu to be slightly offset:
// to look as if the control belongs more to the group *row* than
// the toggle icon button
const alignSlightlyOffsetFromTheRight = {
  placement: 'bottom-end',
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [16, 0],
      },
    },
  ],
};

function GroupContextMenu({ userCanViewGroups, userCanEditGroups, groupId, groupType, visible }) {
  return userCanViewGroups && (
    <Dropdown className={visible ? 'visible' : 'must-hover'}>
      <Dropdown.Toggle as="span">
        <GroupDropdownButton />
      </Dropdown.Toggle>
      <Dropdown.Menu popperConfig={alignSlightlyOffsetFromTheRight}>
        {userCanEditGroups && (
          <Fragment>
            <AddGroupFormModal groupId={groupId}>
              <Dropdown.Item>
                <IoIosAddCircle size="1.2em" /> <span>Add group</span>
              </Dropdown.Item>
            </AddGroupFormModal>
            {groupType === 'group' && (
              <Fragment>
                <EditGroupFormModal groupId={groupId}>
                  <Dropdown.Item>
                    <IoIosCreate size="1.2em" /> <span>Rename group</span>
                  </Dropdown.Item>
                </EditGroupFormModal>
                <RemoveGroupConfirmModal groupId={groupId}>
                  <Dropdown.Item>
                    <IoIosTrash size="1.2em" /> <span>Remove group</span>
                  </Dropdown.Item>
                </RemoveGroupConfirmModal>
              </Fragment>
            )}
          </Fragment>
        )}
        <LinkContainer to="/group/devices">
          <Dropdown.Item>
            <IoIosCube size="1.2em" /> <span>Manage group equipment</span>
          </Dropdown.Item>
        </LinkContainer>
      </Dropdown.Menu>
    </Dropdown>
  );
}

const ConnectedGroupContextMenu = connect(state => {
  const userIsAdmin = isAdmin(state);
  return {
    userCanViewGroups: userIsAdmin,
    userCanEditGroups: userIsAdmin && !!isStandardOrganisation(state),
  };
})(GroupContextMenu);

// recursively find active node
function findActiveNode(node) {
  return node.isSelected
    ? node
    : node.childNodes
      ? node.childNodes.find(findActiveNode)
      : undefined;
}

// recursive reduce a list of parent node ids
function listParentNodeIds(acc=[], node) {
  return [
    ...acc,
    node.id,
    ...node.childNodes ? node.childNodes.reduce(listParentNodeIds, []) : [],
  ];
}

// translate a group
function getGroupStateFromGroup({ id, name, type, members=[] }={}, {
  isRootNode = false,
  expandedIds = [],
  selectedId,
}={}) {
  // translate group redux state to group tree state
  const groupState = {
    // add required information
    id,
    label: `${isRootNode ? `${name} (All Equipment)` : name}`.trim(),
    // append extra information
    nodeData: {
      type,
    },
    // add context menu
    secondaryLabel: (
      <ConnectedGroupContextMenu
        groupId={id}
        groupType={type}
        visible={selectedId === id}
      />
    ),
    // add children if needed
    ...members.length && {
      childNodes: members
        .map(group => getGroupStateFromGroup(group, { expandedIds, selectedId }))
        .sort(({ label: labelA='' }, { label: labelB='' }) => labelA.localeCompare(labelB)),
    },
  };

  // add tree node statuses
  groupState.isSelected = selectedId === groupState.id;
  // if at least one child node is selected or is ancestor of selected, then mark as ancestor
  groupState.isSelectedAncestor = !!groupState.childNodes && groupState.childNodes.some(group => {
    return group.isSelected || group.isSelectedAncestor;
  });
  // force ancestor nodes to be expanded, if not already expanded
  groupState.isExpanded = expandedIds.includes(groupState.id) || groupState.isSelectedAncestor;

  // add visual information
  if (groupState.isSelectedAncestor) {
    groupState.className = "tree__node--ancestor-of-selected";
  }

  return groupState;
}

function getGroupsStateFromGroup(group, expandedIds, selectedId) {
  if (group && group.members) {
    // place inside an array
    return [getGroupStateFromGroup(group, { expandedIds, selectedId, isRootNode: true })];
  }
  else {
    return [];
  }
}

function Sidebar({
  pathname,
  group,
  nearestOrganisationGroup = {},
  nearestOrganisationGroupNode = {},
  // default active group to the top-level group
  activeGroupId = group && group.id,
  userIsLoggedIn,
  userCanEditGroups,
  organisations,
  currentOrganisation,
  toggleExpanded,
  selectActiveGroup,
  fetchGroup,
  fetchOrganisationGroups,
}) {

  // fetch nearest organisation group if not known
  useEffect(() => {
    if (nearestOrganisationGroupNode.id && !nearestOrganisationGroup.id) {
      fetchGroup(nearestOrganisationGroupNode);
    }
  }, [nearestOrganisationGroup.id, nearestOrganisationGroupNode.id]);

  // fetch organisations list if not available
  useEffect(() => {
    if (userIsLoggedIn && !organisations) {
      fetchOrganisations();
    }
  }, [userIsLoggedIn, !!organisations]);

  // fetch organisation logo if not available
  useEffect(() => {
    if (userIsLoggedIn && currentOrganisation && currentOrganisation.logo_url === undefined) {
      fetchOrganisationWithId(currentOrganisation);
    }
  }, [userIsLoggedIn, currentOrganisation && currentOrganisation.logo_url]);

  // fetch groups on first load
  useEffect(() => {
    if (currentOrganisation && currentOrganisation.id) {
      fetchOrganisationGroups(currentOrganisation);
    }
  }, [currentOrganisation && currentOrganisation.id]);

  const [expandedGroupIds, setExpandedGroupIds] = useState([]);
  const [expandedOnFirstLoad, setExpandedOnFirstLoad] = useState(false);

  // on top-level group loaded, select and expand top-level
  useEffect(() => {
    if (group && !expandedOnFirstLoad) {
      setExpandedOnFirstLoad(true);
      const activeNode = groupState.find(findActiveNode);
      setExpandedGroupIds([
        group.id,
        ...activeNode ? [activeNode].reduce(listParentNodeIds, []) : [],
      ]);
    }
  }, [group, expandedOnFirstLoad]);

  // use state derived from current state and available groupMembers
  const [groupState, setGroupState] = useState(() => {
    return getGroupsStateFromGroup(group, expandedGroupIds, activeGroupId);
  });

  // if the found groupMembers or node state changes, then update the tree state
  useEffect(() => {
    setGroupState(getGroupsStateFromGroup(group, expandedGroupIds, activeGroupId));
  }, [group, expandedGroupIds, activeGroupId]);

  const toggleGroupExpanded = useCallback(node => {
    // collapse group
    if (expandedGroupIds.includes(node.id)) {
      // if node is an ancestor of the selected group, then make this the new selection
      if (node.isSelectedAncestor) {
        selectActiveGroup({ id: node.id });
      }
      // then collapse group
      setExpandedGroupIds(expandedGroupIds.filter(id => id !== node.id));
    }
    // expand group
    else {
      setExpandedGroupIds([...expandedGroupIds, node.id]);
    }
  }, [expandedGroupIds]);

  const selectGroup = useCallback((node, nodePath, e) => {
    // only action on this click if the clicked element is not the context toggle
    if (!e.target.classList.contains('dropdown-toggle')) {
      // select the active group in Redux
      selectActiveGroup({ id: node.id });
      // navigate the user conditionally
      const nextPathname = (() => {
        switch (pathname) {
          // on certain pages do not navigate
          case '/home':
          case '/equipment/map':
          case '/devices/admin':
          case '/gateways/admin':
          case '/organisations/admin':
          case '/users/admin':
          case '/alarms/list':
          case '/alarms':
          case '/developer/admin':
          case '/developer/admin/tokens':
          case '/developer/admin/streaming':
            return null;
          // but by default, go to the equipment list
          default:
            return '/equipment/list';
        }
      })();
      if (nextPathname && pathname !== nextPathname) {
        history.push({ pathname: nextPathname });
      }
    }
  }, [pathname]);

  // append title attributes to group labels
  const treeRef = useRef(null);
  useEffect(() => {
    const tree = treeRef.current;
    // if the tree is rendered and has rendered nodes
    // then tag the group title text onto the HTML elements
    if (tree && tree.nodeRefs) {
      Object.values(tree.nodeRefs).forEach(el => {
        // update only if needed
        if (el.title !== el.innerText) {
          el.title = el.innerText;
        }
      });
    }
  }, [treeRef.current, groupState]); // update when tree node or tree data changes

  return (
    <div className="sidebar__full-width">
      <button className="sidebar__background" onClick={toggleExpanded}>
        <IoIosClose size="4em"/>
      </button>
      <div className="sidebar__body">
        <div className="sidebar__inner d-flex flex-column h-100">
          <Container>
            <Row className="mt-1 pt-1 mb-1">
              <Col xs={12}>
                <SidebarLogo src={currentOrganisation && currentOrganisation.logo_url} />
              </Col>
            </Row>
          </Container>
          <div className="sidebar__inner-scroll py-2">
            <Container className="groups-and-views">
              <Row className="my-4">
                <Col className="title mt-1" xs="auto">
                  <h5>Groups</h5>
                </Col>
                {userCanEditGroups && activeGroupId && (
                  <Col className="title ml-auto" xs="auto">
                    <AddGroupFormModal groupId={activeGroupId}>
                      <Button className="pb-0" size="sm">
                        <IoIosAddCircleOutline size="1.4em" className="mb-1" /> <span>Add</span>
                      </Button>
                    </AddGroupFormModal>
                  </Col>
                )}
                <Col className="groups-and-views__groups" xs={12}>
                  <Tree
                    className="my-1"
                    ref={treeRef}
                    contents={groupState}
                    onNodeClick={selectGroup}
                    onNodeExpand={toggleGroupExpanded}
                    onNodeCollapse={toggleGroupExpanded}
                  />
                </Col>
              </Row>
            </Container>
          </div>
          <Footer className="visible d-none d-md-block"/>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state, { location }) => {
  const organisations = getOrganisations(state);
  const currentOrganisation = getOrganisation(state);
  const group = getOrganisationGroup(state);
  const userIsAdmin = isAdmin(state);
  const activeGroupId = getActiveGroupId(state);
  const nearestOrganisationGroupNode = getNearestGroupOrganisationAncestor(state, activeGroupId);
  const nearestOrganisationGroup = nearestOrganisationGroupNode && getGroup(state, nearestOrganisationGroupNode.id);
  return {
    // return top level path name if any
    pathname: location.pathname,
    userIsLoggedIn: isUserLoggedIn(state),
    userCanViewGroups: userIsAdmin,
    userCanEditGroups: userIsAdmin && !!isStandardOrganisation(state),
    organisations,
    currentOrganisation,
    nearestOrganisationGroupNode,
    nearestOrganisationGroup,
    group,
    activeGroupId,
  };
};

const mapDispatchToProps = {
  logout,
  selectActiveGroup,
  fetchOrganisations,
  fetchOrganisationWithId,
  fetchOrganisationGroups,
  fetchGroup,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sidebar));
